import {
  DeliveryRequestListResponse,
  PaginatedListResponseDeliveryRequestListResponse,
  amsV3Service
} from '../../services';
import { IAsyncType, useAsync } from '.';

export interface IDeliveryRequests<T> extends IAsyncType<T> {
  deliveryRequests: T;
}

const useDeliveryRequests = (
  ids?: number[],
  supplierIds?: number[],
  warehouseIds?: number[],
  fromDate?: string,
  toDate?: string
): IDeliveryRequests<DeliveryRequestListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseDeliveryRequestListResponse>(
      amsV3Service.getDeliveryRequests,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [ids, undefined, undefined, fromDate, toDate, supplierIds, warehouseIds]
    );
  return {
    deliveryRequests: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useDeliveryRequests;
