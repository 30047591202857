import { Collapse, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useMemo } from 'react';

import { AccessPermissions } from '../../../../services';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { MenuItem } from '../listItems';
import { useHistory } from 'react-router';

interface MenuItemContainerProps {
  menuItem: MenuItem;
  selected: string;
  setSelected: (selected: string) => void;
  permissions: AccessPermissions[];
}

const MenuItemContainer = ({
  menuItem,
  selected,
  setSelected,
  permissions
}: MenuItemContainerProps) => {
  const history = useHistory();
  const hasChildSelected = useMemo(
    () => (menuItem.children ?? []).findIndex((child: MenuItem) => child.route === selected) >= 0,
    [menuItem, selected]
  );

  const availableChildren: MenuItem[] = useMemo(
    () =>
      menuItem.children?.filter((item: MenuItem) =>
        item.permissions.some((permission) => permissions.includes(permission))
      ) ?? [],
    [menuItem, permissions]
  );
  return (
    <div key={menuItem.route}>
      <ListItem
        key={menuItem.route}
        button
        onClick={() => {
          history.push(menuItem.route);
          if (selected === menuItem.route || hasChildSelected) {
            setSelected('/');
          } else {
            setSelected(menuItem.route);
          }
        }}
        selected={selected === menuItem.route}
        className="menu-item"
      >
        <ListItemIcon>{menuItem.icon}</ListItemIcon>
        <ListItemText primary={menuItem.label} />
        {availableChildren.length > 0 && (
          <ListItemIcon>
            {selected === menuItem.route || hasChildSelected ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </ListItemIcon>
        )}
      </ListItem>
      {availableChildren.length > 0 && (
        <Collapse in={selected === menuItem.route || hasChildSelected} timeout="auto" unmountOnExit>
          {availableChildren.map((child: any) => (
            <ListItem
              key={child.route}
              button
              disabled={child.disabled}
              onClick={() => {
                history.push(child.route);
                setSelected(child.route);
              }}
              selected={selected === child.route}
              className="menu-item"
            >
              <ListItemIcon style={{ marginLeft: 16 }}>{child.icon}</ListItemIcon>
              <ListItemText primary={child.label} />
            </ListItem>
          ))}
        </Collapse>
      )}
    </div>
  );
};

export default MenuItemContainer;
