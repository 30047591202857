import { OrderAggregatedNumbers, orderStatusesInProgress } from './Home';
import {
  OrderItemV2Response,
  OrderV2Response,
  ReportDepletedAmountWithPrices
} from '../../services/api';
import { OrderStatusKeys, OrderTypeIds } from '../../services';
import React, { useEffect, useMemo } from 'react';
import { getFirstDayOfWeek, toEndOfDay, toStartOfDay } from '../../helpers/date-helper';
import { useOrders, useReportDetailedTurnover } from '../../helpers/hooks';

import FinanceInfoComponent from './FinanceInfoComponent';
import { Grid } from '@material-ui/core';
import { getExtendedOrderId } from '../../helpers/utils';

interface HomeProfitRatioReportProps {
  fromDate: Date | null;
  toDate: Date | null;
}

const HomeProfitRatioReport = ({ fromDate, toDate }: HomeProfitRatioReportProps) => {
  const { orders, setOrdersParameters } = useOrders(
    orderStatusesInProgress,
    undefined,
    undefined,
    fromDate ? toStartOfDay(fromDate) : undefined,
    toDate ? toEndOfDay(toDate) : undefined,
    undefined,
    undefined,
    true
  );

  const { reportDetailedTurnover, setReportDetailedTurnoverParameters } = useReportDetailedTurnover(
    fromDate ? toStartOfDay(fromDate) : toStartOfDay(getFirstDayOfWeek(new Date())),
    toDate ? toEndOfDay(toDate) : toEndOfDay(new Date()),
    [],
    []
  );

  useEffect(() => {
    setOrdersParameters([
      orderStatusesInProgress,
      undefined,
      undefined,
      fromDate ? toStartOfDay(fromDate) : undefined,
      toDate ? toEndOfDay(toDate) : undefined,
      undefined,
      undefined,
      true
    ]);
    setReportDetailedTurnoverParameters([
      fromDate ? toStartOfDay(fromDate) : toStartOfDay(getFirstDayOfWeek(new Date())),
      toDate ? toEndOfDay(toDate) : toEndOfDay(new Date()),
      [],
      []
    ]);
  }, [fromDate, toDate, setOrdersParameters, setReportDetailedTurnoverParameters]);

  const ordersByPartner = useMemo(
    () =>
      orders.filter(
        (order: OrderV2Response) =>
          order.orderType !== OrderTypeIds.B2B || order.orderType === OrderTypeIds.B2B
      ),
    [orders]
  );

  const ordersInfo: OrderAggregatedNumbers[] = useMemo(
    () =>
      ordersByPartner.map((order: OrderV2Response) => ({
        label: `${getExtendedOrderId(order.id)} ${order.receiverName}`,
        receiverId: order.receiverId,
        receiverName: order.receiverName ?? '',
        forPicking:
          order.statusKey === OrderStatusKeys.PRELIMINARY ||
          order.statusKey === OrderStatusKeys.FOR_PICKING ||
          order.statusKey === OrderStatusKeys.PICKING_IN_PROGRESS ||
          order.statusKey === OrderStatusKeys.FOR_CLARIFICATION ||
          order.statusKey === OrderStatusKeys.CLARIFICATION_IN_PROGRESS
            ? order.items?.filter((orderItem: OrderItemV2Response) => !orderItem.collected)
                .length ?? 0
            : 0,
        collected:
          order.statusKey === OrderStatusKeys.FOR_PICKING ||
          order.statusKey === OrderStatusKeys.PICKING_IN_PROGRESS ||
          order.statusKey === OrderStatusKeys.FOR_CLARIFICATION ||
          order.statusKey === OrderStatusKeys.CLARIFICATION_IN_PROGRESS ||
          order.statusKey === OrderStatusKeys.FOR_INSPECTION ||
          order.statusKey === OrderStatusKeys.INSPECTION_IN_PROGRESS ||
          order.statusKey === OrderStatusKeys.FOR_SHIPPING
            ? order.items?.filter(
                (orderItem: OrderItemV2Response) =>
                  orderItem.collected && !orderItem.receivedQuantity
              ).length ?? 0
            : 0,
        delivered:
          order.statusKey === OrderStatusKeys.SHIPPED ||
          order.statusKey === OrderStatusKeys.RECEIVED_WITH_REMARKS ||
          order.statusKey === OrderStatusKeys.DELIVERED ||
          order.statusKey === OrderStatusKeys.AWAITING_PAYMENT ||
          order.statusKey === OrderStatusKeys.FINISHED_SUCCESSFULLY
            ? order.items?.length ?? 0
            : 0,
        totalItems: order.items?.length ?? 0
      })),
    [ordersByPartner]
  );

  const incomeTotal = useMemo(
    () =>
      Math.round(
        (reportDetailedTurnover.reduce(
          (total: number, reportItem: ReportDepletedAmountWithPrices) =>
            total + reportItem.itemQuantity * reportItem.orderItemPrice,
          0
        ) +
          Number.EPSILON) *
          100
      ) / 100,
    [reportDetailedTurnover]
  );

  const expensesTotal = useMemo(
    () =>
      Math.round(
        (reportDetailedTurnover.reduce(
          (total: number, reportItem: ReportDepletedAmountWithPrices) =>
            total + reportItem.itemQuantity * reportItem.deliveryItemPrice,
          0
        ) +
          Number.EPSILON) *
          100
      ) / 100,
    [reportDetailedTurnover]
  );

  const orderAverage = useMemo(
    () =>
      ordersInfo.length > 0
        ? Math.round((incomeTotal / ordersInfo.length + Number.EPSILON) * 100) / 100
        : 0,
    [ordersInfo, incomeTotal]
  );

  const orderItemAverage = useMemo(
    () =>
      reportDetailedTurnover.length > 0
        ? Math.round((incomeTotal / reportDetailedTurnover.length + Number.EPSILON) * 100) / 100
        : 0,
    [incomeTotal, reportDetailedTurnover]
  );

  return (
    <Grid container spacing={2} style={{ padding: '50px 20px 10px 20px ' }}>
      <Grid item lg={6}>
        <FinanceInfoComponent
          items={[
            { title: '', amount: incomeTotal, subtitle: 'лв' },
            {
              title: ordersByPartner.length > 1 ? 'Поръчки' : 'Поръчка',
              amount: ordersByPartner.length
            },
            {
              title: 'На поръчка',
              amount: !isNaN(orderAverage) ? orderAverage : 0,
              subtitle: 'лв'
            }
          ]}
        />
      </Grid>
      <Grid item lg={6}>
        <FinanceInfoComponent
          items={[
            {
              title: 'Средно за позиция',
              amount: !isNaN(orderItemAverage) ? orderItemAverage : 0,
              subtitle: 'лв'
            }
          ]}
        />
      </Grid>
      <Grid item lg={6}>
        <FinanceInfoComponent
          items={[{ title: 'Баланс', amount: incomeTotal - expensesTotal, subtitle: 'лв' }]}
        />
      </Grid>
      <Grid item lg={6}>
        <FinanceInfoComponent
          items={[{ title: 'Разходи', amount: expensesTotal, subtitle: 'лв' }]}
        />
      </Grid>
    </Grid>
  );
};
export default HomeProfitRatioReport;
