import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';
import { ItemDeliverySubscriptionResponse, amsV3Service } from '../../services';

export interface ISubscription<T> extends IAsyncType<T> {
  subscription: T;
  setSubscription: Dispatch<SetStateAction<T>>;
}

const useItemDeliverySubscription = (
  id?: number
): ISubscription<ItemDeliverySubscriptionResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<ItemDeliverySubscriptionResponse | null>(
      amsV3Service.getItemDeliverySubscription,
      null,
      id ? [id] : null
    );
  return {
    subscription: data,
    setSubscription: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useItemDeliverySubscription;
