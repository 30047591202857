import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponsePaymentOptionResponse,
  PaymentOptionResponse,
  amsV3Service
} from '../../services';

export interface IPaymentOptions<T> extends IAsyncType<T> {
  paymentOptions: T;
}

const usePaymentOptions = (): IPaymentOptions<PaymentOptionResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponsePaymentOptionResponse>(
      amsV3Service.getPaymentOptions,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [],
      true
    );
  return {
    paymentOptions: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default usePaymentOptions;
