import { BrowserView, MobileView } from 'react-device-detect';
import { Link, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';

const Copyright = () => {
  return (
    <Fragment>
      <BrowserView>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://coshera.com">
            Coshera
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </BrowserView>
      <MobileView>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://coshera.com">
            Coshera
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </MobileView>
    </Fragment>
  );
};

export default Copyright;
