import { Container, Grid, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { SupplierReserveSubscriptionResponse, amsV3Service } from '../../../services';
import { WeekdayKey, getWeekdaysByKey } from '../../../helpers/date-helper';
import { useSupplierReserveSubscriptions, useSuppliers, useUsers } from '../../../helpers/hooks';

import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    root: { marginLeft: 0 }
  })
);

const SupplierReserveSubscriptionsComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  const { supplierReserveSubscriptions, setParameters, loading, setLoading } =
    useSupplierReserveSubscriptions([], []);
  const { suppliers } = useSuppliers();
  const { users } = useUsers();

  const handleAdd = useCallback(() => history.push(`/supplier-reserve-subscription`), [history]);

  const handleEdit = useCallback(
    (ids: SupplierReserveSubscriptionResponse) =>
      history.push(`/supplier-reserve-subscription?id=${ids.id}`),
    [history]
  );

  const handleDelete = async (supplierReserveSubscription: SupplierReserveSubscriptionResponse) => {
    setLoading(true);
    if (supplierReserveSubscription && supplierReserveSubscription.id) {
      await amsV3Service.deleteSupplierReserveSubscription(supplierReserveSubscription.id);
      setParameters(
        supplierReserveSubscriptions.filter(
          (s: SupplierReserveSubscriptionResponse) => s.id !== supplierReserveSubscription.id
        )
      );
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AMSTable
            title="Складов резерв"
            columns={[
              {
                title: 'Доставчик',
                cellStyle: { width: '20%' },
                field: 'supplierId',
                render: (rowData: SupplierReserveSubscriptionResponse) => {
                  const supplier = suppliers.find((s) => s.id === rowData.supplierId);
                  return supplier ? supplier.name : 'Неактивен доставчик';
                }
              },
              {
                title: 'Дни от седмицата',
                field: 'weekdays',
                render: (rowData: SupplierReserveSubscriptionResponse) =>
                  rowData.weekdays
                    .map((weekday) => getWeekdaysByKey(weekday as WeekdayKey).label)
                    .join(', '),
                cellStyle: { width: '20%' }
              },
              {
                title: 'Основа',
                field: 'baseDays',
                cellStyle: { width: '10%' }
              },
              {
                title: 'Запас',
                field: 'reserveDays',
                cellStyle: { width: '10%' }
              },
              {
                title: 'Създаден от',
                field: 'createdBy',
                render: (rowData: SupplierReserveSubscriptionResponse) => {
                  const createdBy = users.find((u) => u.id === rowData.lastUpdatedById);
                  return createdBy ? createdBy.name : 'Неактивен потребител';
                },
                cellStyle: { width: '15%' }
              },
              {
                title: 'Последна промяна',
                field: 'lastUpdatedBy',
                type: 'date',
                render: (rowData: SupplierReserveSubscriptionResponse) => {
                  const lastUpdatedBy = users.find((u) => u.id === rowData.lastUpdatedById);
                  const lastUpdatedByName = lastUpdatedBy
                    ? lastUpdatedBy.name
                    : 'Неактивен потребител';
                  const updateInfo = `${lastUpdatedByName}, ${moment(rowData.lastUpdatedAt).format(
                    'DD/MM/yy, hh:mm'
                  )}`;
                  return (
                    <Tooltip title={rowData.comment ?? ''}>
                      <div>{updateInfo}</div>
                    </Tooltip>
                  );
                },
                cellStyle: { width: '15%' }
              }
            ]}
            data={supplierReserveSubscriptions}
            onAdd={handleAdd}
            onEdit={handleEdit}
            onDelete={handleDelete}
            isLoading={loading}
          />
        </Grid>
      </Grid>
      {}
    </Container>
  );
};

export default SupplierReserveSubscriptionsComponent;
