import {
  ActOfAcceptanceItemResponse,
  PaginatedListResponseActOfAcceptanceItemResponse,
  amsV3Service
} from '../../services';
import { IAsyncType, useAsync } from '.';

export interface IActOfAcceptanceItems<T> extends IAsyncType<T> {
  actOfAcceptanceItems: T;
}

const useActOfAcceptanceItems = (
  actOfAcceptanceId: number
): IActOfAcceptanceItems<ActOfAcceptanceItemResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseActOfAcceptanceItemResponse>(
      amsV3Service.getActOfAcceptanceItems,
      {
        data: [],
        total: 0,
        page: 1,
        size: 100
      },
      [actOfAcceptanceId],
      true
    );
  return {
    actOfAcceptanceItems: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useActOfAcceptanceItems;
