import { Button, CircularProgress, Theme, createStyles, makeStyles } from '@material-ui/core';

import React from 'react';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonCircularProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  })
);

interface AMSButtonProps {
  text: string;
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
  variant?: 'text' | 'outlined' | 'contained';
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  style?: any;
  className?: any;
  fullWidth?: boolean;
}

const AMSButton = ({
  text,
  color,
  variant,
  onClick,
  disabled = false,
  loading = false,
  style,
  className,
  fullWidth = false
}: AMSButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={onClick}
      style={style}
      className={className}
      fullWidth={fullWidth}
    >
      {text}
      {loading && <CircularProgress size={24} className={classes.buttonCircularProgress} />}
    </Button>
  );
};

export default AMSButton;
