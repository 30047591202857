import { DeliveryRequestResponse, amsV3Service } from '../../services';

import { useAsync } from '.';

const useDeliveryRequest = (id?: number) => {
  const { data, setData, loading } = useAsync<DeliveryRequestResponse | null>(
    amsV3Service.getDeliveryRequest,
    null,
    id ? [id] : null
  );
  return {
    deliveryRequest: data,
    setDeliveryRequest: setData,
    loading
  };
};

export default useDeliveryRequest;
