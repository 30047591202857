import { createMuiTheme } from '@material-ui/core/styles';

export const mainColor = '#3f51b5';
export const secondaryColor = '#F65B40';

const CustomTheme = createMuiTheme({
  palette: {
    primary: {
      main: mainColor,
      light: mainColor,
      dark: mainColor,
      contrastText: '#fff'
    },
    error: {
      main: '#FD9728'
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        '@media (max-height: 768px), (max-width: 1820px)': {
          padding: 9,
          fontSize: '0.75rem !important'
        }
      },
      footer: {
        left: 0,
        bottom: 0,
        zIndex: 2,
        position: 'sticky'
      }
    },
    MuiTableRow: {
      root: {
        '&:hover:nth-child(even)': {
          backgroundColor: '#F0F0F0 !important'
        },
        '&:hover:nth-child(odd)': {
          backgroundColor: '#F0F0F0 !important'
        },
        '&:nth-child(even)': {
          backgroundColor: '#F8F8F8'
        },
        '&:nth-child(odd)': {
          backgroundColor: '#FFFFFF'
        }
      },
      footer: {
        '&:hover.MuiTableRow-footer': {
          backgroundColor: '#FFFFFF !important'
        }
      }
    },
    MuiListItemText: {
      root: {
        marginTop: 1,
        marginBottom: 1
      },
      primary: {
        fontSize: 14
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36,
        maxWidth: 36
      }
    },
    MuiInputLabel: {
      marginDense: {
        '@media (max-height: 768px), (max-width: 1820px)': {
          transform: 'translate(12px, 10px) scale(1)'
        }
      }
    }
    // MuiAlert: {
    //   standardSuccess: {
    //     backgroundColor: '#4CAF50'
    //   }
    // }
  }
});

export default CustomTheme;
