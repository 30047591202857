import { CircularProgress, Container, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import SupplierInfoComponent from './Tabs/SupplierInfoComponent/SupplierInfoComponent';
import { parseQuery } from '../../helpers/url';
import { useSupplier } from '../../helpers/hooks';

const SupplierComponent = ({ location }: { location: any }) => {
  const { id } = parseQuery(location.search);

  const { supplier, loading } = useSupplier(id);
  const [formSupplier, setFormSupplier] = useState<any>({ isActive: true });

  useEffect(() => {
    setFormSupplier(supplier ? supplier : { isActive: true });
  }, [supplier]);

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="xl">
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Typography component="h5" variant="h5">
          {formSupplier?.name ?? 'Нов доставчик'}
        </Typography>
      </div>
      <SupplierInfoComponent supplier={formSupplier} setSupplier={setFormSupplier} />
    </Container>
  );
};

export default SupplierComponent;
