import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CompanyResponse } from '../../services/api-v3/api';
import { amsV3Service } from '../../services/services';

export interface ICompany<T> extends IAsyncType<T> {
  company: T;
  setCompany: Dispatch<SetStateAction<T>>;
}

const useCompany = (id: number): ICompany<CompanyResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<CompanyResponse | null>(amsV3Service.getCompany, null, id ? [id] : null);
  return {
    company: data,
    setCompany: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useCompany;
