import { Link } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../../hooks';

const LEFT_MOUSE_CLICK = 0;

interface COSLinkProps {
  children?: React.ReactNode;
  href: string;
  style?: React.CSSProperties;
}

const AMSLink = ({ children, href, style }: COSLinkProps) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Link
      href={href}
      className={classes.link}
      style={{ color: '#2c44c8', ...style }}
      onClick={(e: any) => {
        if (
          e.metaKey ||
          e.button !== LEFT_MOUSE_CLICK ||
          href.startsWith('mailto') ||
          href.startsWith('tel')
        ) {
          return;
        }
        e.preventDefault();
        history.push(href);
      }}
    >
      {children}
    </Link>
  );
};

export default AMSLink;
