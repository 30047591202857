import {
  CompanyResponse,
  CreateDeliveryDocumentRequest,
  DeliveryDocumentResponse,
  DeliveryDocumentType,
  DeliveryDocumentTypes,
  DeliveryItemResponse,
  DeliveryResponse,
  ItemListResponse,
  SupplierResponse,
  UpdateDeliveryDocumentRequest,
  amsV3Service
} from '../../../../../services';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ignoreOffset, isValidDate, toDateString } from '../../../../../helpers/date-helper';

import AMSAutocomplete from '../../../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../../../helpers/ui/AMSButton/AMSButton';
import DateFnsUtils from '@date-io/date-fns';
import { DocumentOption } from '../../../../OrderComponent/Panels/OrderInvoiceAndTransactionsPanel/Dialog/AddTransactionDialog';
import InputMask from 'react-input-mask';
import { pad } from '../../../../../helpers/utils';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontWeight: 'bold'
    },
    typeWrapper: {
      paddingTop: 8
    },
    button: {
      marginTop: 8
    }
  })
);

interface DeliveryDocumentDialogProps {
  open: boolean;
  onClose: (deliveryDocument: DeliveryDocumentResponse | null) => void;
  delivery: DeliveryResponse;
  deliveryItems: DeliveryItemResponse[];
  company: CompanyResponse;
  supplier: SupplierResponse;
  deliveryDocument: DeliveryDocumentResponse | null;
  documents: DeliveryDocumentResponse[];
  totalPrice: number;
}

const DeliveryDocumentDialog = ({
  open,
  onClose,
  delivery,
  deliveryItems,
  company,
  supplier,
  deliveryDocument,
  documents,
  totalPrice
}: DeliveryDocumentDialogProps) => {
  const classes = useStyles();
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(new Date());
  const [invoiceTotal, setInvoiceTotal] = useState<string>('');
  const [paymentDue, setPaymentDue] = useState<Date | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<string>('');
  const [tradeDocumentNumber, setTradeDocumentNumber] = useState<string>('');
  const [actionLoading, setActionLoading] = useState(false);
  const [deliveryDocumentType, setDeliveryDocumentType] = useState(DeliveryDocumentTypes.INVOICE);
  const [documentOption, setDocumentOption] = useState<DocumentOption | null>(null);
  const [creditNoteValues, setCreditNoteValues] = useState<any>({});

  const invoiceBalance = useMemo(
    () =>
      documents.length > 0
        ? documents.reduce((result: number, document: DeliveryDocumentResponse) => {
            if (document.documentType === DeliveryDocumentTypes.CREDIT_NOTE) {
              return result - +document.invoiceTotal;
            } else {
              return result + +document.invoiceTotal;
            }
          }, 0)
        : totalPrice,
    [documents, totalPrice]
  );

  useEffect(() => {
    if (deliveryDocument) {
      setDeliveryDocumentType(deliveryDocument.documentType as DeliveryDocumentTypes);
      setInvoiceNumber(deliveryDocument.invoiceNumber);
      setInvoiceDate(new Date(deliveryDocument.invoiceDate));
      setInvoiceTotal(`${deliveryDocument.invoiceTotal}`);
      setPaymentDue(new Date(deliveryDocument.paymentDue));
      setPaymentInfo(deliveryDocument.paymentInfo);
      setTradeDocumentNumber(deliveryDocument.tradeDocumentNumber);
    } else {
      setInvoiceNumber('');
      setInvoiceDate(new Date());
      setInvoiceTotal(delivery ? `${invoiceBalance}` : '');
      setPaymentDue(null);
      setPaymentInfo(supplier?.paymentInfo ?? '');
      setTradeDocumentNumber('');
    }
  }, [delivery, deliveryDocument, supplier, invoiceBalance]);

  const isInvoiceDataValid = () =>
    delivery &&
    delivery.id &&
    invoiceNumber &&
    invoiceDate &&
    isValidDate(invoiceDate) &&
    invoiceTotal &&
    paymentDue &&
    isValidDate(paymentDue) &&
    ((deliveryDocumentType !== DeliveryDocumentTypes.CREDIT_NOTE && paymentInfo) ||
      deliveryDocumentType === DeliveryDocumentTypes.CREDIT_NOTE) &&
    tradeDocumentNumber;

  const handleSaveDocument = async () => {
    setActionLoading(true);
    if (delivery && isInvoiceDataValid() && invoiceDate && invoiceTotal && paymentDue) {
      if (!deliveryDocument || !deliveryDocument.id) {
        const createDeliveryDocument: CreateDeliveryDocumentRequest = {
          deliveryId: delivery.id,
          invoiceNumber,
          invoiceDate: toDateString(invoiceDate),
          invoiceTotal,
          paymentDue: toDateString(paymentDue),
          paymentInfo,
          tradeDocumentNumber,
          documentType: deliveryDocumentType,
          proformaId: documentOption?.id,
          creditNoteItems:
            deliveryDocumentType === DeliveryDocumentTypes.CREDIT_NOTE
              ? Object.keys(creditNoteValues).map((id) => ({
                  deliveryItemId: +id,
                  quantity: creditNoteValues[id]
                }))
              : []
        };
        const deliveryDocumentResp = await amsV3Service.createDeliveryDocument(
          createDeliveryDocument
        );
        if (deliveryDocumentResp && deliveryDocumentResp.data) {
          onClose(deliveryDocumentResp.data);
        }
      } else {
        const updateDeliveryDocument: UpdateDeliveryDocumentRequest = {
          invoiceNumber,
          invoiceDate: toDateString(invoiceDate),
          invoiceTotal,
          paymentDue: toDateString(paymentDue),
          paymentInfo,
          tradeDocumentNumber
        };
        const deliveryDocumentResp = await amsV3Service.updateDeliveryDocument(
          deliveryDocument.id,
          updateDeliveryDocument
        );
        if (deliveryDocumentResp && deliveryDocumentResp.data) {
          onClose(deliveryDocumentResp.data);
        }
      }
    }
    setActionLoading(false);
  };

  const isInvoiceWithProforma =
    documents && documents.length > 0 && deliveryDocumentType === DeliveryDocumentTypes.INVOICE;

  const isCreditNote = deliveryDocumentType === DeliveryDocumentTypes.CREDIT_NOTE;

  const gridPart = isCreditNote ? 3 : 12;

  const [items, setItems] = useState<ItemListResponse[]>([]);

  const loadData = useCallback(async () => {
    if (deliveryItems) {
      const itemsIds = deliveryItems.map((di: any) => di.itemId);
      const itemsResp = await amsV3Service.getItems(itemsIds);
      setItems(itemsResp.data.data);
    }
  }, [deliveryItems]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const itemLookup = useMemo(
    () =>
      items.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item.artNo;
        return res;
      }, {}),
    [items]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog
        open={open}
        onClose={() => onClose(null)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={isCreditNote ? 'xl' : 'sm'}
        fullWidth={true}
      >
        <DialogTitle>
          {!deliveryDocument ? (
            <div className={classes.title}>Добавяне на счетоводен документ</div>
          ) : (
            <div className={classes.title}>Преглед на счетоводен документ</div>
          )}
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={1}>
            <Grid item sm={isCreditNote ? 6 : 12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Тип</FormLabel>
                <RadioGroup
                  row
                  value={deliveryDocumentType}
                  onChange={(event) =>
                    setDeliveryDocumentType(event.target.value as DeliveryDocumentTypes)
                  }
                  defaultValue="top"
                >
                  <Grid container>
                    <Grid item lg={4}>
                      <FormControlLabel
                        value={'PROFORMA'}
                        disabled={!!deliveryDocument}
                        control={<Radio color="primary" />}
                        label="Проформа"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <FormControlLabel
                        value={'INVOICE'}
                        disabled={!!deliveryDocument}
                        control={<Radio color="primary" />}
                        label="Фактура"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <FormControlLabel
                        value={'CREDIT_NOTE'}
                        disabled={!!deliveryDocument}
                        control={<Radio color="primary" />}
                        label="Кредитно известие"
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            {documents && isInvoiceWithProforma && (
              <Grid item lg={gridPart}>
                <AMSAutocomplete
                  label="Документ"
                  options={documents.map((t: DeliveryDocumentResponse) => ({
                    id: t.id,
                    value: `${t.invoiceNumber} ${amsV3Service.getDeliveryDocumentTypeAlias(
                      t.documentType as DeliveryDocumentType
                    )}`,
                    paymentDue: t.paymentDue,
                    type: t.documentType
                  }))}
                  value={documentOption}
                  onChange={(selectedValue: DocumentOption | null) => {
                    setDocumentOption(selectedValue);
                    if (selectedValue) {
                      const document = documents.find(
                        (d: DeliveryDocumentResponse) => d.id === selectedValue.id
                      );
                      if (document) {
                        setInvoiceDate(new Date(document.invoiceDate));
                        setInvoiceTotal(`${document.invoiceTotal}`);
                        setPaymentDue(new Date(document.paymentDue));
                        setPaymentInfo(document.paymentInfo);
                      }
                    }
                  }}
                  minChar={0}
                  required
                />
              </Grid>
            )}
            <Grid item sm={gridPart}>
              <TextField
                label="Титуляр"
                margin="dense"
                variant="outlined"
                fullWidth
                disabled
                value={company.note ?? '-'}
              />
            </Grid>
            <Grid item sm={gridPart}>
              <TextField
                label="Контрагент"
                margin="dense"
                variant="outlined"
                fullWidth
                disabled
                value={supplier.name}
              />
            </Grid>
            <Grid item sm={gridPart}>
              <InputMask
                mask="999 99 99999"
                value={invoiceNumber ? invoiceNumber : ''}
                onChange={(event) => setInvoiceNumber(event?.target?.value)}
                onBlur={(event) => setInvoiceNumber(pad(invoiceNumber, 10))}
              >
                {() => (
                  <TextField
                    label="Фактура №"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item sm={gridPart}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                required
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="Дата"
                helperText={''}
                value={invoiceDate ? invoiceDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    setInvoiceDate(ignoreOffset(value));
                  } else {
                    setInvoiceDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </Grid>
            {!isCreditNote && (
              <Grid item sm={gridPart}>
                <TextField
                  label="Общо"
                  required
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={invoiceTotal ? invoiceTotal : ''}
                  onChange={(e) => setInvoiceTotal(e.target.value)}
                />
              </Grid>
            )}
            <Grid item sm={gridPart}>
              <KeyboardDatePicker
                disableToolbar
                disabled={isInvoiceWithProforma}
                autoOk={true}
                required
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="Падеж"
                helperText={''}
                value={paymentDue ? paymentDue : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    setPaymentDue(ignoreOffset(value));
                  } else {
                    setPaymentDue(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </Grid>
            {deliveryDocumentType !== DeliveryDocumentTypes.CREDIT_NOTE && (
              <Grid item sm={12}>
                <TextField
                  label="Плащане"
                  disabled={isInvoiceWithProforma}
                  required
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={paymentInfo ? paymentInfo : ''}
                  onChange={(e) => setPaymentInfo(e.target.value)}
                />
              </Grid>
            )}
            <Grid item sm={gridPart}>
              <TextField
                label="Търговски документ №"
                required
                margin="dense"
                variant="outlined"
                fullWidth
                value={tradeDocumentNumber ? tradeDocumentNumber : ''}
                onChange={(e) => setTradeDocumentNumber(e.target.value)}
              />
            </Grid>
            {deliveryDocumentType === DeliveryDocumentTypes.CREDIT_NOTE && (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2">Име</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">Партидна информация</Typography>
                    </TableCell>
                    {!deliveryDocument && (
                      <TableCell align="right">
                        <Typography variant="body2">Количество</Typography>
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <Typography variant="body2">Цена</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        {deliveryDocument ? 'Извадено количество' : 'За изваждане'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {(deliveryDocument?.creditNoteItems ?? []).map(
                    (item: DeliveryCreditNoteItemResponse) => (
                      <TableRow key={item.id}>
                        <TableCell width="40%">
                          <Typography variant="body2">{item.itemName}</Typography>
                        </TableCell>
                        <TableCell width="25%">
                          <Typography variant="body2">
                            {item.batchNumber}/{item.expirationDate}
                          </Typography>
                        </TableCell>
                        <TableCell width="20%" align="right">
                          <Typography variant="body2">
                            {(item.quantity * item.priceIncludingVat).toFixed(2)}
                          </Typography>
                        </TableCell>
                        <TableCell width="15%" align="right">
                          <Typography variant="body2">{item.quantity}</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  )} */}
                  {!deliveryDocument &&
                    deliveryItems.map((item: DeliveryItemResponse) => {
                      const itemData = itemLookup[item.itemId];
                      return (
                        <TableRow key={item.id}>
                          <TableCell width="40%">
                            <Typography variant="body2">{itemData.name}</Typography>
                          </TableCell>
                          <TableCell width="25%">
                            <Typography variant="body2">
                              {item.batchNumber}/{item.expirationDate}
                            </Typography>
                          </TableCell>
                          <TableCell width="10%" align="right">
                            <Typography variant="body2">
                              {+item.availableQuantity -
                                (creditNoteValues[item.id] ? creditNoteValues[item.id] : 0)}
                            </Typography>
                          </TableCell>
                          <TableCell width="10%" align="right">
                            <Typography variant="body2">
                              {(+item.quantity * +item.priceIncludingVat).toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell width="15%" align="right">
                            {!deliveryDocument ? (
                              <TextField
                                type="number"
                                variant="outlined"
                                label=""
                                placeholder=""
                                margin="dense"
                                defaultValue={0}
                                inputProps={{
                                  min: 0,
                                  max: item.availableQuantity
                                }}
                                onChange={(event) => {
                                  const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                                  let cn: any = {};
                                  if (onlyNums.length < 10) {
                                    cn = {
                                      ...creditNoteValues,
                                      ['' + item.id]: event.target.value
                                    };
                                    setCreditNoteValues(cn);
                                  } else if (onlyNums.length === 10) {
                                    const number = onlyNums.replace(
                                      /(\d{3})(\d{3})(\d{4})/,
                                      '($1) $2-$3'
                                    );
                                    cn = { ...creditNoteValues, ['' + item.id]: number };
                                    setCreditNoteValues(cn);
                                  }

                                  const total = deliveryItems.reduce(
                                    (res: number, d: DeliveryItemResponse) => {
                                      const count = cn['' + d.id] ? cn['' + d.id] : 0;
                                      return res + count * +d.priceIncludingVat;
                                    },
                                    0
                                  );

                                  setInvoiceTotal(total.toFixed(2));
                                }}
                                error={item.availableQuantity < creditNoteValues[item.id]}
                                helperText={
                                  item.availableQuantity < creditNoteValues[item.id]
                                    ? 'Невалидна стойност'
                                    : `по-малко от ${item.availableQuantity}`
                                }
                                fullWidth
                              />
                            ) : (
                              <Typography variant="body2">
                                {(+item.quantity * +item.priceIncludingVat).toFixed(2)}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow key="footer">
                    <TableCell
                      colSpan={!deliveryDocument ? 4 : 3}
                      style={{ borderBottom: '0px black solid' }}
                    >
                      <Typography variant="h6">Общо</Typography>
                    </TableCell>
                    <TableCell align="right" style={{ borderBottom: '0px black solid' }}>
                      <Typography variant="h6">{invoiceTotal} лв.</Typography>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            )}
            <Grid item md={12} sm={12}>
              <AMSButton
                text="Запиши"
                color="primary"
                variant="contained"
                onClick={handleSaveDocument}
                disabled={actionLoading || !isInvoiceDataValid()}
                loading={actionLoading}
                className={classes.button}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

export default DeliveryDocumentDialog;
