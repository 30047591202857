import { AMSButton, AMSSnackBar, AMSTextField } from '../../../../../helpers/ui';
import {
  AccessPermissions,
  SetMistralConfigurationRequest,
  amsV3Service,
  snackbarService
} from '../../../../../services';
import React, { useMemo, useState } from 'react';
import { useFlag, usePermissions } from '../../../../../helpers/hooks';

import { Grid } from '@material-ui/core';
import OutlinedDiv from '../../../../../helpers/ui/OutlinedDiv';

interface MistralIntegrationFormProps {
  partnerId: number;
}

const MistralIntegrationForm = ({ partnerId }: MistralIntegrationFormProps) => {
  const [endpointUrl, setEndpointUrl] = useState<string>('');
  const [userId, serUserId] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [locationId, setLocationId] = useState<string>('');
  const [actionLoading, setActionLoading] = useFlag(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_PARTNER,
    AccessPermissions.CAN_UPDATE_PARTNER
  ]);

  const handleOnClick = async () => {
    setActionLoading(true);
    const configurationRequest: SetMistralConfigurationRequest = {
      requestType: 'set-mistral-configuration',
      configuration: {
        endpointUrl,
        userId: +userId,
        username,
        password,
        locationId: +locationId
      }
    };
    const resp = await amsV3Service.setPartnerIntegrationConfiguration(
      partnerId,
      configurationRequest
    );
    if (resp) {
      snackbarService.setSnackbarOpen(true);
    }
    setActionLoading(false);
  };

  const isValidMistralIntegration = () =>
    username && userId && password && locationId && endpointUrl;

  const shouldBeDisabled = useMemo(
    () => (partnerId > 0 ? !canUpdate : !canCreate),
    [partnerId, canCreate, canUpdate]
  );

  return (
    <Grid container spacing={1}>
      <Grid item lg={12}>
        <OutlinedDiv label={`Данни за интеграция с Mistral`} disabled={shouldBeDisabled}>
          <Grid container spacing={1}>
            <Grid item lg={12} md={6}>
              <AMSTextField
                label="URL"
                disabled={shouldBeDisabled}
                value={endpointUrl}
                onChange={(value) => setEndpointUrl(value ?? '')}
                placeholder="https://v5.mistral.bg/clientapi/v1/api/GetAllDataH"
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <AMSTextField
                label="Потребителско име"
                disabled={shouldBeDisabled}
                value={username}
                onChange={(value) => setUsername(value ?? '')}
                required
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <AMSTextField
                label="Парола"
                disabled={shouldBeDisabled}
                value={password}
                onChange={(value) => setPassword(value ?? '')}
                type="password"
                required
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <AMSTextField
                label="Потребител ID"
                disabled={shouldBeDisabled}
                value={userId}
                onChange={(value) => serUserId(value ?? '')}
                required
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <AMSTextField
                label="Локация ID"
                disabled={shouldBeDisabled}
                value={locationId}
                onChange={(value) => setLocationId(value ?? '')}
                required
              />
            </Grid>
          </Grid>
        </OutlinedDiv>
      </Grid>
      <Grid item lg={12} container justify="center">
        <AMSButton
          text="Запиши"
          variant="contained"
          color="primary"
          onClick={handleOnClick || !isValidMistralIntegration()}
          disabled={actionLoading}
          loading={actionLoading}
        />
      </Grid>
      <AMSSnackBar
        open={openSnackBar}
        message={'Промените са записани успешно'}
        onClose={() => setOpenSnackBar(false)}
      />
    </Grid>
  );
};

export default MistralIntegrationForm;
