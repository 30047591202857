import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  LegalEntityOrdersForCollectionAmountResponse,
  LegalEntityOverduePaymentsAmountResponse,
  LegalEntityPendingPaymentsAmountResponse
} from '../../services/api';
import {
  LegalEntityResponse,
  PaymentOptionResponse,
  amsLegacyService,
  amsV3Service
} from '../../services';
import { useAsync, useFlag, usePaymentOptions } from '.';

export interface ILegalEntityCreditNote {
  creditLimitAmount: string | null;
  setCreditLimitAmount: Dispatch<SetStateAction<string | null>>;
  paymentDueDays: string | null;
  setPaymentDueDays: Dispatch<SetStateAction<string | null>>;
  usesDeferredBilling: boolean;
  setUsesDeferredBilling: Dispatch<SetStateAction<boolean>>;
  paymentOption: PaymentOptionResponse | null;
  setPaymentOption: Dispatch<SetStateAction<PaymentOptionResponse | null>>;
  underForeclosure: boolean;
  isActive: boolean;
  ordersForCollectionAmount: number;
  ordersForDistributionAmount: number;
  pendingPaymentsAmount: number;
  overduePaymentsAmount: number;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

/**
 *
 * @deprecated
 */
const useLegalEntityCreditLimit = (id: number): ILegalEntityCreditNote => {
  const [creditLimitAmount, setCreditLimitAmount] = useState<string | null>(null);
  const [paymentDueDays, setPaymentDueDays] = useState<string | null>(null);
  const [usesDeferredBilling, setUsesDeferredBilling] = useFlag();
  const [paymentOption, setPaymentOption] = useState<PaymentOptionResponse | null>(null);

  const leData = useAsync<LegalEntityResponse | null>(amsV3Service.getLegalEntity, null, [id]);
  const { paymentOptions } = usePaymentOptions();

  const ordersForCollectionAmount = useAsync<LegalEntityOrdersForCollectionAmountResponse | null>(
    amsLegacyService.getOrdersForCollectionAmount,
    null,
    [id]
  );

  const ordersForDistributionAmount = useAsync<LegalEntityOrdersForCollectionAmountResponse | null>(
    amsLegacyService.getOrdersForDistributionAmount,
    null,
    [id]
  );

  const pendingPaymentsAmount = useAsync<LegalEntityPendingPaymentsAmountResponse | null>(
    amsLegacyService.getPendingPaymentsAmount,
    null,
    [id]
  );

  const overduePaymentsAmount = useAsync<LegalEntityOverduePaymentsAmountResponse | null>(
    amsLegacyService.getOverduePaymentsAmount,
    null,
    [id]
  );

  useEffect(() => {
    setCreditLimitAmount(leData.data?.creditLimitAmount ?? null);
    setPaymentDueDays(leData.data?.paymentDueDays ? `${leData.data?.paymentDueDays}` : null);
    const paymentOption = paymentOptions.find((value) => value.id === leData.data?.paymentOptionId);
    if (paymentOption) {
      setPaymentOption(
        paymentOptions
          ? {
              ...paymentOption,
              companyId: leData.data?.companyId ?? 0
            }
          : null
      );
    }
    setUsesDeferredBilling(leData.data?.usesDeferredBilling ?? false);
  }, [
    leData.data,
    setCreditLimitAmount,
    setPaymentDueDays,
    setUsesDeferredBilling,
    paymentOptions
  ]);

  const underForeclosure = useMemo(() => leData.data?.underForeclosure ?? false, [leData.data]);

  return {
    creditLimitAmount,
    setCreditLimitAmount,
    paymentOption,
    setPaymentOption,
    paymentDueDays,
    setPaymentDueDays,
    usesDeferredBilling,
    setUsesDeferredBilling,
    underForeclosure,
    isActive: leData.data?.isActive ?? false,
    ordersForCollectionAmount: ordersForCollectionAmount.data?.amount
      ? +ordersForCollectionAmount.data?.amount
      : 0,
    ordersForDistributionAmount: ordersForDistributionAmount.data?.amount
      ? +ordersForDistributionAmount.data?.amount
      : 0,
    pendingPaymentsAmount: pendingPaymentsAmount.data?.amount
      ? +pendingPaymentsAmount.data?.amount
      : 0,
    overduePaymentsAmount: overduePaymentsAmount.data?.amount
      ? +overduePaymentsAmount.data?.amount
      : 0,
    loading: leData.loading,
    setLoading: leData.setLoading
  };
};

export default useLegalEntityCreditLimit;
