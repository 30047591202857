import {
  BatchOrderItemV2Response,
  OrderItemV2Response,
  OrderV2Response,
  ReturnOrderItemV2Request
} from '../../../services/api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { amsLegacyService } from '../../../services';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  typeWrapper: {
    paddingTop: 8
  }
}));

export interface CancelOrderDialogProps {
  order: OrderV2Response;
  open: boolean;
  onClose: () => void;
}

const CancelOrderDialog = ({ order, open, onClose }: CancelOrderDialogProps) => {
  const classes = useStyles();

  const [creditNoteValues, setCreditNoteValues] = useState<any>({});
  const [allDepletedAmounts, setAllDepletedAmounts] = useState<any[]>([]);

  const calculateTotal = () => {
    let total = 0;
    (order.items ?? []).forEach((orderItem: OrderItemV2Response) => {
      orderItem.batchItems.forEach((orderBatchItem: BatchOrderItemV2Response) => {
        if (creditNoteValues[orderBatchItem.id]) {
          total += creditNoteValues[orderBatchItem.id] * orderItem.price;
        }
      });
    });
    return total;
  };

  const loadV2Order = useCallback(async (id) => {
    const orderResp = await amsLegacyService.getOrder(id);
    setAllDepletedAmounts(
      (orderResp.data.items ?? []).reduce((result: any[], item: OrderItemV2Response) => {
        result = [
          ...result,
          ...item.batchItems
            .filter((i: BatchOrderItemV2Response) => i.collected)
            .map((orderBatchItem: BatchOrderItemV2Response) => ({
              ...item,
              ...orderBatchItem,
              quantity: orderBatchItem.collected
            }))
        ];
        return result;
      }, [])
    );
  }, []);

  useEffect(() => {
    loadV2Order(order.id);
  }, [order, loadV2Order]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle>
        <div className={classes.title}>Създаване на кредитно известие</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2">Име</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Партидна информация</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Количество</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Цена</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">За изваждане</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allDepletedAmounts.map((item: any) => (
              <TableRow
                key={item.id}
                style={item.isOwnedByUs ? {} : { backgroundColor: '#FDE9EE' }}
              >
                <TableCell width="40%">
                  <Typography variant="body2">{item.itemName}</Typography>
                </TableCell>
                <TableCell width="25%">
                  <Typography variant="body2">
                    {item.batchNumber}/{item.expirationDate}
                  </Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography variant="body2">
                    {item.collected - (creditNoteValues[item.id] ? creditNoteValues[item.id] : 0)}
                  </Typography>
                </TableCell>
                <TableCell width="10%" align="right">
                  <Typography variant="body2">{(item.quantity * item.price).toFixed(2)}</Typography>
                </TableCell>
                <TableCell width="15%">
                  <TextField
                    type="number"
                    variant="outlined"
                    label=""
                    placeholder=""
                    margin="dense"
                    defaultValue={0}
                    inputProps={{
                      min: 0,
                      max: item.collected
                    }}
                    onChange={(event) => {
                      const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                      if (onlyNums.length < 10) {
                        setCreditNoteValues({ ...creditNoteValues, [item.id]: event.target.value });
                      } else if (onlyNums.length === 10) {
                        const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                        setCreditNoteValues({ ...creditNoteValues, [item.id]: number });
                      }
                    }}
                    error={item.collected < creditNoteValues[item.id]}
                    helperText={
                      item.collected < creditNoteValues[item.id]
                        ? 'Невалидна стойност'
                        : `по-малко от ${item.collected}`
                    }
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow key="footer">
              <TableCell colSpan={4} style={{ borderBottom: '0px black solid' }}>
                <Typography variant="h6">Общо</Typography>
              </TableCell>
              <TableCell align="right" style={{ borderBottom: '0px black solid' }}>
                <Typography variant="h6">{calculateTotal().toFixed(2)} лв.</Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button
          onClick={async () => {
            const depletedAmountsItemMap: any = {};
            (order.items ?? []).forEach((orderItem: OrderItemV2Response) => {
              orderItem.batchItems.forEach((orderBatchItem: BatchOrderItemV2Response) => {
                depletedAmountsItemMap[orderBatchItem.id] = orderItem.id;
              });
            });
            const requests: ReturnOrderItemV2Request[] = allDepletedAmounts
              .map((item: any) => {
                return {
                  orderItemId: depletedAmountsItemMap[item.id],
                  depletedAmountId: item.id,
                  quantity: -creditNoteValues[item.id]
                };
              })
              .filter((item: ReturnOrderItemV2Request) => !!item.quantity);
            await amsLegacyService.returnItemsForOrder(order.id, requests);
            onClose();
          }}
          color="primary"
          variant="contained"
        >
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelOrderDialog;
