import { backendApiUrl } from './openapi-axios-helper';

export const flattenTree = (tree: any, fieldToExtract?: string) =>
  tree.reduce((res: any[], item: any, index: number) => {
    if (!res) {
      res = [];
    }
    if (item.children && item.children.length) {
      const flat = flattenTree(item.children, fieldToExtract);
      res = [...res, ...flat];
    }
    res.push(fieldToExtract ? item[fieldToExtract] : { ...item });
    return res;
  }, []);

export const isValidEmail = (email: string): boolean =>
  new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);

export const isValidPhone = (phone: string): boolean =>
  new RegExp(/^\+3598[7-9]{1}[0-9]{7}$/g).test(phone);

export const getExtendedId = (id: number, type: number): string =>
  `${type}${String(id).padStart(7, '0')}`;

export const getExtendedCode = (type: string, id: number): string =>
  `${type}${String(id).padStart(6, '0')}`;

export const getExtendedCompanyId = (id: number) => getExtendedCode('ФА', id);

export const getExtendedWarehouseId = (id: number) => getExtendedCode('СК', id);

export const getExtendedLegalEntityId = (id: number) => getExtendedCode('ЮЛ', id);

export const getExtendedPartnerId = (id: number) => getExtendedCode('К', id);

export const getExtendedSupplierId = (id: number) => getExtendedCode('Д', id);

export const getExtendedOrderId = (id: number) => getExtendedCode('ПК', id);

export const getExtendedDeliveryId = (id: number) => getExtendedCode('ДК', id);

export const getExtendedDeliveryRequestId = (id: number) => getExtendedCode('АЗ', id);

export const getExtendedInvoiceId = (id: number) => getExtendedCode('Ф', id);

export const getExtendedCategoryId = (id: number) => getExtendedCode('KA', id);

export const getExtendedItemCategoryId = (id: number) => getExtendedCode('АК', id);

export const getExtendedItemId = (id: number) => getExtendedCode('AР', id);

export const getExtendedPricingPolicyId = (id: number) => getExtendedCode('ЦП', id);

export const getExtendedPaymentOptionId = (id: number) => getExtendedCode('OП', id);

export const getExtendedManufacturerId = (id: number) => getExtendedCode('П', id);

export const getExtendedClientId = (id: number) => getExtendedCode('К', id);

export const getExtendedStockTakingReportId = (id: number) => getExtendedCode('РП', id);

export const getInvoiceUrl = (invoiceId: number) =>
  `${backendApiUrl}/v2/invoices/${invoiceId}/document`;

export const getActOfAcceptanceUrl = (actOfAcceptanceId: number) =>
  `${backendApiUrl}/v2/acts-of-acceptance/${actOfAcceptanceId}/document`;

export const getDeliveryBatchUrl = (orderId: number) =>
  `${backendApiUrl}/v2/orders/${orderId}/documents/delivery-batch`;

export const getOrderDocumentFullUrl = (documentPath: string) => `${backendApiUrl}${documentPath}`;

export const subtract = (a: number, b: number) => Math.round(a * 100 - b * 100) / 100;

export function pad(s: any, size: number) {
  s = s.split(/_| /).join('');
  while (s.length < size) s = '0' + s;
  return s;
}
