import { TableRow, TableCell, Typography, TextField } from '@material-ui/core'
import React from 'react'
import { OrderItemV2Response } from '../../../../services/api'

interface EditOrderItemPricesRowProps {
    item: OrderItemV2Response;
    setNewPrice: (newPrice: string) => void;
    setNewTotalPrice: (newTotalPrice: string) => void;
}

const floatNumberPattern = /^[+-]?\d+(\.(\d{0,2})?)?$/;

const EditOrderItemPricesRow = ({item, setNewPrice, setNewTotalPrice}: EditOrderItemPricesRowProps) => {

    return (
        <TableRow key={item.id}>
            <TableCell width="50%">
                <Typography variant="body2">{item.name}</Typography>
            </TableCell>
            <TableCell align="right" width="10%">
                <Typography variant="body2">{item.quantity}</Typography>
            </TableCell>
            <TableCell align="right" width="10%">
                <Typography variant="body2">{item.price}</Typography>
            </TableCell>
            <TableCell align="right" width="10%">
                <Typography variant="body2">{item.totalPrice}</Typography>
            </TableCell>
            <TableCell align="right" width="10%">
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  margin="dense"
                  type="text"
                  value={item.livePrice}
                  onChange={(event) => {
                    const newValue = event?.target.value ?? "";
                    if (floatNumberPattern.test(newValue) || newValue === "") {
                        setNewPrice(newValue);
                    }
                  }}
                />
            </TableCell>
            <TableCell align="right" width="10%">
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  margin="dense"
                  type="text"
                  value={item.liveTotalPrice}
                  onChange={(event) => {
                    const newValue = event?.target.value ?? "";
                    if (floatNumberPattern.test(newValue) || newValue === "") {
                        setNewTotalPrice(newValue);
                    }
                  }}
                />
            </TableCell>
        </TableRow>
    );
}

export default EditOrderItemPricesRow