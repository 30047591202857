import {
  OrderStatusKey,
  getOrderStatusByKey,
  getOrderStatusLabelByKey
} from '../../../../services';
import { Paper, Tooltip } from '@material-ui/core';
import React, { useMemo } from 'react';

import AMSTable from '../../../../helpers/ui/AMSTable/AMSTable';
import { OrderStatusLogResponse } from '../../../../services/api';
import moment from 'moment';
import useOrderStatusLogs from '../../../../helpers/hooks/useOrderStatusLogs';
import { useStyles } from '../../../../helpers/hooks';

interface OrderItemsPanelProps {
  id: number;
}

const OrderProcessPanel = ({ id }: OrderItemsPanelProps) => {
  const classes = useStyles();
  const { orderStatusLogs, loading } = useOrderStatusLogs(id);

  const columns = useMemo(
    () => [
      {
        title: 'Дата / Час',
        field: 'createdAt',
        type: 'datetime',
        render: (rowData: OrderStatusLogResponse) =>
          moment.utc(rowData.createdAt).local().format('DD/MM/YY, HH:mm:ss'),
        cellStyle: { width: '20%' }
      },
      {
        title: 'Статус',
        field: 'statusKey',
        render: (rowData: OrderStatusLogResponse) => (
          <Tooltip title={getOrderStatusLabelByKey(rowData.statusKey as OrderStatusKey)}>
            <div className="d-flex">
              <div
                className={classes.statusIndicator}
                style={{
                  backgroundColor: getOrderStatusByKey(rowData.statusKey as OrderStatusKey).color
                }}
              />
              <div className={classes.statusText}>
                {getOrderStatusLabelByKey(rowData.statusKey as OrderStatusKey)}
              </div>
            </div>
          </Tooltip>
        ),
        cellStyle: { width: '20%' }
      },
      {
        title: 'Потребител',
        field: 'userName',
        cellStyle: { width: '60%' }
      }
    ],
    [classes.statusIndicator, classes.statusText]
  );

  return (
    <>
      <AMSTable
        title="Процес"
        columns={columns}
        paging={false}
        inlineActions={false}
        components={{
          Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
        }}
        data={orderStatusLogs}
        isLoading={loading}
        overflowY="scroll"
        minBodyHeight="50vh"
        maxBodyHeight="50vh"
      />
    </>
  );
};

export default OrderProcessPanel;
