import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';
import { ItemResponse, amsV3Service } from '../../services';

export interface IItem<T> extends IAsyncType<T> {
  item: T;
  setItem: Dispatch<SetStateAction<T>>;
}

const useItemItems = (id: number): IItem<ItemResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<ItemResponse | null>(amsV3Service.getItem, null, [id]);
  return {
    item: data,
    setItem: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useItemItems;
