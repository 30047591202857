import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { useFlag } from '.';

interface IAsyncResult<T> {
  data: T;
  setData: Dispatch<SetStateAction<T>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  parameters: any[] | null;
  setParameters: Dispatch<any[] | null>;
}

export type IAsyncType<T> = Omit<IAsyncResult<T>, 'data' | 'setData'>;

const useAsync = <T>(
  axiosCallback: (...args: any[]) => Promise<AxiosResponse<T>>,
  defaultValue: T,
  params: any[] | null,
  alwaysLoad: boolean = false
): IAsyncResult<T> => {
  const [data, setData] = useState<T>(defaultValue);
  const [loading, setLoading] = useFlag(true);
  const [parameters, setParameters] = useState<any[] | null>(params);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!parameters || parameters.length === 0) {
      if (alwaysLoad) {
        const response = await axiosCallback();
        if (response && response.data) {
          setData(response.data);
        }
      }
      setLoading(false);
      return;
    }
    if (parameters.every((param) => param === undefined || param === null) && !alwaysLoad) {
      setLoading(false);
      return;
    }
    const response = await axiosCallback(...parameters);
    if (response && response.data) {
      setData(response.data);
    }
    setLoading(false);
  }, [axiosCallback, parameters, setLoading, alwaysLoad]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, setData, loading, setLoading, parameters, setParameters };
};

export default useAsync;
