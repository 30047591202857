import { ClientResponse, amsV3Service } from '../../services';
import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

export interface IClient<T> extends IAsyncType<T> {
  client: T;
  setClient: Dispatch<SetStateAction<T>>;
}

const useClient = (id?: number): IClient<ClientResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<ClientResponse | null>(amsV3Service.getClient, null, id ? [id] : null);
  return {
    client: data,
    setClient: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useClient;
