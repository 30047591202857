import {
  DeliveryRequestItemResponse,
  PaginatedListResponseDeliveryRequestItemResponse,
  amsV3Service
} from '../../services';
import { IAsyncType, useAsync } from '.';

export interface IDeliveryRequestItems<T> extends IAsyncType<T> {
  deliveryRequestItems: T;
}

const useDeliveryRequestItems = (
  deliveryRequestId: number
): IDeliveryRequestItems<DeliveryRequestItemResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseDeliveryRequestItemResponse>(
      amsV3Service.getDeliveryRequestItems,
      {
        data: [],
        total: 0,
        page: 1,
        size: 100
      },
      [deliveryRequestId],
      true
    );
  return {
    deliveryRequestItems: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useDeliveryRequestItems;
