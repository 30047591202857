import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseSupplierReserveSubscriptionResponse,
  SupplierReserveSubscriptionResponse,
  amsV3Service
} from '../../services';

export interface ISupplierReserveSubscriptions<T> extends IAsyncType<T> {
  supplierReserveSubscriptions: T;
}

const useSupplierReserveSubscriptions = (
  id?: number[],
  suppliers?: number[]
): ISupplierReserveSubscriptions<SupplierReserveSubscriptionResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseSupplierReserveSubscriptionResponse>(
      amsV3Service.getSupplierReserveSubscriptions,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id, suppliers],
      true
    );

  return {
    supplierReserveSubscriptions: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useSupplierReserveSubscriptions;
