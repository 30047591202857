import {
  AccessPermissions,
  CompanyListResponse,
  CreateTreasuryRequest,
  PatchTreasuryRequest,
  TreasuryListResponse,
  TreasuryResponse
} from '../../../services';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import React, { Fragment, useMemo, useState } from 'react';

import AMSWidget from '../../../helpers/ui/AMSWidget/AMSWidget';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditTreasuryDialog from './AddEditTreasuryDialog/AddEditTreasuryDialog';
import { AxiosResponse } from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import { usePermissions } from '../../../helpers/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    tableBody: {
      maxHeight: '80vh !important',
      overflow: 'auto'
    },
    treasuryRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#F5F5F5'
      }
    },
    treasuryName: {
      paddingTop: 2
    },
    selectedTreasury: {
      backgroundColor: '#3F51B530 !important'
    },
    fullWidth: {
      width: '100%'
    },
    checkbox: {
      marginRight: 8
    },
    totalHeader: {
      fontWeight: 'bold',
      borderLeft: '1px solid #C7C7C7',
      backgroundColor: '#E8E8E8 !important'
    },
    totalCell: {
      fontWeight: 'bold',
      borderLeft: '1px solid #C7C7C7'
    },
    tableCell: {
      fontWeight: 'bold',
      borderLeft: '1px solid #C7C7C7'
    },
    colorIndicator: {
      width: 24,
      height: 24,
      minWidth: 24,
      minHeight: 24,
      borderRadius: 12,
      marginRight: 8
    },
    headerCell: {
      fontWeight: 'bold',
      backgroundColor: '#E8E8E8 !important'
    },
    companyRow: {
      backgroundColor: '#F0F0F0'
    },
    footerCell: {
      backgroundColor: '#E8E8E8',
      borderTop: '1px solid #C7C7C7',
      fontWeight: 'bold',
      fontSize: '120%',
      color: 'black'
    },
    footerTotalCell: {
      backgroundColor: '#E8E8E8',
      borderTop: '1px solid #C7C7C7',
      borderLeft: '1px solid #C7C7C7',
      fontWeight: 'bold',
      fontSize: '120%',
      color: 'black'
    },
    actionButton: {
      width: 24,
      height: 24,
      marginLeft: 4
    },
    hiddenActionButton: {
      width: 24,
      height: 24,
      marginLeft: 4,
      visibility: 'hidden'
    }
  })
);

interface FinanceCompaniesTableWidgetComponentProps {
  companies: CompanyListResponse[];
  treasuries: TreasuryListResponse[];
  cashAmountByTreasury: any;
  bankAccountAmountByTreasury: any;
  selected: any;
  setSelected: (selected: any) => void;
  visibleCompanies: any;
  setVisibleCompanies: (selected: any) => void;
  onAdd: (treasury: CreateTreasuryRequest) => Promise<AxiosResponse<TreasuryResponse>>;
  onEdit: (treasury: PatchTreasuryRequest) => Promise<TreasuryResponse>;
  reloadForm: () => void;
}

const FinanceCompaniesTableWidgetComponent = ({
  companies,
  treasuries,
  cashAmountByTreasury,
  bankAccountAmountByTreasury,
  selected,
  setSelected,
  visibleCompanies,
  setVisibleCompanies,
  onAdd,
  onEdit,
  reloadForm
}: FinanceCompaniesTableWidgetComponentProps) => {
  const classes = useStyles();

  const companyTreasuries = useMemo(() => {
    const res: any = {};
    companies.forEach((company) => {
      res[company.id] = treasuries.filter(
        (treasury: TreasuryListResponse) => treasury.companyId === company.id
      );
    });
    return res;
  }, [companies, treasuries]);

  const companyBankAccountBalance = useMemo(() => {
    const res: any = {};
    companies.forEach((company) => {
      res[company.id] = companyTreasuries[company.id].reduce(
        (res: any, treasury: TreasuryListResponse) =>
          res + bankAccountAmountByTreasury[treasury.id],
        0
      );
    });
    return res;
  }, [companies, companyTreasuries, bankAccountAmountByTreasury]);

  const companyCashAmountBalance = useMemo(() => {
    const res: any = {};
    companies.forEach((company) => {
      res[company.id] = companyTreasuries[company.id].reduce(
        (res: any, treasury: TreasuryListResponse) => res + cashAmountByTreasury[treasury.id],
        0
      );
    });
    return res;
  }, [companies, companyTreasuries, cashAmountByTreasury]);

  const [hovered, setHovered] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyListResponse | null>(null);
  const [selectedTreasury, setSelectedTreasury] = useState<TreasuryListResponse | null>(null);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_TREASURY,
    AccessPermissions.CAN_UPDATE_TREASURY
  ]);

  return (
    <AMSWidget title={<Typography>Фирмени каси</Typography>}>
      <TableContainer className={classes.tableBody}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell width="46%" className={classes.headerCell}>
                Име
              </TableCell>
              <TableCell width="18%" className={classes.headerCell} align="right">
                В сметка
              </TableCell>
              <TableCell width="18%" className={classes.headerCell} align="right">
                В брой
              </TableCell>
              <TableCell width="18%" align="right" className={classes.totalHeader}>
                Общо
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ marginBottom: 100 }}>
            {companies.map((company: CompanyListResponse) => (
              <Fragment key={`company-${company.id}`}>
                <TableRow
                  className={classes.companyRow}
                  onMouseEnter={() => setHovered(`company-${company.id}`)}
                  onMouseLeave={() => setHovered('')}
                  onClick={() =>
                    setVisibleCompanies({
                      ...visibleCompanies,
                      [company.id]: !visibleCompanies[company.id]
                    })
                  }
                >
                  <TableCell className="bold">
                    {`${company.note} ${company.name ? `(${company.name})` : ''}`}
                    {canCreate && (
                      <Tooltip title="Добавяне на каса">
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            setOpen(true);
                            setSelectedCompany(company);
                            setSelectedTreasury(null);
                          }}
                          size="small"
                          className={
                            hovered !== `company-${company.id}`
                              ? classes.hiddenActionButton
                              : classes.actionButton
                          }
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="right" className="bold">
                    {companyBankAccountBalance[company.id].toFixed(2) ?? 0} лв.
                  </TableCell>
                  <TableCell align="right" className="bold">
                    {companyCashAmountBalance[company.id].toFixed(2) ?? 0} лв.
                  </TableCell>
                  <TableCell align="right" className={classes.totalCell}>
                    {company && companyBankAccountBalance && companyCashAmountBalance
                      ? (
                          (companyBankAccountBalance[company.id] ?? 0) +
                          (companyCashAmountBalance[company.id] ?? 0)
                        ).toFixed(2)
                      : 0}
                    лв.
                  </TableCell>
                </TableRow>
                {visibleCompanies[company.id] && company && companyTreasuries
                  ? companyTreasuries[company.id].map((treasury: TreasuryListResponse) => (
                      <TableRow
                        key={`treasury-${treasury.id}`}
                        onClick={() => {
                          const newSelected: any = { ...selected };
                          if (selected[treasury.id]) {
                            delete newSelected[treasury.id];
                          } else {
                            newSelected[treasury.id] = true;
                          }
                          setSelected(newSelected);
                        }}
                        className={
                          selected[treasury.id]
                            ? [classes.selectedTreasury, classes.treasuryRow].join(' ')
                            : classes.treasuryRow
                        }
                        onMouseEnter={() => setHovered(`treasury-${treasury.id}`)}
                        onMouseLeave={() => setHovered('')}
                      >
                        <TableCell>
                          <div className="d-flex">
                            <div
                              style={{
                                backgroundColor: treasury.color
                              }}
                              className={classes.colorIndicator}
                            ></div>
                            <div className={classes.treasuryName}>{treasury.name}</div>

                            {canUpdate && (
                              <Tooltip title="Редактиране на каса">
                                <IconButton
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setOpen(true);
                                    setSelectedCompany(company);
                                    setSelectedTreasury(treasury);
                                  }}
                                  size="small"
                                  className={
                                    hovered !== `treasury-${treasury.id}`
                                      ? classes.hiddenActionButton
                                      : classes.actionButton
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          {treasury.id in bankAccountAmountByTreasury
                            ? bankAccountAmountByTreasury[treasury.id].toFixed(2)
                            : 0}
                          лв.
                        </TableCell>
                        <TableCell align="right">
                          {treasury.id in cashAmountByTreasury
                            ? cashAmountByTreasury[treasury.id].toFixed(2)
                            : 0}
                          лв.
                        </TableCell>
                        <TableCell align="right" className={classes.tableCell}>
                          {(
                            (treasury.id in cashAmountByTreasury
                              ? cashAmountByTreasury[treasury.id]
                              : 0) +
                            (treasury.id in bankAccountAmountByTreasury
                              ? bankAccountAmountByTreasury[treasury.id]
                              : 0)
                          ).toFixed(2)}
                          лв.
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </Fragment>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={classes.footerCell}>Общо</TableCell>
              <TableCell align="right" className={classes.footerCell}>
                {companies
                  .reduce(
                    (res, company: CompanyListResponse) =>
                      res + (companyBankAccountBalance[company.id] || 0),
                    0
                  )
                  .toFixed(2)}
                лв.
              </TableCell>
              <TableCell align="right" className={classes.footerCell}>
                {companies
                  .reduce(
                    (res, company: CompanyListResponse) =>
                      res + (companyCashAmountBalance[company.id] || 0),
                    0
                  )
                  .toFixed(2)}
                лв.
              </TableCell>
              <TableCell align="right" className={classes.footerTotalCell}>
                {companies
                  .reduce(
                    (res, company: CompanyListResponse) =>
                      res +
                      (companyBankAccountBalance[company.id] || 0) +
                      (companyCashAmountBalance[company.id] || 0),
                    0
                  )
                  .toFixed(2)}
                лв.
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {selectedCompany && (
        <AddEditTreasuryDialog
          company={selectedCompany}
          treasury={selectedTreasury}
          open={open}
          onClose={() => setOpen(false)}
          onSave={async (t: CreateTreasuryRequest | PatchTreasuryRequest) => {
            let resp = null;
            if (selectedTreasury) {
              resp = await onEdit(t as PatchTreasuryRequest);
            } else {
              resp = await onAdd(t as CreateTreasuryRequest);
            }
            if (!!resp) {
              setOpen(false);
              reloadForm();
            }
          }}
        />
      )}
    </AMSWidget>
  );
};

export default FinanceCompaniesTableWidgetComponent;
