import {
  AvailabilityListResponse,
  PaginatedListResponseAvailabilityListResponse,
  amsV3Service
} from '../../services';
import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

export interface IAvailabilities<T> extends IAsyncType<T> {
  availabilities: T;
  setAvailabilities: Dispatch<SetStateAction<T>>;
  setAvailabilitiesParameters: Dispatch<any[] | null>;
}
const useAvailabilities = (
  itemIds?: number[],
  warehouseIds?: number[],
  realTime?: boolean
): IAvailabilities<AvailabilityListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseAvailabilityListResponse>(
      amsV3Service.getAvailabilities,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [itemIds, warehouseIds, realTime]
    );
  return {
    availabilities: data?.data ?? [],
    setAvailabilities: () => {},
    loading,
    setLoading,
    parameters,
    setParameters,
    setAvailabilitiesParameters: setParameters
  };
};
export default useAvailabilities;
