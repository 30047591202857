import { AMSLegacyService } from './AMSLegacyService';
import { AMSV3Service } from './AMSV3Service';
import { AccessManagementService } from './accessManagementService';
import { CookiesService } from './cookiesService';
import { SnackbarService } from './snackbarService';

export const accessManagementService = new AccessManagementService();
export const amsV3Service = new AMSV3Service();
export const cookiesService = new CookiesService();
export const snackbarService = new SnackbarService();

/**
 *
 * @deprecated
 */
export const amsLegacyService = new AMSLegacyService();
