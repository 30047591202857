import * as H from 'history';

import CreateDeliveryComponent from './CreateDeliveryComponent';
import EditDeliveryComponent from './EditDeliveryComponent';
import React from 'react';
import { parseQuery } from '../../helpers/url';

interface DeliveryComponentProps {
  location: H.Location;
}

const DeliveryComponent = ({ location }: DeliveryComponentProps) => {
  const { id, tabId } = parseQuery(location.search);
  if (!id) {
    return <CreateDeliveryComponent />;
  }
  return <EditDeliveryComponent id={+id} tabId={tabId ? +tabId : undefined} />;
};
export default DeliveryComponent;
