export enum WeekdayKey {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN'
}

export enum WeekdayLabel {
  MON = 'Понеделник',
  TUE = 'Вторник',
  WED = 'Сряда',
  THU = 'Четвъртък',
  FRI = 'Петък',
  SAT = 'Събота',
  SUN = 'Неделя'
}

export class Weekday {
  static readonly MON = new Weekday(0, WeekdayKey.MON, WeekdayLabel.MON);
  static readonly TUE = new Weekday(1, WeekdayKey.TUE, WeekdayLabel.TUE);
  static readonly WED = new Weekday(2, WeekdayKey.WED, WeekdayLabel.WED);
  static readonly THU = new Weekday(3, WeekdayKey.THU, WeekdayLabel.THU);
  static readonly FRI = new Weekday(4, WeekdayKey.FRI, WeekdayLabel.FRI);
  static readonly SAT = new Weekday(5, WeekdayKey.SAT, WeekdayLabel.SAT);
  static readonly SUN = new Weekday(6, WeekdayKey.SUN, WeekdayLabel.SUN);

  private constructor(
    public readonly id: number,
    public readonly key: WeekdayKey,
    public readonly label: WeekdayLabel
  ) {}
}

const invalidWeekday = {
  id: -1,
  key: 'INVALID_WEEKDAY' as WeekdayKey,
  label: '<НЕВАЛИДЕН ДЕН>'
};

export const WEEKDAYS = [
  Weekday.MON,
  Weekday.TUE,
  Weekday.WED,
  Weekday.THU,
  Weekday.FRI,
  Weekday.SAT,
  Weekday.SUN
];

export const getWeekdaysByKey = (key: WeekdayKey) =>
  WEEKDAYS.find((status: Weekday) => status.key === key) || invalidWeekday;

export const isValidDate = (d: any) => {
  return d && d.toString() !== 'Invalid Date';
};

export const ignoreOffset = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const toDateString = (date: Date) => {
  return date.toISOString().substr(0, 10);
};

export const toStartOfDay = (date: Date) => {
  return toDateString(date) + 'T00:00:00';
};

export const toEndOfDay = (date: Date) => {
  return toDateString(date) + 'T23:59:59';
};

export const toStartOfDayDatetimeString = (date: Date) => {
  return toStartOfDay(date);
};

export const toDatetimeString = (date: Date) => {
  return date.toISOString().substr(0, 19);
};

export const isoToDatetimeString = (datestring: string) =>
  datestring.substr(0, 19).split('T').join(', ');

export const toYYYYMMDD = (date: Date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('');
};

export const toDDMMYY = (date: Date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year % 100].join('/');
};

export const toHHMM = (date: Date) => {
  var d = new Date(date),
    hours = '' + d.getHours(),
    minutes = '' + d.getMinutes();

  if (hours.length < 2) hours = '0' + hours;
  if (minutes.length < 2) minutes = '0' + minutes;

  return [hours, minutes].join(':');
};

export const getFirstDayOfWeek = (date: Date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};
