const flattenTree = (tree: any, fieldToExtract?: string) =>
  tree.reduce((res: any[], item: any, index: number) => {
    if (!res) {
      res = [];
    }
    if (item.children && item.children.length) {
      const flat = flattenTree(item.children, fieldToExtract);
      res = [...res, ...flat];
    }
    res.push(fieldToExtract ? item[fieldToExtract] : { ...item, children: item.children });
    return res;
  }, []);

export const toTree = (list: any[], root: number | null) => {
  var r: any[] = [],
    o: any = {};
  list.forEach((a: any) => {
    if (o[a.id] && o[a.id].children) {
      a.children = o[a.id] && o[a.id].children;
    }
    o[a.id] = a;
    if (a.parentId === root) {
      r.push(a);
    } else {
      o[a.parentId] = o[a.parentId] || {};
      o[a.parentId].children = o[a.parentId].children || [];
      o[a.parentId].children.push(a);
    }
  });
  return r;
};

export default flattenTree;
