import { IAsyncType, useAsync } from '.';
import {
  OrderListResponse,
  PaginatedListResponseOrderListResponse,
  amsV3Service
} from '../../services';

export interface IInvoiceOrders<T> extends IAsyncType<T> {
  invoiceOrders: T;
}

const useInvoiceOrders = (invoiceId: number): IInvoiceOrders<OrderListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseOrderListResponse>(
      amsV3Service.getInvoiceOrders,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [invoiceId],
      true
    );
  return {
    invoiceOrders: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useInvoiceOrders;
