import {
  Collapse,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { useDebounce } from 'use-debounce/lib';
import CategoryDialog from '../../../components/Categories/CategoryDialog';
import Category, { CategoryForm } from '../../../models/category';
import { ItemCategoryResponse } from '../../../services/api';
import { flattenTree } from '../../utils';
import AMSConfirmDialog from '../AMSConfirmDialog/AMSConfirmDialog';

interface CategoriesContainerProps {
  data: any[];
  open: any;
  setOpen: (open: any) => void;
  marked: number;
  onAdd?: (category: CategoryForm) => void;
  onEdit?: (category: Category) => void;
  onDelete?: (category: Category) => void;
  viewOnly?: boolean;
  size?: string;
  openDialog?: boolean;
  setOpenDialog?: (open: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    list: {
      padding: 0
    },
    listItem: {
      padding: 0,
      '&:hover': {
        backgroundColor: '#F8F8F8'
      }
    },
    nested: {
      paddingLeft: theme.spacing(4),
      paddingTop: 0,
      paddingBottom: 0
    },
    grayColor: {
      backgroundColor: '#FAFAFA'
    },
    transparent: {
      color: '#00000000'
    }
  })
);

const AMSTree = (props: CategoriesContainerProps) => {
  const {
    data,
    open,
    setOpen,
    marked,
    onAdd,
    onEdit,
    onDelete,
    viewOnly = false,
    size = 'normal',
    openDialog = false,
    setOpenDialog = () => {}
  } = props;

  const classes = useStyles();
  const [selected, setSelected] = useState('-1');
  const [dragged, setDragged] = useState('-1');
  const [dragOver, setDragOver] = useState('-1');
  const [drag] = useDebounce(dragOver, 40);
  const [category, setCategory] = useState<any>();
  const [parent, setParent] = useState<Category | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const handleClick = (id: number) => {
    setOpen({ ...open, [id]: !open[id] });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setCategory(undefined);
  };

  const handleDialogSave = (cat: CategoryForm) => {
    if (cat.id) {
      if (onEdit) onEdit(cat as Category);
    } else {
      if (onAdd) onAdd(cat);
    }
  };

  const handleDialogDelete = (cat: ItemCategoryResponse) => {
    setCategory(cat);
    setOpenConfirmDeleteDialog(true);
  };

  const actions = (item: any) => (
    <>
      {onAdd && (
        <Tooltip title="Добави подкатегория">
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setOpenDialog(true);
              setCategory(undefined);
              setParent(item);
            }}
            size="small"
          >
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      )}
      {onEdit && (
        <Tooltip title="Редактирай категория">
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setOpenDialog(true);
              setCategory(item);
              setParent(flattenTree(data).find((b: any) => b.id === item.parentId));
            }}
            size="small"
          >
            <CreateIcon />
          </IconButton>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title="Изтрий категория">
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setCategory(item);
              setOpenConfirmDeleteDialog(true);
            }}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  const handleOnDrop = (event: any, index: number, layerIndex: number, item: any) => {
    setDragOver('-1');
    setDragged('-1');
    var dragItem = JSON.parse(event.dataTransfer.getData('item'));
    setCategory({ ...dragItem, parentId: item.id });
    setOpenConfirmDialog(true);
  };

  const getItems = (items: any, layerIndex: number) =>
    viewOnly
      ? items.map((item: any, index: number) => getViewOnlyItem(item, index, layerIndex))
      : items.map((item: any, index: number) => getItem(item, index, layerIndex));

  const getViewOnlyItem = (item: any, index: number, layerIndex: number) => (
    <List key={item.id} dense={size === 'sm'} className={classes.list}>
      <ListItem divider className={classes.listItem} onClick={() => handleClick(item.id)}>
        {item.children &&
          !!item.children.length &&
          (open[item.id] ? <ExpandLess /> : <ExpandMore />)}
        <ListItemText primary={`${item.name} ${item.value}`} />
      </ListItem>
      {item.children && getChildren(item, layerIndex + 1)}
    </List>
  );

  const getItem = (item: any, index: number, layerIndex: number) => (
    <List
      key={item.id}
      dense={size === 'sm'}
      className={classes.list}
      style={drag === item.id ? { backgroundColor: '#FAFAFA' } : {}}
    >
      <ListItem
        divider
        draggable={!!onEdit}
        onDragStart={(event) => {
          setDragged(item.id);
          event.dataTransfer.setData('item', JSON.stringify(item));
        }}
        onDragOver={(event) => {
          event.preventDefault();
          if (drag !== item.id) {
            setDragOver(item.id);
          }
        }}
        onDrop={(event) => handleOnDrop(event, index, layerIndex, item)}
        onDragEnd={(event) => {
          setDragged('-1');
          setDragOver('-1');
          setSelected('-1');
        }}
        selected={dragged === item.id}
        className={classes.listItem}
        onClick={() => handleClick(item.id)}
        onMouseEnter={() => setSelected(item.id)}
        onMouseLeave={() => setSelected('')}
        style={marked === item.id ? { backgroundColor: '#3F51B517' } : {}}
      >
        {item.children &&
          !!item.children.length &&
          (open[item.id] ? <ExpandLess /> : <ExpandMore />)}
        <ListItemText primary={item.name} />
        {/* {actions(item)} */}
        {selected === item.id ? (
          actions(item)
        ) : (
          <IconButton className={classes.transparent} size="small">
            <AddBoxIcon />
          </IconButton>
        )}
      </ListItem>
      {item.children && getChildren(item, layerIndex + 1)}
    </List>
  );

  const getChildren = (item: any, layerIndex: number) => (
    <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
      <List dense={size === 'sm'} component="div" disablePadding className={classes.nested}>
        {item.children.map((i: any, index: number) =>
          viewOnly ? getViewOnlyItem(i, index, layerIndex) : getItem(i, index, layerIndex)
        )}
      </List>
    </Collapse>
  );

  return (
    <>
      {data && !!data.length && getItems(data, 0)}
      {onAdd && (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            setOpenDialog(true);
            setCategory(undefined);
            setParent(null);
          }}
          size="small"
        >
          <AddBoxIcon />
        </IconButton>
      )}
      <CategoryDialog
        open={openDialog}
        category={category}
        categories={flattenTree(data)}
        parent={parent}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onDelete={handleDialogDelete}
      />
      <AMSConfirmDialog
        open={openConfirmDialog}
        onConfirm={() => {
          if (category && onEdit) {
            onEdit(category);
          }
          setOpenConfirmDialog(false);
        }}
        onClose={() => setOpenConfirmDialog(false)}
        title={'Потвърждение за промяна на категория'}
        message={'Наистина ли искате да промените категорията?'}
      />
      <AMSConfirmDialog
        open={openConfirmDeleteDialog}
        onConfirm={() => {
          if (category && onDelete) {
            onDelete(category);
          }
          setOpenConfirmDeleteDialog(false);
        }}
        onClose={() => setOpenConfirmDeleteDialog(false)}
        title={'Потвърждение за изтриване на категория'}
        message={'Наистина ли искате да изтриете категорията?'}
      />
    </>
  );
};
export default AMSTree;
