import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseTransactionListResponse,
  TransactionListResponse,
  amsV3Service
} from '../../services';

export interface ITransactions<T> extends IAsyncType<T> {
  transactions: T;
}

const useTransactions = (
  id?: number[],
  invoiceId?: number,
  deliveryInvoiceId?: number,
  treasuries?: number[],
  fromCreatedDate?: Date | null,
  toCreatedDate?: Date | null
): ITransactions<TransactionListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseTransactionListResponse>(
      amsV3Service.getTransactions,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id, invoiceId, deliveryInvoiceId, treasuries, fromCreatedDate, toCreatedDate],
      true
    );
  return {
    transactions: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useTransactions;
