import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseSupplierListResponse,
  SupplierListResponse,
  amsV3Service
} from '../../services';

export interface ISuppliers<T> extends IAsyncType<T> {
  suppliers: T;
}

const useSuppliers = (
  id?: number[],
  query?: string,
  showInactive: boolean = false
): ISuppliers<SupplierListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseSupplierListResponse>(
      amsV3Service.getSuppliers,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id, query, showInactive]
    );
  return {
    suppliers: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useSuppliers;
