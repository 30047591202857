import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid
} from '@material-ui/core';
import { ItemResponse, UnitEnum, unitEnumLocalizations } from '../../../../services';
import React, { useEffect, useState } from 'react';

import AMSAsyncAutocomplete from '../../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSViewOnlyTextField from '../../../../helpers/ui/AMSViewOnlyTextField/AMSViewOnlyTextField';
import { s3ImagesUrl } from '../../../../helpers/openapi-axios-helper';
import { useStyles } from '../../../../helpers/hooks';

export interface AddStocktakingItemDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (request: any, addNew: boolean) => void;
}

const AddStocktakingItemDialog = ({ open, onClose, onSave }: AddStocktakingItemDialogProps) => {
  const classes = useStyles();

  const [item, setItem] = useState<ItemResponse | null>(null);

  const [addNew, setAddNew] = useState<boolean>(false);

  useEffect(() => {
    setItem(null);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>
        <div className={classes.title}>Добавяне на артикул</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className={classes.imageWrapper}>
          {!!item && (
            <img
              src={`${s3ImagesUrl}/items/${item.artNo}.png`}
              onError={(ev: any) => (ev.target.src = require('../../../../images/fallback.png'))}
              alt="-"
              className={classes.image}
            />
          )}
          {!item && (
            <img
              src={require('../../../../images/fallback.png')}
              alt="-"
              className={classes.image}
            />
          )}
        </div>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12}>
            <AMSAsyncAutocomplete
              label="Търсене"
              onChange={(value: ItemResponse | null) => {
                setItem(value);
              }}
              value={item}
              minChar={3}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <AMSViewOnlyTextField label="Име" value={item ? item.name : ''} />
          </Grid>

          <Grid item lg={6} md={6} sm={12}>
            <AMSViewOnlyTextField
              label="Артикулен номер"
              value={item && item.artNo ? item.artNo : ''}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <AMSViewOnlyTextField
              label="Мерна единица"
              value={
                item
                  ? `${unitEnumLocalizations[item.unitId as UnitEnum]}${
                      item.packageQty && item.packageQty > 1 ? ` от ${item.packageQty}` : ''
                    }`
                  : ''
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.addNewCheckboxWrapper}>
          <FormControlLabel
            className={classes.label}
            control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
            label="Добави нов след запис"
            labelPlacement="end"
          />
        </div>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button
          onClick={() => {
            if (item) {
              onSave(
                {
                  id: item.id,
                  itemArtNo: item.artNo,
                  itemName: item.name,
                  itemUnitName: unitEnumLocalizations[item.unitId as UnitEnum]
                },
                addNew
              );
              if (addNew) {
                setItem(null);
              }
            }
          }}
          color="primary"
          variant="contained"
        >
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddStocktakingItemDialog;
