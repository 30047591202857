import { AMSButton, AMSTextField } from '../../helpers/ui';
import { AccessPermissions, PartnerListResponse, cookiesService } from '../../services';
import { Checkbox, CircularProgress, Container, FormControlLabel, Grid } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { getExtendedLegalEntityId, getExtendedPartnerId } from '../../helpers/utils';
import { useFlag, usePartners, usePermissions, useStyles } from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import SearchIcon from '@material-ui/icons/Search';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const integrationTypes = ['-', 'Barsy', 'ICash', 'Coshera Retail', 'Mistral'];

const PartnersComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { showInactive } = useMemo(() => parseFilter(location.search), [location.search]);
  const filter = cookiesService.getPartnersList();
  const [text, setText] = useState(filter.query ? filter.query : '');
  const [filterShowInactive, setFilterShowInactive] = useFlag(
    showInactive !== undefined && showInactive !== null
      ? showInactive
      : (filter.filterShowInactive ?? false)
  );

  const { partners, setParameters, loading } = usePartners(text, filterShowInactive);

  const [canCreate] = usePermissions([AccessPermissions.CAN_CREATE_PARTNER]);

  const onSearch = useCallback(() => {
    cookiesService.setPartnersList({
      query: text,
      filterShowInactive
    });
    let params: any = {};
    if (text) {
      params.search = text;
    }
    if (filterShowInactive) {
      params.showInactive = filterShowInactive;
    }
    history.push({
      pathname: '/partners',
      search: new URLSearchParams(params).toString()
    });
    setParameters([text, filterShowInactive]);
  }, [text, filterShowInactive, history, setParameters]);

  const handleAdd = useCallback(() => history.push(`/partner`), [history]);

  return (
    <Container maxWidth="xl" className={classes.flex}>
      <Grid container spacing={1}>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <AMSTextField
            label="Търсене"
            placeholder="Търсене по име / адрес / юридическо лице / категория"
            value={text}
            onEnter={onSearch}
            onChange={(value) => setText(value ?? '')}
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: loading ? <CircularProgress /> : null
            }}
          />
        </Grid>
        <Grid item container lg={2} md={4} sm={12} xs={12} alignContent="flex-start">
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                checked={filterShowInactive}
                onChange={() => setFilterShowInactive(!showInactive)}
              />
            }
            label="Покажи неактивни"
            labelPlacement="end"
          />
        </Grid>
        <Grid item lg={1} md={2} sm={12} xs={12}>
          <AMSButton
            color="primary"
            variant="contained"
            text="Търсене"
            loading={loading}
            disabled={false}
            onClick={onSearch}
            style={{
              marginTop: 8
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AMSTable
            title="Контрагенти"
            columns={[
              {
                title: 'ИД',
                field: 'id',
                render: (rowData: PartnerListResponse) => (
                  <AMSLink href={`/partner?id=${rowData.id}`}>
                    {getExtendedPartnerId(rowData.id)}
                  </AMSLink>
                ),
                cellStyle: { width: '5%' }
              },
              {
                title: 'Име',
                field: 'name',
                render: (rowData: PartnerListResponse) => (
                  <AMSLink href={`/partner?id=${rowData.id}`}>{rowData.name}</AMSLink>
                ),
                cellStyle: { width: '20%' }
              },
              {
                title: 'Юридическо лице',
                field: 'legalEntityId',
                render: (rowData: PartnerListResponse) => (
                  <AMSLink href={`/legal-entity?id=${rowData.legalEntityId}`}>
                    {getExtendedLegalEntityId(rowData.legalEntityId)}
                  </AMSLink>
                ),
                cellStyle: { width: '20%' }
              },
              {
                title: 'Забележка',
                field: 'note',
                cellStyle: { width: '45%' }
              },
              {
                title: 'Интеграция',
                field: 'integrationType',
                render: (rowData: PartnerListResponse) => integrationTypes[rowData.integrationType],
                cellStyle: { width: '10%' }
              }
            ]}
            data={partners}
            onAdd={canCreate ? handleAdd : undefined}
            isLoading={loading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PartnersComponent;
