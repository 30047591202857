import { Autocomplete } from '@material-ui/lab';
import Lookup from '../../../models/lookup';
import React from 'react';
import { TextField } from '@material-ui/core';

interface Props {
  label?: string;
  options: Lookup[];
  sortOptions?: boolean;
  value?: any;
  onChange: (option: any) => void;
  onClick?: (option: any) => void;
  onChangeFilter?: (filter: any) => void;
  minChar?: number;
  required?: boolean;
  disabled?: boolean;
  groupBy?: (option: any) => string;
  limit?: number;
  limitTags?: number;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  getOptionLabel?: (option: any) => string;
  renderOption?: (option: any, data: any) => JSX.Element;
  renderGroup?: (option: any) => JSX.Element;
  renderInput?: (params: any) => JSX.Element;
}

const AMSAutocomplete = ({
  label = '',
  options,
  sortOptions = true,
  value,
  onChange,
  onClick,
  onChangeFilter,
  disabled = false,
  required = false,
  minChar = 3,
  groupBy,
  limit = 50,
  limitTags = 3,
  multiple = false,
  disableCloseOnSelect = false,
  getOptionLabel = (option: any) => `${option.value}`,
  renderOption,
  renderGroup,
  renderInput = (params) => (
    <TextField
      {...params}
      label={label}
      margin="dense"
      variant="outlined"
      placeholder={!!minChar ? `Минимум ${minChar} символа` : ''}
      fullWidth
      required={required}
      disabled={disabled}
      onChange={(event) => {
        if (event && onChangeFilter) {
          onChangeFilter(event.target.value);
        }
      }}
    />
  )
}: Props) => {
  return (
    <Autocomplete
      multiple={multiple}
      limitTags={limitTags}
      options={sortOptions ? options.sort((a, b) => a.id - b.id) : options}
      onClick={onClick}
      filterOptions={(opts: any, state: any) => {
        let options: any[] = [];
        if (state.inputValue.length >= minChar) {
          options = opts
            .filter((i: any) =>
              state.inputValue.includes(' ')
                ? state.inputValue
                    .toUpperCase()
                    .split(' ')
                    .every((v: string) => {
                      return (
                        i.value.toUpperCase().indexOf(v) > -1 ||
                        (i.group && i.group.toUpperCase().indexOf(v) > -1)
                      );
                    })
                : i.value.toUpperCase().includes(state.inputValue.toUpperCase()) ||
                  (i.group && i.group.toUpperCase().includes(state.inputValue.toUpperCase()))
            )
            .reduce(
              (res: any[], i: any) => {
                var idx = i.value
                  .toUpperCase()
                  .indexOf(state.inputValue.split(' ')[0].toUpperCase());
                var idxGroup =
                  i.group &&
                  i.group.toUpperCase().indexOf(state.inputValue.split(' ')[0].toUpperCase());
                if (idx === 0 || idxGroup === 0) {
                  res[0].push(i as never);
                } else if (idx > 0 || idxGroup > 0) {
                  res[1].push(i as never);
                }
                return res;
              },
              [[], []]
            )
            .reduce((res: any[], arr: any[]) => {
              res = [...res, ...arr];
              return res;
            }, []);
          if (limit > 0) {
            options = options.splice(0, limit);
          }
        }
        return options;
      }}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => option.id === value.id}
      fullWidth
      onChange={(_, option: any | null) => onChange(option)}
      value={value ? value : null}
      noOptionsText="Няма намерени резултати"
      disabled={disabled}
      groupBy={groupBy}
      renderOption={renderOption}
      renderGroup={renderGroup}
      renderInput={renderInput}
      disableCloseOnSelect={disableCloseOnSelect}
    />
  );
};

export default AMSAutocomplete;
