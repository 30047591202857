import {
  AccessPermissions,
  ItemPartnerConfigurationResponse,
  PartnerListResponse,
  amsV3Service,
  snackbarService
} from '../../../services';
import { CheckCircle, WarningOutlined } from '@material-ui/icons';
import { Container, Grid, Paper, Tooltip } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useFlag, useItem, usePartners, usePermissions, useStyles } from '../../../helpers/hooks';

import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import { AMSTable } from '../../../helpers/ui';
import EditPartnerItemConfigurationDialog from '../../PartnerComponent/Tabs/PartnerItemConfigurationComponent/EditPartnerItemConfigurationDialog/EditPartnerItemConfigurationDialog';
import { useItemPartnerConfigurations } from '../../../helpers/hooks';

interface ItemPartnerConfigurationPanelProps {
  itemId: number;
}

const ItemPartnerConfigurationComponent = ({ itemId }: ItemPartnerConfigurationPanelProps) => {
  const classes = useStyles();
  const [openEditDialog, setOpenEditDialog] = useFlag(false);
  const { partners } = usePartners(undefined, true);
  const [itemPartnerConfiguration, setItemPartnerConfiguration] =
    useState<ItemPartnerConfigurationResponse>();

  const { item } = useItem(itemId);

  const { itemPartnerConfigurations, loading, setLoading, setParameters } =
    useItemPartnerConfigurations(itemId);

  const [canUpdate] = usePermissions([AccessPermissions.CAN_UPDATE_PARTNER]);

  const handleAdd = useCallback(
    async (item, itemId) => {
      setLoading(true);
      const request = {
        ...item
      };
      if (itemPartnerConfiguration?.id) {
        const resp = await amsV3Service.updatePartnerItemConfiguration(
          itemPartnerConfiguration.id,
          request
        );
        if (resp?.data) {
          snackbarService.setSnackbarOpen(true);
          setItemPartnerConfiguration(undefined);
          setParameters([itemId]);
        }
      } else {
        const resp = await amsV3Service.createPartnerItemConfiguration(itemId, request);
        if (resp?.data) {
          if (!canUpdate) snackbarService.setSnackbarOpen(true);
          setItemPartnerConfiguration(undefined);
          setParameters([itemId]);
        }
      }
      setLoading(false);
    },
    [setParameters, itemPartnerConfiguration, canUpdate, setLoading]
  );

  const handleDelete = useCallback(
    async (deleteItem: any) => {
      setLoading(true);
      const resp = await amsV3Service.deletePartnerItemConfiguration(deleteItem.id);
      if (resp?.data) {
        snackbarService.setSnackbarOpen(true);
        setParameters([itemId]);
      }
      setLoading(false);
    },
    [itemId, setParameters, setLoading]
  );

  const titleArtNo = useMemo(() => 'Външен арт №', []);

  const titleName = useMemo(() => 'Външно име', []);

  const partnersById = useMemo(
    () =>
      partners.reduce((acc: any, partner: PartnerListResponse) => {
        acc[partner.id] = partner;
        return acc;
      }, {}),
    [partners]
  );

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper elevation={2} className={classes.paper}>
            <AMSTable
              title="Списък с мапинги"
              data={itemPartnerConfigurations}
              columns={[
                {
                  title: 'Контрагент',
                  field: 'partnerName',
                  render: (rowData: ItemPartnerConfigurationResponse) => {
                    const partner = partnersById[rowData.partnerId];
                    return (
                      <AMSLink
                        href={`/partner?id=${rowData.partnerId}&tabId=5`}
                        style={item?.isActive ? undefined : { color: '#6476dd' }}
                      >
                        {partner?.name}
                      </AMSLink>
                    );
                  },
                  cellStyle: { width: '25%' }
                },
                {
                  title: titleArtNo,
                  field: 'externalItemArtNo',
                  cellStyle: { width: '20%' }
                },
                {
                  title: titleName,
                  field: 'externalItemName',
                  cellStyle: { width: '25%' }
                },
                {
                  title: 'Минимум',
                  field: 'min',
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Максимум',
                  field: 'max',
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Предупреждение',
                  field: 'itemId',
                  render: (rowData: ItemPartnerConfigurationResponse) => {
                    let warning = '';
                    if (item && rowData.max) {
                      const effectiveMin = rowData.min ? +rowData.min : +rowData.max * 0.5;
                      if (
                        +rowData.max - effectiveMin < item.packageQtyDelivery &&
                        +rowData.max > effectiveMin
                      ) {
                        warning = `Разликата между МИН и МАКС не трябва да е по-малка от ${item.packageQtyDelivery}`;
                      }
                      if (+rowData.max > 0 && +rowData.max < item.packageQtyDelivery) {
                        warning = `МАКС не трябва да е по-малко от ${item.packageQtyDelivery}`;
                      }
                    }
                    return (
                      <Tooltip title={warning}>
                        {warning ? (
                          <WarningOutlined color="error" />
                        ) : (
                          <CheckCircle color="primary" />
                        )}
                      </Tooltip>
                    );
                  },
                  align: 'center',
                  cellStyle: { width: '10%' }
                }
              ]}
              paging={false}
              inlineActions={false}
              components={{
                Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
              }}
              onDelete={itemId && canUpdate ? handleDelete : undefined}
              onEdit={
                itemId && canUpdate
                  ? (value: ItemPartnerConfigurationResponse) => {
                      setItemPartnerConfiguration({ ...item, ...value });
                      setOpenEditDialog(true);
                    }
                  : undefined
              }
              isLoading={loading}
            />
          </Paper>
          {canUpdate && item && itemPartnerConfiguration && (
            <EditPartnerItemConfigurationDialog
              partnerId={itemPartnerConfiguration.partnerId}
              partnerIntegrationType={
                partnersById[itemPartnerConfiguration.partnerId].partnerIntegrationType
              }
              partnerItemConfiguration={itemPartnerConfiguration}
              internalItem={{
                id: item.id,
                value: item.name,
                selectedView: item.artNo
              }}
              open={openEditDialog}
              onClose={() => setOpenEditDialog(false)}
              onSave={(item: any, addNew: boolean) => {
                if (!addNew) {
                  setOpenEditDialog(false);
                }
                handleAdd(item, itemId);
              }}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ItemPartnerConfigurationComponent;
