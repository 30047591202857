import { Container, Grid, createStyles, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback, useEffect, useState } from 'react';
import { UserListResponse, amsLegacyService, cookiesService } from '../../../services';
import { ignoreOffset, isValidDate, toEndOfDay, toStartOfDay } from '../../../helpers/date-helper';

import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import DateFnsUtils from '@date-io/date-fns';
import { StockTakingReportResponse } from '../../../services/api';
import { getExtendedStockTakingReportId } from '../../../helpers/utils';
import { useUsers } from '../../../helpers/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: { marginLeft: 0 }
  })
);

const StockTakingReportsComponent = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [stockTakingReports, setStockTakingReports] = useState<any[]>([]);
  const { users } = useUsers();

  const filter = cookiesService.getReportDeliveryItemsFilter();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    filter.filterToDate ? ignoreOffset(new Date(filter.filterToDate)) : new Date()
  );
  const [filterUser, setFilterUser] = useState<UserListResponse | undefined>();

  const search = useCallback(async () => {
    setLoading(true);
    if (
      (filterFromDate === null || isValidDate(filterFromDate)) &&
      (filterToDate === null || isValidDate(filterToDate)) &&
      (filterUser === undefined || filterUser === null || filterUser)
    ) {
      const fromExecutionDate = isValidDate(filterFromDate)
        ? toStartOfDay(filterFromDate)
        : undefined;
      const toExecutionDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate) : undefined;
      const userId = filterUser ? filterUser.id : undefined;
      const stockTakingReportsResponse = await amsLegacyService.getStockTakingReports(
        fromExecutionDate,
        toExecutionDate,
        userId
      );
      if (stockTakingReportsResponse) {
        setStockTakingReports(stockTakingReportsResponse.data);
      }
    }
    setLoading(false);
  }, [filterFromDate, filterToDate, filterUser]);

  useEffect(() => {
    cookiesService.setStockTakingReportsFilter({
      filterFromDate: filterFromDate ? toStartOfDay(filterFromDate) : undefined,
      filterToDate: filterToDate ? toEndOfDay(filterToDate) : undefined,
      filterUser
    });
    search();
  }, [search, filterFromDate, filterToDate, filterUser]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={1}>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              variant="inline"
              format="dd/MM/yy"
              margin="dense"
              label="От"
              helperText={''}
              value={filterFromDate ? filterFromDate : null}
              onChange={(value: Date | null) => {
                if (value) {
                  if (isValidDate(value)) {
                    setFilterFromDate(ignoreOffset(value));
                  }
                } else {
                  setFilterFromDate(null);
                }
              }}
              inputVariant="outlined"
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              maxDate={filterToDate}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              variant="inline"
              format="dd/MM/yy"
              margin="dense"
              label="До"
              helperText={''}
              value={filterToDate ? filterToDate : null}
              onChange={(value: Date | null) => {
                if (value) {
                  if (isValidDate(value)) {
                    setFilterToDate(ignoreOffset(value));
                  }
                } else {
                  setFilterToDate(null);
                }
              }}
              inputVariant="outlined"
              fullWidth
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              minDate={filterFromDate}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <AMSAutocomplete
              label="Потребител"
              options={users.map((u) => ({
                id: u.id,
                value: u.name
              }))}
              value={filterUser ?? null}
              onChange={(values) => setFilterUser(values)}
              minChar={0}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AMSTable
              title="Ревизионни протоколи"
              columns={[
                {
                  title: 'ИД',
                  field: 'id',
                  render: (rowData: StockTakingReportResponse) => (
                    <AMSLink href={`/stock-taking-report?id=${rowData.id}`}>
                      {getExtendedStockTakingReportId(rowData.id)}
                    </AMSLink>
                  ),
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Дата на изпълнение',
                  field: 'executionDate',
                  cellStyle: { width: '30%' }
                },
                {
                  title: 'Потребител',
                  field: 'userResponsibleName',
                  cellStyle: { width: '40%' }
                },
                {
                  title: 'Брой артикули',
                  field: 'items.length',
                  cellStyle: { width: '20%' }
                }
              ]}
              data={stockTakingReports}
              pageSize={10}
              isLoading={loading}
            />
          </Grid>
        </Grid>
      </Container>
    </MuiPickersUtilsProvider>
  );
};

export default StockTakingReportsComponent;
