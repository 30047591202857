import {
  AccessPermissions,
  accessManagementService,
  amsLegacyService,
  snackbarService
} from '../../services';
import Category, { CategoryForm } from '../../models/category';
import { CircularProgress, Container, Paper } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AMSTree from '../../helpers/ui/AMSTable/AMSTree';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0
  },
  paper: {
    padding: 20,
    minHeight: '85vh'
  }
}));

const Categories = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [open, setOpen] = useState<any>({ '-1': true });
  const [marked, setMarked] = useState<number>(-1);

  const loadCategories = useCallback(async () => {
    const resp: any = await amsLegacyService.getCategories();
    setData(resp ? resp.data : []);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  const canCreate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_ITEM_CATEGORY),
    []
  );

  const canRead = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_READ_ITEM_CATEGORY),
    []
  );

  const canUpdate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_ITEM_CATEGORY),
    []
  );

  const canDelete = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_DELETE_ITEM_CATEGORY),
    []
  );

  const onAdd = async (category: CategoryForm) => {
    const resp = await amsLegacyService.createCategory(category);
    if (resp) {
      setLoading(true);
      await loadCategories();
      setMarked(resp.data.id);
      setTimeout(() => {
        setMarked(-1);
      }, 2500);
      snackbarService.setSnackbarOpen(true);
      setOpenDialog(false);
      setLoading(false);
    }
  };

  const onEdit = async (category: Category) => {
    setLoading(true);
    const resp = await amsLegacyService.updateCategory(category);
    if (resp) {
      setOpenDialog(false);
      await loadCategories();
      setMarked(resp.data.id);
      setTimeout(() => {
        setMarked(-1);
      }, 2500);
    }
    setLoading(false);
  };

  const onDelete = async (category: Category) => {
    if (category.id) {
      const resp = await amsLegacyService.deleteCategory(category.id);
      if (resp) {
        setLoading(true);
        await loadCategories();
      }
      setOpenDialog(false);
      setLoading(false);
    }
  };

  return loading && !data.length ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="sm" style={loading ? { opacity: 0.5 } : {}} className={classes.container}>
      <Paper elevation={2} className={classes.paper}>
        <AMSTree
          data={data}
          open={open}
          setOpen={setOpen}
          marked={marked}
          onAdd={canCreate ? onAdd : undefined}
          onEdit={canRead && canUpdate ? onEdit : undefined}
          onDelete={canDelete ? onDelete : undefined}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      </Paper>
    </Container>
  );
};

export default Categories;
