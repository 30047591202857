import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponsePartnerItemConfigurationResponse,
  PartnerItemConfigurationResponse,
  amsV3Service
} from '../../services';

export interface IPartnerItemConfiguration<T> extends IAsyncType<T> {
  partnerItemConfigurations: T;
}

const usePartnerItemConfiguration = (
  id: number
): IPartnerItemConfiguration<PartnerItemConfigurationResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponsePartnerItemConfigurationResponse>(
      amsV3Service.getPartnerItemConfigurations,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id],
      true
    );
  return {
    partnerItemConfigurations: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default usePartnerItemConfiguration;
