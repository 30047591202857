interface CurrencyInterface {
  name: string;
  alpha3: string;
}

export default class Currency implements CurrencyInterface {
  constructor(name: string, alpha3: string) {
    this.name = name;
    this.alpha3 = alpha3;
  }

  name: string;
  alpha3: string;
}
