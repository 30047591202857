import { AMSPdfViewerDialog, AMSTable } from '../../../../helpers/ui';
import { CircularProgress, Grid } from '@material-ui/core';
import React, { useState } from 'react';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import { InvoiceListResponse } from '../../../../services';
import { getInvoiceUrl } from '../../../../helpers/utils';
import { useInvoiceCreditNotes } from '../../../../helpers/hooks';

interface InvoiceCreditNotesComponentProps {
  id: number;
}

const InvoiceCreditNotesComponent = ({ id }: InvoiceCreditNotesComponentProps) => {
  const { invoiceCreditNotes, loading } = useInvoiceCreditNotes(id);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [openInvoicePreview, setOpenInvoicePreview] = useState(false);

  return loading ? (
    <CircularProgress />
  ) : (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <AMSTable
          title="Кредитни известия"
          columns={[
            {
              title: '№',
              field: 'number',
              render: (rowData: InvoiceListResponse) => (
                <AMSLink href={`/invoice?id=${rowData.id}`}>{rowData.number}</AMSLink>
              ),
              cellStyle: { width: '5%' }
            },
            {
              title: 'Дата',
              field: 'createdDate',
              type: 'date',
              cellStyle: { width: '15%' }
            },
            {
              title: 'Падеж',
              field: 'paymentDue',
              type: 'date',
              cellStyle: { width: '15%' }
            },
            {
              title: 'Сума (без ДДС)',
              field: 'netTotal',
              cellStyle: { width: '15%' },
              type: 'currency',
              currencySetting: {
                locale: 'bg',
                currencyCode: 'bgn',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            },
            {
              title: 'ДДС',
              field: 'vatTotal',
              cellStyle: { width: '15%' },
              type: 'currency',
              currencySetting: {
                locale: 'bg',
                currencyCode: 'bgn',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            },
            {
              title: 'Сума (с ДДС)',
              field: 'totalIncludingVat',
              cellStyle: { width: '15%' },
              type: 'currency',
              currencySetting: {
                locale: 'bg',
                currencyCode: 'bgn',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            },
            {
              title: 'За плащане',
              field: 'remainingAmount',
              cellStyle: { width: '15%' },
              type: 'currency',
              currencySetting: {
                locale: 'bg',
                currencyCode: 'bgn',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }
            }
          ]}
          pageSize={12}
          data={invoiceCreditNotes}
          onView={(rowData: InvoiceListResponse) => {
            setTitle(rowData.number);
            setUrl(getInvoiceUrl(rowData.id));
            setOpenInvoicePreview(true);
          }}
          isDeletable={() => true}
          isLoading={loading}
        />
      </Grid>
      <AMSPdfViewerDialog
        title={title}
        url={url}
        open={openInvoicePreview}
        onClose={() => setOpenInvoicePreview(false)}
      />
    </Grid>
  );
};

export default InvoiceCreditNotesComponent;
