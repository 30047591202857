import { IAsyncType, useAsync } from '.';
import {
  LegalEntityListResponse,
  PaginatedListResponseLegalEntityListResponse,
  amsV3Service
} from '../../services';

export interface ILegalEntities<T> extends IAsyncType<T> {
  legalEntities: T;
}

const useLegalEntities = (
  id?: number[],
  showInactive: boolean = false,
  query?: string,
  company?: number
): ILegalEntities<LegalEntityListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseLegalEntityListResponse>(
      amsV3Service.getLegalEntities,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id, showInactive, query, company]
    );
  return {
    legalEntities: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useLegalEntities;
