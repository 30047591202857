import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { OrderV2Response } from '../../services/api';
import { amsLegacyService } from '../../services';

export interface IOrder<T> extends IAsyncType<T> {
  order: T;
  setOrder: Dispatch<SetStateAction<T>>;
}

/**
 *
 * @deprecated
 */
const useOrder = (
  id?: number,
  withLivePrices?: boolean | undefined
): IOrder<OrderV2Response | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<OrderV2Response | null>(amsLegacyService.getOrder, null, [id, withLivePrices]);
  return {
    order: data,
    setOrder: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useOrder;
