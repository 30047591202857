import {
  AccessPermissions,
  DeliveryResponse,
  SupplierListResponse,
  UserListResponse,
  WarehouseListResponse,
  amsV3Service,
  cookiesService
} from '../../services';
import { Container, Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import {
  ignoreOffset,
  isValidDate,
  toDatetimeString,
  toEndOfDay,
  toStartOfDay
} from '../../helpers/date-helper';
import { supplierToLookup, warehouseToLookup } from '../../models/lookup';
import {
  useDeliveries,
  usePermissions,
  useStyles,
  useSuppliers,
  useUsers,
  useWarehouses
} from '../../helpers/hooks';

import AMSAutocomplete from '../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import { AMSButton } from '../../helpers/ui';
import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import { getExtendedDeliveryId } from '../../helpers/utils';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Deliveries = ({ location }: any) => {
  const history = useHistory();
  const classes = useStyles();

  const { fromDate, toDate, supplierIds, warehouseIds } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const filter = cookiesService.getDeliveriesList();
  const [filterFromDate, setFilterFromDate] = useState<any>(
    fromDate
      ? fromDate
      : filter.filterFromDate
        ? ignoreOffset(new Date(filter.filterFromDate))
        : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<any>(
    toDate ? toDate : filter.filterToDate ? ignoreOffset(new Date(filter.filterToDate)) : new Date()
  );
  const [filterSuppliers, setFilterSuppliers] = useState<SupplierListResponse[]>(
    filter.filterSuppliers ? filter.filterSuppliers : []
  );
  const [filterWarehouses, setFilterWarehouses] = useState<WarehouseListResponse[]>(
    filter.filterWarehouses ? filter.filterWarehouses : []
  );

  const { deliveries, setParameters, loading } = useDeliveries(
    supplierIds ?? filterSuppliers.map((s: any) => s.id),
    warehouseIds ?? filterWarehouses.map((w: any) => w.id),
    filterFromDate ? toDatetimeString(filterFromDate) : undefined,
    filterToDate ? toDatetimeString(filterToDate) : undefined
  );

  const [canCreate, canDelete] = usePermissions([
    AccessPermissions.CAN_CREATE_DELIVERY,
    AccessPermissions.CAN_DELETE_DELIVERY
  ]);

  const { suppliers } = useSuppliers();
  const { warehouses } = useWarehouses();
  const { users } = useUsers();

  const warehouseNames: any = useMemo(
    () =>
      warehouses.reduce((res: any, warehouse: WarehouseListResponse) => {
        if (!res) {
          res = {};
        }
        res[`${warehouse.id}`] = warehouse.name;
        return res;
      }, {}),
    [warehouses]
  );

  const supplierNames: any = useMemo(
    () =>
      suppliers.reduce((res: any, supplier: SupplierListResponse) => {
        if (!res) {
          res = {};
        }
        res[`${supplier.id}`] = supplier.name;
        return res;
      }, {}),
    [suppliers]
  );

  const userNames: any = useMemo(
    () =>
      users.reduce((res: any, user: UserListResponse) => {
        if (!res) {
          res = {};
        }
        res[`${user.id}`] = user.name;
        return res;
      }, {}),
    [users]
  );

  const onSearch = useCallback(async () => {
    if (
      (filterFromDate === null || isValidDate(filterFromDate)) &&
      (filterToDate === null || isValidDate(filterToDate))
    ) {
      const fromDate = isValidDate(filterFromDate) ? toStartOfDay(filterFromDate) : undefined;
      const toDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate) : undefined;
      cookiesService.setDeliveriesList({
        filterFromDate: filterFromDate ? toStartOfDay(filterFromDate) : undefined,
        filterToDate: filterToDate ? toEndOfDay(filterToDate) : undefined,
        filterSuppliers,
        filterWarehouses
      });
      let params: any = {};
      if (filterFromDate) {
        params.fromDate = toDatetimeString(filterFromDate);
      }
      if (filterToDate) {
        params.toDate = toDatetimeString(filterToDate);
      }
      if (filterSuppliers?.length > 0) {
        params.suppliers = filterSuppliers.map((s) => s.id).join(',');
      }
      if (filterWarehouses?.length > 0) {
        params.warehouses = filterWarehouses.map((w) => w.id).join(',');
      }
      history.push({
        pathname: '/deliveries',
        search: new URLSearchParams(params).toString()
      });
      setParameters([
        filterSuppliers.map((supplier: SupplierListResponse) => supplier.id),
        filterWarehouses.map((warehouse: WarehouseListResponse) => warehouse.id),
        fromDate,
        toDate
      ]);
    }
  }, [filterFromDate, filterToDate, filterSuppliers, filterWarehouses, history, setParameters]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-align-left">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={suppliers.map(supplierToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Доставчик"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterSuppliers}
                  onChange={(values) => setFilterSuppliers(values)}
                />
              </Grid>
              <Grid item lg={2} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="От"
                  helperText={''}
                  value={filterFromDate ? filterFromDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterFromDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterFromDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  maxDate={filterToDate}
                />
              </Grid>
              <Grid item lg={2} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="До"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDate={filterFromDate}
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={warehouses.map(warehouseToLookup)}
                  sortOptions={false}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Складове"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterWarehouses}
                  onChange={(values) => setFilterWarehouses(values)}
                />
              </Grid>
              <Grid item lg={1} md={2} sm={12} xs={12}>
                <AMSButton
                  color="primary"
                  variant="contained"
                  text="Търсене"
                  loading={loading}
                  disabled={false}
                  onClick={onSearch}
                  style={{
                    marginTop: 8
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AMSTable
                  title="Списък с доставки"
                  columns={[
                    {
                      title: '№',
                      field: 'id',
                      render: (delivery: DeliveryResponse) => (
                        <AMSLink href={`/delivery?id=${delivery.id}`}>
                          {getExtendedDeliveryId(delivery.id)}
                        </AMSLink>
                      ),
                      cellStyle: { width: '5%' }
                    },
                    {
                      title: 'Създадена на',
                      field: 'deliveryDate',
                      render: (rowData: any) =>
                        rowData.deliveryDate.substr(0, 19).split('T').join(', '),
                      cellStyle: { width: '20%' }
                    },
                    {
                      title: 'Доставчик',
                      field: 'supplierName',
                      render: (delivery: DeliveryResponse) => (
                        <AMSLink href={`/supplier?id=${delivery.supplierId}`}>
                          {supplierNames ? supplierNames[delivery.supplierId] : 'Зареждане ...'}
                        </AMSLink>
                      ),
                      cellStyle: { width: '25%' }
                    },
                    {
                      title: 'Склад',
                      field: 'warehouseName',
                      render: (delivery: DeliveryResponse) => (
                        <AMSLink href={`/warehouse?id=${delivery.warehouseId}`}>
                          {warehouseNames ? warehouseNames[delivery.warehouseId] : 'Зареждане ...'}
                        </AMSLink>
                      ),
                      cellStyle: { width: '25%' }
                    },
                    {
                      title: 'Създадена от',
                      field: 'createdBy',
                      render: (delivery: DeliveryResponse) => (
                        <AMSLink href={`/user?id=${delivery.createdById}`}>
                          {userNames ? userNames[delivery.createdById] : 'Зареждане ...'}
                        </AMSLink>
                      ),
                      cellStyle: { width: '25%' }
                    }
                  ]}
                  pageSize={10}
                  data={deliveries}
                  onAdd={canCreate ? () => history.push(`/delivery`) : undefined}
                  onDelete={
                    canDelete
                      ? async (delivery: DeliveryResponse) => {
                          await amsV3Service.deleteDelivery(delivery.id);
                          onSearch();
                        }
                      : undefined
                  }
                  isLoading={loading}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default Deliveries;
