import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

import { OrderV2Response } from '../../services/api';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    textAlign: 'center'
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    width: '100%'
  },
  icon: {
    width: 72,
    height: 72
  }
}));

export interface OrderNoteDialogProps {
  open: boolean;
  viewMode: boolean;
  onSave: () => void;
  onClose: () => void;
  note: string;
  setNote: (note: string) => void;
  order: OrderV2Response;
}

const OrderNoteDialog = ({
  open,
  viewMode,
  onSave,
  onClose,
  note,
  setNote,
  order
}: OrderNoteDialogProps) => {
  const classes = useStyles();

  return order ? (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>Забележка</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container>
          <Grid item xs={12} className={classes.centered}>
            <TextField
              required
              variant="outlined"
              multiline
              rows={4}
              value={note ? note : ''}
              disabled={viewMode}
              inputProps={{ style: { fontSize: '125%' } }}
              onChange={(event: any) => setNote(event.target?.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!viewMode && (
          <Button onClick={onClose} color="secondary" variant="contained">
            Отказ
          </Button>
        )}
        <Button
          onClick={() => (viewMode ? onClose() : onSave())}
          color="primary"
          variant="contained"
        >
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default OrderNoteDialog;
