import { AMSAutocomplete, AMSButton, AMSViewOnlyTextField } from '../../../../helpers/ui';
import {
  AccessPermissions,
  DeliveryRequestItemResponse,
  DeliveryRequestStatus,
  StartStockReceivingProcessRequest,
  amsV3Service
} from '../../../../services';
import CreateStockReceivingProcessTableComponent, {
  StockReceivingProcessItem
} from './CreateStockReceivingProcessTableComponent';
import { Grid, Paper } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toDateString, toYYYYMMDD } from '../../../../helpers/date-helper';
import { useLookup, usePermissions, useStyles, useUsers } from '../../../../helpers/hooks';

import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import { getExtendedDeliveryRequestId } from '../../../../helpers/utils';
import { useHistory } from 'react-router-dom';
import { userToLookup } from '../../../../models/lookup';

interface CreateStockReceivingProcessComponentProps {
  deliveryRequestId: number;
  supplierId: number;
  supplierInfo: string;
  warehouseInfo: string;
  deliveryDate: string;
  createdDate: string;
  status: DeliveryRequestStatus;
  items: DeliveryRequestItemResponse[];
}

const CreateStockReceivingProcessComponent = ({
  deliveryRequestId,
  supplierId,
  supplierInfo,
  warehouseInfo,
  deliveryDate,
  createdDate,
  status,
  items
}: CreateStockReceivingProcessComponentProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = useLookup();
  const { users } = useUsers();
  const [documentItems, setDocumentItems] = useState<StockReceivingProcessItem[]>(items);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_DELIVERY,
    AccessPermissions.CAN_UPDATE_DELIVERY
  ]);

  useEffect(() => {
    setDocumentItems(items);
  }, [items]);

  const submitDeliveryRequestTask = useCallback(async () => {
    const request: StartStockReceivingProcessRequest = {
      counterUserId: user!.id,
      items: documentItems.map((item: StockReceivingProcessItem) => {
        const cleanValue = item.documentQuantity?.replace(',', '.') ?? item.quantity;
        return {
          itemId: item.itemId!,
          quantity: (cleanValue && !isNaN(+cleanValue) ? +cleanValue : 0).toFixed(3),
          batchNumber: !!item.batchNumber
            ? item.batchNumber
            : `${deliveryRequestId}_${toYYYYMMDD(new Date())}`,
          expirationDate:
            item.expirationDate ??
            toDateString(new Date(new Date().getTime() + 20 * 365 * 24 * 60 * 60 * 1000)),
          countryOfOrigin: item.countryOfOrigin ?? 'BGR'
        };
      })
    };
    const resp = await amsV3Service.createStockReceivingProcess(deliveryRequestId, request);
    if (resp.status === 204) {
      history.go(0);
    }
  }, [deliveryRequestId, documentItems, user, history]);

  const isCancelled = useMemo(() => status === DeliveryRequestStatus.CANCELLED, [status]);

  return (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <OutlinedDiv label={!isCancelled ? 'Данни от документ' : 'Заявка'} disabled={true}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} lg={2}>
                <AMSViewOnlyTextField
                  label="Номер"
                  value={getExtendedDeliveryRequestId(deliveryRequestId)}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <AMSViewOnlyTextField label="Склад" value={warehouseInfo} />
              </Grid>
              <Grid item sm={12} md={3} lg={2}>
                <AMSViewOnlyTextField label="Доставка на" value={deliveryDate} />
              </Grid>
              <Grid item sm={12} md={3} lg={2}>
                <AMSViewOnlyTextField label="Статус" value={status.label} />
              </Grid>
              <Grid item sm={12} md={6} lg={2}>
                <AMSViewOnlyTextField label="Създадена на" value={createdDate} />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <AMSViewOnlyTextField label="Доставчик" value={supplierInfo} />
              </Grid>
              <Grid item sm={12} md={6} lg={4}>
                {!isCancelled && (
                  <AMSAutocomplete
                    label="Отговорник"
                    options={users.map(userToLookup)}
                    value={user}
                    onChange={setUser}
                    minChar={0}
                    required
                  />
                )}
              </Grid>
            </Grid>
          </OutlinedDiv>
        </Grid>
        <Grid item lg={12}>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <CreateStockReceivingProcessTableComponent
                supplierId={supplierId}
                items={documentItems}
                setItems={setDocumentItems}
                status={status}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} justify="flex-end">
          {(canCreate || canUpdate) && !isCancelled && (
            <AMSButton
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={submitDeliveryRequestTask}
              disabled={!user}
              text="Запиши"
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreateStockReceivingProcessComponent;
