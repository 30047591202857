import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { ReportDeliveryDocumentResponse } from '../../services/api';
import { amsLegacyService } from '../../services';

export interface IDeliveryDocuments<T> extends IAsyncType<T> {
  deliveryDocuments: T;
  setDeliveryDocuments: Dispatch<SetStateAction<T>>;
  setDeliveryDocumentsParameters: Dispatch<any[] | null>;
  deliveryDocumentsLoading: boolean;
}

/**
 *
 * @deprecated
 */
const useDeliveryDocuments = (
  fromDate?: string,
  toDate?: string,
  suppliersIds?: number[],
  companyIds?: number[],
  hidePaid?: boolean
): IDeliveryDocuments<ReportDeliveryDocumentResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    ReportDeliveryDocumentResponse[]
  >(
    amsLegacyService.getDeliveryDocumentsReport,
    [],
    [fromDate, toDate, suppliersIds, companyIds, hidePaid]
  );
  return {
    deliveryDocuments: data,
    setDeliveryDocuments: setData,
    loading,
    deliveryDocumentsLoading: loading,
    setLoading,
    parameters,
    setParameters,
    setDeliveryDocumentsParameters: setParameters
  };
};

export default useDeliveryDocuments;
