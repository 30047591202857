import React, { useMemo } from 'react';

export interface FinancialInfoItem {
  title: string;
  subtitle?: string;
  amount: number;
}

interface FinanceInfoComponentProps {
  items: FinancialInfoItem[];
}

const FinanceInfoComponent = ({ items }: FinanceInfoComponentProps) => {
  const mainItem = useMemo(() => (items?.length > 0 ? items[0] : null), [items]);
  const subItems = useMemo(() => (items?.length > 0 ? items.slice(1) : []), [items]);
  if (mainItem === null) {
    return <div>'No data'</div>;
  }
  return (
    <div
      style={{
        textTransform: 'uppercase',
        color: 'black',
        padding: '1em',
        justifyContent: 'space-around',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          color: 'orange',
          fontWeight: 'bold',
          fontSize: '3.5rem',
          marginBottom: '10px',
          display: 'flex'
        }}
      >
        {mainItem.amount.toFixed(2)}
        <div style={{ fontSize: '50%', marginTop: 'auto', marginBottom: '12px' }}>
          {mainItem.subtitle ?? ''}
        </div>
      </div>
      <div style={{ marginBottom: '0.5rem' }}>{mainItem.title}</div>
      {subItems?.map((item: FinancialInfoItem) => (
        <div
          key={item.title}
          style={{
            display: 'flex',
            flex: 1,
            fontSize: '1.25rem',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{ color: 'orange', fontWeight: 'bold', marginRight: '10px', display: 'flex' }}
          >
            {item.amount}
            <div style={{ fontSize: '50%', marginTop: 'auto', marginBottom: '3px' }}>
              {item.subtitle ?? ''}
            </div>
          </div>
          <div>{item.title}</div>
        </div>
      ))}
    </div>
  );
};

export default FinanceInfoComponent;
