import { AMSApiFactory, Configuration } from '../services/api';

import { AMSApiFactory as AMSV3ApiFactory } from '../services';
import firebase from 'firebase/app';

export const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
export const s3ImagesUrl = process.env.REACT_APP_S3_IMAGES_URL;
export const clientAppApiKey = process.env.REACT_APP_CLIENT_APP_API_KEY;
export const isB2B = process.env.REACT_APP_OPERATIONAL_MODE === 'B2B';
export const MAX_NUMBER_OF_RESULTS = 1000;

const configWithToken = async () => {
  const idToken: string | undefined = await firebase.auth().currentUser?.getIdToken(false);
  return new Configuration({
    accessToken: `Bearer ${idToken}`,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  });
};

/**
 *
 * @deprecated
 */
export const AMSApi = async () => {
  const config = await configWithToken();
  return AMSApiFactory(config, backendApiUrl);
};

export const AMSV3Api = async () => {
  const config = await configWithToken();
  return AMSV3ApiFactory(config, `${backendApiUrl}/v3`);
};
