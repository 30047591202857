import {
  AMSAutocomplete,
  AMSButton,
  AMSCountryAutocomplete,
  AMSTextField
} from '../../../../helpers/ui';
import {
  AccessPermissions,
  CompanyListResponse,
  LegalEntityResponse,
  PaymentOptionResponse,
  SaveLegalEntityRequest,
  UserAccessResponse,
  accessManagementService,
  amsV3Service,
  snackbarService
} from '../../../../services';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { findCountry, findCountryByAlpha2 } from '../../../../helpers/country-helper';
import {
  getExtendedCompanyId,
  getExtendedLegalEntityId,
  getExtendedPaymentOptionId
} from '../../../../helpers/utils';
import {
  useCompanies,
  useObservable,
  usePaymentOptions,
  usePermissions
} from '../../../../helpers/hooks';

import Country from '../../../../models/country';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Lookup from '../../../../models/lookup';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import React from 'react';
import { SearchOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    saveButton: {
      marginTop: 10
    },
    label: {
      padding: 6
    },
    addNewCheckboxWrapper: {
      paddingTop: 8
    },
    copyButtonWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    paper: {
      padding: 20,
      marginRight: 260
    }
  })
);

interface LegalEntityInfoComponentProps {
  legalEntity: LegalEntityResponse;
  setLegalEntity: (company: any) => void;
}

const LegalEntityInfoComponent = ({
  legalEntity,
  setLegalEntity
}: LegalEntityInfoComponentProps) => {
  const currentUser: UserAccessResponse | undefined = useObservable(
    accessManagementService.user
  ).user;

  const classes = useStyles();
  const history = useHistory();

  const { companies } = useCompanies();
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [addNew, setAddNew] = useState<boolean>(false);
  const [country, setCountry] = useState<Country | null>(null);
  const [company, setCompany] = useState<Lookup>();
  const [vatError, setVatError] = useState<boolean>(false);
  const [vatErrorText, setVatErrorText] = useState<string>();
  const { paymentOptions } = usePaymentOptions();
  const [paymentOption, setPaymentOption] = useState<Lookup>();
  const [canCreate, canRead, canUpdate, canReadAllLegalEntities] = usePermissions([
    AccessPermissions.CAN_CREATE_LEGAL_ENTITY,
    AccessPermissions.CAN_READ_LEGAL_ENTITY,
    AccessPermissions.CAN_UPDATE_LEGAL_ENTITY,
    AccessPermissions.CAN_READ_ALL_LEGAL_ENTITIES
  ]);

  const shouldBeDisabled = useMemo(
    () => (legalEntity?.id ? !canUpdate : !canCreate),
    [legalEntity, canCreate, canUpdate]
  );

  useEffect(() => {
    const loadLegalEntity = async () => {
      setLoading(true);
      if (legalEntity.id) {
        const legalEntityResp = await amsV3Service.getLegalEntity(legalEntity.id);
        setLegalEntity(legalEntityResp ? legalEntityResp.data : {});
        const company = companies.find(
          (co: CompanyListResponse) => legalEntity.companyId === co.id
        );
        const paymentOption = paymentOptions.find(
          (p: PaymentOptionResponse) => legalEntity.paymentOptionId === p.id
        );
        setCompany({
          id: legalEntityResp.data.companyId,
          value: `${getExtendedCompanyId(legalEntityResp.data?.companyId ?? 0)}  ${
            company?.note
          } (${company?.name})`
        });
        setPaymentOption({
          id: legalEntityResp.data.paymentOptionId,
          value: `${getExtendedPaymentOptionId(legalEntityResp.data?.paymentOptionId ?? 0)}  ${
            paymentOption?.holder
          } (${paymentOption?.account})`
        });
      } else {
        setLegalEntity({ isActive: true, underForeclosure: false });
        if (currentUser) {
          setCompany({
            id: currentUser.companyId,
            value: `${getExtendedCompanyId(currentUser.companyId)} ${currentUser.companyNote} (${
              currentUser.companyName
            })`
          });
        }
      }
      setLoading(false);
    };
    loadLegalEntity();
  }, [
    currentUser,
    legalEntity.id,
    setLegalEntity,
    companies,
    paymentOptions,
    setPaymentOption,
    legalEntity.paymentOptionId,
    legalEntity.companyId
  ]);

  const isValidLegalEntity = () => {
    return (
      legalEntity?.name &&
      legalEntity?.idnumber &&
      legalEntity?.vat &&
      legalEntity?.country &&
      legalEntity?.city &&
      legalEntity?.addressLine &&
      legalEntity?.accountable &&
      paymentDueDays >= 0 &&
      creditLimitAmount > 0 &&
      (company || paymentOption || canReadAllLegalEntities)
    );
  };

  const paymentDueDays = useMemo(
    () => (legalEntity?.paymentDueDays > 0 ? +legalEntity.paymentDueDays : 0),
    [legalEntity]
  );
  const creditLimitAmount = useMemo(
    () => (legalEntity?.creditLimitAmount?.length > 0 ? +legalEntity.creditLimitAmount : 0),
    [legalEntity]
  );

  const reload = () => {
    setLegalEntity({});
  };

  const handleOnClick = async () => {
    setActionLoading(true);
    let resp = null;
    const legalEntityRequest = {
      ...legalEntity,
      companyId: company?.id ?? 0,
      paymentOptionId: paymentOption?.id ?? 0,
      usesDeferredBilling: !!legalEntity.usesDeferredBilling,
      paymentDueDays: +legalEntity.paymentDueDays
    };
    if (legalEntityRequest.id) {
      if (legalEntity.isActive) {
        resp = await amsV3Service.updateLegalEntity(legalEntity.id, legalEntityRequest);
      } else {
        const request: SaveLegalEntityRequest = {
          name: legalEntity.name,
          country: legalEntity.country,
          companyId: legalEntity.companyId,
          city: legalEntity.city,
          addressLine: legalEntity.addressLine,
          idnumber: legalEntity.idnumber,
          vat: legalEntity.vat,
          paymentOptionId: legalEntity.paymentOptionId,
          usesDeferredBilling: legalEntity.usesDeferredBilling,
          accountable: legalEntity.accountable,
          paymentDueDays: legalEntity.paymentDueDays,
          isActive: false
        };
        resp = await amsV3Service.updateLegalEntity(legalEntity.id, request);
      }
      if (resp) {
        if (addNew) {
          reload();
          history.push('/legal-entity?tabId=1');
        }
        snackbarService.setSnackbarOpen(true);
      }
    } else {
      const resp = await amsV3Service.createLegalEntity(legalEntityRequest);
      if (resp) {
        if (addNew || !canRead) {
          reload();
        } else {
          history.push(`/legal-entity?id=${resp.data.id}`);
        }
        snackbarService.setSnackbarOpen(true);
      }
    }
    setActionLoading(false);
  };

  useEffect(() => {
    if (legalEntity && legalEntity.country) {
      const country = findCountry(legalEntity.country);
      if (country) {
        setCountry(country);
      }
    } else {
      setCountry(null);
    }
  }, [legalEntity]);

  const handleVisualizationChange = (event: ChangeEvent) =>
    setLegalEntity({
      ...legalEntity,
      visualization: `${(event.target as any).value}`
    });

  const getInvoiceVisualization = useCallback(
    (legalEntity: any) =>
      `${legalEntity.name || '-'}\nИ№ ${legalEntity.idnumber || '-'}, ДДС № ${
        legalEntity.vat || '-'
      } \n${legalEntity.city || '-'}, ${legalEntity.addressLine || '-'}\nМОЛ:${
        legalEntity.accountable || '-'
      }`,
    []
  );

  const handleInvoiceAddressChange = useCallback(
    (event: ChangeEvent) =>
      setLegalEntity({
        ...legalEntity,
        invoiceAddress: `${(event.target as any).value}`,
        addressOverride: `${(event.target as any).value}`
      }),
    [legalEntity, setLegalEntity]
  );

  const getInvoiceFullAddress = useCallback((legalEntity: any) => {
    let countryName = '-';
    if (legalEntity.country) {
      const country = findCountry(legalEntity.country);
      if (country) {
        countryName = country.name;
      }
    }
    return `${countryName}, ${legalEntity.city || '-'}, ${legalEntity.addressLine || '-'}`;
  }, []);

  const generateInvoiceFullAddress = useCallback(() => {
    setLegalEntity({
      ...legalEntity,
      invoiceAddress: getInvoiceFullAddress(legalEntity),
      addressOverride: getInvoiceFullAddress(legalEntity)
    });
  }, [legalEntity, setLegalEntity, getInvoiceFullAddress]);

  const loadCompanyInfo = useCallback(async () => {
    setActionLoading(true);
    const info = await amsV3Service.loadInfoByVat(legalEntity.vat);
    if (info && info.data) {
      if (info.data.valid) {
        let alpha3 = '';
        if (info.data.countryCode) {
          const country = findCountryByAlpha2(info.data.countryCode);
          if (country) {
            alpha3 = country.alpha3;
          }
        }
        setVatError(false);
        setVatErrorText(info.data.errorMessage);
        setLegalEntity({
          ...legalEntity,
          name: info.data.name,
          addressLine: info.data.address,
          country: alpha3
        });
      } else {
        setCountry(null);
        setVatError(true);
        setVatErrorText(info.data.errorMessage ? info.data.errorMessage : 'Невалиден ДДС №');
        setLegalEntity({
          ...legalEntity,
          name: undefined,
          addressLine: undefined,
          country: undefined
        });
      }
    }
    setActionLoading(false);
  }, [legalEntity, setLegalEntity]);

  const onChange = (field: string, value: any) => {
    setLegalEntity({ ...legalEntity, [field]: value });
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item lg={6}>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <OutlinedDiv label="Данни за лице за фактуриране" disabled={shouldBeDisabled}>
                <Grid container spacing={1}>
                  <Grid item container lg={3}>
                    <FormControlLabel
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={legalEntity.isActive}
                          onChange={() => onChange('isActive', !legalEntity.isActive)}
                        />
                      }
                      disabled={!legalEntity || !legalEntity.id}
                      label="Активен"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      label="Персонален код"
                      variant="outlined"
                      value={legalEntity?.id ? getExtendedLegalEntityId(legalEntity.id) : ''}
                      disabled={true}
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      label="Име"
                      variant="outlined"
                      value={legalEntity.name ?? ''}
                      fullWidth
                      margin="dense"
                      disabled={shouldBeDisabled || !legalEntity.isActive}
                      required
                      onChange={(event: ChangeEvent) =>
                        setLegalEntity({
                          ...legalEntity,
                          name: (event.target as any).value
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Grid>
            <Grid item lg={12}>
              <OutlinedDiv
                label="Адрес на лице за фактуриране"
                disabled={shouldBeDisabled || !legalEntity.isActive}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6}>
                    <AMSCountryAutocomplete
                      country={country}
                      onChange={(_, value: any) => {
                        if (value) {
                          setLegalEntity({
                            ...legalEntity,
                            country: value.alpha3
                          });
                        } else {
                          setLegalEntity({ ...legalEntity, country: null });
                        }
                      }}
                      disabled={shouldBeDisabled || !legalEntity.isActive}
                      required
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      label="Населено място"
                      variant="outlined"
                      value={legalEntity && legalEntity.city ? legalEntity.city : ''}
                      disabled={shouldBeDisabled || !legalEntity.isActive}
                      fullWidth
                      margin="dense"
                      required
                      onChange={(event: ChangeEvent) =>
                        setLegalEntity({
                          ...legalEntity,
                          city: (event.target as any).value
                        })
                      }
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <TextField
                      label="Адрес"
                      variant="outlined"
                      value={legalEntity && legalEntity.addressLine ? legalEntity.addressLine : ''}
                      disabled={shouldBeDisabled || !legalEntity.isActive}
                      fullWidth
                      multiline
                      margin="dense"
                      required
                      onChange={(event: ChangeEvent) =>
                        setLegalEntity({
                          ...legalEntity,
                          addressLine: (event.target as any).value
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </OutlinedDiv>
            </Grid>
            {!legalEntity.id && (
              <Grid item lg={12}>
                <OutlinedDiv
                  label="Фактуриране"
                  disabled={shouldBeDisabled || !legalEntity.isActive}
                >
                  <Grid container spacing={1}>
                    <Grid item lg={6}>
                      <TextField
                        label="Дни до падеж"
                        value={legalEntity?.paymentDueDays ?? '0'}
                        variant="outlined"
                        disabled={shouldBeDisabled || !legalEntity.isActive}
                        fullWidth
                        margin="dense"
                        required
                        onChange={(event: ChangeEvent) =>
                          setLegalEntity({
                            ...legalEntity,
                            paymentDueDays: (event.target as any).value
                          })
                        }
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <AMSTextField
                        label="Кредитен лимит"
                        value={
                          +legalEntity?.creditLimitAmount > 0 ? legalEntity.creditLimitAmount : '0'
                        }
                        disabled={shouldBeDisabled || !legalEntity.isActive}
                        required
                        onChange={(value?: string) =>
                          setLegalEntity({
                            ...legalEntity,
                            creditLimitAmount: value
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </OutlinedDiv>
              </Grid>
            )}
            {canReadAllLegalEntities && (
              <Grid item lg={12} md={12} sm={12}>
                <AMSAutocomplete
                  label="Компания"
                  options={companies.map((c: CompanyListResponse) => ({
                    id: c.id,
                    value: `${getExtendedCompanyId(c.id)} ${c.note} (${c.name})`
                  }))}
                  value={company}
                  onChange={(value: Lookup | undefined) => setCompany(value)}
                  disabled={shouldBeDisabled || !legalEntity.isActive}
                  minChar={0}
                  required
                />
              </Grid>
            )}
            {!legalEntity.id && (
              <Grid item lg={12}>
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={legalEntity?.usesDeferredBilling}
                      onChange={() =>
                        setLegalEntity({
                          ...legalEntity,
                          usesDeferredBilling: !legalEntity.usesDeferredBilling
                        })
                      }
                    />
                  }
                  disabled={shouldBeDisabled || !legalEntity.isActive}
                  label="Отложено фактуриране"
                  labelPlacement="end"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item lg={6}>
          <Grid container spacing={1}>
            <Grid item lg={12}>
              <OutlinedDiv
                label="Данни за фактура"
                disabled={shouldBeDisabled || !legalEntity.isActive}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6}>
                    <TextField
                      label="ИН"
                      value={legalEntity && legalEntity.idnumber ? legalEntity.idnumber : ''}
                      variant="outlined"
                      disabled={shouldBeDisabled || !legalEntity.isActive}
                      fullWidth
                      margin="dense"
                      required
                      onChange={(event: ChangeEvent) =>
                        setLegalEntity({
                          ...legalEntity,
                          idnumber: (event.target as any).value
                        })
                      }
                    />
                  </Grid>
                  <Grid item lg={shouldBeDisabled ? 6 : 4}>
                    <TextField
                      label="ДДС №"
                      value={legalEntity && legalEntity.vat ? legalEntity.vat : ''}
                      variant="outlined"
                      disabled={shouldBeDisabled || !legalEntity.isActive}
                      fullWidth
                      margin="dense"
                      required
                      error={vatError}
                      helperText={vatErrorText}
                      onChange={(event: ChangeEvent) =>
                        setLegalEntity({
                          ...legalEntity,
                          vat: (event.target as any).value
                        })
                      }
                    />
                  </Grid>
                  {!shouldBeDisabled && (
                    <Grid item lg={2} className={classes.copyButtonWrapper}>
                      {actionLoading ? (
                        <CircularProgress />
                      ) : (
                        <IconButton
                          onClick={loadCompanyInfo}
                          disabled={!legalEntity.vat || shouldBeDisabled || !legalEntity.isActive}
                        >
                          <SearchOutlined />
                        </IconButton>
                      )}
                    </Grid>
                  )}
                  <Grid item lg={shouldBeDisabled ? 12 : 10}>
                    <TextField
                      label="МОЛ"
                      value={legalEntity && legalEntity.accountable ? legalEntity.accountable : ''}
                      variant="outlined"
                      disabled={shouldBeDisabled || !legalEntity.isActive}
                      fullWidth
                      margin="dense"
                      required
                      onChange={(event: ChangeEvent) =>
                        setLegalEntity({
                          ...legalEntity,
                          accountable: (event.target as any).value
                        })
                      }
                    />
                  </Grid>
                  {!legalEntity.id && (
                    <Grid item lg={shouldBeDisabled ? 12 : 10}>
                      <AMSAutocomplete
                        label="Начин на плащане"
                        options={paymentOptions.map((c: PaymentOptionResponse) => ({
                          id: c.id,
                          value: `${getExtendedPaymentOptionId(c.id)} ${c.holder} (${c.account})`
                        }))}
                        value={paymentOption}
                        onChange={(value: Lookup | undefined) => setPaymentOption(value)}
                        disabled={shouldBeDisabled || !legalEntity.isActive}
                        minChar={0}
                        required
                      />
                    </Grid>
                  )}
                  <Grid item lg={shouldBeDisabled ? 12 : 10}>
                    <TextField
                      label="Визуализация във фактурата"
                      value={
                        legalEntity && legalEntity.visualization ? legalEntity.visualization : ''
                      }
                      variant="outlined"
                      fullWidth
                      disabled={shouldBeDisabled || !legalEntity.isActive}
                      margin="dense"
                      multiline
                      rows={5}
                      onChange={handleVisualizationChange}
                    />
                  </Grid>
                  {!shouldBeDisabled && (
                    <Grid item lg={2} className={classes.copyButtonWrapper}>
                      <IconButton
                        onClick={() => {
                          setLegalEntity({
                            ...legalEntity,
                            visualization: getInvoiceVisualization(legalEntity)
                          });
                        }}
                        disabled={shouldBeDisabled || !legalEntity.isActive}
                      >
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item lg={shouldBeDisabled ? 12 : 10}>
                    <TextField
                      label="Адрес за изпращане на фактура"
                      value={legalEntity && legalEntity.addressLine ? legalEntity.addressLine : ''}
                      variant="outlined"
                      fullWidth
                      disabled={shouldBeDisabled || !legalEntity.isActive}
                      margin="dense"
                      multiline
                      rows={3}
                      onChange={handleInvoiceAddressChange}
                    />
                  </Grid>
                  {!shouldBeDisabled && (
                    <Grid item lg={2} className={classes.copyButtonWrapper}>
                      <IconButton
                        onClick={generateInvoiceFullAddress}
                        disabled={shouldBeDisabled || !legalEntity.isActive}
                      >
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </OutlinedDiv>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {((canUpdate && legalEntity?.id) || (canCreate && !legalEntity?.id)) && (
        <Grid container>
          <Grid item lg={6} container justify="flex-end">
            <div className={classes.addNewCheckboxWrapper}>
              <FormControlLabel
                className={classes.label}
                control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
                label="Добави нов след запис"
                labelPlacement="end"
              />
            </div>
          </Grid>
          <Grid item lg={6} container justify="flex-start">
            <AMSButton
              text={addNew ? 'Запиши и добави нов' : 'Запиши'}
              color="primary"
              variant="contained"
              onClick={handleOnClick}
              disabled={actionLoading || !isValidLegalEntity()}
              loading={actionLoading}
              className={classes.saveButton}
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default LegalEntityInfoComponent;
