import { Checkbox, CircularProgress, Container, FormControlLabel, Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';
import { WarehouseListResponse, amsLegacyService } from '../../../services';
import { ignoreOffset, isValidDate, toEndOfDay } from '../../../helpers/date-helper';

import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import DateFnsUtils from '@date-io/date-fns';
import { ReportDeliveryItemByOrdersResponse } from '../../../services/api';
import { useWarehouses } from '../../../helpers/hooks';
import { warehouseToLookup } from '../../../models/lookup';

const ReportOrderQuantitiesComponent = ({ location }: any) => {
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState<ReportDeliveryItemByOrdersResponse[]>([]);
  const [filterWarehouseId, setFilterWarehouseId] = useState<number>();
  const [filterToDate, setFilterToDate] = useState<any>(
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
  );
  const [filterHideOrdersWithoutDeliveryDate, setFilterHideOrdersWithoutDeliveryDate] =
    useState<boolean>(true);

  const { warehouses } = useWarehouses();

  const [warehouse, setWarehouse] = useState<WarehouseListResponse | null>(null);

  const loadStock = async () => {
    setLoading(true);
    if (filterWarehouseId && filterToDate && isValidDate(filterToDate)) {
      const stockResponse = await amsLegacyService.getStockByOrders(
        filterWarehouseId,
        toEndOfDay(filterToDate),
        filterHideOrdersWithoutDeliveryDate
      );
      if (stockResponse) {
        setStock(stockResponse.data);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-align-left">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <AMSAutocomplete
                  label="Склад"
                  options={warehouses.map(warehouseToLookup).sort((as1, as2) => as2.id - as1.id)}
                  value={warehouse ? warehouse : null}
                  onChange={(selectedValue: WarehouseListResponse | null) => {
                    setWarehouse(selectedValue);
                    if (selectedValue) {
                      setFilterWarehouseId(selectedValue.id);
                    } else {
                      setFilterWarehouseId(undefined);
                    }
                  }}
                  required={true}
                  minChar={0}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  required={true}
                  label="До"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <FormControlLabel
                  style={{ paddingTop: 6 }}
                  control={
                    <Checkbox
                      checked={filterHideOrdersWithoutDeliveryDate}
                      onChange={() => {
                        setFilterHideOrdersWithoutDeliveryDate(
                          !filterHideOrdersWithoutDeliveryDate
                        );
                      }}
                    />
                  }
                  label="Скриване на поръчки без дата за доставка"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item lg={1} md={1} sm={12} xs={12}>
                <AMSButton
                  color="primary"
                  variant="contained"
                  text="Търсене"
                  loading={loading}
                  disabled={!filterWarehouseId}
                  onClick={loadStock}
                  style={{
                    marginTop: 12
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <AMSTable
                    title="Наличности"
                    columns={[
                      {
                        title: 'Арт №',
                        field: 'itemArtNo',
                        render: (deliveryItem: ReportDeliveryItemByOrdersResponse) => (
                          <AMSLink href={`/item?id=${deliveryItem.itemId}`}>
                            {deliveryItem.itemArtNo}
                          </AMSLink>
                        ),
                        cellStyle: { width: '15%' }
                      },
                      {
                        title: 'Артикул',
                        field: 'itemName',
                        render: (deliveryItem: ReportDeliveryItemByOrdersResponse) => (
                          <AMSLink href={`/item?id=${deliveryItem.itemId}`}>
                            {deliveryItem.itemName}
                          </AMSLink>
                        ),
                        cellStyle: { width: '40%' }
                      },
                      {
                        title: 'Необходими',
                        field: 'orderRequiredQuantity',
                        cellStyle: { width: '15%' },
                        align: 'right',
                        type: 'numeric'
                      },
                      {
                        title: 'Налични',
                        field: 'itemAvailableQuantity',
                        cellStyle: { width: '15%' },
                        align: 'right',
                        type: 'numeric'
                      },
                      {
                        title: 'Баланс',
                        field: 'stockBalance',
                        cellStyle: (data: any[], rowData: ReportDeliveryItemByOrdersResponse) =>
                          rowData.stockBalance <= 0
                            ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', width: '15%' }
                            : { width: '15%' },
                        align: 'right',
                        type: 'numeric'
                      }
                    ]}
                    paging={false}
                    overflowY="scroll"
                    minBodyHeight="65vh"
                    maxBodyHeight="65vh"
                    data={stock}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ReportOrderQuantitiesComponent;
