import { AMSButton, AMSSnackBar, AMSTextField } from '../../../../../helpers/ui';
import {
  AccessPermissions,
  SetBarsyConfigurationRequest,
  TestConnectionDetailsResponse,
  TestConnectionResultKeys,
  amsV3Service,
  snackbarService
} from '../../../../../services';
import { Grid, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useFlag, usePermissions } from '../../../../../helpers/hooks';

import OutlinedDiv from '../../../../../helpers/ui/OutlinedDiv';

interface BarsyIntegrationFormProps {
  partnerId: number;
  disableTestButton: boolean;
}

const BarsyIntegrationForm = ({ partnerId, disableTestButton }: BarsyIntegrationFormProps) => {
  const [endpointUrl, setEndpointUrl] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [amsSupplierId, setAmsSupplierId] = useState<string>('');
  const [depotId, setDepotId] = useState<string>('');
  const [testButtonDisabled, setTestButtonDisabled] = useFlag(disableTestButton);
  const [actionLoading, setActionLoading] = useFlag(false);
  const [testActionLoading, setTestActionLoading] = useFlag(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [testConnectionDetails, setTestConnectionDetails] =
    useState<TestConnectionDetailsResponse | null>(null);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_PARTNER,
    AccessPermissions.CAN_UPDATE_PARTNER
  ]);

  const handleOnClick = async () => {
    setActionLoading(true);
    const configurationRequest: SetBarsyConfigurationRequest = {
      requestType: 'set-barsy-configuration',
      configuration: {
        endpointUrl,
        username,
        password,
        amsSupplierId: +amsSupplierId,
        depotId: +depotId
      }
    };
    const resp = await amsV3Service.setPartnerIntegrationConfiguration(
      partnerId,
      configurationRequest
    );

    if (resp) {
      snackbarService.setSnackbarOpen(true);
      setTestButtonDisabled(false);
    }
    setActionLoading(false);
  };

  const handleTest = async () => {
    setTestActionLoading(true);
    if (partnerId) {
      const resp = await amsV3Service.testBarsyConnection(partnerId);
      setTestConnectionDetails(resp.data);
      if (resp.data.result === TestConnectionResultKeys.SUCCESSFUL) {
        setSnackBarMessage('Установена е връзка с Barsy');
        setSnackBarSeverity('success');
      } else {
        setSnackBarMessage('Неуспешна връзка с Barsy');
        setSnackBarSeverity('error');
      }
      setOpenSnackBar(true);
    }
    setTestActionLoading(false);
  };

  const shouldBeDisabled = useMemo(
    () => (partnerId > 0 ? !canUpdate : !canCreate),
    [partnerId, canCreate, canUpdate]
  );

  const isValidBarsyIntegration = () =>
    endpointUrl && username && password && amsSupplierId && depotId;

  const invalidDepotId = useMemo(() => {
    if (isNaN(+depotId)) {
      return 'Складът трябва да е число';
    }
    if (testConnectionDetails?.result === TestConnectionResultKeys.DEPOT_NOT_FOUND) {
      return 'Складът не е намерен';
    }
  }, [depotId, testConnectionDetails]);

  const invalidEndpointUrl = useMemo(() => {
    if (testConnectionDetails?.result === TestConnectionResultKeys.HTTP_ERROR) {
      return 'Невалиден URL';
    }
  }, [testConnectionDetails]);

  const invalidAmsSupplierId = useMemo(() => {
    if (testConnectionDetails?.result === TestConnectionResultKeys.SUPPLIER_NOT_FOUND) {
      return 'ИД на доставчик не е намерен';
    }
  }, [testConnectionDetails]);

  return (
    <Grid container spacing={1}>
      <Grid item lg={12}>
        <OutlinedDiv label={`Данни за интеграция с Barsy`} disabled={shouldBeDisabled}>
          <Grid container spacing={1}>
            <Grid item lg={12}>
              <AMSTextField
                label="URL"
                disabled={shouldBeDisabled}
                value={endpointUrl}
                onChange={(value) => setEndpointUrl(value ?? '')}
                error={!!invalidEndpointUrl}
                helperText={invalidEndpointUrl}
              />
            </Grid>
            <Grid item lg={6}>
              <AMSTextField
                label="ИД на AMS"
                disabled={shouldBeDisabled}
                value={amsSupplierId}
                onChange={(value) => setAmsSupplierId(value ?? '')}
                error={!!invalidAmsSupplierId}
                helperText={invalidAmsSupplierId}
              />
            </Grid>
            <Grid item lg={6}>
              <AMSTextField
                label="Склад"
                disabled={shouldBeDisabled}
                value={depotId}
                onChange={(value) => setDepotId(value ?? '')}
                error={!!invalidDepotId}
                helperText={invalidDepotId}
              />
            </Grid>
            <Grid item lg={6}>
              <AMSTextField
                label="Потребител"
                disabled={shouldBeDisabled}
                value={username}
                onChange={(value) => setUsername(value ?? '')}
              />
            </Grid>
            <Grid item lg={6}>
              <AMSTextField
                type="password"
                label="Парола"
                disabled={shouldBeDisabled}
                value={password}
                onChange={(value) => setPassword(value ?? '')}
              />
            </Grid>
            {testConnectionDetails?.result === TestConnectionResultKeys.INCORRECT_CREDENTIALS && (
              <Grid item lg={12}>
                <Typography style={{ color: '#FD9728', fontSize: '0.75rem', fontWeight: 400 }}>
                  Невалидни данни за достъп
                </Typography>
              </Grid>
            )}
          </Grid>
        </OutlinedDiv>
      </Grid>
      <Grid item lg={6} container justify="center">
        <AMSButton
          text="Тест"
          variant="outlined"
          color="primary"
          onClick={handleTest}
          disabled={testButtonDisabled}
          loading={testActionLoading}
        />
      </Grid>
      <Grid item lg={6} container justify="center">
        <AMSButton
          text={'Запиши'}
          variant="contained"
          color="primary"
          onClick={handleOnClick}
          disabled={actionLoading || !isValidBarsyIntegration()}
          loading={actionLoading}
        />
      </Grid>
      <AMSSnackBar
        open={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSeverity}
        onClose={() => setOpenSnackBar(false)}
      />
    </Grid>
  );
};

export default BarsyIntegrationForm;
