import {
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import {
  CompanyListResponse,
  ItemResponse,
  PartnerListResponse,
  WarehouseListResponse,
  amsLegacyService,
  cookiesService
} from '../../../services';
import { ItemCategoryResponse, ReportDepletedAmountWithPrices } from '../../../services/api';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, {
  companyToLookup,
  itemCategoryToLookup,
  itemToLookup,
  legalEntityToLookup,
  partnerToLookup,
  warehouseToLookup
} from '../../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { getExtendedDeliveryId, getExtendedOrderId } from '../../../helpers/utils';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../../helpers/date-helper';
import { useCompanies, useLegalEntities, usePartners, useWarehouses } from '../../../helpers/hooks';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AMSConfirmDialog from '../../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DateFnsUtils from '@date-io/date-fns';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: 8
  }
}));

const ReportOrderItemsComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { fromDate, toDate, companyIds, warehouseIds, partnerIds, itemCategoryIds, itemId } =
    useMemo(() => parseFilter(location.search), [location.search]);

  const [loading, setLoading] = useState(false);
  const [depletedAmounts, setDepletedAmounts] = useState<ReportDepletedAmountWithPrices[]>([]);
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(null);
  const [filterToDate, setFilterToDate] = useState<Date | null>(null);
  const [filterCompanyIds, setFilterCompanyIds] = useState<Lookup[]>([]);
  const [filterWarehouseIds, setFilterWarehouseIds] = useState<Lookup[]>([]);
  const [filterLegalEntityIds, setFilterLegalEntityIds] = useState<Lookup[]>([]);
  const [filterPartnerIds, setFilterPartnerIds] = useState<Lookup[]>([]);
  const [filterCategoryIds, setFilterCategoryIds] = useState<Lookup[]>([]);
  const [filterItemId, setFilterItemId] = useState<number | undefined>(itemId ? itemId : undefined);

  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();
  const { partners } = usePartners();
  const { legalEntities } = useLegalEntities();
  const [categories, setCategories] = useState<ItemCategoryResponse[]>([]);
  const [item, setItem] = useState<Lookup | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const filteredPartners = useMemo(() => {
    if (filterLegalEntityIds.length > 0) {
      const legalEntityIds = filterLegalEntityIds.map((le) => le.id);
      return partners.filter((partner) => legalEntityIds.includes(partner.legalEntityId));
    }
    return partners;
  }, [partners, filterLegalEntityIds]);

  useEffect(() => {
    const loadData = async () => {
      const [categoriesResp] = await Promise.all([amsLegacyService.getCategoriesFlat(true)]);
      setCategories(categoriesResp ? categoriesResp.data : []);
    };
    loadData();
  }, []);

  useEffect(() => {
    const {
      filterFromDate,
      filterToDate,
      filterCompanyIds,
      filterWarehouseIds,
      filterPartnerIds,
      filterCategoryIds
    } = cookiesService.getReportOrderItemsFilter();
    setFilterFromDate(
      fromDate
        ? fromDate
        : filterFromDate
          ? ignoreOffset(new Date(filterFromDate))
          : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
    );
    setFilterToDate(
      toDate ? toDate : filterToDate ? ignoreOffset(new Date(filterToDate)) : new Date()
    );
    setFilterCompanyIds(
      companyIds
        ? companies
            .filter((c: CompanyListResponse) => companyIds.includes(c.id))
            .map(companyToLookup)
        : (filterCompanyIds ?? [])
    );
    setFilterWarehouseIds(
      warehouseIds
        ? warehouses
            .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
            .map(warehouseToLookup)
        : (filterWarehouseIds ?? [])
    );
    setFilterPartnerIds(
      partnerIds
        ? partners
            .filter((p: PartnerListResponse) => partnerIds.includes(p.id))
            .map(partnerToLookup)
        : (filterPartnerIds ?? [])
    );
    setFilterCategoryIds(
      itemCategoryIds
        ? categories
            .filter((ic: ItemCategoryResponse) => itemCategoryIds.includes(ic.id))
            .map(itemCategoryToLookup)
        : (filterCategoryIds ?? [])
    );
  }, [
    categories,
    companies,
    partners,
    warehouses,
    fromDate,
    toDate,
    companyIds,
    warehouseIds,
    partnerIds,
    itemCategoryIds
  ]);

  const loadOrderItems = useCallback(async () => {
    setLoading(true);
    const fromDate = isValidDate(filterFromDate) ? toStartOfDay(filterFromDate!) : undefined;
    const toDate = isValidDate(filterToDate) ? toEndOfDay(filterToDate!) : undefined;
    const depletedAmountsResponse = await amsLegacyService.getDepletedAmountsWithPrices(
      fromDate,
      toDate,
      [
        ...filterPartnerIds.map((partner: Lookup) => partner.id),
        ...filteredPartners.map((p: PartnerListResponse) => p.id)
      ].join(','),
      filterCompanyIds.map((company: Lookup) => company.id).join(','),
      filterWarehouseIds.map((warehouse: Lookup) => warehouse.id).join(','),
      filterCategoryIds.map((category: Lookup) => category.id).join(','),
      filterItemId ? filterItemId : undefined
    );
    if (depletedAmountsResponse) {
      setDepletedAmounts(depletedAmountsResponse.data);
    }
    setLoading(false);
  }, [
    filterFromDate,
    filterToDate,
    filterCompanyIds,
    filterWarehouseIds,
    filteredPartners,
    filterPartnerIds,
    filterCategoryIds,
    filterItemId
  ]);

  const onSearch = useCallback(() => {
    cookiesService.setReportOrderItemsFilter({
      filterFromDate: filterFromDate ? toStartOfDay(filterFromDate) : undefined,
      filterToDate: filterToDate ? toEndOfDay(filterToDate) : undefined,
      filterCompanyIds: filterCompanyIds,
      filterWarehouseIds: filterWarehouseIds,
      filterPartnerIds: filterPartnerIds,
      filterCategoryIds: filterCategoryIds
    });
    let params: any = {};
    if (filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    }
    if (filterToDate) {
      params.toDate = toDateString(filterToDate);
    }
    if (filterCompanyIds?.length > 0) {
      params.companyIds = filterCompanyIds.map((c) => c.id).join(',');
    }
    if (filterWarehouseIds?.length > 0) {
      params.warehouseIds = filterWarehouseIds.map((w) => w.id).join(',');
    }
    if (filterPartnerIds?.length > 0) {
      params.partnerIds = filterPartnerIds.map((p) => p.id).join(',');
    }
    if (filterCategoryIds?.length > 0) {
      params.categoryIds = filterCategoryIds.map((c) => c.id).join(',');
    }
    history.push({
      pathname: '/reports-detailed-turnover',
      search: new URLSearchParams(params).toString()
    });
    loadOrderItems();
  }, [
    loadOrderItems,
    filterFromDate,
    filterToDate,
    filterCompanyIds,
    filterWarehouseIds,
    filterPartnerIds,
    filterCategoryIds,
    history
  ]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="text-align-left">
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="От"
                  helperText={''}
                  value={filterFromDate ? filterFromDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterFromDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterFromDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  maxDate={filterToDate}
                />
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="До"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDate={filterFromDate}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={legalEntities.map(legalEntityToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Юридически лица"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterLegalEntityIds}
                  onChange={(values) => setFilterLegalEntityIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={20}
                  options={partners.map(partnerToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Контрагент"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterPartnerIds}
                  onChange={(values) => setFilterPartnerIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={companies.map(companyToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Компания"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCompanyIds}
                  onChange={(values) => setFilterCompanyIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={(filterCompanyIds.length > 0
                    ? warehouses.filter((wh: WarehouseListResponse) =>
                        filterCompanyIds.map((company: Lookup) => company.id).includes(wh.companyId)
                      )
                    : warehouses
                  ).map(warehouseToLookup)}
                  sortOptions={false}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Складове"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterWarehouseIds}
                  onChange={(values) => setFilterWarehouseIds(values)}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={categories.map(itemCategoryToLookup)}
                  disableCloseOnSelect
                  getOptionLabel={(option: Lookup) => option.value}
                  renderOption={(option: Lookup, { selected }: any) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Категория"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterCategoryIds}
                  onChange={(values) => setFilterCategoryIds(values)}
                  groupBy={(g) => g.group}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <AMSAsyncAutocomplete
                  label="Артикул"
                  onChange={(item: ItemResponse | null) => {
                    if (item) {
                      setFilterItemId(item?.id);
                      setItem(itemToLookup(item));
                    } else {
                      setFilterItemId(undefined);
                      setItem(null);
                    }
                  }}
                  value={item?.id}
                  minChar={3}
                />
              </Grid>
              <Grid item lg={2} md={6} sm={12} xs={12}>
                {filterCategoryIds.length === 0 &&
                filterPartnerIds.length === 0 &&
                filterCompanyIds.length === 0 &&
                filterWarehouseIds.length === 0 &&
                !filterFromDate &&
                !filterToDate &&
                !filterItemId ? (
                  <Tooltip
                    title={
                      <div style={{ fontSize: 14 }}>
                        Търсенето без филтри може да доведе до забавяне на системата
                      </div>
                    }
                    placement="top"
                    style={{ fontSize: 24 }}
                  >
                    <div>
                      <AMSButton
                        color="primary"
                        variant="contained"
                        text="Търсене"
                        loading={loading}
                        disabled={false}
                        onClick={() => setOpenConfirmDialog(true)}
                        style={{
                          marginTop: 8,
                          backgroundColor: '#ff7b00'
                        }}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <AMSButton
                    color="primary"
                    variant="contained"
                    text="Търсене"
                    loading={loading}
                    disabled={false}
                    onClick={onSearch}
                    style={{
                      marginTop: 8
                    }}
                  />
                )}
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <AMSTable
                    title="Продажба на артикули"
                    columns={[
                      {
                        title: 'Дата',
                        field: 'orderDate',
                        type: 'date',
                        cellStyle: { width: '4%' }
                      },
                      {
                        title: 'Поръчка №',
                        field: 'orderId',
                        cellStyle: { width: '8%' },
                        render: (item: ReportDepletedAmountWithPrices) => (
                          <AMSLink href={`/order?id=${item.orderId}`}>
                            {getExtendedOrderId(item.orderId)}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Контрагент',
                        field: 'receiverName',
                        cellStyle: { width: '8%' },
                        render: (item: ReportDepletedAmountWithPrices) => (
                          <AMSLink href={`/partner?id=${item.receiverId}`}>
                            {item.receiverName}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Склад',
                        field: 'warehouseName',
                        cellStyle: { width: '8%' }
                      },
                      {
                        title: 'Доставка №',
                        field: 'deliveryId',
                        cellStyle: { width: '8%' },
                        render: (item: ReportDepletedAmountWithPrices) => (
                          <AMSLink href={`/delivery?id=${item.deliveryId}&tabId=1`}>
                            {getExtendedDeliveryId(item.deliveryId)}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Доставчик',
                        field: 'supplierName',
                        cellStyle: { width: '8%' },
                        render: (item: ReportDepletedAmountWithPrices) => (
                          <AMSLink href={`/supplier?id=${item.supplierId}`}>
                            {item.supplierName}
                          </AMSLink>
                        )
                      },
                      {
                        title: 'Група',
                        field: 'itemCategoryName',
                        cellStyle: { width: '10%' }
                      },
                      {
                        title: 'Артикул',
                        field: 'itemName',
                        cellStyle: { width: '10%' }
                      },
                      {
                        title: 'Количество',
                        field: 'itemQuantity',
                        cellStyle: { width: '8%' },
                        align: 'right',
                        type: 'numeric'
                      },
                      {
                        title: 'Покупка',
                        field: 'deliveryItemPrice',
                        cellStyle: { width: '6%' },
                        type: 'currency',
                        currencySetting: {
                          locale: 'bg',
                          currencyCode: 'bgn',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      },
                      {
                        title: 'Коефициент',
                        field: 'profitRatio',
                        cellStyle: { width: '6%' },
                        type: 'numeric'
                      },
                      {
                        title: 'Продажба',
                        field: 'orderItemPrice',
                        cellStyle: { width: '6%' },
                        type: 'currency',
                        currencySetting: {
                          locale: 'bg',
                          currencyCode: 'bgn',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      }
                    ]}
                    paging={true}
                    pageSize={8}
                    overflowY="scroll"
                    minBodyHeight="55vh"
                    maxBodyHeight="55vh"
                    data={depletedAmounts}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
          <AMSConfirmDialog
            open={openConfirmDialog}
            onConfirm={() => {
              onSearch();
              setOpenConfirmDialog(false);
            }}
            onClose={() => setOpenConfirmDialog(false)}
            title={'Търсене без филтри!'}
            message={
              'Търсенето без филтри може да доведе до забавяне на системата. Искате да продължите?'
            }
          />
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ReportOrderItemsComponent;
