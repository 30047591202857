import { AccessPermissions, accessManagementService } from '../../../../services';
import {
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AMSButton from '../../../../helpers/ui/AMSButton/AMSButton';
import Category from '../../../../models/category';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { ItemCategoryResponse } from '../../../../services/api';
import SearchIcon from '@material-ui/icons/Search';
import flattenTree from '../../../../helpers/tree-helper';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 20,
      maxWidth: '50%'
    },
    list: {
      minHeight: '60vh',
      maxHeight: '60vh',
      overflow: 'auto'
    },
    titleRow: {
      display: 'flex',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#F8F8F8'
      }
    },
    title: {
      borderBottom: '1px solid #DDD',
      textAlign: 'left',
      marginTop: 6
    },
    disabledTitle: {
      borderBottom: '1px solid #BBB',
      color: '#888',
      textAlign: 'left',
      marginTop: 6
    }
  })
);

interface PartnerCategoriesProps {
  categories: Category[];
  partnerCategories: any;
  setPartnerCategories: any;
  onSave: () => void;
  actionLoading: boolean;
}

const PartnerCategoriesContainer = ({
  categories,
  partnerCategories,
  setPartnerCategories,
  onSave,
  actionLoading
}: PartnerCategoriesProps) => {
  const classes = useStyles();

  const canUpdate = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_PARTNER),
    []
  );

  const tree = useCallback((categories: any[], root: number | null) => {
    var r: any[] = [],
      o: any = {};
    categories.forEach((a: any) => {
      if (o[a.id] && o[a.id].children) {
        a.children = o[a.id] && o[a.id].children;
      }
      o[a.id] = a;
      if (a.parentId === root) {
        r.push(a);
      } else {
        o[a.parentId] = o[a.parentId] || {};
        o[a.parentId].children = o[a.parentId].children || [];
        o[a.parentId].children.push(a);
      }
    });
    return r;
  }, []);

  const [loading, setLoading] = useState(true);
  const [categoriesList, setCategoriesList] = useState<ItemCategoryResponse[]>([]);
  const [categoriesVisibleTree, setCategoriesVisibleTree] = useState<ItemCategoryResponse[]>([]);
  const [open, setOpen] = useState<any>({ '-1': true });

  const [categoryFilter, setCategoryFilter] = useState<string | undefined>();

  useEffect(() => {
    setCategoriesList(flattenTree(categories));
    setCategoriesVisibleTree(categories);
    setLoading(false);
  }, [categories]);

  const getCategory = (id: number) => {
    return flattenTree(categories).find((c: Category) => c.id === id);
  };

  useEffect(() => {
    if (categoryFilter && categoryFilter.length >= 3) {
      const filter = `(${categoryFilter
        .trim()
        .split(' ')
        .map((word) => `(?=.*${word})`)
        .join('+')})`;
      const matches: Category[] = categoriesList.filter(
        (l: any) => categoryFilter && new RegExp(filter, 'gi').test(l.name)
      );

      const resultList: ItemCategoryResponse[] = [];

      matches.forEach((itemCategory: ItemCategoryResponse) => {
        const resultListIds = resultList.map((l) => l.id);
        if (!resultListIds.includes(itemCategory.id)) {
          resultList.push(itemCategory);
        }
        if (itemCategory.parentId) {
          const parentCategory: ItemCategoryResponse | undefined = getCategory(
            itemCategory.parentId
          );
          if (parentCategory && !resultListIds.includes(parentCategory.id)) {
            resultList.push(parentCategory);
            if (parentCategory.parentId) {
              const grandParentCategory: ItemCategoryResponse | undefined = getCategory(
                parentCategory.parentId
              );
              if (grandParentCategory && !resultListIds.includes(grandParentCategory.id)) {
                resultList.push(grandParentCategory);
              }
            }
          }
        }
      });
      setCategoriesVisibleTree(tree(resultList, null));
    } else {
      setCategoriesVisibleTree(categories);
    }
    setCategoryFilter(categoryFilter);
    // eslint-disable-next-line
  }, [categoryFilter]);

  const getItemVisibility = (id: number, newVisibility: any) => {
    const category: any = getCategory(id);
    if (category) {
      if (category.children) {
        const allChildren = flattenTree(category.children);
        const visibleChildren = allChildren.reduce((arr: any[], item: ItemCategoryResponse) => {
          if (newVisibility[item.id] === 2) {
            arr.push(item);
          }
          return arr;
        }, []);
        return visibleChildren.length === 0
          ? 0
          : !(allChildren.length - visibleChildren.length)
            ? 2
            : 1;
      } else {
        return newVisibility[id];
      }
    } else {
      return 0;
    }
  };

  const getListVisible = (data: any, style: any) => {
    return (
      <div style={style}>
        {data &&
          !!data.length &&
          data.map((itemCategory: ItemCategoryResponse) => {
            const lineView = (
              <div key={`titleRow-${itemCategory.id}`} className={classes.titleRow}>
                <IconButton
                  size="small"
                  disabled={!canUpdate}
                  onClick={
                    canUpdate
                      ? () => {
                          const itemCategoryId = itemCategory.id;
                          const parentId = itemCategory.parentId ? itemCategory.parentId : null;
                          let grandParentId = null;
                          if (parentId) {
                            const parentCategory: ItemCategoryResponse | undefined =
                              getCategory(parentId);
                            grandParentId =
                              parentCategory && parentCategory.parentId
                                ? parentCategory.parentId
                                : null;
                          }
                          const category = getCategory(itemCategory.id);
                          const ids = flattenTree([category], 'id').reduce(
                            (res: any, id: number) => {
                              res[id] = partnerCategories[itemCategoryId] === 2 ? 0 : 2;
                              return res;
                            },
                            {}
                          );
                          let newVisible = { ...partnerCategories, ...ids };
                          if (parentId) {
                            const parentVisibility = getItemVisibility(parentId, newVisible);
                            if (grandParentId) {
                              newVisible = { ...newVisible, [parentId]: parentVisibility };
                              const grandParentVisibility = getItemVisibility(
                                grandParentId,
                                newVisible
                              );
                              setPartnerCategories({
                                ...newVisible,
                                [grandParentId]: grandParentVisibility
                              });
                            } else {
                              setPartnerCategories({
                                ...newVisible,
                                [parentId]: parentVisibility
                              });
                            }
                          } else {
                            setPartnerCategories(newVisible);
                          }
                        }
                      : undefined
                  }
                >
                  {!partnerCategories[itemCategory.id] && <CheckBoxOutlineBlankOutlinedIcon />}
                  {partnerCategories[itemCategory.id] === 1 && (
                    <IndeterminateCheckBoxOutlinedIcon />
                  )}
                  {partnerCategories[itemCategory.id] === 2 && <CheckBoxOutlinedIcon />}
                </IconButton>
                <Typography
                  key={`typography-${itemCategory.id}`}
                  onClick={() => {
                    setOpen({ ...open, [itemCategory.id]: !open[itemCategory.id] });
                  }}
                  className={canUpdate ? classes.title : classes.disabledTitle}
                >{`${itemCategory.name}${
                  itemCategory.children && itemCategory.children.length
                    ? ` (${itemCategory.children.length})`
                    : ''
                }`}</Typography>
              </div>
            );
            return itemCategory.children && itemCategory.children.length > 0 ? (
              <div key={`collapse-${itemCategory.id}`}>
                {lineView}
                <Collapse in={open[itemCategory.id]} timeout="auto" unmountOnExit>
                  {getListVisible(itemCategory.children, { paddingLeft: 50 })}
                </Collapse>
              </div>
            ) : (
              lineView
            );
          })}
      </div>
    );
  };

  return (
    <Paper elevation={2} className={classes.root}>
      <Grid container>
        <Grid item lg={6} md={12}>
          <TextField
            label="Търсене на категория"
            variant="outlined"
            margin="dense"
            fullWidth
            defaultValue={categoryFilter}
            onChange={(event) => {
              setCategoryFilter(event.target.value);
            }}
            placeholder="Минимум 3 символа"
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null
            }}
          />
        </Grid>
        <Grid item lg={12} className={classes.list}>
          {getListVisible(categoriesVisibleTree, { width: '100%' })}
        </Grid>
      </Grid>
      {canUpdate && (
        <AMSButton
          variant="contained"
          color="primary"
          onClick={onSave}
          text="Запиши"
          loading={actionLoading}
          disabled={actionLoading}
        />
      )}
    </Paper>
  );
};

export default PartnerCategoriesContainer;
