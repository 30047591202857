import { ClientResponse, PaginatedListResponseClientResponse, amsV3Service } from '../../services';
import { IAsyncType, useAsync } from '.';

export interface IClients<T> extends IAsyncType<T> {
  clients: T;
}

const useClients = (
  id?: number[],
  showInactive?: boolean,
  partners?: number[],
  name?: string,
  email?: string
): IClients<ClientResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseClientResponse>(
      amsV3Service.getClients,
      {
        data: [],
        total: 0,
        page: 1,
        size: 100
      },
      [id, showInactive, partners, name, email],
      true
    );
  return {
    clients: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useClients;
