import {
  DeliveryItemResponse,
  DeliveryResponse,
  SupplierResponse,
  UserResponse,
  WarehouseResponse
} from '../../../../services';
import { Grid, Paper, TextField } from '@material-ui/core';
import { supplierToLookup, warehouseToLookup } from '../../../../models/lookup';

import { AMSViewOnlyTextField } from '../../../../helpers/ui';
import DateFnsUtils from '@date-io/date-fns';
import DeliveryItemsPanel from '../DeliveryItemsPanel/DeliveryItemsPanel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import React from 'react';
import { getExtendedDeliveryId } from '../../../../helpers/utils';
import { useStyles } from '../../../../helpers/hooks';

interface DeliveryInfoPanelProps {
  delivery: DeliveryResponse;
  deliveryItems: DeliveryItemResponse[];
  supplier: SupplierResponse;
  warehouse: WarehouseResponse;
  createdBy: UserResponse;
  totalPrice: number;
  note: string;
  loading: boolean;
}

const DeliveryInfoPanel = ({
  delivery,
  deliveryItems,
  supplier,
  warehouse,
  createdBy,
  totalPrice,
  note,
  loading
}: DeliveryInfoPanelProps) => {
  const classes = useStyles();
  return (
    <Paper elevation={2} className={classes.paper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <OutlinedDiv label="Данни за доставката" disabled={!!delivery} loading={loading}>
          <Grid container spacing={2}>
            <Grid container spacing={1} item sm={12} md={8} lg={8}>
              <Grid item sm={12} md={2} lg={2}>
                <TextField
                  label="Номер"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={
                    delivery && delivery.id > 0 ? getExtendedDeliveryId(delivery.id) : 'Нов запис'
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12} md={2} lg={2}>
                <TextField
                  label="Дата"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={
                    delivery?.deliveryDate ? delivery.deliveryDate.substring(0, 10) : 'Нова дата'
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12} md={8} lg={8}>
                <AMSViewOnlyTextField label="Склад" value={warehouse?.name ?? ''} />
              </Grid>
              <Grid item sm={12} md={4} lg={4}>
                <TextField
                  label="Създадена от"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '-'}
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <AMSViewOnlyTextField label="Доставчик" value={supplier?.name ?? '-'} />
              </Grid>
              <Grid item sm={12} md={2} lg={2}>
                <AMSViewOnlyTextField
                  label="Обща сума"
                  value={totalPrice ? totalPrice.toFixed(2) : '0.00'}
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <Grid style={{ height: '100%' }}>
                <AMSViewOnlyTextField label="Забележка" value={note} multiline rows={4} />
              </Grid>
            </Grid>
          </Grid>
        </OutlinedDiv>
        <Grid container spacing={2}>
          <Grid item sm={12} lg={12}>
            {delivery && supplier && warehouse && deliveryItems ? (
              <DeliveryItemsPanel
                delivery={delivery}
                supplier={supplierToLookup(supplier)}
                warehouse={warehouseToLookup(warehouse)}
                deliveryItems={deliveryItems}
                loading={loading}
              />
            ) : null}
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

export default DeliveryInfoPanel;
