import { Observable } from '../helpers/observable';

export interface Snackbar {
  readonly snackbarOpen: boolean;
}

export class SnackbarService {
  readonly snackbar = new Observable<Snackbar>({ snackbarOpen: false });

  isSnackbarOpen = () => this.snackbar.get();

  setSnackbarOpen = (isOpen: boolean) => {
    this.snackbar.set({ snackbarOpen: isOpen });
    // setTimeout(() => this.snackbar.set({ snackbarOpen: false }), 3000);
  };
}
