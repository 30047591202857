import { InputBaseComponentProps, TextField } from '@material-ui/core';

import React from 'react';

export interface AMSViewOnlyTextFieldProps {
  label: string;
  value: string;
  multiline?: boolean;
  rows?: string | number;
  inputProps?: InputBaseComponentProps | undefined;
  helperText?: string;
}

const AMSViewOnlyTextField = ({
  label,
  value,
  multiline,
  rows,
  inputProps,
  helperText
}: AMSViewOnlyTextFieldProps) => {
  return (
    <TextField
      label={label}
      margin="dense"
      variant="outlined"
      fullWidth
      disabled
      value={value}
      multiline={multiline}
      rows={rows}
      inputProps={inputProps}
      helperText={helperText}
    />
  );
};

export default AMSViewOnlyTextField;
