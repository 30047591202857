import { IAsyncType, useAsync } from '.';
import {
  ItemListResponse,
  PaginatedListResponseItemListResponse,
  amsV3Service
} from '../../services';

export interface IItems<T> extends IAsyncType<T> {
  items: T;
}

const useItems = (
  ids?: number[],
  search?: string,
  categoryIds?: number[],
  supplierIds?: number[],
  manufacturerIds?: number[],
  partnerId?: number,
  showInactive: boolean = false,
  skip?: number,
  limit?: number
): IItems<ItemListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseItemListResponse>(
      amsV3Service.getItems,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [ids, search, categoryIds, supplierIds, manufacturerIds, partnerId, showInactive, skip, limit]
    );
  return {
    items: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useItems;
