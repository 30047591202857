import {
  ClientDeviceResponse,
  PaginatedListResponseClientDeviceResponse,
  amsV3Service
} from '../../services';
import { IAsyncType, useAsync } from '.';

export interface IClientDevices<T> extends IAsyncType<T> {
  clientDevices: T;
}

const useClientDevices = (id: number): IClientDevices<ClientDeviceResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseClientDeviceResponse>(
      amsV3Service.getClientDevices,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [id ? [id] : null],
      true
    );
  return {
    clientDevices: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useClientDevices;
