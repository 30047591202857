import {
  DeliveryItemResponse,
  DeliveryResponse,
  PaginatedListResponseDeliveryItemResponse,
  amsV3Service
} from '../../services';
import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

export interface IDelivery<T> extends IAsyncType<T> {
  delivery: T;
  setDelivery: Dispatch<SetStateAction<T>>;
  deliveryItems: DeliveryItemResponse[];
}

const useDelivery = (id: number): IDelivery<DeliveryResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<DeliveryResponse | null>(amsV3Service.getDelivery, null, id ? [id] : null);
  const deliveryItemsResp = useAsync<PaginatedListResponseDeliveryItemResponse>(
    amsV3Service.getDeliveryItemsByDeliveryId,
    { data: [], total: 0, page: 0, size: 0 },
    id ? [id] : null
  );
  return {
    delivery: data,
    setDelivery: setData,
    deliveryItems: deliveryItemsResp.data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useDelivery;
