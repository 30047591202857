import { Grow, Snackbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Alert } from '@material-ui/lab';
import React from 'react';

interface AMSSnackBarProps {
  open: boolean;
  onClose: (open: boolean) => void;
  severity?: 'success' | 'info' | 'warning' | 'error' | undefined;
  message?: string;
}

const GrowTransition = (props: TransitionProps) => {
  return <Grow {...props} />;
};

const AMSSnackBar = ({
  open,
  onClose,
  severity = 'success',
  message = 'Данните са записани успешно'
}: AMSSnackBarProps) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      TransitionComponent={GrowTransition}
      message={message}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AMSSnackBar;
