import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplate: 'auto 1fr auto / 1fr',
    height: '100%',
    maxHeight: '100%'
  },
  header: {
    padding: 4,
    gridColumn: '1 / 4',
    color: '#fff',
    backgroundColor: '#3f51b5'
  },
  content: {
    gridColumn: '1 / 4',
    backgroundColor: 'white',
    overflow: 'auto',
    padding: 8
  },
  footer: {
    gridColumn: '1 / 4',
    backgroundColor: '#EFEFEF',
    maxHeight: 30
  },
  title: {
    flexGrow: 1
  }
}));

interface AMSWidgetProps {
  title: any;
  children: any;
  footer?: any;
}

const AMSWidget = ({ title, children, footer }: AMSWidgetProps) => {
  const classes = useStyles();
  return (
    <Paper elevation={2} className={classes.root}>
      <div className={classes.header}>{title}</div>
      <div className={classes.content}>{children}</div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </Paper>
  );
};

export default AMSWidget;
