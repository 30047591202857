import { Collapse, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { ChangeEvent, useState } from 'react';
import Category from '../../models/category';

const useStyles = makeStyles({
  root: {
    maxWidth: 256
  },
  media: {
    height: 140
  },
  titleRow: {
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F8F8F8'
    }
  },
  title: {
    borderBottom: '1px solid #DDD',
    textAlign: 'left',
    marginTop: 6
  },
  selected: {
    backgroundColor: '#F0F0F0',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  normal: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  hasDifferentChildren: {
    marginLeft: 16,
    width: 125,
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #F79803'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #F79803'
    }
  },
  normalTextField: {
    marginLeft: 16,
    width: 125
  },
  leftPadding: {
    paddingLeft: 50
  }
});

const ListPricing = ({ data, coeff, className, setCoeff, selected, setSelected }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState<any>({ '-1': true });

  const hasDifferentChildren = (category: any) => {
    return (
      flattenTree([category]).findIndex(
        (item: any) => item.id !== category.id && coeff[category.id] !== coeff[item.id]
      ) > -1
    );
  };

  return (
    <div className={className}>
      {data &&
        !!data.length &&
        data.map((line: any, index: number) => {
          const lineView = (
            <div key={`line-view-${index}`} className={classes.titleRow}>
              {line.children && !!line.children.length && (
                <IconButton
                  size="small"
                  onClick={(event) => {
                    setOpen({ ...open, [line.id]: !open[line.id] });
                  }}
                >
                  {open[line.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}

              <div
                className={selected && selected.id === line.id ? classes.selected : classes.normal}
                onClick={() => {
                  if (line.children && line.children.length) {
                    setOpen({ ...open, [line.id]: !open[line.id] });
                  } else {
                    setSelected(line);
                  }
                }}
              >
                <Typography
                  key={`typography-${line.id}`}
                  className={classes.title}
                >{`${line.name}`}</Typography>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  id={`coeff-${line.id}`}
                  type="number"
                  value={coeff[line.id]}
                  onClick={(event) => event.stopPropagation()}
                  className={
                    hasDifferentChildren(line)
                      ? classes.hasDifferentChildren
                      : classes.normalTextField
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    const value: number = event.target.value ? +event.target.value : 0;
                    if (value === 0 || value >= 1) {
                      const children: any = flattenTree([line]).reduce((res: any, l: Category) => {
                        if (!res) {
                          res = {};
                        }
                        if (l.id && line.id !== l.id) {
                          res[l.id] = value.toFixed(2);
                        }
                        return res;
                      }, {});
                      setCoeff({ ...coeff, [line.id]: event.target.value, ...children });
                    }
                  }}
                  onBlur={(event) => {
                    if (event.target.value) {
                      setCoeff({
                        ...coeff,
                        [line.id]: (+event.target.value).toFixed(2)
                      });
                    } else {
                      setCoeff({
                        ...coeff,
                        [line.id]: '0.00'
                      });
                    }
                  }}
                  inputProps={{
                    step: '0.01',
                    style: {
                      padding: '2px 8px 2px 4px',
                      textAlign: 'right'
                    }
                  }}
                  size="small"
                />
              </div>
            </div>
          );
          return line.children && line.children.length > 0 ? (
            <div key={`collapse-${line.id}`}>
              {lineView}
              <Collapse in={open[line.id]} timeout="auto" unmountOnExit>
                <ListPricing
                  data={line.children}
                  className={classes.leftPadding}
                  coeff={coeff}
                  selected={selected}
                  setSelected={setSelected}
                  setCoeff={setCoeff}
                />
              </Collapse>
            </div>
          ) : (
            lineView
          );
        })}
    </div>
  );
};

const flattenTree = (tree: any, fieldToExtract?: string) =>
  tree.reduce((res: any[], item: any, index: number) => {
    if (!res) {
      res = [];
    }
    if (item.children && item.children.length) {
      const flat = flattenTree(item.children, fieldToExtract);
      res = [...res, ...flat];
    }
    res.push(fieldToExtract ? item[fieldToExtract] : { ...item });
    return res;
  }, []);

export default ListPricing;
