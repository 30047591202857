import { Grid, Typography, makeStyles } from '@material-ui/core';

import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { cookiesService } from '../../services';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  containerMobile: {
    marginTop: '15vh'
  },
  containerBrowserExpandedDrawer: {
    marginTop: '15vh',
    paddingRight: 260
  },
  containerBrowser: {
    marginTop: '15vh',
    paddingRight: 60
  },
  fullWidth: {
    width: '100%'
  },
  warningIcon: {
    width: 200,
    height: 200
  },
  warningText: {
    fontWeight: 'bold'
  }
}));

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={
        isMobile
          ? classes.containerMobile
          : cookiesService.isSideDrawerExpanded()
          ? classes.containerBrowserExpandedDrawer
          : classes.containerBrowser
      }
      alignItems="center"
    >
      <Grid item lg={12} className={classes.fullWidth}>
        <WarningIcon color="error" className={classes.warningIcon} />
      </Grid>
      <Grid item lg={12} className={classes.fullWidth}>
        <Typography variant="h1" component="h1" className={classes.warningText}>
          404
        </Typography>
      </Grid>
      <Grid item lg={12} className={classes.fullWidth}>
        <Typography variant="h5" component="h1" className={classes.warningText}>
          Страницата не е намерена,
          <br /> моля използвайте менюто за навигация
        </Typography>
      </Grid>
    </Grid>
  );
};
export default NotFoundPage;
