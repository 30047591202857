import {
  AMSAutocomplete,
  AMSButton,
  AMSDatePicker,
  AMSTable,
  AMSViewOnlyTextField
} from '../../../../helpers/ui';
import { AccessPermissions, amsLegacyService, snackbarService } from '../../../../services';
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { StocktakingItemResponse, StocktakingRequest } from '../../../../services/api';
import { isValidDate, toDateString } from '../../../../helpers/date-helper';
import {
  useFlag,
  useLookup,
  usePermissions,
  useUsers,
  useWarehouses
} from '../../../../helpers/hooks';
import { useStocktaking, useStyles } from '../../../../helpers/hooks';
import { userToLookup, warehouseToLookup } from '../../../../models/lookup';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import AddStocktakingItemDialog from '../Dialog/AddStocktakingItemDialog';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import { useHistory } from 'react-router-dom';

interface StocktakingInfoComponentProps {
  id?: number;
}

const StocktakingInfoComponent = ({ id }: StocktakingInfoComponentProps) => {
  const history = useHistory();
  const classes = useStyles();

  const [addItemOpenDialog, setAddItemOpenDialog] = useFlag();
  const [warehouse, setWarehouse] = useLookup();
  const { warehouses } = useWarehouses();
  const [user, setUser] = useLookup();
  const { users } = useUsers();
  const [stocktakingItems, setStocktakingItems] = useState<any[]>([]);
  const [itemIds, setItemIds] = useState<number[]>([]);
  const [executionDate, setExecutionDate] = useState<Date | null>(null);

  const { stocktaking, loading, setParameters } = useStocktaking(id);

  const [canCreate] = usePermissions([AccessPermissions.CAN_CREATE_STOCKTAKING]);

  useEffect(() => {
    if (stocktaking) {
      setWarehouse({ id: stocktaking.warehouseId, value: stocktaking.warehouseName });
      setExecutionDate(new Date(stocktaking.executionDate));
      setStocktakingItems(stocktaking.items);
    }
  }, [stocktaking, setWarehouse]);

  useEffect(() => {
    if (id) {
      setParameters([id]);
    }
  }, [id, setParameters]);

  const handleAdd = useCallback(
    async (newStocktakingItem: any) => {
      setStocktakingItems([...stocktakingItems, newStocktakingItem]);
      setItemIds([...itemIds, newStocktakingItem.id]);
    },
    [stocktakingItems, itemIds]
  );

  const handleDelete = useCallback(
    async (deleteStocktakingItem: any) => {
      const tempItems = [...stocktakingItems];
      tempItems.splice(deleteStocktakingItem.tableData.id, 1);
      setStocktakingItems(tempItems);
    },
    [stocktakingItems]
  );

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <OutlinedDiv label="Данни за ревизия" disabled={true} loading={loading}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={6} sm={12}>
                <AMSViewOnlyTextField label="ИД" value={id ? `${id}` : 'Нова ревизия'} />
              </Grid>
              <Grid item lg={!id ? 9 : 6} md={6} sm={12}>
                {!id ? (
                  <AMSAutocomplete
                    label="Склад"
                    options={warehouses.map(warehouseToLookup)}
                    value={warehouse}
                    onChange={setWarehouse}
                    minChar={0}
                    required
                  />
                ) : (
                  <AMSViewOnlyTextField
                    label="Склад"
                    value={stocktaking?.warehouseName ?? 'Склад'}
                  />
                )}
              </Grid>
              <Grid item lg={3} md={3} sm={12}>
                {!id ? (
                  <AMSDatePicker
                    label="Дата"
                    value={executionDate ? executionDate : null}
                    onChange={(value: Date | null) => {
                      if (value) {
                        if (isValidDate(value)) {
                          setExecutionDate(value);
                        }
                      } else {
                        setExecutionDate(null);
                      }
                    }}
                    minDate={new Date()}
                    required
                  />
                ) : (
                  <AMSViewOnlyTextField label="Дата" value={stocktaking?.executionDate ?? 'Дата'} />
                )}
              </Grid>
              {!id ? (
                <Grid item lg={9} md={9} sm={12}>
                  <AMSAutocomplete
                    label="Потребител"
                    options={users.map(userToLookup)}
                    value={user}
                    onChange={setUser}
                    minChar={0}
                    required
                  />
                </Grid>
              ) : undefined}
            </Grid>
          </OutlinedDiv>
        </Grid>
        <Grid item lg={12}>
          <AMSTable
            title={'Артикули за ревизия'}
            columns={[
              {
                title: 'Арт №',
                field: 'itemArtNo',
                render: (stocktakingItem: StocktakingItemResponse) => (
                  <AMSLink href={`/item?id=${stocktakingItem.itemId}`}>
                    {stocktakingItem.itemArtNo}
                  </AMSLink>
                ),
                cellStyle: { width: '20%' }
              },
              {
                title: 'Име',
                field: 'itemName',
                render: (stocktakingItem: StocktakingItemResponse) => (
                  <AMSLink href={`/item?id=${stocktakingItem.itemId}`}>
                    {stocktakingItem.itemName}
                  </AMSLink>
                ),
                cellStyle: { width: '60%' }
              },
              {
                title: 'Мерна единица',
                field: 'itemUnitName',
                cellStyle: { width: '20%' }
              }
            ]}
            data={stocktakingItems}
            components={{
              Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
            }}
            paging={false}
            overflowY="scroll"
            minBodyHeight="45vh"
            maxBodyHeight="45vh"
            addRowPosition="first"
            onAdd={
              warehouse && user && executionDate && !id
                ? () => {
                    setAddItemOpenDialog(true);
                  }
                : undefined
            }
            onDelete={!id ? handleDelete : undefined}
          />
        </Grid>
        <Grid container item lg={12} justify="flex-end">
          {canCreate && (
            <AMSButton
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={async () => {
                if (executionDate) {
                  const request: StocktakingRequest = {
                    warehouseId: warehouse?.id,
                    userId: user?.id,
                    items: itemIds,
                    executionDate: toDateString(executionDate)
                  };
                  const stocktakingResp = await amsLegacyService.createStocktaking(request);
                  if (stocktakingResp && stocktakingResp.data) {
                    snackbarService.setSnackbarOpen(true);
                    history.push(`/stocktaking?id=${stocktakingResp.data.id}`);
                  }
                }
              }}
              text={'Запиши'}
              disabled={!warehouse || !user || !executionDate || stocktakingItems.length === 0}
              loading={loading}
            />
          )}
        </Grid>
        {canCreate && !id && (
          <AddStocktakingItemDialog
            open={addItemOpenDialog}
            onClose={() => setAddItemOpenDialog(false)}
            onSave={(item: any, addNew: boolean) => {
              if (!addNew) {
                setAddItemOpenDialog(false);
              }
              handleAdd(item);
            }}
          />
        )}
      </Grid>
    </Paper>
  );
};

export default StocktakingInfoComponent;
