import React from 'react';

import TextField from '@material-ui/core/TextField';
import { CircularProgress } from '@material-ui/core';

const defaultStyle = { padding: '10px 10px 10px 10px' };

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedDiv = ({
  children,
  label,
  style = {},
  className = '',
  disabled = false,
  required = false,
  loading = false
}) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      multiline
      fullWidth
      required={required}
      disabled={disabled ? true : false}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
        style: { ...defaultStyle, ...style },
        className
      }}
      inputProps={{ children: loading ? <CircularProgress disabled={!disabled} /> : children }}
    />
  );
};
export default OutlinedDiv;
