import React, { useState } from 'react';
import { ignoreOffset, isValidDate } from '../../date-helper';

import { KeyboardDatePicker } from '@material-ui/pickers';

export interface AMSDatePickerProps {
  label: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  error?: boolean;
}

const AMSDatePicker = ({
  label,
  value = null,
  onChange,
  required,
  minDate,
  maxDate,
  placeholder,
  helperText,
  disabled = false,
  error = false
}: AMSDatePickerProps) => {
  const [date, setDate] = useState<Date | null>(value);
  return (
    <KeyboardDatePicker
      disableToolbar
      autoOk={true}
      variant="inline"
      format="dd/MM/yy"
      margin="dense"
      label={label}
      helperText={helperText}
      value={date}
      error={error}
      onChange={(value: Date | null) => {
        if (value) {
          if (isValidDate(value)) {
            setDate(ignoreOffset(value));
            onChange(ignoreOffset(value));
          }
        } else {
          setDate(null);
          onChange(null);
        }
      }}
      inputVariant="outlined"
      fullWidth
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
      required={required}
      minDate={minDate}
      maxDate={maxDate}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default AMSDatePicker;
