import Category, { CategoryForm } from '../models/category';
import {
  CreateOrderV2Request,
  CreatePricingPolicyRequest,
  ReturnOrderItemV2Request,
  SetOrderingPolicyRequest,
  StocktakingRequest,
  UpdateOrderV2Request,
  UpdatePricingPolicyRequest
} from './api';
import { OrderStatusKey, OrderStatusKeys, documentTypeAliasMap } from '.';

import { AMSApi } from '../helpers/openapi-axios-helper';

export class AMSLegacyService {
  getPartnerVisibleCategories = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1PartnersPartnerIdItemCategoryAssociationGet(id);
  };

  updatePartnerVisibleCategories = async (partnerId: number, ids: number[]) => {
    const axios = await AMSApi();
    return await axios.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, ids);
  };
  getCategories = async () => {
    const axios = await AMSApi();
    return await axios.readAllV1ItemCategoriesGet();
  };

  getCategoriesFlat = async (leafOnly: boolean = false) => {
    const axios = await AMSApi();
    return await axios.readAllFlatV1ItemCategoriesFlatGet(leafOnly);
  };

  getCategory = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1ItemCategoriesIdGet(id);
  };

  createCategory = async (category: CategoryForm) => {
    const axios = await AMSApi();
    if (category && category.name) {
      return await axios.createV1ItemCategoriesPost({
        name: category.name,
        parentId: category.parentId
      });
    }
  };

  updateCategory = async (category: Category) => {
    const axios = await AMSApi();
    return await axios.updateV1ItemCategoriesPut(category);
  };

  deleteCategory = async (id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1ItemCategoriesIdDelete(id);
  };

  getCompanyCategories = async () => {
    const axios = await AMSApi();
    return await axios.readAllV1CompanyCategoriesGet();
  };

  getCompanyCategory = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1CompanyCategoriesIdGet(id);
  };

  createCompanyCategory = async (category: CategoryForm) => {
    const axios = await AMSApi();
    if (category && category.name) {
      return await axios.createV1CompanyCategoriesPost({
        name: category.name,
        parentId: category.parentId
      });
    }
  };

  updateCompanyCategory = async (category: Category) => {
    const axios = await AMSApi();
    return await axios.updateV1CompanyCategoriesPut(category);
  };

  deleteCompanyCategory = async (id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1CompanyCategoriesIdDelete(id);
  };

  getDeliveryItems = async (
    id: number,
    warehouseId?: number,
    skipDepleted: boolean = false,
    skip?: number,
    limit?: number
  ) => {
    const axios = await AMSApi();
    return await axios.readAllItemsV1DeliveriesItemsGet(id, warehouseId, skipDepleted, skip, limit);
  };

  getOrderingPolicy = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id);
  };

  setOrderingPolicy = async (id: number, orderPolicy: SetOrderingPolicyRequest) => {
    const axios = await AMSApi();
    return await axios.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, orderPolicy);
  };

  getOrdersForCollectionAmount = async (legalEntityId: number) => {
    const axios = await AMSApi();
    return await axios.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(
      legalEntityId
    );
  };

  getOrdersForDistributionAmount = async (legalEntityId: number) => {
    const axios = await AMSApi();
    return await axios.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(
      legalEntityId
    );
  };

  getPendingPaymentsAmount = async (legalEntityId: number) => {
    const axios = await AMSApi();
    return await axios.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(
      legalEntityId
    );
  };

  getOverduePaymentsAmount = async (legalEntityId: number) => {
    const axios = await AMSApi();
    return await axios.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(
      legalEntityId
    );
  };

  getPricingPolicies = async (search?: string) => {
    const axios = await AMSApi();
    return await axios.readListV1PricingPoliciesGet(search);
  };

  getPricingPolicy = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readV1PricingPoliciesIdGet(id);
  };

  getPricingPolicyCategories = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id);
  };

  getPricingPolicyItems = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id);
  };

  createPricingPolicy = async (pricingPolicy: CreatePricingPolicyRequest) => {
    const axios = await AMSApi();
    return await axios.createV1PricingPoliciesPost(pricingPolicy);
  };

  updatePricingPolicy = async (pricingPolicy: UpdatePricingPolicyRequest) => {
    const axios = await AMSApi();
    return await axios.updateV1PricingPoliciesPut(pricingPolicy);
  };

  deletePricingPolicy = async (id: number) => {
    const axios = await AMSApi();
    return await axios.deleteV1PricingPoliciesIdDelete(id);
  };

  getDeliveryDocumentsReport = async (
    fromDate?: string,
    toDate?: string,
    suppliersIds?: number[],
    companyIds?: number[],
    hidePaid?: boolean
  ) => {
    const axios = await AMSApi();
    return await axios.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(
      fromDate,
      toDate,
      suppliersIds?.join(','),
      companyIds?.join(','),
      hidePaid
    );
  };

  getStockByOrders = async (
    warehouseId: number,
    toDate: string,
    hideOrdersWithoutDeliveryDate?: boolean
  ) => {
    const axios = await AMSApi();
    return await axios.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(
      warehouseId,
      toDate,
      hideOrdersWithoutDeliveryDate
    );
  };

  getItemPrices = async (itemIds: number[]) => {
    const axios = await AMSApi();
    return await axios.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(
      itemIds.join(',')
    );
  };

  getItemCategoryMultipliers = async (itemCategoryIds: number[]) => {
    const axios = await AMSApi();
    return await axios.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(
      itemCategoryIds.join(',')
    );
  };

  getOrdersBalance = async (
    statusKeys?: OrderStatusKey[],
    companyIds?: number[],
    warehouseIds?: number[],
    fromDate?: string,
    toDate?: string,
    noInvoice: boolean = false
  ) => {
    const axios = await AMSApi();
    return await axios.readOrdersBalanceV1ReportsOrdersBalanceGet(
      statusKeys?.join(','),
      companyIds?.join(','),
      warehouseIds?.join(','),
      fromDate,
      toDate,
      noInvoice,
      false
    );
  };

  getDepletedAmountsWithPrices = async (
    fromDate?: string,
    toDate?: string,
    partners?: string,
    companies?: string,
    warehouses?: string,
    itemCategories?: string,
    itemId?: number
  ) => {
    const axios = await AMSApi();
    return await axios.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(
      fromDate,
      toDate,
      partners,
      companies,
      warehouses,
      itemCategories,
      itemId
    );
  };

  getItemMovements = async (
    fromDate?: string,
    toDate?: string,
    partners?: number[],
    warehouses?: number[]
  ) => {
    const axios = await AMSApi();
    return await axios.getAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(
      fromDate,
      toDate,
      partners,
      warehouses
    );
  };

  getStockTakingReports = async (
    fromExecutionDate?: string,
    toExecutionDate?: string,
    userId?: number
  ) => {
    const axios = await AMSApi();
    return await axios.listStockTakingReportsV2StockTakingReportsGet(
      fromExecutionDate,
      toExecutionDate,
      userId
    );
  };

  getStockTakingReport = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readStockTakingReportV2StockTakingReportsIdGet(id);
  };

  getStockTakingReportItems = async (itemId: number) => {
    const axios = await AMSApi();
    return await axios.listStockTakingReportItemsV2StockTakingReportItemsGet(itemId);
  };

  getStocktakings = async (fromDate?: string, toDate?: string, warehouses?: string) => {
    const axios = await AMSApi();
    return await axios.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses);
  };

  getStocktaking = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readStocktakingV2StocktakingsIdGet(id);
  };

  getStocktakingTasks = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readStocktakingTasksV2StocktakingsIdTasksGet(id);
  };

  createStocktaking = async (request: StocktakingRequest) => {
    const axios = await AMSApi();
    return await axios.createStocktakingV2StocktakingsPost(request);
  };

  createOrder = async (order: CreateOrderV2Request) => {
    const axios = await AMSApi();
    return await axios.createOrderV2OrdersPost(order);
  };

  getOrders = async (
    statusKeys?: string[],
    companyIds?: number[],
    warehouseIds?: number[],
    fromDate?: string,
    toDate?: string,
    noInvoice: boolean = false,
    withExecutionPercentage: boolean = false,
    withItems: boolean = false
  ) => {
    const axios = await AMSApi();
    return await axios.listOrdersV2OrdersGet(
      statusKeys?.join(','),
      companyIds?.join(','),
      warehouseIds?.join(','),
      fromDate,
      toDate,
      noInvoice,
      withExecutionPercentage,
      withItems
    );
  };

  getOrdersWithExecutionPercentage = async (companyIds?: number[], warehouseIds?: number[]) => {
    const axios = await AMSApi();
    return await axios.listOrdersV2OrdersGet(
      undefined,
      companyIds?.join(','),
      warehouseIds?.join(','),
      undefined,
      undefined,
      undefined,
      true
    );
  };

  getOrder = async (id: number, withLivePrices?: boolean) => {
    const axios = await AMSApi();
    return await axios.readOrderByOrderIdV2OrdersOrderIdGet(id, withLivePrices);
  };

  getOrderDocuments = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readOrderDocumentsV2OrdersOrderIdDocumentsGet(id);
  };

  getOrderDocumentTypeAlias = (id: number) => documentTypeAliasMap[id];

  updateOrder = async (id: number, order: UpdateOrderV2Request) => {
    const axios = await AMSApi();
    return await axios.updateOrderV2OrdersOrderIdPatch(id, order);
  };

  updateOrderStatus = async (orderId: number, statusKey: OrderStatusKeys) => {
    const axios = await AMSApi();
    return await axios.updateOrderStatusV2OrdersStatusPut({ orderId, statusKey });
  };

  returnItemsForOrder = async (orderId: number, requests: ReturnOrderItemV2Request[]) => {
    const axios = await AMSApi();
    return await axios.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, requests);
  };

  getOrderStatusLogs = async (id: number) => {
    const axios = await AMSApi();
    return await axios.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(id);
  };
}
