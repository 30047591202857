import { CircularProgress, TextField } from '@material-ui/core';
import { ItemListResponse, ItemResponse, amsV3Service } from '../../../services';
import React, { useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { getItemValue } from '../../../models/lookup';
import { useDebounce } from 'use-debounce/lib';

interface Props {
  label?: string;
  value: any;
  onChange: (option: ItemResponse | null) => void;
  minChar?: number;
  required?: boolean;
  disabled?: boolean;
  forDelivery?: boolean;
  supplierIds?: number[];
  partnerId?: number;
  getItemLabel?: (item: any) => string;
}

const AMSAsyncAutocomplete = ({
  label = '',
  value,
  onChange,
  disabled = false,
  required = false,
  minChar = 3,
  forDelivery = false,
  supplierIds,
  partnerId,
  getItemLabel = getItemValue
}: Props) => {
  const [text, setText] = useState('');
  const [query] = useDebounce(text, 250);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const reloadItems = async (q: string) => {
      setLoading(true);
      setText(q);
      const suppliersResp = await amsV3Service.getSuppliers();
      const supplierNamesById = suppliersResp.data.data.reduce(
        (acc: any, supplier: any) => ({ ...acc, [supplier.id]: supplier.name }),
        {}
      );
      const itemResponse = await amsV3Service.getItems([], q, [], supplierIds, [], partnerId);
      setOptions(
        itemResponse.data.data.map((item: ItemListResponse) => ({
          id: item.id,
          value: getItemLabel(item),
          selectedView: item.artNo,
          group: supplierNamesById[item.supplierId]
        }))
      );
      setLoading(false);
    };
    if (query && query.length >= minChar) {
      reloadItems(query);
    }
  }, [query, minChar, supplierIds, partnerId, getItemLabel]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        `${option.selectedView ? `${option.selectedView} ` : ''}${option.value}`
      }
      options={options}
      filterOptions={(opts: any, state: any) => {
        setText(state.inputValue);
        return opts;
      }}
      loading={loading}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="dense"
          variant="outlined"
          placeholder={!!minChar ? `Минимум ${minChar} символа` : ''}
          fullWidth
          required={required}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      value={value}
      onChange={async (_, option: any | null) => {
        if (option) {
          const itemResp = await amsV3Service.getItem(option.id);
          const itemData: any = { ...itemResp.data };
          itemData.value = getItemLabel(itemResp.data);
          onChange(itemData);
        } else {
          onChange(null);
        }
      }}
      loadingText="Зареждане"
      noOptionsText="Няма намерени резултати"
      groupBy={(option) => option.group}
    />
  );
};

export default AMSAsyncAutocomplete;
