import Country from '../models/country';

export const countriesData = [
  new Country('България', 'BG', 'BGR'),
  new Country('Андора', 'AD', 'AND'),
  new Country('Обединени арабски емирства', 'AE', 'ARE'),
  new Country('Афганистан', 'AF', 'AFG'),
  new Country('Антигуа и Барбуда', 'AG', 'ATG'),
  new Country('Ангуила', 'AI', 'AIA'),
  new Country('Албания', 'AL', 'ALB'),
  new Country('Армения', 'AM', 'ARM'),
  new Country('Ангола', 'AO', 'AGO'),
  new Country('Антарктика', 'AQ', 'ATA'),
  new Country('Аржентина', 'AR', 'ARG'),
  new Country('Американска Самоа', 'AS', 'ASM'),
  new Country('Австрия', 'AT', 'AUT'),
  new Country('Австралия', 'AU', 'AUS'),
  new Country('Аруба', 'AW', 'ABW'),
  new Country('Оландски острови', 'AX', 'ALA'),
  new Country('Азербайджан', 'AZ', 'AZE'),
  new Country('Босна и Херцеговина', 'BA', 'BIH'),
  new Country('Барбадос', 'BB', 'BRB'),
  new Country('Бангладеш', 'BD', 'BGD'),
  new Country('Белгия', 'BE', 'BEL'),
  new Country('Буркина Фасо', 'BF', 'BFA'),
  new Country('Бахрейн', 'BH', 'BHR'),
  new Country('Бурунди', 'BI', 'BDI'),
  new Country('Бенин', 'BJ', 'BEN'),
  new Country('Сен Бартелеми', 'BL', 'BLM'),
  new Country('Бермуда', 'BM', 'BMU'),
  new Country('Бруней Даруссалам', 'BN', 'BRN'),
  new Country('Боливия', 'BO', 'BOL'),
  new Country('Карибска Нидерландия', 'BQ', 'BES'),
  new Country('Бразилия', 'BR', 'BRA'),
  new Country('Бахами', 'BS', 'BHS'),
  new Country('Бутан', 'BT', 'BTN'),
  new Country('остров Буве', 'BV', 'BVT'),
  new Country('Ботсвана', 'BW', 'BWA'),
  new Country('Беларус', 'BY', 'BLR'),
  new Country('Белиз', 'BZ', 'BLZ'),
  new Country('Канада', 'CA', 'CAN'),
  new Country('Кокосови острови (острови Кийлинг)', 'CC', 'CCK'),
  new Country('Конго (Киншаса)', 'CD', 'COD'),
  new Country('Централноафриканска република', 'CF', 'CAF'),
  new Country('Конго (Бразавил)', 'CG', 'COG'),
  new Country('Швейцария', 'CH', 'CHE'),
  new Country('Кот д’Ивоар', 'CI', 'CIV'),
  new Country('острови Кук', 'CK', 'COK'),
  new Country('Чили', 'CL', 'CHL'),
  new Country('Камерун', 'CM', 'CMR'),
  new Country('Китай', 'CN', 'CHN'),
  new Country('Колумбия', 'CO', 'COL'),
  new Country('Коста Рика', 'CR', 'CRI'),
  new Country('Куба', 'CU', 'CUB'),
  new Country('Кабо Верде', 'CV', 'CPV'),
  new Country('Кюрасао', 'CW', 'CUW'),
  new Country('остров Рождество', 'CX', 'CXR'),
  new Country('Кипър', 'CY', 'CYP'),
  new Country('Чехия', 'CZ', 'CZE'),
  new Country('Германия', 'DE', 'DEU'),
  new Country('Джибути', 'DJ', 'DJI'),
  new Country('Дания', 'DK', 'DNK'),
  new Country('Доминика', 'DM', 'DMA'),
  new Country('Доминиканска република', 'DO', 'DOM'),
  new Country('Алжир', 'DZ', 'DZA'),
  new Country('Еквадор', 'EC', 'ECU'),
  new Country('Естония', 'EE', 'EST'),
  new Country('Египет', 'EG', 'EGY'),
  new Country('Западна Сахара', 'EH', 'ESH'),
  new Country('Еритрея', 'ER', 'ERI'),
  new Country('Испания', 'ES', 'ESP'),
  new Country('Етиопия', 'ET', 'ETH'),
  new Country('Финландия', 'FI', 'FIN'),
  new Country('Фиджи', 'FJ', 'FJI'),
  new Country('Фолклендски острови', 'FK', 'FLK'),
  new Country('Микронезия', 'FM', 'FSM'),
  new Country('Фарьорски острови', 'FO', 'FRO'),
  new Country('Франция', 'FR', 'FRA'),
  new Country('Габон', 'GA', 'GAB'),
  new Country('Обединеното кралство', 'GB', 'GBR'),
  new Country('Гренада', 'GD', 'GRD'),
  new Country('Грузия', 'GE', 'GEO'),
  new Country('Френска Гвиана', 'GF', 'GUF'),
  new Country('Гърнзи', 'GG', 'GGY'),
  new Country('Гана', 'GH', 'GHA'),
  new Country('Гибралтар', 'GI', 'GIB'),
  new Country('Гренландия', 'GL', 'GRL'),
  new Country('Гамбия', 'GM', 'GMB'),
  new Country('Гвинея', 'GN', 'GIN'),
  new Country('Гваделупа', 'GP', 'GLP'),
  new Country('Екваториална Гвинея', 'GQ', 'GNQ'),
  new Country('Гърция', 'GR', 'GRC'),
  new Country('Южна Джорджия и Южни Сандвичеви острови', 'GS', 'SGS'),
  new Country('Гватемала', 'GT', 'GTM'),
  new Country('Гуам', 'GU', 'GUM'),
  new Country('Гвинея-Бисау', 'GW', 'GNB'),
  new Country('Гаяна', 'GY', 'GUY'),
  new Country('Хонконг, САР на Китай', 'HK', 'HKG'),
  new Country('остров Хърд и острови Макдоналд', 'HM', 'HMD'),
  new Country('Хондурас', 'HN', 'HND'),
  new Country('Хърватия', 'HR', 'HRV'),
  new Country('Хаити', 'HT', 'HTI'),
  new Country('Унгария', 'HU', 'HUN'),
  new Country('Индонезия', 'ID', 'IDN'),
  new Country('Ирландия', 'IE', 'IRL'),
  new Country('Израел', 'IL', 'ISR'),
  new Country('остров Ман', 'IM', 'IMN'),
  new Country('Индия', 'IN', 'IND'),
  new Country('Британска територия в Индийския океан', 'IO', 'IOT'),
  new Country('Ирак', 'IQ', 'IRQ'),
  new Country('Иран', 'IR', 'IRN'),
  new Country('Исландия', 'IS', 'ISL'),
  new Country('Италия', 'IT', 'ITA'),
  new Country('Джърси', 'JE', 'JEY'),
  new Country('Ямайка', 'JM', 'JAM'),
  new Country('Йордания', 'JO', 'JOR'),
  new Country('Япония', 'JP', 'JPN'),
  new Country('Кения', 'KE', 'KEN'),
  new Country('Киргизстан', 'KG', 'KGZ'),
  new Country('Камбоджа', 'KH', 'KHM'),
  new Country('Кирибати', 'KI', 'KIR'),
  new Country('Коморски острови', 'KM', 'COM'),
  new Country('Сейнт Китс и Невис', 'KN', 'KNA'),
  new Country('Северна Корея', 'KP', 'PRK'),
  new Country('Южна Корея', 'KR', 'KOR'),
  new Country('Кувейт', 'KW', 'KWT'),
  new Country('Кайманови острови', 'KY', 'CYM'),
  new Country('Казахстан', 'KZ', 'KAZ'),
  new Country('Лаос', 'LA', 'LAO'),
  new Country('Ливан', 'LB', 'LBN'),
  new Country('Сейнт Лусия', 'LC', 'LCA'),
  new Country('Лихтенщайн', 'LI', 'LIE'),
  new Country('Шри Ланка', 'LK', 'LKA'),
  new Country('Либерия', 'LR', 'LBR'),
  new Country('Лесото', 'LS', 'LSO'),
  new Country('Литва', 'LT', 'LTU'),
  new Country('Люксембург', 'LU', 'LUX'),
  new Country('Латвия', 'LV', 'LVA'),
  new Country('Либия', 'LY', 'LBY'),
  new Country('Мароко', 'MA', 'MAR'),
  new Country('Монако', 'MC', 'MCO'),
  new Country('Молдова', 'MD', 'MDA'),
  new Country('Черна гора', 'ME', 'MNE'),
  new Country('Сен Мартен', 'MF', 'MAF'),
  new Country('Мадагаскар', 'MG', 'MDG'),
  new Country('Маршалови острови', 'MH', 'MHL'),
  new Country('Северна Македония', 'MK', 'MKD'),
  new Country('Мали', 'ML', 'MLI'),
  new Country('Мианмар (Бирма)', 'MM', 'MMR'),
  new Country('Монголия', 'MN', 'MNG'),
  new Country('Макао, САР на Китай', 'MO', 'MAC'),
  new Country('Северни Мариански острови', 'MP', 'MNP'),
  new Country('Мартиника', 'MQ', 'MTQ'),
  new Country('Мавритания', 'MR', 'MRT'),
  new Country('Монтсерат', 'MS', 'MSR'),
  new Country('Малта', 'MT', 'MLT'),
  new Country('Мавриций', 'MU', 'MUS'),
  new Country('Малдиви', 'MV', 'MDV'),
  new Country('Малави', 'MW', 'MWI'),
  new Country('Мексико', 'MX', 'MEX'),
  new Country('Малайзия', 'MY', 'MYS'),
  new Country('Мозамбик', 'MZ', 'MOZ'),
  new Country('Намибия', 'NA', 'NAM'),
  new Country('Нова Каледония', 'NC', 'NCL'),
  new Country('Нигер', 'NE', 'NER'),
  new Country('остров Норфолк', 'NF', 'NFK'),
  new Country('Нигерия', 'NG', 'NGA'),
  new Country('Никарагуа', 'NI', 'NIC'),
  new Country('Нидерландия', 'NL', 'NLD'),
  new Country('Норвегия', 'NO', 'NOR'),
  new Country('Непал', 'NP', 'NPL'),
  new Country('Науру', 'NR', 'NRU'),
  new Country('Ниуе', 'NU', 'NIU'),
  new Country('Нова Зеландия', 'NZ', 'NZL'),
  new Country('Оман', 'OM', 'OMN'),
  new Country('Панама', 'PA', 'PAN'),
  new Country('Перу', 'PE', 'PER'),
  new Country('Френска Полинезия', 'PF', 'PYF'),
  new Country('Папуа-Нова Гвинея', 'PG', 'PNG'),
  new Country('Филипини', 'PH', 'PHL'),
  new Country('Пакистан', 'PK', 'PAK'),
  new Country('Полша', 'PL', 'POL'),
  new Country('Сен Пиер и Микелон', 'PM', 'SPM'),
  new Country('Острови Питкерн', 'PN', 'PCN'),
  new Country('Пуерто Рико', 'PR', 'PRI'),
  new Country('Палестински територии', 'PS', 'PSE'),
  new Country('Португалия', 'PT', 'PRT'),
  new Country('Палау', 'PW', 'PLW'),
  new Country('Парагвай', 'PY', 'PRY'),
  new Country('Катар', 'QA', 'QAT'),
  new Country('Реюнион', 'RE', 'REU'),
  new Country('Румъния', 'RO', 'ROU'),
  new Country('Сърбия', 'RS', 'SRB'),
  new Country('Русия', 'RU', 'RUS'),
  new Country('Руанда', 'RW', 'RWA'),
  new Country('Саудитска Арабия', 'SA', 'SAU'),
  new Country('Соломонови острови', 'SB', 'SLB'),
  new Country('Сейшели', 'SC', 'SYC'),
  new Country('Судан', 'SD', 'SDN'),
  new Country('Швеция', 'SE', 'SWE'),
  new Country('Сингапур', 'SG', 'SGP'),
  new Country('Света Елена', 'SH', 'SHN'),
  new Country('Словения', 'SI', 'SVN'),
  new Country('Свалбард и Ян Майен', 'SJ', 'SJM'),
  new Country('Словакия', 'SK', 'SVK'),
  new Country('Сиера Леоне', 'SL', 'SLE'),
  new Country('Сан Марино', 'SM', 'SMR'),
  new Country('Сенегал', 'SN', 'SEN'),
  new Country('Сомалия', 'SO', 'SOM'),
  new Country('Суринам', 'SR', 'SUR'),
  new Country('Южен Судан', 'SS', 'SSD'),
  new Country('Сао Томе и Принсипи', 'ST', 'STP'),
  new Country('Салвадор', 'SV', 'SLV'),
  new Country('Синт Мартен', 'SX', 'SXM'),
  new Country('Сирия', 'SY', 'SYR'),
  new Country('Свазиленд', 'SZ', 'SWZ'),
  new Country('острови Търкс и Кайкос', 'TC', 'TCA'),
  new Country('Чад', 'TD', 'TCD'),
  new Country('Френски южни територии', 'TF', 'ATF'),
  new Country('Того', 'TG', 'TGO'),
  new Country('Тайланд', 'TH', 'THA'),
  new Country('Таджикистан', 'TJ', 'TJK'),
  new Country('Токелау', 'TK', 'TKL'),
  new Country('Източен Тимор', 'TL', 'TLS'),
  new Country('Туркменистан', 'TM', 'TKM'),
  new Country('Тунис', 'TN', 'TUN'),
  new Country('Тонга', 'TO', 'TON'),
  new Country('Турция', 'TR', 'TUR'),
  new Country('Тринидад и Тобаго', 'TT', 'TTO'),
  new Country('Тувалу', 'TV', 'TUV'),
  new Country('Тайван', 'TW', 'TWN'),
  new Country('Танзания', 'TZ', 'TZA'),
  new Country('Украйна', 'UA', 'UKR'),
  new Country('Уганда', 'UG', 'UGA'),
  new Country('Отдалечени острови на САЩ', 'UM', 'UMI'),
  new Country('Съединени щати', 'US', 'USA'),
  new Country('Уругвай', 'UY', 'URY'),
  new Country('Узбекистан', 'UZ', 'UZB'),
  new Country('Ватикан', 'VA', 'VAT'),
  new Country('Сейнт Винсънт и Гренадини', 'VC', 'VCT'),
  new Country('Венецуела', 'VE', 'VEN'),
  new Country('Британски Вирджински острови', 'VG', 'VGB'),
  new Country('Американски Вирджински острови', 'VI', 'VIR'),
  new Country('Виетнам', 'VN', 'VNM'),
  new Country('Вануату', 'VU', 'VUT'),
  new Country('Уолис и Футуна', 'WF', 'WLF'),
  new Country('Самоа', 'WS', 'WSM'),
  new Country('Косово', 'XK', 'XKX'),
  new Country('Йемен', 'YE', 'YEM'),
  new Country('Майот', 'YT', 'MYT'),
  new Country('Южна Африка', 'ZA', 'ZAF'),
  new Country('Замбия', 'ZM', 'ZMB'),
  new Country('Зимбабве', 'ZW', 'ZWE')
];

export const countryToFlag = (alpha3: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? alpha3
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : alpha3;
};

export const findCountry = (alpha3: string) =>
  countriesData.find((c: Country) => c.alpha3 === alpha3);

export const findCountryByAlpha2 = (alpha2: string) =>
  countriesData.find((c: Country) => c.alpha2 === alpha2);
