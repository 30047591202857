import {
  DeliveryResponse,
  PaginatedListResponseDeliveryResponse,
  amsV3Service
} from '../../services';
import { IAsyncType, useAsync } from '.';

export interface IDeliveries<T> extends IAsyncType<T> {
  deliveries: T;
}

const useDeliveries = (
  suppliers?: number[],
  warehouses?: number[],
  fromDate?: string,
  toDate?: string
): IDeliveries<DeliveryResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseDeliveryResponse>(
      amsV3Service.getDeliveries,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [suppliers, warehouses, fromDate, toDate]
    );
  return {
    deliveries: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useDeliveries;
