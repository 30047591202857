import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseUserListResponse,
  UserListResponse,
  amsV3Service
} from '../../services';

export interface IUsers<T> extends IAsyncType<T> {
  users: T;
}

const useUsers = (
  showInactive: boolean = false,
  company?: number,
  query?: string
): IUsers<UserListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseUserListResponse>(
      amsV3Service.getUsers,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [showInactive, company, query]
    );
  return {
    users: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useUsers;
