import { Container, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import StocktakingInfoComponent from './StocktakingInfoComponent/StocktakingInfoComponent';
import StocktakingTasksComponent from './StocktakingTasksComponent/StocktakingTasksComponent';
import TabPanel from '../../../helpers/ui/TabPanel/TabPanel';
import { parseQuery } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../../../helpers/hooks';

const StocktakingComponent = ({ location }: { location: any }) => {
  const { id, tabId } = parseQuery(location.search);

  const history = useHistory();
  const classes = useStyles();

  const [tab, setTab] = useState(tabId);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
    history.replace(`stocktaking?${id ? `id=${id}&` : ''}tabId=${newTab}`);
  };

  useEffect(() => {
    if (tabId) {
      setTab(+tabId);
    } else {
      setTab(0);
    }
  }, [tabId]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="Информация" />
        <Tab label="Задачи" disabled={!id} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <StocktakingInfoComponent id={id} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <StocktakingTasksComponent id={id} />
      </TabPanel>
    </Container>
  );
};

export default StocktakingComponent;
