import { createStyles, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, Fragment, useState } from 'react';
import Country from '../../../models/country';
import { countriesData, countryToFlag } from '../../country-helper';

const useStyles = makeStyles(() =>
  createStyles({
    flagContainer: { marginRight: 4 }
  })
);

interface Props {
  country: Country | null;
  disabled?: boolean;
  required?: boolean;
  onChange: (event: ChangeEvent<{}>, country: Country | null) => void;
  placeholder?: string;
}

const AMSCountryAutocomplete = ({
  country,
  disabled = false,
  required = false,
  onChange,
  placeholder
}: Props) => {
  const classes = useStyles();
  const [countries] = useState<Country[]>(countriesData);

  return (
    <Autocomplete
      options={countries}
      renderOption={(option: any) => (
        <Fragment key={option.alpha3}>
          <div className={classes.flagContainer}>{countryToFlag(option.alpha2)}</div>
          {option.name} ({option.alpha3})
        </Fragment>
      )}
      getOptionLabel={(option: any) => `(${option.alpha2}) ${option.name}`}
      getOptionSelected={(option, value) => option.alpha3 === value.alpha3}
      fullWidth
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Държава"
          margin="dense"
          variant="outlined"
          fullWidth
          required={required}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
      onChange={onChange}
      value={country}
    />
  );
};

export default AMSCountryAutocomplete;
