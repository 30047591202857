import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseStockReceivingProcessItemResponse,
  StockReceivingProcessItemResponse,
  amsV3Service
} from '../../services';

export interface IStockReceivingProcessItems<T> extends IAsyncType<T> {
  stockReceivingProcessItems: T;
  setStockReceivingProcessItems: (value: T) => T;
}

const useStockReceivingProcessItems = (
  id?: number
): IStockReceivingProcessItems<StockReceivingProcessItemResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseStockReceivingProcessItemResponse>(
      amsV3Service.getStockReceivingProcessItems,
      {
        data: [],
        total: 0,
        page: 1,
        size: 100
      },
      id ? [id] : null
    );
  return {
    stockReceivingProcessItems: data?.data ?? [],
    setStockReceivingProcessItems: (value: StockReceivingProcessItemResponse[]) => {
      setData({ data: value, total: value.length, page: 1, size: 100 });
      return value;
    },
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useStockReceivingProcessItems;
