import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  createStyles,
  makeStyles
} from '@material-ui/core';
import {
  CreateItemDeliverySubscriptionRequest,
  ItemResponse,
  PatchItemDeliverySubscriptionRequest,
  WarehouseResponse,
  amsV3Service,
  snackbarService
} from '../../../services';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useItemDeliverySubscription, useWarehouses } from '../../../helpers/hooks';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AMSViewOnlyTextField from '../../../helpers/ui/AMSViewOnlyTextField/AMSViewOnlyTextField';
import { parseQuery } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';
import { warehouseToLookup } from '../../../models/lookup';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: 0
    },
    root: {
      padding: 20,
      marginTop: 25
    },
    saveButton: {
      marginTop: 10
    }
  })
);

const ItemDeliverySubscriptionComponent = ({ location }: { location: any }) => {
  const { id } = parseQuery(location.search);

  const classes = useStyles();
  const history = useHistory();

  const [item, setItem] = useState<ItemResponse | null>(null);
  const [warehouse, setWarehouse] = useState<WarehouseResponse | null>(null);
  const [createdByName, setCreatedByName] = useState('');
  const [lastUpdatedByName, setLastUpdatedByName] = useState('');
  const [subscriptionForm, setSubscriptionForm] = useState<any>({});
  const { subscription, loading } = useItemDeliverySubscription(id);
  const { warehouses } = useWarehouses();

  const loadCreatedByName = useCallback(async () => {
    if (subscription?.createdById) {
      const resp = await amsV3Service.getUser(subscription.createdById);
      setCreatedByName(resp.data.name);
    }
  }, [subscription]);

  const loadLastUpdatedByName = useCallback(async () => {
    if (subscription?.lastUpdatedById) {
      const resp = await amsV3Service.getUser(subscription.lastUpdatedById);
      setLastUpdatedByName(resp.data.name);
    }
  }, [subscription]);

  const loadItem = useCallback(async () => {
    if (subscription?.itemId) {
      const itemResp = await amsV3Service.getItem(subscription?.itemId);
      setItem(itemResp.data);
    }
  }, [subscription]);

  const loadWarehouse = useCallback(async () => {
    if (subscription?.warehouseId) {
      const warehouseResp = await amsV3Service.getWarehouse(subscription?.warehouseId);
      setWarehouse(warehouseResp.data);
    }
  }, [subscription]);

  useEffect(() => {
    setSubscriptionForm({ ...subscription });
    loadItem();
    loadWarehouse();
    loadCreatedByName();
    loadLastUpdatedByName();
  }, [subscription, loadItem, loadWarehouse, loadCreatedByName, loadLastUpdatedByName]);

  const [actionLoading, setActionLoading] = useState(false);

  const handleOnClick = async () => {
    setActionLoading(true);
    if (id) {
      const patchRequest: PatchItemDeliverySubscriptionRequest = {
        daysForDelivery: subscriptionForm.daysForDelivery,
        batchSize: subscriptionForm.batchSize,
        bufferPercentage: subscriptionForm.bufferPercentage
      };
      const resp = await amsV3Service.updateItemDeliverySubscription(id, patchRequest);
      if (resp) {
        snackbarService.setSnackbarOpen(true);
        // setItemDeliverySubscription(resp.data);
      }
    } else {
      const createRequest: CreateItemDeliverySubscriptionRequest = {
        itemId: item!.id,
        warehouseId: warehouse!.id,
        daysForDelivery: subscriptionForm.daysForDelivery,
        batchSize: subscriptionForm.batchSize,
        bufferPercentage: subscriptionForm.bufferPercentage
      };
      const resp = await amsV3Service.createItemDeliverySubscription(createRequest);
      if (resp) {
        history.push(`/item-delivery-subscription?id=${resp.data.id}`);
        snackbarService.setSnackbarOpen(true);
      }
    }
    setActionLoading(false);
  };

  const isValidItemDeliverySubscription = useMemo(
    () =>
      (id || (item && warehouse)) &&
      subscriptionForm?.daysForDelivery &&
      subscriptionForm?.batchSize &&
      subscriptionForm?.bufferPercentage,
    [subscriptionForm, id, item, warehouse]
  );
  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="md" className={classes.container}>
      <Paper elevation={2} className={classes.root}>
        {subscription ? <></> : <></>}
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Grid container spacing={1}>
              <Grid item lg={6}>
                {!id && (
                  <AMSAsyncAutocomplete
                    label="Артикул"
                    onChange={(item: ItemResponse | null) => {
                      setItem(item);
                    }}
                    value={item}
                    minChar={3}
                  />
                )}
                {id && <AMSViewOnlyTextField label="Артикул" value={item?.name ?? ''} />}
              </Grid>
              <Grid item lg={6}>
                {!id && (
                  <AMSAutocomplete
                    label="Склад"
                    options={warehouses.map(warehouseToLookup)}
                    value={warehouse ? warehouse : null}
                    onChange={setWarehouse}
                    minChar={0}
                    required
                  />
                )}
                {id && <AMSViewOnlyTextField label="Склад" value={warehouse?.name ?? ''} />}
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Дни за доставка"
                  variant="outlined"
                  fullWidth
                  required
                  margin="dense"
                  value={subscriptionForm?.daysForDelivery ?? ''}
                  onChange={(event) =>
                    setSubscriptionForm({
                      ...subscriptionForm,
                      daysForDelivery: +event?.target.value ? +event.target.value : 0
                    })
                  }
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Мин. количество на поръчка"
                  variant="outlined"
                  fullWidth
                  required
                  margin="dense"
                  value={subscriptionForm?.batchSize ?? ''}
                  onChange={(event) =>
                    setSubscriptionForm({
                      ...subscriptionForm,
                      batchSize: event?.target.value ? +event.target.value : 0
                    })
                  }
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Буфер (%)"
                  variant="outlined"
                  fullWidth
                  required
                  margin="dense"
                  value={subscriptionForm?.bufferPercentage ?? ''}
                  onChange={(event) =>
                    setSubscriptionForm({
                      ...subscriptionForm,
                      bufferPercentage: event?.target.value ? +event.target.value : 0
                    })
                  }
                />
              </Grid>
              {id && (
                <Grid item lg={12}>
                  {subscription?.createdById && subscription?.createdAt && (
                    <AMSViewOnlyTextField
                      label="Създаден"
                      value={`От ${createdByName} на ${formatDate(subscription?.createdAt)}`}
                    />
                  )}
                  {subscription?.lastUpdatedById && subscription?.lastUpdatedAt && (
                    <AMSViewOnlyTextField
                      label="Последна редакция"
                      value={`От ${lastUpdatedByName} на ${formatDate(
                        subscription?.lastUpdatedAt
                      )}`}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {
          <AMSButton
            text="Запиши"
            color="primary"
            variant="contained"
            onClick={handleOnClick}
            disabled={actionLoading || !isValidItemDeliverySubscription}
            loading={actionLoading}
            className={classes.saveButton}
          />
        }
      </Paper>
    </Container>
  );
};

export default ItemDeliverySubscriptionComponent;

const formatDate = (dateString: string) => {
  return dateString.substr(0, 19).split('T').join(', ');
};
