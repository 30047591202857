import { AMSButton, AMSTextField } from '../../helpers/ui';
import { CircularProgress, Container, Grid } from '@material-ui/core';
import { ClientResponse, cookiesService } from '../../services';
import React, { useCallback, useMemo, useState } from 'react';
import { getExtendedClientId, getExtendedPartnerId } from '../../helpers/utils';
import { useClients, usePartners } from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import SearchIcon from '@material-ui/icons/Search';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const ClientsComponent = ({ location }: any) => {
  useMemo(() => parseFilter(location.search), [location.search]);

  const filter = useMemo(() => cookiesService.getClientsFilter(), []);
  const [filterName, setFilterName] = useState(filter.name ? filter.name : '');
  const [filterEmail, setFilterEmail] = useState(filter.email ? filter.email : '');

  const { clients, setParameters, loading } = useClients([], true, [], filterName, filterEmail);
  const { partners } = usePartners();

  const partnerNamesById = useMemo(
    () =>
      partners.reduce((res: any, partner) => {
        if (!res) {
          res = {};
        }
        res[partner.id] = partner.name;
        return res;
      }, {}),
    [partners]
  );

  const history = useHistory();

  const onSearch = useCallback(() => {
    cookiesService.setClientsFilter({
      filterName,
      filterEmail
    });
    let params: any = {};
    if (filterName) {
      params.name = filterName;
    }
    if (filterEmail) {
      params.email = filterEmail;
    }
    history.push({
      pathname: '/clients',
      search: new URLSearchParams(params).toString()
    });
    setParameters([[], false, [], filterName, filterEmail, []]);
  }, [filterName, filterEmail, history, setParameters]);

  return (
    <Container maxWidth="xl" className="margin-left-0">
      <Grid container spacing={1}>
        <Grid item lg={3} md={5} sm={6} xs={12}>
          <AMSTextField
            label="Търсене по име"
            placeholder="Търсене по име"
            value={filterName}
            onEnter={onSearch}
            onChange={(value) => setFilterName(value ?? '')}
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: loading ? <CircularProgress /> : null
            }}
          />
        </Grid>
        <Grid item lg={3} md={5} sm={6} xs={12}>
          <AMSTextField
            label="Търсене по имейл"
            placeholder="Търсене по имейл"
            value={filterEmail}
            onEnter={onSearch}
            onChange={(value) => setFilterEmail(value ?? '')}
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: loading ? <CircularProgress /> : null
            }}
          />
        </Grid>
        <Grid item lg={1} md={2} sm={12} xs={12}>
          <AMSButton
            color="primary"
            variant="contained"
            text="Търсене"
            loading={loading}
            disabled={false}
            onClick={onSearch}
            style={{
              marginTop: 8
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AMSTable
            title="Клиенти"
            columns={[
              {
                title: 'Номер',
                field: 'id',
                cellStyle: { width: '10%' },
                editable: 'never',

                render: (rowData: ClientResponse) => (
                  <AMSLink href={`/client-info?id=${rowData.id}`}>
                    {getExtendedClientId(rowData.id)}
                  </AMSLink>
                )
              },
              {
                title: 'Име',
                field: 'firstName',
                cellStyle: { width: '30%' },
                render: (rowData: ClientResponse) => `${rowData.firstName} ${rowData.lastName}`
              },
              {
                title: 'Телефон',
                field: 'phone',
                cellStyle: { width: '20%' },
                render: (rowData: ClientResponse) => (
                  <AMSLink href={`tel:${rowData.phone}`}>{rowData.phone}</AMSLink>
                )
              },
              {
                title: 'Имейл',
                field: 'email',
                cellStyle: { width: '20%' },
                render: (rowData: ClientResponse) => (
                  <AMSLink href={`mailto:${rowData.email}`}>{rowData.email}</AMSLink>
                )
              },
              {
                title: 'Контрагент',
                field: 'partnerId',
                cellStyle: { width: '20%' },
                render: (rowData: ClientResponse) => (
                  <AMSLink href={`/partner?id=${rowData.partnerId}`}>
                    {`${getExtendedPartnerId(rowData.partnerId ?? 0)} ${
                      partnerNamesById[rowData.partnerId ?? '']
                    }`}
                  </AMSLink>
                )
              }
            ]}
            data={clients}
            search={false}
            inlineActions={true}
            isLoading={loading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClientsComponent;
