import '@react-pdf-viewer/core/lib/styles/index.css';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Viewer, Worker } from '@react-pdf-viewer/core';

import React from 'react';
import printJS from 'print-js';

interface AMSPdfViewerDialogProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  url: string;
}

const AMSPdfViewerDialog = ({ open, onClose, title, url }: AMSPdfViewerDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // fullScreen={true}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={url} />
        </Worker>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => printJS(url)} color="primary" variant="outlined">
          Печат
        </Button>
        <Button onClick={onClose} color="primary" variant="contained">
          Затвори
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AMSPdfViewerDialog;
