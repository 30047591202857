import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { OrderStatusLogResponse } from '../../services/api';
import { amsLegacyService } from '../../services';

export interface IOrderStatusLogs<T> extends IAsyncType<T> {
  orderStatusLogs: T;
  setOrderStatusLogs: Dispatch<SetStateAction<T>>;
}

/**
 *
 * @deprecated
 */
const useOrderStatusLogs = (id: number): IOrderStatusLogs<OrderStatusLogResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    OrderStatusLogResponse[]
  >(amsLegacyService.getOrderStatusLogs, [], [id]);
  return {
    orderStatusLogs: data,
    setOrderStatusLogs: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useOrderStatusLogs;
