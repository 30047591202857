import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { StocktakingResponse } from '../../services/api';
import { amsLegacyService } from '../../services';

export interface IStocktakings<T> extends IAsyncType<T> {
  stocktakings: T;
  setStocktakings: Dispatch<SetStateAction<T>>;
}

/**
 *
 * @deprecated
 */
const useStocktakings = (
  fromDate?: string,
  toDate?: string,
  warehouses?: string
): IStocktakings<StocktakingResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    StocktakingResponse[]
  >(amsLegacyService.getStocktakings, [], [fromDate, toDate, warehouses]);
  return {
    stocktakings: data,
    setStocktakings: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useStocktakings;
