import { AMSAsyncAutocomplete, AMSAutocomplete, AMSTextField } from '../../../../../helpers/ui';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { ItemResponse, PartnerListResponse, amsV3Service } from '../../../../../services';
import Lookup, { partnerToLookup } from '../../../../../models/lookup';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { usePartners, useStyles } from '../../../../../helpers/hooks';

import AMSExternalItemsAutocomplete from '../../../../../helpers/ui/AMSExternalItemsAutocomplete/AMSExternalItemsAutocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface AddPartnerItemConfigurationDialogProps {
  partnerId: number;
  partnerLegalEntityId: number;
  partnerIntegrationType: number;
  open: boolean;
  onClose: () => void;
  onSave: (request: any, addNew: boolean, partnerIds?: number[]) => void;
}

const AddPartnerItemConfigurationDialog = ({
  partnerId,
  partnerLegalEntityId,
  partnerIntegrationType,
  open,
  onClose,
  onSave
}: AddPartnerItemConfigurationDialogProps) => {
  const classes = useStyles();
  const [min, setMin] = useState<string | null>(null);
  const [max, setMax] = useState<string | null>(null);
  const [item, setItem] = useState<Lookup | null>(null);
  const [partnerItem, setPartnerItem] = useState<Lookup | null>(null);
  const [addNew, setAddNew] = useState<boolean>(false);
  const { partners } = usePartners();
  const [selectedPartners, setSelectedPartners] = useState<Lookup[]>([]);

  const handleAddItem = async () => {
    if (partnerId) {
      const newItem = {
        externalItemUniqueKey: partnerItem!.id,
        externalItemArtNo: partnerItem!.selectedView,
        externalItemName: partnerItem!.value,
        itemId: item!.id,
        itemArtNo: item!.selectedView,
        itemName: item!.value,
        min: min && min.length > 0 ? min : undefined,
        max: max && max.length > 0 ? max : undefined
      };
      onSave(
        newItem,
        addNew,
        selectedPartners.map((p) => p.id)
      );
      setItem(null);
      setPartnerItem(null);
      setMin(null);
      setMax(null);
    }
  };

  useEffect(() => {
    setItem(null);
    setPartnerItem(null);
    setMin(null);
    setMax(null);
  }, []);

  useEffect(() => {
    setSelectedPartners(
      partners
        .filter(
          (p: PartnerListResponse) =>
            p.legalEntityId === partnerLegalEntityId && p.integrationType === partnerIntegrationType
        )
        .map(partnerToLookup)
    );
  }, [partners, partnerLegalEntityId, partnerIntegrationType]);

  const minValue = useMemo(() => (min && min.length > 0 ? +min : 0), [min]);
  const maxValue = useMemo(() => (max && max.length > 0 ? +max : 0), [max]);
  const isValid = useMemo(
    () =>
      partnerItem &&
      item &&
      minValue >= 0 &&
      maxValue >= 0 &&
      minValue <= maxValue &&
      selectedPartners &&
      selectedPartners.length > 0,
    [partnerItem, item, minValue, maxValue, selectedPartners]
  );
  const label = useMemo(
    () =>
      partnerIntegrationType === 1
        ? 'Barsy артикул'
        : partnerIntegrationType === 2
        ? 'I-Cash артикул'
        : partnerIntegrationType === 3
        ? 'Coshera Retail артикул'
        : partnerIntegrationType === 4
        ? 'Mistral артикул'
        : 'Артикул',
    [partnerIntegrationType]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>
        <div className={classes.title}>Добавяне на артикул</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Grid container spacing={1}>
              <Grid item lg={9} sm={12}>
                <AMSExternalItemsAutocomplete
                  label={label}
                  partnerId={partnerId}
                  value={partnerItem}
                  onChange={async (value: Lookup | null) => {
                    setPartnerItem(value);
                    if (value) {
                      const resp = await amsV3Service.getItems(undefined, value?.selectedView);
                      if (resp?.data?.size > 0) {
                        setItem({
                          id: resp.data.data[0].id,
                          value: `${resp.data.data[0].artNo} ${resp.data.data[0].name}`
                        });
                      }
                    }
                  }}
                  required
                />
              </Grid>
              <Grid item lg={3} sm={12}>
                <AMSTextField
                  label="Минимум"
                  value={min ?? ''}
                  onChange={(value) => setMin(value ?? '')}
                  error={minValue > maxValue}
                />
              </Grid>
              <Grid item lg={9} sm={12}>
                <AMSAsyncAutocomplete
                  label="Артикул"
                  value={item}
                  onChange={(value: ItemResponse | null) => {
                    setItem({
                      id: value?.id,
                      value: `${value?.artNo} ${value?.name}`
                    });
                  }}
                  getItemLabel={(item: any) => `${item.artNo} ${item.name}`}
                  required
                />
              </Grid>
              <Grid item lg={3} sm={12}>
                <AMSTextField
                  label="Максимум"
                  value={max ?? ''}
                  onChange={(value) => setMax(value ?? '')}
                  error={minValue > maxValue}
                  required={!!min}
                />
              </Grid>
              <Grid item lg={12} sm={12}>
                <Typography
                  style={{
                    visibility: minValue <= maxValue ? 'hidden' : undefined,
                    color: '#FD9728'
                  }}
                  align="right"
                >
                  Минималната стойност трябва да е по-малка от максималната
                </Typography>
              </Grid>
              <Grid item lg={12} sm={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={10}
                  options={partners
                    .filter(
                      (partner: PartnerListResponse) =>
                        partner.legalEntityId === partnerLegalEntityId &&
                        partner.integrationType === partnerIntegrationType
                    )
                    .map(partnerToLookup)}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Контрагенти"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={selectedPartners}
                  onChange={setSelectedPartners}
                  groupBy={(g) => (g.group ? g.group : '')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.addNewCheckboxWrapper}>
          <FormControlLabel
            className={classes.label}
            control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
            label="Добави нов след запис"
            labelPlacement="end"
          />
        </div>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button onClick={handleAddItem} color="primary" variant="contained" disabled={!isValid}>
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPartnerItemConfigurationDialog;
