import { Grid, Tab, Tabs } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { SupplierResponse, UserResponse, WarehouseResponse, amsV3Service } from '../../services';

import DeliveryDocumentAndTransactionsPanel from './Panels/DeliveryDocumentAndTransactionsPanel/DeliveryDocumentAndTransactionsPanel';
import DeliveryInfoPanel from './Panels/DeliveryInfoPanel/DeliveryInfoPanel';
import TabPanel from '../../helpers/ui/TabPanel/TabPanel';
import { useDelivery } from '../../helpers/hooks';
import { useHistory } from 'react-router-dom';

interface EditDeliveryComponentProps {
  id: number;
  tabId?: number;
}

const EditDeliveryComponent = ({ id, tabId }: EditDeliveryComponentProps) => {
  const history = useHistory();
  const { delivery, deliveryItems, loading, setParameters } = useDelivery(id);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [warehouse, setWarehouse] = useState<WarehouseResponse | null>(null);
  const [supplier, setSupplier] = useState<SupplierResponse | null>(null);
  const [user, setUser] = useState<UserResponse | null>(null);

  const loadData = useCallback(async () => {
    if (delivery) {
      const [warehouseResp, supplierResp, userResp] = await Promise.all([
        amsV3Service.getWarehouse(delivery.warehouseId),
        amsV3Service.getSupplier(delivery.supplierId),
        amsV3Service.getUser(delivery.createdById)
      ]);
      setWarehouse(warehouseResp.data);
      setSupplier(supplierResp.data);
      setUser(userResp.data);
    }
  }, [delivery]);

  useEffect(() => {
    loadData();
  }, [delivery, loadData]);

  const a11yProps = (index: any) => {
    return {
      id: `item-tab-${index}`,
      'aria-controls': `item-tabpanel-${index}`
    };
  };

  useEffect(() => {
    setTotalPrice(
      deliveryItems.reduce((result: number, item: any) => result + +item.totalIncludingVat, 0)
    );
  }, [deliveryItems]);

  useEffect(() => {
    if (tabId) {
      if (id) {
        setSelectedTab(+tabId);
      } else {
        setSelectedTab(0);
        history.push(`delivery?${id ? `id=${id}` : ''}&tabId=0`);
      }
    } else {
      setSelectedTab(0);
    }
  }, [tabId, id, history]);

  const loadDelivery = useCallback(async () => {
    setParameters(id ? [id] : null);
  }, [id, setParameters]);

  useEffect(() => {
    loadDelivery();
  }, [id, loadDelivery]);

  const handleChange = (event: React.ChangeEvent<{}>, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
    history.replace(`delivery?${id ? `id=${id}` : ''}&tabId=${newSelectedTab}`);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12}>
          <Tabs value={selectedTab} onChange={handleChange}>
            <Tab label="Информация" {...a11yProps(0)} />
            <Tab disabled={!id} label="Документи и плащания" {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid item lg={12}>
          <TabPanel value={selectedTab} index={0}>
            <DeliveryInfoPanel
              delivery={delivery!}
              deliveryItems={deliveryItems}
              supplier={supplier!}
              warehouse={warehouse!}
              createdBy={user!}
              totalPrice={totalPrice}
              note={delivery?.note ?? ''}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            {warehouse && (
              <DeliveryDocumentAndTransactionsPanel
                delivery={delivery}
                deliveryItems={deliveryItems}
                warehouse={warehouse!}
                supplier={supplier!}
                loadDelivery={loadDelivery}
                loading={loading}
                totalPrice={totalPrice}
              />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default EditDeliveryComponent;
