import { ActOfAcceptanceResponse, amsV3Service } from '../../services';
import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

export interface IActOfAcceptance<T> extends IAsyncType<T> {
  actOfAcceptance: T;
  setActOfAcceptance: Dispatch<SetStateAction<T>>;
}

const useActOfAcceptance = (id?: number): IActOfAcceptance<ActOfAcceptanceResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<ActOfAcceptanceResponse | null>(
      amsV3Service.getActOfAcceptance,
      null,
      id ? [id] : null
    );
  return {
    actOfAcceptance: data,
    setActOfAcceptance: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useActOfAcceptance;
