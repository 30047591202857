import { AMSButton, AMSCountryAutocomplete, AMSViewOnlyTextField } from '../../../../helpers/ui';
import {
  AccessPermissions,
  SaveSupplierRequest,
  amsLegacyService,
  amsV3Service,
  snackbarService
} from '../../../../services';
import { Checkbox, Container, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  flattenTree,
  getExtendedCategoryId,
  getExtendedSupplierId,
  isValidEmail
} from '../../../../helpers/utils';
import { useLookup, usePermissions, useStyles } from '../../../../helpers/hooks';

import AMSAutocomplete from '../../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import { CompanyCategoryResponse } from '../../../../services/api';
import Country from '../../../../models/country';
import InputMask from 'react-input-mask';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import { companyCategoryToLookup } from '../../../../models/lookup';
import { findCountry } from '../../../../helpers/country-helper';
import { useHistory } from 'react-router-dom';

interface SupplierInfoComponentProps {
  supplier: any;
  setSupplier: (supplier: any) => void;
}

const SupplierInfoComponent = ({ supplier, setSupplier }: SupplierInfoComponentProps) => {
  const classes = useStyles();
  const history = useHistory();

  const [actionLoading, setActionLoading] = useState(false);

  const [companyCategories, setCompanyCategories] = useState<CompanyCategoryResponse[]>([]);
  const [contacts, setContacts] = useState<any[]>((supplier?.contacts ?? []).concat([{}, {}, {}]));
  const [country, setCountry] = useState<Country | null>(null);
  const [companyCategory, setCompanyCategory] = useLookup(null);
  const [errors, setErrors] = useState<any>({ email1: false, email2: false, email3: false });

  const [addNew, setAddNew] = useState<boolean>(false);

  const [canCreate, canRead, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_SUPPLIER,
    AccessPermissions.CAN_READ_SUPPLIER,
    AccessPermissions.CAN_UPDATE_SUPPLIER
  ]);

  const shouldBeDisabled = useMemo(
    () => (supplier?.id ? !canUpdate : !canCreate),
    [supplier, canCreate, canUpdate]
  );

  useEffect(() => {
    if (supplier?.country) {
      const country = findCountry(supplier.country);
      if (country) {
        setCountry(country);
      }
    } else {
      setCountry(null);
    }
  }, [supplier]);

  useEffect(() => {
    if (companyCategories?.length > 0) {
      const cc = companyCategories.find(
        (cc: CompanyCategoryResponse) => cc.id === supplier.companyCategoryId
      );
      if (cc) {
        setCompanyCategory(companyCategoryToLookup(cc));
      } else {
        setCompanyCategory(null);
      }
    }
  }, [companyCategories, supplier.companyCategoryId, setCompanyCategory]);

  useEffect(() => {
    const loadData = async () => {
      const companyCategoriesResp = await amsLegacyService.getCompanyCategory(2);
      setCompanyCategories(
        flattenTree(companyCategoriesResp ? [companyCategoriesResp.data] : []).sort(
          (cc1: CompanyCategoryResponse, cc2: CompanyCategoryResponse) => cc2.id - cc1.id
        )
      );
    };
    loadData();
  }, []);

  const companyCategoryNames = useMemo(
    () =>
      companyCategories.reduce((res: any, companyCategory: CompanyCategoryResponse) => {
        if (!res) {
          res = {};
        }
        res[companyCategory.id] = companyCategory.name;
        return res;
      }, {}),
    [companyCategories]
  );

  const isValidContact = useCallback((contact: any) => {
    return (
      contact &&
      (contact.email ? isValidEmail(contact.email) && contact.name : true) &&
      (contact.phone ? contact.phone.replace(/\s/g, '') && contact.name : true)
    );
  }, []);

  const areContactsValid = useCallback(
    () => isValidContact(contacts[0]) && isValidContact(contacts[1]) && isValidContact(contacts[2]),
    [contacts, isValidContact]
  );

  const isValidSupplier = useMemo(
    () =>
      supplier &&
      supplier.name &&
      supplier.country &&
      supplier.city &&
      supplier.addressLine &&
      supplier.companyCategoryId &&
      supplier.paymentInfo &&
      supplier.deliveryRequestThreshold &&
      !isNaN(+supplier.deliveryRequestThreshold) &&
      supplier.daysForDelivery &&
      !isNaN(+supplier.daysForDelivery) &&
      areContactsValid(),
    [areContactsValid, supplier]
  );

  const reload = () => {
    setSupplier({ isActive: true });
    setContacts([{}, {}, {}]);
  };

  const handleOnClick = async () => {
    setActionLoading(true);
    if (supplier.id) {
      const supplierRequest: SaveSupplierRequest = {
        ...supplier,
        contacts: contacts.filter((c: any) => c.name)
      };
      if (supplier.isActive) {
        const resp = await amsV3Service.updateSupplier(supplier.id, supplierRequest);
        if (resp) {
          snackbarService.setSnackbarOpen(true);
        }
      } else {
        const request: SaveSupplierRequest = {
          daysForDelivery: supplier.daysForDelivery,
          deliveryRequestThreshold: supplier.deliveryRequestThreshold,
          name: supplier.name,
          country: supplier.country,
          city: supplier.city,
          addressLine: supplier.addressLine,
          paymentInfo: supplier.paymentInfo,
          companyCategoryId: supplier.companyCategoryId
        };
        const resp = await amsV3Service.updateSupplier(supplier.id, request);
        if (resp) {
          snackbarService.setSnackbarOpen(true);
        }
      }
    } else {
      const supplierRequest: SaveSupplierRequest = {
        ...supplier,
        companyCategoryId: supplier.companyCategoryId,
        contacts: contacts.filter((c: any) => c.name)
      };
      const resp = await amsV3Service.createSupplier(supplierRequest);
      if (resp || !canRead) {
        if (addNew) {
          reload();
          history.push('/supplier');
        } else {
          history.push(`/supplier?id=${resp.data.id}`);
        }
        snackbarService.setSnackbarOpen(true);
      }
    }
    setActionLoading(false);
  };

  const contactEntity = useCallback(
    (index: number) => (
      <OutlinedDiv
        label={`Контакт ${index + 1}`}
        disabled={shouldBeDisabled || !supplier?.isActive}
      >
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <TextField
              label="Име"
              variant="outlined"
              fullWidth
              margin="dense"
              required={!!contacts[index].email || !!contacts[index].phone}
              value={contacts[index].name ? contacts[index].name : ''}
              onChange={(event) => {
                const currentContacts = [...contacts];
                currentContacts[index].name = event?.target.value;
                setContacts([...currentContacts]);
                setSupplier({ ...supplier, contacts: currentContacts });
              }}
              disabled={shouldBeDisabled || !supplier?.isActive}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputMask
              mask="+35\9 999 999 999"
              value={contacts[index].phone ? contacts[index].phone : ''}
              disabled={shouldBeDisabled || !supplier?.isActive}
              onChange={(event) => {
                const currentContacts = [...contacts];
                if (event?.target.value && event?.target.value.length > 0) {
                  currentContacts[index].phone = event?.target.value;
                } else {
                  currentContacts[index].phone = null;
                }
                setContacts([...currentContacts]);
                setSupplier({ ...supplier, contacts: currentContacts });
              }}
            >
              {() => (
                <TextField
                  label="Телефон"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="text"
                  disabled={shouldBeDisabled || !supplier?.isActive}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              error={errors[`contactEmail${index}`]}
              label="Е-Поща"
              variant="outlined"
              fullWidth
              margin="dense"
              disabled={shouldBeDisabled || !supplier?.isActive}
              value={contacts[index].email ? contacts[index].email : ''}
              onChange={(event) => {
                const currentContacts = [...contacts];
                currentContacts[index].email = event?.target.value;
                setContacts([...currentContacts]);
                setSupplier({ ...supplier, contacts: currentContacts });
              }}
              onBlur={(event: any) => {
                const errs = { ...errors };
                const val = event?.target.value;
                if (val && !!val.length) {
                  errs[`contactEmail${index}`] = !new RegExp(
                    /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
                  ).test(val);
                } else {
                  errs[`contactEmail${index}`] = false;
                }
                setErrors(errs);
              }}
              helperText={errors[`contactEmail${index}`] ? 'Невалиден формат на Е-Поща' : ''}
            />
          </Grid>
        </Grid>
      </OutlinedDiv>
    ),
    [contacts, errors, setSupplier, setContacts, supplier, shouldBeDisabled]
  );

  const onChange = (field: string, value: any) => {
    setSupplier({ ...supplier, [field]: value });
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={2} className={classes.root}>
        <Grid container spacing={1}>
          <Grid item lg={6}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} xs={12}>
                <OutlinedDiv label={`Данни за доставчика`} disabled={shouldBeDisabled}>
                  <Grid container spacing={1}>
                    <Grid item container lg={3} md={12} sm={12} xs={12} alignContent="flex-start">
                      <FormControlLabel
                        className={classes.label}
                        control={
                          <Checkbox
                            checked={supplier?.isActive}
                            onChange={() => onChange('isActive', !supplier?.isActive)}
                          />
                        }
                        disabled={!supplier || !supplier.id}
                        label="Активен"
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                      <AMSViewOnlyTextField
                        label="Персонален код"
                        value={supplier?.id ? getExtendedSupplierId(supplier.id) : ''}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        label="Име"
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        value={supplier?.name ?? ''}
                        onChange={(event) =>
                          setSupplier({ ...supplier, name: event?.target.value })
                        }
                        disabled={shouldBeDisabled || !supplier?.isActive}
                      />
                    </Grid>
                  </Grid>
                </OutlinedDiv>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <OutlinedDiv
                  label={`Адрес на доставчика`}
                  disabled={shouldBeDisabled || !supplier?.isActive}
                >
                  <Grid container spacing={1}>
                    <Grid item lg={6} md={3} xs={12}>
                      <AMSCountryAutocomplete
                        onChange={(_, value: any) => {
                          if (value) {
                            setSupplier({ ...supplier, country: value.alpha3 });
                            setCountry(value);
                          }
                        }}
                        country={country}
                        required
                        disabled={shouldBeDisabled || !supplier?.isActive}
                      />
                    </Grid>
                    <Grid item lg={6} md={4} xs={12}>
                      <TextField
                        label="Населено място"
                        variant="outlined"
                        fullWidth
                        required
                        margin="dense"
                        value={supplier?.city ?? ''}
                        onChange={(event) =>
                          setSupplier({ ...supplier, city: event?.target.value })
                        }
                        disabled={shouldBeDisabled || !supplier?.isActive}
                      />
                    </Grid>
                    <Grid item lg={12} md={5} xs={12}>
                      <TextField
                        label="Адрес"
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                        margin="dense"
                        value={supplier?.addressLine ?? ''}
                        onChange={(event) =>
                          setSupplier({ ...supplier, addressLine: event?.target.value })
                        }
                        disabled={shouldBeDisabled || !supplier?.isActive}
                      />
                    </Grid>
                  </Grid>
                </OutlinedDiv>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <OutlinedDiv
                  label="Категория"
                  required
                  disabled={shouldBeDisabled || !supplier?.isActive}
                >
                  <AMSAutocomplete
                    options={companyCategories.map((c) => ({
                      id: c.id,
                      value: `${getExtendedCategoryId(c.id)} - ${c.name}`
                    }))}
                    value={
                      companyCategory
                        ? {
                            id: companyCategory.id,
                            value: `${getExtendedCategoryId(companyCategory.id)} - ${
                              companyCategoryNames[companyCategory.id]
                            }`
                          }
                        : ''
                    }
                    onChange={(value: any) =>
                      setSupplier({
                        ...supplier,
                        companyCategoryId: value?.id
                      })
                    }
                    disabled={shouldBeDisabled || !supplier?.isActive}
                    minChar={0}
                    required
                  />
                </OutlinedDiv>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <OutlinedDiv
                  label={`Забележка за доставчик`}
                  disabled={shouldBeDisabled || !supplier?.isActive}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={8}
                    margin="dense"
                    disabled={shouldBeDisabled || !supplier?.isActive}
                    value={supplier?.note ?? ''}
                    onChange={(event) => setSupplier({ ...supplier, note: event?.target.value })}
                  />
                </OutlinedDiv>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} xs={12}>
                <OutlinedDiv
                  label="Начин на плащане"
                  required
                  disabled={shouldBeDisabled || !supplier?.isActive}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    multiline
                    margin="dense"
                    disabled={shouldBeDisabled || !supplier?.isActive}
                    value={supplier?.paymentInfo ?? ''}
                    onChange={(event) =>
                      setSupplier({ ...supplier, paymentInfo: event?.target.value })
                    }
                  />
                </OutlinedDiv>
              </Grid>
              <Grid item lg={12} md={12}>
                {contactEntity(0)}
              </Grid>
              <Grid item lg={12} md={12}>
                {contactEntity(1)}
              </Grid>
              <Grid item lg={12} md={12}>
                {contactEntity(2)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12}>
            <OutlinedDiv
              label={`Абонамент за автоматична поръчка`}
              disabled={shouldBeDisabled || !supplier?.isActive}
            >
              <Grid container spacing={1}>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="Имейл"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    disabled={shouldBeDisabled || !supplier?.isActive}
                    value={supplier?.subscriptionEmail ?? ''}
                    onChange={(event) =>
                      setSupplier({ ...supplier, subscriptionEmail: event?.target.value })
                    }
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <TextField
                    label="Дни за доставка"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    disabled={shouldBeDisabled || !supplier?.isActive}
                    value={supplier?.daysForDelivery ?? ''}
                    onChange={(event) =>
                      setSupplier({ ...supplier, daysForDelivery: event?.target.value })
                    }
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <TextField
                    label="Минимална сума"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    disabled={shouldBeDisabled || !supplier?.isActive}
                    value={supplier?.deliveryRequestThreshold ?? ''}
                    onChange={(event) =>
                      setSupplier({ ...supplier, deliveryRequestThreshold: event?.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </OutlinedDiv>
          </Grid>
        </Grid>
        {(canUpdate || canCreate) && (
          <Grid container>
            <Grid item lg={6} container justify="flex-end">
              {canCreate && !(supplier?.id > 0) && (
                <div className={classes.addNewCheckboxWrapper}>
                  <FormControlLabel
                    className={classes.label}
                    control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
                    label="Добави нов след запис"
                    labelPlacement="end"
                  />
                </div>
              )}
            </Grid>
            {((canCreate && !(supplier?.id > 0)) || canUpdate) && (
              <Grid item lg={6} container justify="flex-start">
                <AMSButton
                  text={addNew ? 'Запиши и добави нов' : 'Запиши'}
                  color="primary"
                  variant="contained"
                  onClick={handleOnClick}
                  disabled={actionLoading || !isValidSupplier}
                  loading={actionLoading}
                  className={classes.saveButton}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Paper>
    </Container>
  );
};

export default SupplierInfoComponent;
