import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      marginTop: '40vh'
    },
    paper: {
      padding: 20
    },
    saveButton: {
      marginTop: 10,
      marginLeft: 10
    },
    flexStart: {
      alignContent: 'flex-start'
    },
    shouldSendEmailCheckboxWrapper: {
      paddingTop: 8
    },
    flex: {
      display: 'flex'
    },
    icon: {
      color: '#252525 !important',
      transform: 'rotate(90deg)'
    },
    title: {
      fontWeight: 'bold'
    },
    imageWrapper: {
      width: '100%',
      height: 256,
      display: 'flex',
      justifyContent: 'center'
    },
    image: {
      width: 256,
      height: 256
    },
    label: {
      padding: 6
    },
    addNewCheckboxWrapper: {
      paddingTop: 8
    },
    checkbox: {
      marginRight: 8
    },
    container: {
      margin: 0
    },
    root: {
      padding: 20,
      marginTop: 25
    },
    link: {
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
      color: 'inherit',
      backgroundColor: 'transparent',
      textDecoration: 'none'
    },
    searchButton: {
      marginTop: 10,
      marginLeft: 10
    },
    totalInfo: {
      paddingBottom: 10,
      paddingLeft: 8,
      width: '100%',
      textAlign: 'right'
    },
    expense: {
      color: '#F03901'
    },
    income: {
      color: '#93AB3B'
    },
    statusIndicator: {
      borderRadius: 24,
      width: 24,
      height: 24,
      minHeight: 24,
      minWidth: 24,
      maxHeight: 24,
      maxWidth: 24,
      marginRight: 8,
      border: '1px solid #737373'
    },
    statusText: {
      paddingTop: 2
    },
    button: {
      marginTop: 8
    }
  })
);

export default useStyles;
