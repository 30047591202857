import 'firebase/auth';
import './App.css';

import * as Sentry from '@sentry/react';

import { AccessPermissions, accessManagementService, snackbarService } from './services';
import { Container, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  hiddenMenuItems,
  visibleMenuItems
} from './containers/MainContainer/MenuContainer/listItems';
import { useObservable, useStyles } from './helpers/hooks';

import AMSSnackBar from './helpers/ui/AMSSnackBar/AMSSnackBar';
import Axios from 'axios';
import CustomTheme from './Theme';
import DateFnsUtils from '@date-io/date-fns';
import Home from './components/Home/Home';
import LoginContainer from './containers/LoginContainer/LoginContainer';
import MainContainer from './containers/MainContainer/MainContainer';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import { QRCodeCanvas } from 'qrcode.react';
import { ThemeProvider } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import withErrorHandler from './helpers/hoc/withErrorHandler/withErrorHandler';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

const FallbackComponent = ({
  error,
  eventId
}: {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}) => {
  return (
    <Container
      component="main"
      maxWidth="xl"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <h1>Възникна грешка при изпълнение</h1>
      <QRCodeCanvas
        value={JSON.stringify({ message: `${error.name}: ${error.message}` })}
        style={{ width: '100%', height: 'auto', maxWidth: 480, padding: 16 }}
      />
      {eventId && <h3>Грешката е предадена на поддръжката: {eventId}</h3>}
    </Container>
  );
};

const SentryRoute = Sentry.withErrorBoundary(Sentry.withSentryRouting(Route), {
  fallback: FallbackComponent
});

const App = () => {
  const classes = useStyles();
  const [user, loading] = useAuthState(auth);
  const [loadingPermissions, setLoadingPermissions] = useState(true);

  const permissions: AccessPermissions[] = useObservable(
    accessManagementService.permissions
  ).permissions;
  const snackbarOpen: boolean = useObservable(snackbarService.snackbar).snackbarOpen;

  useEffect(() => {
    const loadUserData = async () => {
      setLoadingPermissions(true);
      if (user && !accessManagementService.currentUser) {
        await accessManagementService.getMe();
        setLoadingPermissions(false);
      } else {
        setLoadingPermissions(true);
      }
      if (user === null) {
        setLoadingPermissions(false);
      }
    };
    loadUserData();
  }, [user]);

  const routes: any[] = [];
  for (let menuItem of [...visibleMenuItems, ...hiddenMenuItems]) {
    if (menuItem.permissions.some((permission) => permissions.includes(permission))) {
      routes.push(
        <SentryRoute key={menuItem.route} path={menuItem.route} component={menuItem.component} />
      );
      if (menuItem.children) {
        for (let childMenuItem of menuItem.children) {
          if (childMenuItem.permissions.some((permission) => permissions.includes(permission))) {
            routes.push(
              <SentryRoute
                key={childMenuItem.route}
                path={childMenuItem.route}
                component={childMenuItem.component}
              />
            );
          }
        }
      }
    }
  }

  routes.push(<SentryRoute key="home" exact path="/" component={Home} />);
  routes.push(<SentryRoute key="not_found" path="*" component={NotFoundPage} />);

  return (
    <ThemeProvider theme={CustomTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="App">
          {loading || loadingPermissions ? (
            <Container maxWidth="sm">
              <LinearProgress className={classes.loading} />
            </Container>
          ) : user ? (
            <MainContainer>
              <Switch>{routes}</Switch>
            </MainContainer>
          ) : (
            <LoginContainer />
          )}
          <AMSSnackBar open={snackbarOpen} onClose={() => snackbarService.setSnackbarOpen(false)} />
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default withErrorHandler(App, Axios);
