import React, { useMemo } from 'react';
import { useItems, useWarehouses } from '../../../helpers/hooks';

import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import { AMSTable } from '../../../helpers/ui';
import { AvailabilityListResponse } from '../../../services';
import { Grid } from '@material-ui/core';

interface AvailabilitiesTableComponentProps {
  availabilities: AvailabilityListResponse[];
  loading: boolean;
}

const AvailabilitiesTableComponent = ({
  availabilities,
  loading
}: AvailabilitiesTableComponentProps) => {
  const { items } = useItems();
  const { warehouses } = useWarehouses();

  const warehouseNamesById = useMemo(
    () =>
      warehouses.reduce((res: any, warehouse) => {
        if (!res) {
          res = {};
        }
        res[warehouse.id] = warehouse.name;
        return res;
      }, {}),
    [warehouses]
  );

  const itemNamesById = useMemo(
    () =>
      items.reduce((res: any, item) => {
        if (!res) {
          res = {};
        }
        res[item.id] = item.name;
        return res;
      }, {}),
    [items]
  );

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AMSTable
          title={'Наличности'}
          data={availabilities}
          pageSize={12}
          isLoading={loading}
          columns={[
            {
              title: 'Склад',
              field: 'warehouseId',
              cellStyle: { width: '20%' },
              render: (rowData: AvailabilityListResponse) =>
                warehouseNamesById ? warehouseNamesById[rowData.warehouseId] : 'Зареждане ...'
            },
            {
              title: 'Артикул',
              field: 'itemId',
              cellStyle: { width: '20%' },
              render: (rowData: any) => (
                <AMSLink href={`/item?id=${rowData.itemId}`}>
                  {itemNamesById[rowData.itemId]}
                </AMSLink>
              )
            },
            {
              title: 'Номер на партида',
              field: 'batchNumber',
              cellStyle: { width: '10%' }
            },
            {
              title: 'Произведено в',
              field: 'countryOfOrigin',
              cellStyle: { width: '10%' }
            },
            {
              title: 'Срок на годност',
              field: 'expirationDate',
              type: 'datetime',
              cellStyle: { width: '10%' }
            },
            {
              title: 'Баланс',
              field: 'balanceQuantity',
              type: 'numeric',
              cellStyle: { width: '10%' }
            },
            {
              title: 'Към дата',
              field: 'lastSyncedAt',
              type: 'datetime',
              cellStyle: { width: '20%' }
            }
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default AvailabilitiesTableComponent;
