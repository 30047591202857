import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponsePartnerListResponse,
  PartnerListResponse,
  amsV3Service
} from '../../services';

export interface IPartners<T> extends IAsyncType<T> {
  partners: T;
}

const usePartners = (
  search?: string,
  showInactive: boolean = false
): IPartners<PartnerListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponsePartnerListResponse>(
      amsV3Service.getPartners,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [search, showInactive]
    );
  return {
    partners: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default usePartners;
