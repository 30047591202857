import * as H from 'history';

import CreateDeliveryRequestComponent from './CreateDeliveryRequestComponent';
import React from 'react';
import StockReceivingProcessComponent from './StockReceivingProcessComponent/StockReceivingProcessComponent';
import { parseQuery } from '../../helpers/url';

interface DeliveryRequestComponentProps {
  location: H.Location;
}

const DeliveryRequestComponent = ({ location }: DeliveryRequestComponentProps) => {
  const { id, originalId } = parseQuery(location.search);
  if (!id) {
    return <CreateDeliveryRequestComponent originalId={originalId} />;
  }
  return <StockReceivingProcessComponent id={id} />;
};

export default DeliveryRequestComponent;
