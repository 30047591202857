import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  createStyles,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { StockTakingReportItemResponse, StockTakingReportResponse } from '../../../services/api';

import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import AMSViewOnlyTextField from '../../../helpers/ui/AMSViewOnlyTextField/AMSViewOnlyTextField';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import OutlinedDiv from '../../../helpers/ui/OutlinedDiv';
import { amsLegacyService } from '../../../services';
import { getExtendedStockTakingReportId } from '../../../helpers/utils';
import { parseQuery } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 20,
      marginTop: 25
    },
    container: {
      margin: 0
    }
  })
);

const StockTakingReportComponent = ({ location }: { location: any }) => {
  const { id } = parseQuery(location.search);

  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [stockTakingReport, setStockTakingReport] = useState<
    StockTakingReportResponse | undefined
  >();

  useEffect(() => {
    const loadStockTakingReport = async () => {
      setLoading(true);
      if (id) {
        const stockTakingReportResp = await amsLegacyService.getStockTakingReport(id);
        if (!stockTakingReportResp?.data) {
          history.push('not-found');
        }
        setStockTakingReport(stockTakingReportResp?.data);
      }
      setLoading(false);
    };
    loadStockTakingReport();
  }, [id, history]);

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="lg" className={classes.container}>
      <Paper elevation={2} className={classes.root}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <OutlinedDiv label={`Данни за доставчика`} disabled={true}>
              <Grid container spacing={1}>
                <Grid item lg={3}>
                  <AMSViewOnlyTextField
                    label="Персонален код"
                    value={
                      stockTakingReport?.id
                        ? getExtendedStockTakingReportId(stockTakingReport.id)
                        : ''
                    }
                  />
                </Grid>
                <Grid item lg={3}>
                  <AMSViewOnlyTextField
                    label="Дата на изпълнение"
                    value={stockTakingReport!.executionDate}
                  />
                </Grid>
                <Grid item lg={4}>
                  <AMSViewOnlyTextField
                    label="Отговорник"
                    value={stockTakingReport!.userResponsibleName}
                  />
                </Grid>
                <Grid item lg={2}>
                  <AMSViewOnlyTextField
                    label="Позиции"
                    value={`${stockTakingReport!.items?.length ?? 0}`}
                  />
                </Grid>
              </Grid>
            </OutlinedDiv>
          </Grid>
          <Grid item lg={12}>
            <AMSTable
              title="Артикули"
              columns={[
                {
                  title: 'Име',
                  field: 'itemName',
                  render: (rowData: StockTakingReportItemResponse) => (
                    <AMSLink href={`/item?id=${rowData.itemId}&tabId=2`}>
                      {rowData.itemName}
                    </AMSLink>
                  ),
                  cellStyle: { width: '40%' }
                },
                {
                  title: 'Очаквано количество',
                  field: 'expectedQuantity',
                  type: 'numeric',
                  cellStyle: { width: '15%' }
                },
                {
                  title: 'Преброено количество',
                  field: 'countedQuantity',
                  type: 'numeric',
                  cellStyle: { width: '15%' }
                },
                {
                  title: 'Потвърдено количество',
                  field: 'confirmedQuantity',
                  type: 'numeric',
                  cellStyle: { width: '15%' }
                },
                {
                  title: 'Баланс',
                  render: (row: StockTakingReportItemResponse) =>
                    `${row.confirmedQuantity - row.expectedQuantity > 0 ? '+' : ''}${
                      row.confirmedQuantity - row.expectedQuantity
                    }`,
                  cellStyle: (
                    data: StockTakingReportItemResponse[],
                    row: StockTakingReportItemResponse
                  ) => {
                    const style: CSSProperties = { width: '15%' };
                    if (row.expectedQuantity > row.confirmedQuantity) {
                      style.backgroundColor = 'rgba(255, 0, 0, 0.3)';
                    }
                    if (row.expectedQuantity < row.confirmedQuantity) {
                      style.backgroundColor = 'rgba(255, 128, 0, 0.3)';
                    }
                    return style;
                  },
                  type: 'numeric'
                }
              ]}
              data={stockTakingReport!.items}
              components={{
                Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default StockTakingReportComponent;
