import * as H from 'history';

import {
  AccessPermissions,
  CompanyListResponse,
  CreateTransactionRequest,
  DocumentTypes,
  OrderStatus,
  OrderStatusKey,
  OrderStatusKeys,
  OrderStatusLabels,
  OrderTypeIds,
  TransactionType,
  WarehouseListResponse,
  amsLegacyService,
  amsV3Service,
  cookiesService,
  getOrderStatusByKey,
  getOrderStatusLabelByKey,
  orderStatuses
} from '../../services';
import AddTransactionDialog, {
  DocumentOption
} from '../OrderComponent/Panels/OrderInvoiceAndTransactionsPanel/Dialog/AddTransactionDialog';
import { Container, FormControlLabel, Grid, TextField, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, {
  companyToLookup,
  orderStatusToLookup,
  warehouseToLookup
} from '../../models/lookup';
import { OrderDocumentV2Response, OrderV2Response } from '../../services/api';
import React, { Fragment, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ignoreOffset,
  isValidDate,
  toDateString,
  toEndOfDay,
  toStartOfDay
} from '../../helpers/date-helper';
import {
  useCompanies,
  useFlag,
  useOrder,
  useOrderDocuments,
  useOrders,
  usePermissions,
  useStyles,
  useWarehouses
} from '../../helpers/hooks';

import AMSAutocomplete from '../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import { AMSButton } from '../../helpers/ui';
import AMSConfirmDialog from '../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CancelOrderDialog from './Dialog/CancelOrderDialog';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import PreviewOrderDialog from './Dialog/PreviewOrderDialog';
import { getExtendedOrderId } from '../../helpers/utils';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface OrdersComponentProps {
  id: number;
  location: H.Location;
}

/**
 *
 * @deprecated
 */
const OrdersComponent = ({ location }: OrdersComponentProps) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    toDate,
    fromDate,
    companyIds,
    warehouseIds,
    statusKeys,
    noInvoice,
    withExecutionPercentage
  } = useMemo(() => parseFilter(location.search), [location.search]);

  const { companies } = useCompanies();
  const { warehouses } = useWarehouses();

  const orderData = useOrder();
  const orderDocumentsData = useOrderDocuments();
  const [openDialog, setOpenDialog] = useState(false);
  const [openOrderDialog, setOpenOrderDialog] = useState(false);
  const [openTransactionDialog, setOpenTransactionDialog] = useState(false);
  const [оpenConfirmDialog, setOpenConfirmDialog] = useState(false);

  const filter = cookiesService.getOrdersList();
  const [filterStatus, setFilterStatus] = useState<Lookup[]>(
    statusKeys
      ? statusKeys.map((key: string) =>
          orderStatusToLookup(getOrderStatusByKey(key as OrderStatusKey) as OrderStatus)
        )
      : filter.filterStatus
        ? filter.filterStatus
        : []
  );

  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    !withExecutionPercentage && fromDate
      ? fromDate
      : !filter.filterWithExecutionPercentage && filter.filterFromDate
        ? ignoreOffset(new Date(filter.filterFromDate))
        : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(
    !withExecutionPercentage && fromDate
      ? toDate
      : filter.filterToDate
        ? ignoreOffset(new Date(filter.filterToDate))
        : new Date()
  );
  const [filterCompanies, setFilterCompanies] = useState<Lookup[]>(
    filter.filterCompanies ? filter.filterCompanies : []
  );
  const [filterWarehouses, setFilterWarehouses] = useState<Lookup[]>(
    filter.filterWarehouses ? filter.filterWarehouses : []
  );
  const [filterNoInvoice, setFilterNoInvoice] = useFlag(
    noInvoice !== undefined && noInvoice !== null ? noInvoice : (filter.filterNoInvoice ?? false)
  );
  const [filterWithExecutionPercentage, setFilterWithExecutionPercentage] = useFlag(
    withExecutionPercentage !== undefined && withExecutionPercentage !== null
      ? withExecutionPercentage
      : (filter.filterWithExecutionPercentage ?? false)
  );

  const { orders, setOrders, loading, setLoading, setParameters } = useOrders(
    statusKeys ?? filterStatus.map((s: Lookup) => s.id),
    companyIds ?? filterCompanies.map((c: Lookup) => c.id),
    warehouseIds ?? filterWarehouses.map((w: Lookup) => w.id),
    !filterWithExecutionPercentage && filterFromDate ? toStartOfDay(filterFromDate) : undefined,
    !filterWithExecutionPercentage && filterToDate ? toEndOfDay(filterToDate) : undefined,
    noInvoice ?? filterNoInvoice,
    withExecutionPercentage ?? filterWithExecutionPercentage,
    withExecutionPercentage ?? filterWithExecutionPercentage
  );

  const [canDoOrderCheck, canUpdate, canDelete, canCreateTransaction] = usePermissions([
    AccessPermissions.CAN_DO_ORDER_CHECK,
    AccessPermissions.CAN_UPDATE_PARTNER,
    AccessPermissions.CAN_DELETE_ORDER,
    AccessPermissions.CAN_CREATE_TRANSACTION
  ]);

  useEffect(() => {
    const { filterCompanies } = cookiesService.getOrdersList();

    setFilterCompanies(
      companyIds && companies
        ? companies
            .filter((c: CompanyListResponse) => companyIds.includes(c.id))
            .map(companyToLookup)
        : (filterCompanies ?? [])
    );
  }, [companies, companyIds]);

  useEffect(() => {
    const { filterWarehouses } = cookiesService.getOrdersList();

    setFilterWarehouses(
      warehouseIds && warehouses.length > 0
        ? warehouses
            .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
            .map(warehouseToLookup)
        : (filterWarehouses ?? [])
    );
  }, [warehouses, warehouseIds]);

  const onSearch = useCallback(() => {
    const fromDate =
      filterFromDate === null || isValidDate(filterFromDate)
        ? toStartOfDay(filterFromDate!)
        : undefined;
    const toDate =
      filterToDate === null || isValidDate(filterToDate) ? toEndOfDay(filterToDate!) : undefined;
    cookiesService.setOrdersList({
      filterFromDate: fromDate,
      filterToDate: toDate,
      filterStatus,
      filterCompanies,
      filterWarehouses,
      filterWithExecutionPercentage,
      filterNoInvoice
    });
    let params: any = {};
    if (filterStatus?.length > 0) {
      params.statusKeys = filterStatus.map((s) => s.id).join(',');
    }
    if (filterCompanies?.length > 0) {
      params.companyIds = filterCompanies.map((c) => c.id).join(',');
    }
    if (filterWarehouses?.length > 0) {
      params.warehouseIds = filterWarehouses.map((w) => w.id).join(',');
    }
    if (filterWithExecutionPercentage) {
      params.withExecutionPercentage = filterWithExecutionPercentage;
    }
    if (filterNoInvoice) {
      params.noInvoice = filterNoInvoice;
    }
    if (!filterWithExecutionPercentage && filterFromDate) {
      params.fromDate = toDateString(filterFromDate);
    } else {
      params.fromDate = undefined;
    }
    if (!filterWithExecutionPercentage && filterToDate) {
      params.toDate = toDateString(filterToDate);
    } else {
      params.fromDate = undefined;
    }
    history.push({
      pathname: '/orders',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      filterStatus.map((status: Lookup) => status.id),
      filterCompanies.map((c: Lookup) => c.id),
      filterWarehouses.map((w: Lookup) => w.id),
      filterWithExecutionPercentage ? undefined : fromDate,
      filterWithExecutionPercentage ? undefined : toDate,
      filterNoInvoice,
      filterWithExecutionPercentage,
      filterWithExecutionPercentage
    ]);
  }, [
    filterCompanies,
    filterFromDate,
    filterNoInvoice,
    filterStatus,
    filterToDate,
    filterWarehouses,
    filterWithExecutionPercentage,
    history,
    setParameters
  ]);

  const handleOnDelete = useCallback(
    async (deleteOrder: any) => {
      setLoading(true);
      const respOrder = await amsLegacyService.getOrder(deleteOrder.id);
      setOpenDialog(true);
      orderData.setOrder(respOrder.data);
      setLoading(false);
    },
    [setLoading, orderData]
  );

  useEffect(() => {
    cookiesService.setOrderIds(
      orders.map((order: OrderV2Response) => order.id).sort((a, b) => a - b)
    );
  }, [orders]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="text-align-left">
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            {!filterWithExecutionPercentage && (
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <AMSAutocomplete
                  multiple
                  minChar={0}
                  limitTags={3}
                  options={orderStatuses.sort((a, b) => a.id - b.id).map(orderStatusToLookup)}
                  sortOptions={false}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={selected}
                        color="primary"
                      />
                      {option.value}
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Статус"
                      placeholder=""
                      margin="dense"
                      fullWidth
                    />
                  )}
                  value={filterStatus}
                  onChange={(values) => setFilterStatus(values)}
                />
              </Grid>
            )}
            {!filterWithExecutionPercentage && (
              <Grid item lg={2} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="От дата"
                  helperText={''}
                  value={filterFromDate ? filterFromDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterFromDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterFromDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  maxDate={filterToDate}
                />
              </Grid>
            )}
            {!filterWithExecutionPercentage && (
              <Grid item lg={2} md={6} sm={12} xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yy"
                  margin="dense"
                  label="До дата"
                  helperText={''}
                  value={filterToDate ? filterToDate : null}
                  onChange={(value: Date | null) => {
                    if (value) {
                      if (isValidDate(value)) {
                        setFilterToDate(ignoreOffset(value));
                      }
                    } else {
                      setFilterToDate(null);
                    }
                  }}
                  inputVariant="outlined"
                  fullWidth
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  minDate={filterFromDate}
                />
              </Grid>
            )}
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={companies.map(companyToLookup)}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Компании"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterCompanies}
                onChange={setFilterCompanies}
                groupBy={(g) => (g.group ? g.group : '')}
              />
            </Grid>
            <Grid item lg={filterWithExecutionPercentage ? 6 : 4} md={6} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={warehouses.map(warehouseToLookup)}
                sortOptions={false}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Складове"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterWarehouses}
                onChange={setFilterWarehouses}
                groupBy={(g) => (g.group ? g.group : '')}
              />
            </Grid>
            {!filterWithExecutionPercentage && (
              <Grid item lg={canDoOrderCheck ? 2 : 3} md={2} sm={12} xs={12}>
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={filterNoInvoice}
                      onChange={() => setFilterNoInvoice(!filterNoInvoice)}
                    />
                  }
                  label="Само без фактура"
                  labelPlacement="end"
                />
              </Grid>
            )}
            {canDoOrderCheck && (
              <Grid item lg={1} md={filterWithExecutionPercentage ? 6 : 4} sm={12} xs={12}>
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={filterWithExecutionPercentage}
                      onChange={() => {
                        if (!filterWithExecutionPercentage) {
                          setFilterStatus([]);
                        }
                        setFilterWithExecutionPercentage(!filterWithExecutionPercentage);
                      }}
                    />
                  }
                  label="Забавени"
                  labelPlacement="end"
                />
              </Grid>
            )}
            <Grid item lg={1} md={6} sm={12} xs={12}>
              <AMSButton
                color="primary"
                variant="contained"
                text="Търсене"
                loading={loading}
                disabled={false}
                onClick={onSearch}
                style={{
                  marginTop: 8,
                  float: 'right'
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AMSTable
                title="Списък с поръчки"
                columns={[
                  {
                    title: '№',
                    field: 'id',
                    render: (order: OrderV2Response) => (
                      <AMSLink href={`/order?id=${order.id}`}>
                        {getExtendedOrderId(order.id)}
                      </AMSLink>
                    ),
                    cellStyle: { width: '5%' }
                  },
                  {
                    title: 'Статус',
                    field: 'statusKey',
                    render: (rowData: any) => (
                      <Tooltip title={getOrderStatusLabelByKey(rowData.statusKey)}>
                        <div className="d-flex">
                          <div
                            className={classes.statusIndicator}
                            style={{
                              backgroundColor: getOrderStatusByKey(rowData.statusKey).color
                            }}
                          />
                          <div className={classes.statusText}>
                            {getOrderStatusLabelByKey(rowData.statusKey)}
                          </div>
                        </div>
                      </Tooltip>
                    ),
                    cellStyle: { width: '15%' }
                  },
                  {
                    title: 'Дата за доставка',
                    field: 'deliveryDate',
                    type: 'datetime',
                    cellStyle: { width: '10%' }
                  },
                  {
                    title: 'Получател',
                    field: 'receiverName',
                    render: (order: OrderV2Response) => (
                      <AMSLink
                        href={
                          order.orderType === OrderTypeIds.INTERNAL
                            ? `/warehouse?id=${order.receiverId}`
                            : `/partner?id=${order.receiverId}`
                        }
                      >
                        {order.receiverName}
                      </AMSLink>
                    ),
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Компания',
                    field: 'companyNote',
                    render: (order: OrderV2Response) => (
                      <AMSLink href={`/company?id=${order.companyId}`}>{order.companyNote}</AMSLink>
                    ),
                    cellStyle: { width: '15%' }
                  },
                  {
                    title: 'Склад',
                    field: 'warehouseName',
                    render: (order: OrderV2Response) => (
                      <AMSLink href={`/warehouse?id=${order.warehouseId}`}>
                        {order.warehouseName}
                      </AMSLink>
                    ),
                    cellStyle: { width: '20%' }
                  },
                  filterWithExecutionPercentage
                    ? {
                        title: 'Възможно изпълнение (%)',
                        field: 'executionPercentage',
                        cellStyle: { width: '10%', textAlign: 'right' },
                        render: (rowData: any) => rowData.executionPercentage ?? '0.00'
                      }
                    : {
                        title: 'Фактура',
                        field: 'invoiceNumber',
                        cellStyle: { width: '10%' }
                      },
                  {
                    title: 'Сума',
                    field: 'totalPrice',
                    cellStyle: { width: '5%' },
                    type: 'currency',
                    currencySetting: {
                      locale: 'bg',
                      currencyCode: 'bgn',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  }
                ]}
                deleteText={'Наистина ли искате да анулирате поръчката?'}
                deleteTooltip={'Анулиране'}
                deleteIcon={forwardRef((props: any, ref: any) => (
                  <CancelOutlinedIcon {...props} ref={ref} />
                ))}
                pageSize={8}
                data={orders}
                onView={
                  filterWithExecutionPercentage && canDoOrderCheck
                    ? (order: OrderV2Response) => {
                        orderData.setOrder(order);
                        setOpenOrderDialog(true);
                      }
                    : undefined
                }
                onViewText={filterWithExecutionPercentage ? 'Преглед на наличности' : undefined}
                onDelete={canDelete ? handleOnDelete : undefined}
                isDeletable={(order: OrderV2Response) => {
                  if (order.statusKey === OrderStatus.FOR_SHIPPING.key) {
                    return true;
                  }
                  if (order.statusKey === OrderStatus.SHIPPED.key) {
                    return true;
                  }
                  if (order.statusKey === OrderStatus.RECEIVED_WITH_REMARKS.key) {
                    return true;
                  }
                  if (order.statusKey === OrderStatus.DELIVERED.key) {
                    return true;
                  }
                  if (order.statusKey === OrderStatus.AWAITING_PAYMENT.key) {
                    return true;
                  }
                  if (order.statusKey === OrderStatus.FINISHED_SUCCESSFULLY.key) {
                    return true;
                  }
                  return false;
                }}
                customAction={(rowData: OrderV2Response) => {
                  return {
                    icon: () => (
                      <AssignmentTurnedInIcon
                        style={{
                          color: '#252525 !important'
                        }}
                      />
                    ),
                    disabled: !(
                      canUpdate &&
                      canCreateTransaction &&
                      (rowData.statusKey === OrderStatus.DELIVERED.key ||
                        rowData.statusKey === OrderStatus.AWAITING_PAYMENT.key)
                    ),
                    tooltip: 'Приключване',
                    onClick: async (event: Event, rowData: OrderV2Response) => {
                      if (
                        (rowData.statusKey === OrderStatus.DELIVERED.key &&
                          rowData.receiverShouldPayOnDelivery) ||
                        rowData.statusKey === OrderStatus.AWAITING_PAYMENT.key
                      ) {
                        orderData.setParameters([rowData.id]);
                        orderDocumentsData.setParameters([rowData.id]);
                        setOpenTransactionDialog(true);
                      }
                      if (
                        rowData.statusKey === OrderStatus.DELIVERED.key &&
                        !rowData.receiverShouldPayOnDelivery
                      ) {
                        orderData.setParameters([rowData.id]);
                        setOpenConfirmDialog(true);
                      }
                    }
                  };
                }}
                isLoading={loading}
              />
            </Grid>
          </Grid>
        </Container>
        {orderData.order && (
          <CancelOrderDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            order={orderData.order}
          />
        )}
        {orderData.order && (
          <PreviewOrderDialog
            open={openOrderDialog}
            onClose={async () => {
              setOpenOrderDialog(false);
              setLoading(true);
              const ordersResponse = await amsLegacyService.getOrdersWithExecutionPercentage(
                filterCompanies.map((company: Lookup) => company.id),
                filterWarehouses.map((warehouse: Lookup) => warehouse.id)
              );
              if (ordersResponse) {
                setOrders(ordersResponse.data);
              }
              setLoading(false);
            }}
            order={orderData.order}
          />
        )}
        {orderData.order && canUpdate && canCreateTransaction && (
          <AddTransactionDialog
            isOrderTransaction={true}
            documents={(orderDocumentsData.orderDocuments ? orderDocumentsData.orderDocuments : [])
              .filter(
                (doc: OrderDocumentV2Response) => doc.type !== DocumentTypes.ACT_OF_ACCEPTANCE
              )
              .map((t: OrderDocumentV2Response) => ({
                id: t.id,
                value: `${t.number} ${amsV3Service.getOrderDocumentTypeAlias(t.type)}`,
                type: t.type,
                remainingAmount:
                  t.number === orderData.order!.invoiceNumber
                    ? orderData.order!.invoiceRemainingAmount
                    : t.totalAmount
              }))
              .sort((do1: DocumentOption, do2: DocumentOption) => do1.id - do2.id)}
            defaultAmount={orderData.order!.invoiceRemainingAmount}
            defaultTransactionType={
              orderData.order!.receiverShouldPayOnDelivery
                ? TransactionType.CASH
                : TransactionType.BANK_ACCOUNT
            }
            open={openTransactionDialog}
            onClose={() => {
              setOpenTransactionDialog(false);
              setOpenConfirmDialog(true);
            }}
            onSave={async (transaction: CreateTransactionRequest) => {
              const resp = await amsV3Service.createTransaction(transaction);
              if (resp) {
                setOpenTransactionDialog(false);
                setOpenConfirmDialog(true);
              }
            }}
          />
        )}
        {orderData.order && canUpdate && (
          <AMSConfirmDialog
            open={оpenConfirmDialog}
            onConfirm={async () => {
              await amsLegacyService.updateOrderStatus(
                orderData.order!.id,
                orderData.order!.receiverShouldPayOnDelivery
                  ? OrderStatusKeys.FINISHED_SUCCESSFULLY
                  : OrderStatusKeys.AWAITING_PAYMENT
              );
              orderData.setOrder(null);
              setOpenConfirmDialog(false);
              onSearch();
            }}
            onClose={() => setOpenConfirmDialog(false)}
            title={'Промяна на статус'}
            message={`Промяна на статуса на поръчка ${getExtendedOrderId(orderData.order.id)} на '${
              orderData.order.receiverShouldPayOnDelivery
                ? OrderStatusLabels.FINISHED_SUCCESSFULLY
                : OrderStatusLabels.AWAITING_PAYMENT
            }' . Искате да продължите?`}
          />
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default OrdersComponent;
