import Cookies from 'universal-cookie';

export class CookiesService {
  cookies = new Cookies();

  private drawerCookie = 'drawer_expanded';
  private availabilitiesFilter = 'availabilities_filter';
  private expandedCompaniesCookier = 'expanded_companies';
  private availabilitiesList = 'availabilities_list';
  private itemsList = 'items_list';
  private deliveriesList = 'deliveries_list';
  private deliveryRequestsList = 'delivery_requests_list';
  private ordersList = 'orders_list';
  private b2bOrdersList = 'b2b_orders_list';
  private b2cOrdersList = 'b2c_orders_list';
  private internalTransfersList = 'internal_transfers_list';
  private orderIds = 'order_ids';
  private partnersList = 'partners_list';
  private subscriptionsList = 'subscription_list';
  private warehousesList = 'warehouses_list';
  private actsOfAcceptanceFilter = 'acts_of_acceptance_filter';
  private companiesList = 'companies_list';
  private reportInvoicesFilter = 'report_invoices_filter';
  private reportDeliveryDocumentsFilter = 'report_delivery_invoices_filter';
  private reportItemMovementsFilter = 'report_item_movements_filter';
  private reportOrderItemsFilter = 'report_order_items_filter';
  private reportDeliveryItemsFilter = 'report_delivery_items_filter';
  private reportStockFilter = 'report_stock_filter';
  private stockTakingReportsFilter = 'stock_taking_reports_filter';
  private stocktakingsFilter = 'stocktakings_filter';
  private transactionsFilter = 'transactions_filter';
  private reportOrderBalance = 'report_order_balance';
  private suppliersList = 'suppliers_list';
  private usersList = 'users_list';
  private legalEntitiesList = 'legal_entities_list';
  private shippingRoutesFilter = 'shipping_routes_filter';
  private promotionsList = 'promotions_list';
  private manufacturersFilter = 'manufacturers_filter';
  private clientsFilter = 'clients_filter';
  private paymentOptionsFilter = 'payment_options_filter';
  private pricingPoliciesFilter = 'pricing_policies_filter';

  isSideDrawerExpanded = () => this.cookies.get(this.drawerCookie) === 'true';

  toggleSideDrawer = () => {
    this.cookies.set(this.drawerCookie, !this.isSideDrawerExpanded());
  };

  getVisibleCompanies = () => this.cookies.get(this.expandedCompaniesCookier);

  setVisibleCompanies = (expandedCompanies: any) =>
    this.cookies.set(this.expandedCompaniesCookier, expandedCompanies);

  getItemsList = () => this.getFilter(this.itemsList);

  setItemsList = (filter: any) => this.setFilter(this.itemsList, filter);

  getAvailabilitiesList = () => this.getFilter(this.availabilitiesList);

  setAvailabilitiesList = (filter: any) => this.setFilter(this.availabilitiesList, filter);

  getDeliveriesList = () => this.getFilter(this.deliveriesList);

  setDeliveriesList = (filter: any) => this.setFilter(this.deliveriesList, filter);

  getDeliveryRequestsList = () => this.getFilter(this.deliveryRequestsList);

  setDeliveryRequestsList = (filter: any) => this.setFilter(this.deliveryRequestsList, filter);

  getCompaniesList = () => this.getFilter(this.companiesList);

  setCompaniesList = (filter: any) => this.setFilter(this.companiesList, filter);

  getOrdersList = () => this.getFilter(this.ordersList);

  setOrdersList = (filter: any) => this.setFilter(this.ordersList, filter);

  getB2BOrdersList = () => this.getFilter(this.b2bOrdersList);

  setB2BOrdersList = (filter: any) => this.setFilter(this.b2bOrdersList, filter);

  getB2COrdersList = () => this.getFilter(this.b2cOrdersList);

  setB2COrdersList = (filter: any) => this.setFilter(this.b2cOrdersList, filter);

  getInternalTransfersList = () => this.getFilter(this.internalTransfersList);

  setInternalTransfersList = (filter: any) => this.setFilter(this.internalTransfersList, filter);

  getPartnersList = () => this.getFilter(this.partnersList);

  setPartnersList = (filter: any) => this.setFilter(this.partnersList, filter);

  getWarehousesList = () => this.getFilter(this.warehousesList);

  setWarehousesList = (filter: any) => this.setFilter(this.warehousesList, filter);

  getOrderIds = () => {
    const value = this.getFilter(this.orderIds);
    return JSON.stringify(value) !== '{}' ? value : [];
  };

  setOrderIds = (filter: any) => this.setFilter(this.orderIds, filter);

  getActOfAcceptanceFilter = () => this.getFilter(this.actsOfAcceptanceFilter);

  setActOfAcceptanceFilter = (filter: any) => this.setFilter(this.actsOfAcceptanceFilter, filter);

  getInvoiceFilter = () => this.getFilter(this.reportInvoicesFilter);

  setInvoiceFilter = (filter: any) => this.setFilter(this.reportInvoicesFilter, filter);

  getReportItemMovementsFilter = () => this.getFilter(this.reportItemMovementsFilter);

  setReportItemMovementsFilter = (filter: any) =>
    this.setFilter(this.reportItemMovementsFilter, filter);

  getReportDeliveryDocumentFilter = () => this.getFilter(this.reportDeliveryDocumentsFilter);

  setReportDeliveryDocumentFilter = (filter: any) =>
    this.setFilter(this.reportDeliveryDocumentsFilter, filter);

  getReportOrderItemsFilter = () => this.getFilter(this.reportOrderItemsFilter);

  setReportOrderItemsFilter = (filter: any) => this.setFilter(this.reportOrderItemsFilter, filter);

  getReportDeliveryItemsFilter = () => this.getFilter(this.reportDeliveryItemsFilter);

  setReportDeliveryItemsFilter = (filter: any) =>
    this.setFilter(this.reportDeliveryItemsFilter, filter);

  setReportStockFilter = (filter: any) => this.setFilter(this.reportStockFilter, filter);

  getReportStockFilter = () => this.getFilter(this.reportStockFilter);

  getStockTakingReportsFilter = () => this.getFilter(this.stockTakingReportsFilter);

  setStockTakingReportsFilter = (filter: any) =>
    this.setFilter(this.stockTakingReportsFilter, filter);

  getStocktakingsFilter = () => this.getFilter(this.stocktakingsFilter);

  setStocktakingsFilter = (filter: any) => this.setFilter(this.stocktakingsFilter, filter);

  getTransactionsFilter = () => this.getFilter(this.transactionsFilter);

  setTransactionsFilter = (filter: any) => this.setFilter(this.transactionsFilter, filter);

  getShippingRouteFilter = () => this.getFilter(this.shippingRoutesFilter);

  setShippingRouteFilter = (filter: any) => this.setFilter(this.shippingRoutesFilter, filter);

  getReportOrderBalance = () => this.getFilter(this.reportOrderBalance);

  setReportOrderBalance = (filter: any) => this.setFilter(this.reportOrderBalance, filter);

  getSuppliersList = () => this.getFilter(this.suppliersList);

  setSuppliersList = (filter: any) => this.setFilter(this.suppliersList, filter);

  getUsersList = () => this.getFilter(this.usersList);

  setUsersList = (filter: any) => this.setFilter(this.usersList, filter);

  getLegalEntitiesList = () => this.getFilter(this.legalEntitiesList);

  setLegalEntitiesList = (filter: any) => this.setFilter(this.legalEntitiesList, filter);

  getPromotionsList = () => this.getFilter(this.promotionsList);

  setPromotionsList = (filter: any) => this.setFilter(this.promotionsList, filter);

  getClientsFilter = () => this.getFilter(this.clientsFilter);

  setClientsFilter = (filter: any) => this.setFilter(this.clientsFilter, filter);

  getAvailabilitiesFilter = () => this.getFilter(this.availabilitiesFilter);

  setAvailabilitiesFilter = (filter: any) => this.setFilter(this.availabilitiesFilter, filter);

  getPaymentOptionsFilter = () => this.getFilter(this.paymentOptionsFilter);

  setPaymentOptionsFilter = (filter: any) => this.setFilter(this.paymentOptionsFilter, filter);

  getPricingPoliciesFilter = () => this.getFilter(this.pricingPoliciesFilter);

  setPricingPoliciesFilter = (filter: any) => this.setFilter(this.pricingPoliciesFilter, filter);

  getFilter = (filter: string) => {
    return this.cookies.get(filter) ? this.cookies.get(filter) : {};
  };

  setFilter = (filter: string, value: any) => this.cookies.set(filter, JSON.stringify(value));
}
