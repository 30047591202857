import {
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  createStyles,
  makeStyles
} from '@material-ui/core';
import {
  CreateSupplierReserveSubscriptionRequest,
  PatchSupplierReserveSubscriptionRequest,
  SupplierListResponse,
  SupplierResponse,
  amsV3Service,
  snackbarService
} from '../../../services';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { WEEKDAYS, Weekday, WeekdayKey, getWeekdaysByKey } from '../../../helpers/date-helper';
import { useSupplierReserveSubscription, useSuppliers } from '../../../helpers/hooks';

import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AMSTextField from '../../../helpers/ui/AMSTextField/AMSTextField';
import AMSViewOnlyTextField from '../../../helpers/ui/AMSViewOnlyTextField/AMSViewOnlyTextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getExtendedSupplierId } from '../../../helpers/utils';
import { parseQuery } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: 0
    },
    root: {
      padding: 20,
      marginTop: 25
    },
    checkbox: {
      marginRight: 8
    },
    saveButton: {
      marginTop: 10
    }
  })
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SupplierReserveSubscriptionComponent = ({ location }: { location: any }) => {
  const { id } = parseQuery(location.search);

  const classes = useStyles();
  const history = useHistory();

  const [selectedWeekdays, setSelectedWeekdays] = useState<any[]>([]);
  const [supplier, setSupplier] = useState<SupplierResponse | null>(null);
  const [createdByName, setCreatedByName] = useState('');
  const [lastUpdatedByName, setLastUpdatedByName] = useState('');
  const [supplierReserveSubscriptionForm, setSupplierReserveSubscriptionForm] = useState<any>({});
  const { supplierReserveSubscription, loading } = useSupplierReserveSubscription(id);
  const { suppliers } = useSuppliers();

  const loadCreatedByName = useCallback(async () => {
    if (supplierReserveSubscription?.createdById) {
      const resp = await amsV3Service.getUser(supplierReserveSubscription.createdById);
      setCreatedByName(resp.data.name);
    }
  }, [supplierReserveSubscription]);

  const loadLastUpdatedByName = useCallback(async () => {
    if (supplierReserveSubscription?.lastUpdatedById) {
      const resp = await amsV3Service.getUser(supplierReserveSubscription.lastUpdatedById);
      setLastUpdatedByName(resp.data.name);
    }
  }, [supplierReserveSubscription]);

  const loadSupplier = useCallback(async () => {
    if (supplierReserveSubscription?.supplierId) {
      const supplierResp = await amsV3Service.getSupplier(supplierReserveSubscription?.supplierId);
      setSupplier(supplierResp.data);
    }
  }, [supplierReserveSubscription]);

  const loadWeekdays = useCallback(async () => {
    if (supplierReserveSubscription?.supplierId) {
      const weekdays: Weekday[] = supplierReserveSubscription.weekdays.map(
        (weekday) => getWeekdaysByKey(weekday as WeekdayKey) as Weekday
      );
      setSelectedWeekdays(
        weekdays.map((w: Weekday) => ({
          id: w.id,
          value: w.label,
          key: w.key
        }))
      );
    }
  }, [supplierReserveSubscription]);

  useEffect(() => {
    setSupplierReserveSubscriptionForm({ ...supplierReserveSubscription });
    loadSupplier();
    loadCreatedByName();
    loadLastUpdatedByName();
    loadWeekdays();
  }, [
    supplierReserveSubscription,
    loadSupplier,
    loadLastUpdatedByName,
    loadCreatedByName,
    loadWeekdays
  ]);

  const [actionLoading, setActionLoading] = useState(false);

  const handleOnClick = async () => {
    setActionLoading(true);
    if (id) {
      const patchRequest: PatchSupplierReserveSubscriptionRequest = {
        weekdays: selectedWeekdays.map((w: { key: any }) => w.key),
        baseDays: supplierReserveSubscriptionForm.baseDays,
        reserveDays: supplierReserveSubscriptionForm.reserveDays,
        comment: supplierReserveSubscriptionForm.comment
      };
      const resp = await amsV3Service.updateSupplierReserveSubscription(id, patchRequest);
      if (resp) {
        snackbarService.setSnackbarOpen(true);
        // setSupplierReserveSubscription(resp.data);
      }
    } else {
      const createRequest: CreateSupplierReserveSubscriptionRequest = {
        supplierId: supplier!.id,
        weekdays: selectedWeekdays.map((w: { key: any }) => w.key),
        baseDays: supplierReserveSubscriptionForm.baseDays,
        reserveDays: supplierReserveSubscriptionForm.reserveDays,
        comment: supplierReserveSubscriptionForm.comment
      };
      const resp = await amsV3Service.createSupplierReserveSubscription(createRequest);
      if (resp) {
        history.push(`/supplier-reserve-subscription?id=${resp.data.id}`);
        snackbarService.setSnackbarOpen(true);
      }
    }
    setActionLoading(false);
  };

  const isValidSupplierReserveSubscription = useMemo(
    () =>
      (id || supplier) &&
      selectedWeekdays.length > 0 &&
      supplierReserveSubscriptionForm?.baseDays &&
      supplierReserveSubscriptionForm?.reserveDays,
    [supplierReserveSubscriptionForm, id, selectedWeekdays, supplier]
  );

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="md" className={classes.container}>
      <Paper elevation={2} className={classes.root}>
        {supplierReserveSubscription ? <></> : <></>}
        <Grid container spacing={1}>
          <Grid item lg={6} sm={12}>
            {!id && (
              <AMSAutocomplete
                label="Доставчик"
                onChange={setSupplier}
                value={supplier ? supplier : null}
                minChar={0}
                required
                options={suppliers.map((s: SupplierListResponse) => ({
                  id: s.id,
                  value: `${getExtendedSupplierId(s.id)} ${s.name}`
                }))}
                sortOptions={false}
              />
            )}
            {id && <AMSViewOnlyTextField label="Доставчик" value={supplier?.name ?? ''} />}
          </Grid>
          <Grid item lg={6} sm={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={7}
              sortOptions={false}
              options={WEEKDAYS.map((w: Weekday) => ({
                id: w.id,
                value: w.label,
                key: w.key
              }))}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Дни от седмицата"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={selectedWeekdays}
              onChange={(values: any[]) => setSelectedWeekdays(values.sort((a, b) => a.id - b.id))}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <TextField
              label="Основа"
              variant="outlined"
              fullWidth
              required
              margin="dense"
              value={supplierReserveSubscriptionForm?.baseDays ?? ''}
              onChange={(event) =>
                setSupplierReserveSubscriptionForm({
                  ...supplierReserveSubscriptionForm,
                  baseDays: +event?.target.value ? +event.target.value : 0
                })
              }
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <TextField
              label="Запас"
              variant="outlined"
              fullWidth
              required
              margin="dense"
              value={supplierReserveSubscriptionForm?.reserveDays ?? ''}
              onChange={(event) =>
                setSupplierReserveSubscriptionForm({
                  ...supplierReserveSubscriptionForm,
                  reserveDays: +event?.target.value ? +event.target.value : 0
                })
              }
            />
          </Grid>
          {id && (
            <Grid item lg={12}>
              {supplierReserveSubscription?.createdById &&
                supplierReserveSubscription?.createdAt && (
                  <AMSViewOnlyTextField
                    label="Създаден"
                    value={`От ${createdByName} на ${formatDate(
                      supplierReserveSubscription?.createdAt
                    )}`}
                  />
                )}
              {supplierReserveSubscription?.lastUpdatedById &&
                supplierReserveSubscription?.lastUpdatedAt && (
                  <AMSViewOnlyTextField
                    label="Последна редакция"
                    value={`От ${lastUpdatedByName} на ${formatDate(
                      supplierReserveSubscription?.lastUpdatedAt
                    )}`}
                  />
                )}
            </Grid>
          )}
          <Grid item lg={12} sm={12}>
            <AMSTextField
              label="Коментар"
              value={supplierReserveSubscriptionForm?.comment ?? ''}
              onChange={(text) =>
                setSupplierReserveSubscriptionForm({
                  ...supplierReserveSubscriptionForm,
                  comment: text
                })
              }
              multiline={true}
              rows={4}
            />
          </Grid>
        </Grid>
        {
          <AMSButton
            text="Запиши"
            color="primary"
            variant="contained"
            onClick={handleOnClick}
            disabled={actionLoading || !isValidSupplierReserveSubscription}
            loading={actionLoading}
            className={classes.saveButton}
          />
        }
      </Paper>
    </Container>
  );
};

export default SupplierReserveSubscriptionComponent;

const formatDate = (dateString: string) => {
  return dateString.substr(0, 19).split('T').join(', ');
};
