import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Paper
} from '@material-ui/core';
import {
  useClient,
  useClientDevices,
  useClientFavorites,
  useStyles
} from '../../../../helpers/hooks';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../../helpers/ui/AMSTable/AMSTable';
import { AMSViewOnlyTextField } from '../../../../helpers/ui';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import React from 'react';
import { parseQuery } from '../../../../helpers/url';

const ClientInfoComponent = ({ location }: { location: any }) => {
  const { id } = parseQuery(location.search);

  const classes = useStyles();

  const client = useClient(id);
  const clientFavorites = useClientFavorites(id);
  const clientDevices = useClientDevices(id);

  return client.loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={1}>
        <Grid item lg={6}>
          <Paper elevation={2} className={classes.root}>
            <OutlinedDiv label="Профил" disabled={true}>
              <Grid container spacing={1}>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <AMSViewOnlyTextField label="ИД" value={`${client.client!.id}`} />
                </Grid>
                <Grid item lg={8} md={6} sm={12} xs={12}>
                  <AMSViewOnlyTextField label="Имейл" value={client.client!.email} />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <AMSViewOnlyTextField label="Име" value={client.client!.firstName} />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <AMSViewOnlyTextField label="Фамилия" value={client.client!.lastName} />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <AMSViewOnlyTextField label="Телефон" value={client.client!.phone} />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <AMSViewOnlyTextField label="Контрагент" value={`${client.client!.partnerId}`} />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControlLabel
                    className={classes.label}
                    control={<Checkbox checked={client.client!.isActive} />}
                    disabled={true}
                    label="Активен"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControlLabel
                    className={classes.label}
                    control={<Checkbox checked={client.client!.isMain} />}
                    disabled={true}
                    label="Основен"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </OutlinedDiv>
          </Paper>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <AMSTable
            title="Любими"
            columns={[
              {
                title: 'Арт №',
                field: 'artNo',
                cellStyle: { width: '20%' },
                render: (rowData: any) => (
                  <AMSLink href={`/item?id=${rowData.itemId}`}>{rowData.artNo}</AMSLink>
                )
              },
              {
                title: 'Име',
                field: 'name',
                cellStyle: { width: '50%' },
                render: (rowData: any) => (
                  <AMSLink href={`/item?id=${rowData.itemId}`}>{rowData.name}</AMSLink>
                )
              },
              {
                title: 'Мерна единица',
                field: 'unitName',
                cellStyle: { width: '30%' }
              }
            ]}
            data={clientFavorites.clientFavorites}
            isLoading={clientFavorites.loading}
            minBodyHeight="35vh"
            maxBodyHeight="35vh"
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <AMSTable
            title="Устройства"
            columns={[
              {
                title: 'ИД',
                field: 'id',
                cellStyle: { width: '5%' }
              },
              {
                title: 'Token',
                field: 'token',
                cellStyle: { width: '60%' },
                render: (rowData: any) => rowData.token.substring(0, 20) + '...'
              },
              {
                title: 'Последно използван',
                field: 'lastSeenAt',
                type: 'datetime',
                cellStyle: { width: '35%' }
              }
            ]}
            data={clientDevices.clientDevices}
            isLoading={clientDevices.loading}
            minBodyHeight="35vh"
            maxBodyHeight="35vh"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClientInfoComponent;
