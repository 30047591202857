import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';
import {
  InvoiceListResponse,
  PaginatedListResponseInvoiceListResponse,
  amsV3Service
} from '../../services';

export interface IInvoices<T> extends IAsyncType<T> {
  invoices: T;
  setInvoices: Dispatch<SetStateAction<T>>;
  setInvoicesParameters: Dispatch<any[] | null>;
}

const useInvoices = (
  invoiceIds?: number[],
  fromDate?: string,
  toDate?: string,
  legalEntityIds?: number[],
  companyIds?: number[],
  hideCancelled?: boolean,
  hidePaidInFull?: boolean
): IInvoices<InvoiceListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseInvoiceListResponse>(
      amsV3Service.getInvoices,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [invoiceIds, fromDate, toDate, legalEntityIds, companyIds, hideCancelled, hidePaidInFull]
    );
  return {
    invoices: data?.data ?? [],
    setInvoices: () => {},
    loading,
    setLoading,
    parameters,
    setParameters,
    setInvoicesParameters: setParameters
  };
};

export default useInvoices;
