import { Checkbox, Container, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { ItemListResponse, WarehouseListResponse, cookiesService } from '../../../services';
import Lookup, { itemToLookup, warehouseToLookup } from '../../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAvailabilities,
  useFlag,
  useItems,
  useStyles,
  useWarehouses
} from '../../../helpers/hooks';

import AMSAutocomplete from '../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../helpers/ui/AMSButton/AMSButton';
import AvailabilitiesTableComponent from '../AvailabilitiesTableComponent/AvailabilitiesTableComponent';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { parseFilter } from '../../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AvailabiltiyComponent = ({ location }: any) => {
  const history = useHistory();
  const classes = useStyles();
  const { itemIds, warehouseIds, realTime } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const { warehouses } = useWarehouses();
  const { items } = useItems();
  const filter = useMemo(() => cookiesService.getAvailabilitiesFilter(), []);
  const [filterWarehouses, setFilterWarehouses] = useState<Lookup[]>(
    filter.filterWarehouses ? filter.filterWarehouses : []
  );
  const [filterItems, setFilterItems] = useState<Lookup[]>(
    filter.filterItems ? filter.filterItems : []
  );

  const [filterRealTime, setFilterRealTime] = useFlag(
    realTime !== undefined && realTime !== null ? realTime : filter.filterRealTime ?? false
  );
  const { availabilities, setParameters, setLoading, loading } = useAvailabilities(
    itemIds ?? filterItems.map((i: Lookup) => i.id),
    warehouseIds ?? filterWarehouses.map((w: Lookup) => w.id),
    realTime ?? filterRealTime
  );

  useEffect(() => {
    const { filterWarehouses } = cookiesService.getAvailabilitiesList();
    setFilterWarehouses(
      warehouseIds && warehouses
        ? warehouses
            .filter((w: WarehouseListResponse) => warehouseIds.includes(w.id))
            .map(warehouseToLookup)
        : filterWarehouses ?? []
    );
  }, [warehouses, warehouseIds]);

  useEffect(() => {
    const { filterItems } = cookiesService.getAvailabilitiesList();
    setFilterItems(
      itemIds && items
        ? items.filter((i: ItemListResponse) => itemIds.includes(i.id)).map(itemToLookup)
        : filterItems ?? []
    );
  }, [items, itemIds]);

  const onSearch = useCallback(() => {
    setLoading(true);
    cookiesService.setAvailabilitiesList({
      filterItems,
      filterWarehouses,
      filterRealTime
    });
    let params: any = {};
    if (filterItems?.length > 0) {
      params.itemIds = filterItems.map((i) => i.id).join(',');
    }
    if (filterWarehouses?.length > 0) {
      params.warehouseIds = filterWarehouses.map((w) => w.id).join(',');
    }
    if (filterRealTime) {
      params.realTime = filterRealTime;
    }
    history.push({
      pathname: '/availabilities',
      search: new URLSearchParams(params).toString()
    });
    setParameters([
      filterItems.map((i: Lookup) => i.id),
      filterWarehouses.map((w: Lookup) => w.id),
      filterRealTime
    ]);
    setLoading(false);
  }, [setLoading, filterItems, filterWarehouses, filterRealTime, history, setParameters]);

  return (
    <div className="text-align-left">
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={items.map(itemToLookup)}
              sortOptions={false}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Артикули"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterItems}
              onChange={setFilterItems}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>

          <Grid item lg={3} md={6} sm={12} xs={12}>
            <AMSAutocomplete
              multiple
              minChar={0}
              limitTags={3}
              options={warehouses.map(warehouseToLookup)}
              sortOptions={false}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.checkbox}
                    checked={selected}
                    color="primary"
                  />
                  {option.value}
                </Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Складове"
                  placeholder=""
                  margin="dense"
                  fullWidth
                />
              )}
              value={filterWarehouses}
              onChange={setFilterWarehouses}
              groupBy={(g) => (g.group ? g.group : '')}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <FormControlLabel
              className={classes.label}
              control={
                <Checkbox
                  checked={filterRealTime}
                  onChange={() => {
                    setFilterRealTime(!filterRealTime);
                  }}
                />
              }
              label="В реално време"
              labelPlacement="end"
            />
          </Grid>
          <Grid item lg={1} md={6} sm={12} xs={12}>
            <AMSButton
              color="primary"
              variant="contained"
              text="Търсене"
              loading={loading}
              disabled={false}
              onClick={onSearch}
              style={{
                marginTop: 8,
                float: 'right'
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12}>
            <AvailabilitiesTableComponent availabilities={availabilities} loading={loading} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AvailabiltiyComponent;
