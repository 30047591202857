import { AMSTable, AMSTextField } from '../../../helpers/ui';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';

import ImportItemSetupFormComponent from './ImportItemSetupFormComponent';

/**
 *
 * @deprecated
 */
const ImportItemsComponent = () => {
  const [keys, setKeys] = useState<any[]>([]);
  const [mapping, setMapping] = useState<any>({});
  const [fileName, setFileName] = useState('');
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  const handleChange = useCallback((e: any) => {
    const file = e.target.files[0];
    setFileName(file.name);

    let reader = new FileReader();

    reader.onload = (e: any) => {
      const file = e.target.result;
      const rows = file.split('\n');
      const keys = rows[0]
        .split(';')
        .map((key: string) => key.trim())
        .filter((key: string) => key !== '');
      const data = rows.slice(1, rows.length - 1).map((row: string, ind: number) => {
        const values = row.split(';');
        return keys.reduce(
          (obj: any, key: any, index: any) => {
            obj[key] = values[index];
            return obj;
          },
          { id: ind }
        );
      });
      setKeys(keys);
      const keysTotalLength = keys.reduce((res: number, key: string) => res + key.length, 0);
      const keysTotalLengthWithPercent = keys.reduce(
        (res: number, key: string) => res + key.length,
        0
      );
      setColumns(
        keys.map((key: string, index: number) => ({
          title: key,
          field: key,
          width:
            (index === 0 ? 100 - keysTotalLengthWithPercent : 0) +
            Math.floor((key.length / keysTotalLength) * 100) +
            '%'
        }))
      );
      setData(data);
    };

    reader.onerror = (e: any) => alert(e.target.error.name);
    reader.readAsText(file);
  }, []);

  const parsedJsonData = useMemo(() => {
    return data.map((item: any) => {
      return {
        name: item[mapping.name],
        art_no: item[mapping.artNo],
        barcode: item[mapping.barcode],
        item_category_id: item[mapping.itemCategoryId],
        supplier_id: item[mapping.supplierId],
        base_price: item[mapping.basePrice]
          ? +item[mapping.basePrice].replace(',', '.')
          : mapping.basePrice,
        cost_price: item[mapping.costPrice]
          ? +item[mapping.costPrice].replace(',', '.')
          : mapping.basePrice,
        currency_id: item[mapping.currencyId],
        vat: item[mapping.vat],
        package_qty: item[mapping.packageQty],
        package_qty_delivery: item[mapping.packageQtyDelivery],
        unit_id: item[mapping.unitId],
        is_active: true
      };
    });
  }, [mapping, data]);

  const handledDownloadClick = useCallback(() => {
    const fileData = JSON.stringify(parsedJsonData, null, 2);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${fileName}.json`;
    link.href = url;
    link.click();
  }, [fileName, parsedJsonData]);

  const duplicatedArtNo = useMemo(() => {
    const artNos = parsedJsonData.map((item: any) => item.artNo);
    const uniqueArtNos = new Set(artNos);
    return artNos.filter((currentValue) => {
      if (uniqueArtNos.has(currentValue)) {
        uniqueArtNos.delete(currentValue);
        return false;
      } else {
        return currentValue;
      }
    });
  }, [parsedJsonData]);

  const duplicatedBarcode = useMemo(() => {
    const barcodes = parsedJsonData.map((item: any) => item.barcode);
    const uniqueBarcodes = new Set(barcodes);
    return barcodes.filter((currentValue) => {
      if (uniqueBarcodes.has(currentValue)) {
        uniqueBarcodes.delete(currentValue);
        return false;
      } else {
        return currentValue;
      }
    });
  }, [parsedJsonData]);

  const parsedJsonDataHelperText = useMemo(() => {
    return duplicatedArtNo.length > 0 || duplicatedBarcode.length > 0
      ? `Дублирани артикулни номера: ${duplicatedArtNo.join(
          ', '
        )}, Дублирани баркодове: ${duplicatedBarcode.join(', ')}`
      : '';
  }, [duplicatedArtNo, duplicatedBarcode]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <div style={{ display: 'flex' }}>
          <input
            id="raised-button-file"
            onChange={handleChange}
            type="file"
            accept="text/csv"
            style={{ display: 'none' }}
          />
          <label htmlFor="raised-button-file">
            <Button color="primary" variant="contained" component="span">
              Качи CSV
            </Button>
          </label>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h5">{fileName ? fileName : 'Импортиране на артикули'}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Button
          color="primary"
          variant="contained"
          onClick={handledDownloadClick}
          style={{ float: 'right' }}
          disabled={fileName === ''}
        >
          Свали JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ImportItemSetupFormComponent keys={keys} setMapping={setMapping} />
          </Grid>
          <Grid item xs={6}>
            <AMSTextField
              label="JSON Import"
              value={JSON.stringify(parsedJsonData, null, 2)}
              multiline={true}
              rows={15}
              disabled
              error={duplicatedArtNo.length > 0 || duplicatedBarcode.length > 0}
              helperText={parsedJsonDataHelperText}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <AMSTable columns={columns} data={data} paging={false} />
      </Grid>
    </Grid>
  );
};

export default ImportItemsComponent;
