import {
  AccessPermissions,
  ItemListResponse,
  ItemResponse,
  LinkedItemAssociationResponse,
  accessManagementService,
  amsV3Service
} from '../../../services';
import { IconButton, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  }
}));

interface LinkedItemsContainerProps {
  id: number;
}

const LinkedItemsContainer = (props: LinkedItemsContainerProps) => {
  const { id } = props;
  const classes = useStyles();

  const [item, setItem] = useState<any>(null);
  const [linkedItems, setLinkedItems] = useState<LinkedItemAssociationResponse[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [items, setItems] = useState<ItemListResponse[]>([]);

  const loadData = useCallback(async () => {
    setLoading(true);
    if (id) {
      const resp = await amsV3Service.getLinkedItems(id);
      setLinkedItems(resp.data);
      const linkedItemsIds = resp.data.map((li: LinkedItemAssociationResponse) => li.linkedItemId);
      const itemsResp = await amsV3Service.getItems(linkedItemsIds);
      setItems(itemsResp.data.data);
    } else {
      setLinkedItems([]);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const itemArtNoLookup = useMemo(
    () =>
      items.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item.artNo;
        return res;
      }, {}),
    [items]
  );

  const itemNamesLookup = useMemo(
    () =>
      items.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item.name;
        return res;
      }, {}),
    [items]
  );

  const columns = useMemo(
    () => [
      {
        title: 'Арт №',
        field: 'linkedItemId',
        lookup: itemArtNoLookup,
        render: (l: LinkedItemAssociationResponse) => {
          const itemArtNo = itemArtNoLookup[l.linkedItemId];
          return <AMSLink href={`/item?id=${l.itemId}`}>{itemArtNo}</AMSLink>;
        },
        cellStyle: { width: '8%' }
      },
      {
        title: 'Име',
        field: 'linkedItemId',
        lookup: itemNamesLookup,
        render: (l: LinkedItemAssociationResponse) => {
          const itemName = itemNamesLookup[l.linkedItemId];
          return <AMSLink href={`/item?id=${l.itemId}`}>{itemName}</AMSLink>;
        },
        cellStyle: { width: '92%' }
      }
    ],
    [itemArtNoLookup, itemNamesLookup]
  );

  const disabled = useMemo(
    () =>
      !accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_ITEM) &&
      !accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_ITEM),
    []
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AMSTable
          title="Свързани продукти"
          columns={columns}
          data={linkedItems}
          components={{
            EditRow: (props: any) => {
              return (
                <TableRow>
                  <TableCell colSpan={2}>
                    {props.mode === 'add' ? (
                      <AMSAsyncAutocomplete
                        label="Артикул"
                        onChange={(item: ItemResponse | null) => setItem(item)}
                        value={item}
                        minChar={3}
                      />
                    ) : (
                      <Typography component="h1" variant="h6">
                        Наистина ли искате да изтриете този запис?
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <div className={classes.flex}>
                      <IconButton
                        onClick={() =>
                          props.onEditingApproved(
                            props.mode,
                            props.mode === 'add' ? item : props.data,
                            props.mode === 'add' ? item : props.data
                          )
                        }
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton onClick={() => props.onEditingCanceled(props.mode, props.data)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
          }}
          inlineActions={true}
          pageSize={8}
          isLoading={isLoading}
          onAdd={
            !disabled
              ? async (relatedItem: ItemResponse) => {
                  setLoading(true);
                  const resp = await amsV3Service.addLinkedItem(id, relatedItem.id);
                  if (resp?.data) {
                    loadData();
                  }
                  setLoading(false);
                }
              : undefined
          }
          onDelete={
            !disabled
              ? async (linkedItemAssociation: any) => {
                  if (linkedItemAssociation) {
                    setLoading(true);
                    await amsV3Service.deleteLinkedItem(id, linkedItemAssociation.linkedItemId);
                    const data = [...linkedItems];
                    data.splice(linkedItemAssociation.tableData.id, 1);
                    setLinkedItems(data);
                    setLoading(false);
                  }
                }
              : undefined
          }
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default LinkedItemsContainer;
