import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseTransactionListResponse,
  TransactionListResponse,
  amsV3Service
} from '../../services';

export interface IInvoiceTransactions<T> extends IAsyncType<T> {
  invoiceTransactions: T;
}

const useInvoiceTransactions = (
  invoiceId: number
): IInvoiceTransactions<TransactionListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseTransactionListResponse>(
      amsV3Service.getInvoiceTransactions,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [invoiceId],
      true
    );
  return {
    invoiceTransactions: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useInvoiceTransactions;
