import {
  AccessPermissions,
  CreateTransactionRequest,
  DeliveryDocumentResponse,
  DeliveryDocumentType,
  DeliveryDocumentTypes,
  DeliveryItemResponse,
  DeliveryResponse,
  SupplierResponse,
  TransactionListResponse,
  TransactionType,
  TreasuryListResponse,
  WarehouseResponse,
  amsV3Service,
  getTransactionName
} from '../../../../services';
import { Grid, Paper, Tooltip } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCompany, useFlag, usePermissions, useStyles, useTreasuries } from '../../../../helpers/hooks';

import AMSTable from '../../../../helpers/ui/AMSTable/AMSTable';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import AddTransactionDialog from '../../../OrderComponent/Panels/OrderInvoiceAndTransactionsPanel/Dialog/AddTransactionDialog';
import DateFnsUtils from '@date-io/date-fns';
import DeliveryDocumentDialog from './DeliveryDocumentDialog/DeliveryDocumentDialog';
import MoneyTwoToneIcon from '@material-ui/icons/MoneyTwoTone';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PaymentTwoToneIcon from '@material-ui/icons/PaymentTwoTone';

interface DeliveryDocumentAndTransactionsPanelProps {
  delivery: DeliveryResponse | null;
  deliveryItems: DeliveryItemResponse[];
  warehouse: WarehouseResponse;
  supplier: SupplierResponse;
  loadDelivery: () => void;
  loading: boolean;
  totalPrice: number;
}

const DeliveryDocumentAndTransactionsPanel = ({
  delivery,
  deliveryItems,
  warehouse,
  supplier,
  loadDelivery,
  loading,
  totalPrice
}: DeliveryDocumentAndTransactionsPanelProps) => {
  const classes = useStyles();
  const { company } = useCompany(warehouse.companyId);
  const { treasuries} = useTreasuries();

  const [deliveryDocument, setDeliveryDocument] = useState<DeliveryDocumentResponse | null>(null);
  const [openDialog, setOpenDialog] = useFlag(false);
  const [openDocumentViewDialog, setOpenDocumentViewDialog] = useFlag(false);
  const [documents, setDocuments] = useState<DeliveryDocumentResponse[]>([]);
  const [transactions, setTransactions] = useState<TransactionListResponse[]>([]);
  const [remainingAmount, setRemainingAmount] = useState<number>(0);

  const [canUpdate, canCreateTransaction, canDeleteTransaction] = usePermissions([
    AccessPermissions.CAN_UPDATE_DELIVERY,
    AccessPermissions.CAN_CREATE_TRANSACTION,
    AccessPermissions.CAN_DELETE_TRANSACTION
  ]);

  const loadData = useCallback(async () => { 
    if(delivery){
      const [documentsResp, remainingAmountResp] = await Promise.all([
        amsV3Service.getDeliveryDocuments(delivery.id),
        amsV3Service.getDeliveryRemainingAmount(delivery.id)
      ]);
      setDocuments(documentsResp.data?.data ?? []);
      setRemainingAmount(remainingAmountResp.data ? +remainingAmountResp.data : 0);
      
      const transactionsResp: any[] = await Promise.all((documentsResp.data?.data ?? []).map((document: DeliveryDocumentResponse) => amsV3Service.getTransactions(undefined, undefined, document.id)));
      setTransactions(transactionsResp.map((value: any) => value.data.data ?? []).flat());
    }
  }, [delivery]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const remainingAmountByDocument = useMemo(() => {
    const remainingAmountByDocument: any = {};
    documents.forEach((document: DeliveryDocumentResponse) => {
      remainingAmountByDocument[document.id] = document.invoiceTotal;
    });
    transactions.forEach((transaction: TransactionListResponse) => {
      if (transaction.deliveryInvoiceId) {
        remainingAmountByDocument[transaction.deliveryInvoiceId] -= +transaction.amount;
      }
    });
    return remainingAmountByDocument;
  }, [documents, transactions]);

  
  const treasuriesLookup = useMemo(
    () =>
      treasuries.reduce((res: any, treasury: TreasuryListResponse) => {
        if (!res) {
          res = {};
        }
        res[`${treasury.id}`] = treasury.name;
        return res;
      }, {}),
    [treasuries]
  );

  return delivery ? (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={1}>
        <Grid item md={6} sm={12}>
          <AMSTable
            title="Документи"
            isLoading={loading}
            data={documents}
            columns={[
              {
                title: '№',
                field: 'number',
                render: (deliveryDocument: DeliveryDocumentResponse) =>
                  deliveryDocument.invoiceNumber ? deliveryDocument.invoiceNumber : '-',
                cellStyle: { width: '15%' }
              },
              {
                title: 'Тип',
                field: 'type',
                render: (deliveryDocument: DeliveryDocumentResponse) =>
                  amsV3Service.getDeliveryDocumentTypeAlias(
                    deliveryDocument.documentType as DeliveryDocumentType
                  ),
                cellStyle: { width: '20%' }
              },
              {
                title: 'Падежна дата',
                field: 'paymentDue',
                type: 'date',
                cellStyle: { width: '15%' }
              },
              {
                title: 'Търговски документ №',
                field: 'tradeDocumentNumber',
                type: 'date',
                cellStyle: { width: '20%' }
              },
              {
                title: 'Плащане',
                field: 'paymentInfo',
                type: 'date',
                cellStyle: { width: '10%' }
              },
              {
                title: 'Сума',
                field: 'invoiceTotal',
                type: 'currency',
                currencySetting: {
                  locale: 'bg',
                  currencyCode: 'bgn',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                },
                cellStyle: { width: '20%' }
              }
            ]}
            onAdd={
              canUpdate
                ? () => {
                    setOpenDocumentViewDialog(true);
                    setDeliveryDocument(null);
                  }
                : undefined
            }
            onEdit={
              canUpdate
                ? (deliveryDocument: DeliveryDocumentResponse) => {
                    setOpenDocumentViewDialog(true);
                    setDeliveryDocument(deliveryDocument);
                  }
                : undefined
            }
            onDelete={
              canUpdate
                ? async (deliveryDocument: DeliveryDocumentResponse) => {
                    const deliveryResp = await amsV3Service.deleteDeliveryDocument(
                      deliveryDocument.id
                    );
                    if (deliveryResp && deliveryResp.data) {
                      loadDelivery();
                    }
                  }
                : undefined
            }
            paging={false}
            minBodyHeight="65vh"
            maxBodyHeight="65vh"
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <Paper elevation={2} className={classes.paper}>
            <AMSTable
              title="Списък с плащания"
              isLoading={loading}
              columns={[
                {
                  title: 'Тип',
                  field: 'transactionType',
                  render: (transaction: TransactionListResponse) => {
                    switch (transaction.transactionType) {
                      case TransactionType.BANK_ACCOUNT:
                        return (
                          <Tooltip
                            className={classes.income}
                            title={getTransactionName(TransactionType.BANK_ACCOUNT)}
                          >
                            <PaymentTwoToneIcon />
                          </Tooltip>
                        );
                      case TransactionType.CASH:
                        return (
                          <Tooltip
                            className={classes.income}
                            title={getTransactionName(TransactionType.CASH)}
                          >
                            <AccountBalanceWalletTwoToneIcon />
                          </Tooltip>
                        );
                      case TransactionType.EXPENSE_BANK_ACCOUNT:
                        return (
                          <Tooltip
                            className={classes.expense}
                            title={getTransactionName(TransactionType.EXPENSE_BANK_ACCOUNT)}
                          >
                            <AccountBalanceTwoToneIcon />
                          </Tooltip>
                        );
                      case TransactionType.EXPENSE_CASH:
                        return (
                          <Tooltip
                            className={classes.expense}
                            title={getTransactionName(TransactionType.EXPENSE_CASH)}
                          >
                            <MoneyTwoToneIcon />
                          </Tooltip>
                        );
                    }
                  },
                  cellStyle: { width: '10%' }
                },
                {
                  title: 'Дата / Час',
                  field: 'date',
                  render: (transaction: TransactionListResponse) =>
                    `${transaction.date.substr(0, 10)} ${transaction.date.substr(11, 8)}`,
                  cellStyle: { width: '20%' }
                },
                {
                  title: 'Каса',
                  field: 'treasuryId',
                  lookup: treasuriesLookup,
                  cellStyle: { width: '40%' }
                },
                {
                  title: 'Документ',
                  field: 'invoiceNumber',
                  render: (transaction: TransactionListResponse) =>
                    transaction.invoiceNumber ? transaction.invoiceNumber : '-',
                  cellStyle: { width: '20%' }
                },
                {
                  title: 'Сума',
                  field: 'amount',
                  cellStyle: { width: '10%' },
                  type: 'currency',
                  currencySetting: {
                    locale: 'bg',
                    currencyCode: 'bgn',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }
                }
              ]}
              components={{
                Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
              }}
              data={
                transactions.sort(
                      (i1: TransactionListResponse, i2: TransactionListResponse) => i1.id - i2.id
                    )
              }
              onAdd={
                canUpdate && canCreateTransaction
                  ? () => {
                      setDeliveryDocument(deliveryDocument);
                      setOpenDialog(true);
                    }
                  : undefined
              }
              onDelete={
                canUpdate && canDeleteTransaction
                  ? async (transaction: TransactionListResponse) => {
                      const resp = await amsV3Service.deleteTransaction(transaction.id);
                      if (resp) {
                        loadDelivery();
                      }
                    }
                  : undefined
              }
              paging={false}
              minBodyHeight="65vh"
              maxBodyHeight="65vh"
            />
          </Paper>
          {delivery && openDocumentViewDialog && (
            <DeliveryDocumentDialog
              open={openDocumentViewDialog}
              onClose={(deliveryDocument: DeliveryDocumentResponse | null) => {
                if (deliveryDocument) {
                  loadDelivery();
                }
                setOpenDocumentViewDialog(false);
              }}
              delivery={delivery}
              deliveryItems={deliveryItems}
              supplier={supplier}
              company={company!}
              deliveryDocument={deliveryDocument}
              documents={(deliveryDocument != null ? documents : [])
                .filter(
                  (doc: DeliveryDocumentResponse) =>
                    doc.documentType === DeliveryDocumentTypes.PROFORMA
                )
                .sort(
                  (do1: DeliveryDocumentResponse, do2: DeliveryDocumentResponse) => do1.id - do2.id
                )}
                totalPrice={totalPrice}
            />
          )}
          {delivery && openDialog && (
            <AddTransactionDialog
              isDeliveryTransaction={true}
              companyId={warehouse?.companyId}
              open={openDialog}
              defaultAmount={remainingAmount}
              documents={(documents != null ? documents : [])
                .map((t: DeliveryDocumentResponse) => ({
                  id: t.id,
                  value: `${t.invoiceNumber} ${amsV3Service.getDeliveryDocumentTypeAlias(
                    t.documentType as DeliveryDocumentType
                  )}`,
                  type: t.documentType,
                  remainingAmount: remainingAmountByDocument[t.id]
                }))}
              onClose={() => setOpenDialog(false)}
              onSave={async (transaction: CreateTransactionRequest) => {
                const resp = await amsV3Service.createTransaction(transaction);
                if (resp) {
                  setOpenDialog(false);
                  loadDelivery();
                }
              }}
            />
          )}
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  ) : (
    <></>
  );
};

export default DeliveryDocumentAndTransactionsPanel;
