import { SetNoConfigurationRequest, amsV3Service, snackbarService } from '../../../../../services';

import { AMSButton } from '../../../../../helpers/ui';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useFlag } from '../../../../../helpers/hooks';

interface ICASHIntegrationFormProps {
  partnerId: number;
}

const NoConfigurationForm = ({ partnerId }: ICASHIntegrationFormProps) => {
  const [actionLoading, setActionLoading] = useFlag(false);

  const handleOnClick = async () => {
    setActionLoading(true);
    const configurationRequest: SetNoConfigurationRequest = {
      requestType: 'set-no-configuration'
    };
    const resp = await amsV3Service.setPartnerIntegrationConfiguration(
      partnerId,
      configurationRequest
    );
    if (resp) {
      snackbarService.setSnackbarOpen(true);
    }
    setActionLoading(false);
  };
  return (
    <Grid container spacing={1}>
      <Grid item lg={12}></Grid>
      <Grid item lg={12} container justify="center">
        <AMSButton
          text={'Запиши'}
          variant="contained"
          color="primary"
          onClick={handleOnClick}
          loading={actionLoading}
        />
      </Grid>
    </Grid>
  );
};

export default NoConfigurationForm;
