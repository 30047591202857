import * as React from 'react';

import { Badge, Container, Link, Popover } from '@material-ui/core';
import { UserAccessResponse, accessManagementService, cookiesService } from '../../services';
import { useEffect, useMemo, useState } from 'react';

import { BrowserView } from 'react-device-detect';
import Copyright from '../../components/Copyright/Copyright';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import MenuContainer from './MenuContainer/MenuContainer';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import firebase from 'firebase/app';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useObservable } from '../../helpers/hooks';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplate: 'auto 1fr auto / auto 1fr',
    height: '100vh'
  },
  toolbar: {
    paddingRight: 4,
    paddingLeft: 4,
    gridColumn: '1 / 4',
    color: '#fff',
    backgroundColor: '#3f51b5',
    minHeight: 54
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 4px',
    ...theme.mixins.toolbar
  },
  leftToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10vw 0 0'
  },
  rightToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 4px'
  },
  drawer: {
    gridColumn: '1 / 2',
    backgroundColor: '#FAFAFA',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - ' + theme.mixins.toolbar.minHeight + 'px - 20px)'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  },
  content: {
    gridColumn: '2 / 3',
    backgroundColor: 'white',
    overflow: 'auto',
    maxHeight: 'calc(100vh - ' + theme.mixins.toolbar.minHeight + 'px - 20px)'
  },
  appBar: {
    top: 'auto',
    bottom: 0
  },
  grow: {
    flexGrow: 1
  },
  // TODO
  // additionalMenu: {
  //   gridColumn: '3 / 4',
  //   backgroundColor: '#FAFAFA'
  // },
  footer: {
    gridColumn: '1 / 4',
    backgroundColor: '#EFEFEF',
    maxHeight: 30
  },
  title: {
    flexGrow: 1
  },
  username: {
    paddingLeft: 16,
    paddingRight: 16
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: 20,
    margin: 0
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto'
  }
}));

interface MainContainerProps {
  children: any;
}

const pageLabelNew: any = {
  '/item': 'Нов артикул',
  '/partner': 'Нов контрагент',
  '/supplier': 'Нов доставчик',
  '/delivery': 'Нова доставка',
  '/order': 'Нова поръчка',
  '/invoice': 'Нова фактура',
  '/items': 'Артикули',
  '/item-categories': 'Артикулни групи',
  '/orders': 'Поръчки',
  '/b2b-orders': 'Поръчки B2B',
  '/b2c-orders': 'Поръчки B2C',
  '/internal-transfers': 'Вътрешни трансфери',
  '/legal-entities': 'Юридически лица',
  '/partners': 'Контрагенти',
  '/clients': 'Клиенти',
  '/suppliers': 'Доставчици',
  '/deliveries': 'Доставки',
  '/company-categories': 'Категории',
  '/pricing-policies': 'Ценови политики',
  '/pricing-demo': 'Ценообразуване',
  '/finance-dashboard': 'Финанси',
  '/warehouses': 'Складове',
  '/companies': 'Компании',
  '/payment-options': 'Начини на плащане',
  '/reports': 'Справки',
  '/reports-invoice': 'Справки, Фактури',
  '/reports-delivery-invoice': 'Справки, Фактури по доставки',
  '/reports-stock': 'Справки, Наличности',
  '/reports-order-quantities': 'Справки, Поръчки',
  '/reports-order-balance': 'Справки, Приходи по поръчки'
};

const pageLabelEdit: any = {
  '/item': 'Редактиране на артикул',
  '/partner': 'Редактиране на контрагент',
  '/supplier': 'Редактиране на доставчик',
  '/delivery': 'Преглед на доставка',
  '/order': 'Редактиране на поръчка',
  '/legal-entity': 'Редактиране на юридическо лице',
  '/payment-option': 'Редактиране на начин на плащане',
  '/pricing-policy': 'Редактиране на ценова политика',
  '/warehouse': 'Редактиране на склад',
  '/company': 'Редактиране на компания'
};

const MainContainer = (props: MainContainerProps) => {
  const classes = useStyles();
  const [header, setHeader] = useState('');
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(cookiesService.isSideDrawerExpanded());

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  history.listen((location) => {
    if (location.search?.length > 0 && location.search.includes('id=')) {
      setHeader(pageLabelEdit[history.location.pathname] ?? 'AMS');
    } else {
      setHeader(pageLabelNew[history.location.pathname] ?? 'AMS');
    }
  });

  useEffect(() => {
    if (history.location.search?.length > 0 && history.location.search.includes('id')) {
      setHeader(pageLabelEdit[history.location.pathname] ?? 'AMS');
    } else {
      setHeader(pageLabelNew[history.location.pathname] ?? 'AMS');
    }
  }, [history.location.pathname, history.location.search]);

  const handleDrawerToggle = () => {
    setOpen(!open);
    cookiesService.toggleSideDrawer();
  };

  const currentUser: UserAccessResponse | undefined = useObservable(
    accessManagementService.user
  ).user;

  const isTodayVessyBirthday = useMemo(() => new Date().toISOString().indexOf('10-21') === 5, []);

  const shouldShowNotifications = useMemo(
    () => currentUser?.id === 1 && isTodayVessyBirthday,
    [currentUser, isTodayVessyBirthday]
  );

  useEffect(() => {
    if (header !== 'VESSY' && shouldShowNotifications) {
      setHeader('VESSY');
    }
  }, [header, shouldShowNotifications]);

  const navigateHome = () => history.push('/');

  const logOut = async () => {
    await firebase.auth().signOut();
    accessManagementService.logout();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar id="mainContainerToolbar" className={classes.toolbar}>
        <div className={classes.leftToolbar}>
          <IconButton color="inherit" onClick={navigateHome}>
            <HomeIcon />
          </IconButton>
        </div>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          {header}
        </Typography>
        <div className={classes.rightToolbar}>
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <BrowserView>
            {currentUser && (
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.username}
              >
                {`${currentUser.firstName} ${currentUser.lastName}`}
              </Typography>
            )}
          </BrowserView>
          {shouldShowNotifications && (
            <IconButton aria-describedby={id} color="inherit" onClick={handleClick}>
              <Badge badgeContent={1} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
          <Popover
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <div style={{ padding: 20 }}>
              <Link
                target="_blank"
                rel="noopener"
                href="https://www.youtube.com/watch?v=6GTVDP4Lxps"
                variant="body1"
              >
                Честит Рожден Ден!
              </Link>
            </div>
          </Popover>
          <IconButton color="inherit" onClick={logOut}>
            <ExitToAppIcon />
          </IconButton>
        </div>
      </Toolbar>
      <Drawer
        id="mainContainerDrawer"
        variant="permanent"
        classes={{
          paper: clsx(classes.drawer, classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <MenuContainer
          open={open}
          handleDrawerToggle={handleDrawerToggle}
          toolbarIcon={classes.toolbarIcon}
        />
      </Drawer>
      <div className={classes.content}>
        {/* <div className={classes.appBarSpacer} /> */}
        <Container maxWidth="xl" className={classes.container}>
          {props.children}
        </Container>
      </div>
      {/* <div className={classes.additionalMenu}>ADDITIONAL MENU</div> */}
      <div id="mainContainerFooter" className={classes.footer}>
        <Copyright />
      </div>
    </div>
  );
};

export default MainContainer;
