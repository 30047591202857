import { Dispatch, SetStateAction, useState } from "react";

import Lookup from "../../models/lookup";

const useLookup = (initialValue: Lookup | null = null): [Lookup | null, Dispatch<SetStateAction<Lookup | null>>] => {
  const [lookup, setLookup] = useState<Lookup | null>(initialValue);

  return [lookup, setLookup];
}

export default useLookup;