interface CountryInterface {
  name: string;
  alpha2: string;
  alpha3: string;
}

export default class Country implements CountryInterface {
  constructor(name: string, alpha2: string, alpha3: string) {
    this.name = name;
    this.alpha2 = alpha2;
    this.alpha3 = alpha3;
  }
  name: string;
  alpha2: string;
  alpha3: string;
}
