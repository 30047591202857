import { AMSAutocomplete, AMSButton, AMSSnackBar } from '../../../../../helpers/ui';
import { Checkbox, Grid, TextField } from '@material-ui/core';
import { OrderingPolicyResponse, SetOrderingPolicyRequest } from '../../../../../services/api';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { WEEKDAYS, Weekday, isValidDate, toHHMM } from '../../../../../helpers/date-helper';
import { useFlag, useStyles } from '../../../../../helpers/hooks';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { KeyboardTimePicker } from '@material-ui/pickers';
import OutlinedDiv from '../../../../../helpers/ui/OutlinedDiv';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface OrderCreationPolicyFormProps {
  id: number;
  onLoad: (id: number) => Promise<OrderingPolicyResponse>;
  onSave: (id: number, request: SetOrderingPolicyRequest) => Promise<OrderingPolicyResponse | null>;
}

const OrderCreationPolicyForm = ({ id, onLoad, onSave }: OrderCreationPolicyFormProps) => {
  const classes = useStyles();
  const [allowedWeekdays, setAllowedWeekdays] = useState<any[]>([]);
  const [cutoffTime, setCutoffTime] = useState<Date | null>(new Date('2014-08-18T16:00:00'));
  const [actionLoading, setActionLoading] = useFlag(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleDateChange = useCallback((date: Date | null) => {
    setCutoffTime(date);
  }, []);

  const loadOrderingPolicy = useCallback(async () => {
    const response = await onLoad(id);
    setAllowedWeekdays(
      response.allowedWeekdays
        .map((w) => WEEKDAYS.find((d) => d.key === w)!)
        .map((w: Weekday) => ({
          id: w.id,
          value: w.label,
          key: w.key
        }))
    );
    setCutoffTime(new Date(`2014-08-18T${response.nextDayDeliveryCutoff}`));
  }, [id, onLoad]);

  useEffect(() => {
    loadOrderingPolicy();
  }, [loadOrderingPolicy]);

  const handleOnClick = useCallback(async () => {
    setActionLoading(true);
    const request: SetOrderingPolicyRequest = {
      allowedWeekdays: allowedWeekdays.map((w) => w.key).join(','),
      nextDayDeliveryCutoff: toHHMM(cutoffTime!)
    };
    try {
      await onSave(id, request);
      setSnackBarMessage('Политиката за поръчки е запазена успешно');
      setSnackBarSeverity('success');
    } catch (e) {
      setSnackBarMessage('Грешка при запазване на политиката за поръчки');
      setSnackBarSeverity('error');
    } finally {
      setOpenSnackBar(true);
      setActionLoading(false);
    }
  }, [allowedWeekdays, cutoffTime, id, onSave, setActionLoading]);

  const isValidCuttoffTime = useMemo(() => !!cutoffTime && isValidDate(cutoffTime), [cutoffTime]);

  const isValid = useMemo(
    () => allowedWeekdays.length && isValidCuttoffTime,
    [allowedWeekdays.length, isValidCuttoffTime]
  );

  return (
    <Grid container spacing={1}>
      <Grid item lg={12}>
        <OutlinedDiv label={`Поръчки`}>
          <Grid container spacing={1}>
            <Grid item lg={9} md={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={7}
                sortOptions={false}
                options={WEEKDAYS.map((w: Weekday) => ({
                  id: w.id,
                  value: w.label,
                  key: w.key
                }))}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Доставки за дни от седмицата"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={allowedWeekdays}
                onChange={(values: any[]) => setAllowedWeekdays(values.sort((a, b) => a.id - b.id))}
              />
            </Grid>
            <Grid item lg={3} md={12}>
              <KeyboardTimePicker
                margin="dense"
                label="Час"
                value={cutoffTime}
                onChange={handleDateChange}
                keyboardIcon={<AccessTimeIcon fontSize="small" />}
                ampm={false}
                inputVariant="outlined"
                minutesStep={30}
                error={!isValidCuttoffTime}
                helperText={!isValidCuttoffTime ? 'Невалиден час' : ''}
              />
            </Grid>
            <Grid item lg={12} container justify="center">
              <AMSButton
                text="Запиши"
                variant="contained"
                color="primary"
                onClick={handleOnClick}
                disabled={!isValid}
                loading={actionLoading}
              />
            </Grid>
          </Grid>
        </OutlinedDiv>
      </Grid>
      <AMSSnackBar
        open={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSeverity}
        onClose={() => setOpenSnackBar(false)}
      />
    </Grid>
  );
};

export default OrderCreationPolicyForm;
