import Currency from '../models/currency';

export const currenciesData = [
  new Currency('Български лев', 'BGN'),
  new Currency('Евро', 'EUR'),
  new Currency('Щатски долар', 'USD')
];

export const findCurrency = (alpha3: string) =>
  currenciesData.find((c: Currency) => c.alpha3 === alpha3);
