import { AMSButton, AMSTextField } from '../../helpers/ui';
import {
  AccessPermissions,
  SupplierListResponse,
  SupplierResponse,
  cookiesService
} from '../../services';
import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useFlag, usePermissions, useStyles, useSuppliers } from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import SearchIcon from '@material-ui/icons/Search';
import { getExtendedSupplierId } from '../../helpers/utils';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const SuppliersComponent = ({ location }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { showInactive } = useMemo(() => parseFilter(location.search), [location.search]);
  const filter = useMemo(() => cookiesService.getSuppliersList(), []);
  const [filterShowInactive, setFilterShowInactive] = useFlag(
    showInactive !== undefined && showInactive !== null
      ? showInactive
      : (filter.filterShowInactive ?? false)
  );
  const [text, setText] = useState(filter.query ? filter.query : undefined);

  const { suppliers, setParameters, loading } = useSuppliers([], text, filterShowInactive);

  const suppliersWithContactInfo = useMemo(
    () =>
      suppliers
        ? suppliers.map((sup: SupplierListResponse) => ({
            ...sup,
            contactInfo:
              sup.contacts && sup.contacts.length > 0
                ? `${sup.contacts[0].name}, ${sup.contacts[0].phone ?? ''}, ${
                    sup.contacts[0].email ?? ''
                  }`
                : undefined
          }))
        : [],
    [suppliers]
  );

  const [canCreate] = usePermissions([AccessPermissions.CAN_CREATE_SUPPLIER]);

  const onSearch = useCallback(() => {
    cookiesService.setSuppliersList({
      query: text,
      filterShowInactive
    });
    let params: any = {};
    if (text) {
      params.search = text;
    }
    if (filterShowInactive) {
      params.showInactive = filterShowInactive;
    }
    history.push({
      pathname: '/suppliers',
      search: new URLSearchParams(params).toString()
    });
    setParameters([[], text, filterShowInactive]);
  }, [text, filterShowInactive, history, setParameters]);

  const handleAdd = useCallback(() => history.push(`/supplier`), [history]);

  return (
    <Container maxWidth="xl" className={classes.flex}>
      <Grid container spacing={1}>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <AMSTextField
            label="Търсене"
            placeholder="Търсене по име / адрес / категория"
            value={text}
            onEnter={onSearch}
            onChange={(value) => setText(value ?? '')}
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: loading ? <CircularProgress /> : null
            }}
          />
        </Grid>
        <Grid item container lg={2} md={4} sm={6} xs={12} alignContent="flex-start">
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                checked={filterShowInactive}
                onChange={() => setFilterShowInactive(!showInactive)}
              />
            }
            label="Покажи неактивни"
            labelPlacement="end"
          />
        </Grid>
        <Grid item lg={1} md={2} sm={12} xs={12}>
          <AMSButton
            color="primary"
            variant="contained"
            text="Търсене"
            loading={loading}
            disabled={false}
            onClick={onSearch}
            style={{
              marginTop: 8
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AMSTable
            title="Доставчици"
            columns={[
              {
                title: 'ИД',
                field: 'id',
                render: (rowData: SupplierResponse) => (
                  <AMSLink href={`/supplier?id=${rowData.id}`}>
                    {getExtendedSupplierId(rowData.id)}
                  </AMSLink>
                ),
                cellStyle: { width: '5%' }
              },
              {
                title: 'Име',
                field: 'name',
                render: (rowData: SupplierResponse) => (
                  <AMSLink href={`/supplier?id=${rowData.id}`}>{rowData.name}</AMSLink>
                ),
                cellStyle: { width: '20%' }
              },
              {
                title: 'Контакт',
                field: 'contactInfo',
                render: (rowData: SupplierResponse) => {
                  if (rowData.contacts) {
                    if (rowData.contacts.length > 0) {
                      const contact = rowData.contacts[0];
                      return (
                        <Grid container>
                          <Grid item lg={12} md={12}>
                            <Typography variant="body2">
                              {contact.phone ? `${contact.name}, ${contact.phone}` : contact.name}
                            </Typography>
                          </Grid>
                          <Grid item lg={12} md={12}>
                            {contact.email ? (
                              <AMSLink href={`mailto:${contact.email}`}>{contact.email}</AMSLink>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                      );
                    }
                  }
                  return '-';
                },
                cellStyle: { width: '25%' }
              },
              {
                title: 'Дни за доставка',
                field: 'daysForDelivery',
                cellStyle: { width: '10%' }
              },
              {
                title: 'Забележка',
                field: 'note',
                cellStyle: { width: '40%' }
              }
            ]}
            data={suppliersWithContactInfo}
            onAdd={canCreate ? handleAdd : undefined}
            isLoading={loading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SuppliersComponent;
