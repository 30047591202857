import React, { useEffect, useState } from 'react';

import { AMSTextField } from '../../../helpers/ui';
import { Grid } from '@material-ui/core';

interface ImportSetupFormComponentProps {
  keys: string[];
  setMapping: (mapping: any) => void;
}

const ImportItemSetupFormComponent = ({ keys, setMapping }: ImportSetupFormComponentProps) => {
  const [name, setName] = useState<string>();
  const [artNo, setArtNo] = useState<string>();
  const [barcode, setBarcode] = useState<string>();
  const [itemCategoryId, setItemCategoryId] = useState<string>();
  const [supplierId, setSupplierId] = useState<string>();
  const [basePrice, setBasePrice] = useState<string>();
  const [costPrice, setCostPrice] = useState<string>();
  const [currencyId, setCurrencyId] = useState<string>();
  const [vat, setVat] = useState<string>();
  const [packageQty, setPackageQty] = useState<string>();
  const [packageQtyDelivery, setPackageQtyDelivery] = useState<string>();
  const [unitId, setUnitId] = useState<string>();

  useEffect(() => {
    if (keys.length > 0) {
      setName(keys[0]);
    }
    if (keys.length > 1) {
      setArtNo(keys[1]);
    }
    if (keys.length > 2) {
      setBarcode(keys[2]);
    }
    if (keys.length > 3) {
      setItemCategoryId(keys[3]);
    }
    if (keys.length > 4) {
      setSupplierId(keys[4]);
    }
    if (keys.length > 5) {
      setBasePrice(keys[5]);
    }
    if (keys.length > 6) {
      setCostPrice(keys[6]);
    }
    if (keys.length > 7) {
      setCurrencyId(keys[7]);
    }
    if (keys.length > 8) {
      setVat(keys[8]);
    }
    if (keys.length > 9) {
      setPackageQty(keys[9]);
    }
    if (keys.length > 10) {
      setPackageQtyDelivery(keys[10]);
    }
    if (keys.length > 11) {
      setUnitId(keys[11]);
    }
  }, [keys]);

  useEffect(() => {
    setMapping({
      name,
      artNo,
      barcode,
      itemCategoryId,
      supplierId,
      basePrice,
      costPrice,
      currencyId,
      vat,
      packageQty,
      packageQtyDelivery,
      unitId
    });
  }, [
    setMapping,
    name,
    artNo,
    barcode,
    itemCategoryId,
    supplierId,
    basePrice,
    costPrice,
    currencyId,
    vat,
    packageQty,
    packageQtyDelivery,
    unitId
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <AMSTextField label="name" value={name ? name : ''} onChange={(value) => setName(value)} />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="artNo"
          value={artNo ? artNo : ''}
          onChange={(value) => setArtNo(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="barcode"
          value={barcode ? barcode : ''}
          onChange={(value) => setBarcode(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="itemCategoryId"
          value={itemCategoryId ? itemCategoryId : ''}
          onChange={(value) => setItemCategoryId(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="supplierId"
          value={supplierId ? supplierId : ''}
          onChange={(value) => setSupplierId(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="basePrice"
          value={basePrice ? basePrice : ''}
          onChange={(value) => setBasePrice(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="costPrice"
          value={costPrice ? costPrice : ''}
          onChange={(value) => setCostPrice(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="currencyId"
          value={currencyId ? currencyId : ''}
          onChange={(value) => setCurrencyId(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField label="vat" value={vat ? vat : ''} onChange={(value) => setVat(value)} />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="packageQty"
          value={packageQty ? packageQty : ''}
          onChange={(value) => setPackageQty(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="packageQtyDelivery"
          value={packageQtyDelivery ? packageQtyDelivery : ''}
          onChange={(value) => setPackageQtyDelivery(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AMSTextField
          label="unitId"
          value={unitId ? unitId : ''}
          onChange={(value) => setUnitId(value)}
        />
      </Grid>
    </Grid>
  );
};

export default ImportItemSetupFormComponent;
