import { AMSCountrySelector, AMSDatePicker, AMSTextField } from '../../../../helpers/ui';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  DeliveryRequestStatus,
  ItemListResponse,
  StockReceivingProcessItemResponse,
  amsV3Service
} from '../../../../services';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';

import Country from '../../../../models/country';
import { findCountry } from '../../../../helpers/country-helper';
import { toDateString } from '../../../../helpers/date-helper';

interface EditStockReceivingProcessTableComponentProps {
  stockReceivingItems: StockReceivingProcessItemResponse[];
  status: DeliveryRequestStatus;
}

const EditStockReceivingProcessTableComponent = ({
  stockReceivingItems,
  status
}: EditStockReceivingProcessTableComponentProps) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ItemListResponse[]>([]);

  const loadData = useCallback(async () => {
    setLoading(true);
    if (stockReceivingItems) {
      const itemsIds = stockReceivingItems.map(
        (si: StockReceivingProcessItemResponse) => si.itemId
      );
      const itemsResp = await amsV3Service.getItems(itemsIds);
      setItems(itemsResp.data.data);
    }
    setLoading(false);
  }, [stockReceivingItems]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const isFinalized = useMemo(
    () =>
      status === DeliveryRequestStatus.DELIVERED ||
      status === DeliveryRequestStatus.DECLINED_AFTER_VERIFICATION,
    [status]
  );
  const forInspection = useMemo(() => status === DeliveryRequestStatus.FOR_INSPECTION, [status]);
  const isInspectedWithRemarks = useMemo(
    () => status === DeliveryRequestStatus.INSPECTED_WITH_REMARKS,
    [status]
  );
  const isInspectionInProgress = useMemo(
    () => status === DeliveryRequestStatus.INSPECTION_IN_PROGRESS,
    [status]
  );
  const cellStyle: CSSProperties = useMemo(
    () => ({
      width: '10%',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 15,
      paddingRight: 15
    }),
    []
  );
  const wideCellStyle = useMemo(() => ({ ...cellStyle, width: '20%' }), [cellStyle]);

  const itemArtNoLookup = useMemo(
    () =>
      items.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item.artNo;
        return res;
      }, {}),
    [items]
  );

  const itemNamesLookup = useMemo(
    () =>
      items.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item.name;
        return res;
      }, {}),
    [items]
  );

  const itemShouldTrackBatchesLookup = useMemo(
    () =>
      items.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item.tracksBatches;
        return res;
      }, {}),
    [items]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={wideCellStyle}>Артикул</TableCell>
          <TableCell style={cellStyle} align="right">
            Партида
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Срок на годност
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Държава
          </TableCell>
          <TableCell style={cellStyle} align="right">
            По документ
          </TableCell>
          {(isInspectionInProgress || isFinalized || isInspectedWithRemarks) && (
            <TableCell style={cellStyle} align="right">
              Преброено
            </TableCell>
          )}
          {(isInspectedWithRemarks || isFinalized) && (
            <TableCell style={cellStyle} align="right">
              Потвърдено
            </TableCell>
          )}
          {(isInspectedWithRemarks || isFinalized) && (
            <TableCell style={cellStyle} align="right">
              Заприходено
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell colSpan={8} align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        ) : (
          stockReceivingItems.map((stockReceivingItem: StockReceivingProcessItemResponse) => (
            <TableRow key={stockReceivingItem.id}>
              <TableCell style={wideCellStyle}>
                <Typography variant="body2">
                  {itemArtNoLookup[stockReceivingItem.itemId]},{' '}
                  {itemNamesLookup[stockReceivingItem.itemId]}
                </Typography>
              </TableCell>
              {!isFinalized && (
                <>
                  <TableCell style={cellStyle}>
                    {itemShouldTrackBatchesLookup[stockReceivingItem.itemId] && (
                      <AMSTextField
                        label=""
                        defaultValue={stockReceivingItem.batchNumber}
                        onChange={(text?: string) => {
                          stockReceivingItem.batchNumber = text ?? '';
                        }}
                        disabled={!forInspection}
                      />
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {itemShouldTrackBatchesLookup[stockReceivingItem.itemId] && (
                      <AMSDatePicker
                        label=""
                        value={
                          stockReceivingItem.expirationDate
                            ? new Date(stockReceivingItem.expirationDate)
                            : null
                        }
                        onChange={(date: Date | null) => {
                          stockReceivingItem.expirationDate = date ? toDateString(date) : '';
                        }}
                        disabled={!forInspection}
                      />
                    )}
                  </TableCell>
                  <TableCell style={wideCellStyle}>
                    {itemShouldTrackBatchesLookup[stockReceivingItem.itemId] && (
                      <AMSCountrySelector
                        defaultValue={
                          stockReceivingItem.countryOfOrigin
                            ? findCountry(stockReceivingItem.countryOfOrigin)
                            : null
                        }
                        onChange={(country: Country | null) => {
                          stockReceivingItem.countryOfOrigin = country?.alpha3 ?? '';
                        }}
                        placeholder={`по подр. България`}
                        disabled={!forInspection}
                      />
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    <AMSTextField
                      label=""
                      defaultValue={stockReceivingItem.documentQuantity}
                      onChange={(text?: string) => {
                        const cleanValue = text && text.replace(',', '.');
                        stockReceivingItem.documentQuantity = (
                          cleanValue && !isNaN(+cleanValue) ? +cleanValue : 0
                        ).toFixed(3);
                      }}
                      disabled={!forInspection}
                    />
                  </TableCell>
                  {(isInspectionInProgress || isInspectedWithRemarks) && (
                    <TableCell style={cellStyle}>
                      <Typography variant="body2" align="right">
                        {stockReceivingItem.countedQuantity}
                      </Typography>
                    </TableCell>
                  )}
                  {isInspectedWithRemarks && (
                    <TableCell style={cellStyle}>
                      <AMSTextField
                        label=""
                        defaultValue={stockReceivingItem.verifiedQuantity}
                        onChange={(text?: string) => {
                          const cleanValue = text && text.replace(',', '.');
                          stockReceivingItem.verifiedQuantity = (
                            cleanValue && !isNaN(+cleanValue) ? +cleanValue : 0
                          ).toFixed(3);
                        }}
                        placeholder={`по подр. ${stockReceivingItem.documentQuantity}`}
                      />
                    </TableCell>
                  )}
                </>
              )}
              {isFinalized && (
                <>
                  <TableCell style={cellStyle}>
                    <Typography variant="body2" align="right">
                      {itemShouldTrackBatchesLookup[stockReceivingItem.itemId]
                        ? stockReceivingItem.batchNumber
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell style={cellStyle}>
                    <Typography variant="body2" align="right">
                      {itemShouldTrackBatchesLookup[stockReceivingItem.itemId]
                        ? stockReceivingItem.expirationDate
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell style={wideCellStyle} align="right">
                    <Typography variant="body2">
                      {itemShouldTrackBatchesLookup[stockReceivingItem.itemId]
                        ? findCountry(stockReceivingItem.countryOfOrigin)?.name
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell style={cellStyle} align="right">
                    <Typography variant="body2">{stockReceivingItem.documentQuantity}</Typography>
                  </TableCell>
                  <TableCell style={cellStyle} align="right">
                    <Typography variant="body2">{stockReceivingItem.countedQuantity}</Typography>
                  </TableCell>
                  <TableCell style={cellStyle} align="right">
                    <Typography variant="body2">{stockReceivingItem.verifiedQuantity}</Typography>
                  </TableCell>
                  <TableCell style={cellStyle} align="right">
                    <Typography variant="body2">{stockReceivingItem.finalQuantity}</Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default EditStockReceivingProcessTableComponent;
