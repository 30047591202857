import {
  Checkbox,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import Category, { CategoryForm } from '../../models/category';
import { getExtendedCategoryId } from '../../helpers/utils';
import AMSAutocomplete from '../../helpers/ui/AMSAutocomplete/AMSAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    }
  })
);

interface CategoryDialogProps {
  open: boolean;
  category?: CategoryForm;
  categories: Category[];
  parent: Category | null;
  onClose: () => void;
  onSave: (cat: CategoryForm) => void;
  onDelete: (cat: Category) => void;
}

const CategoryDialog = ({
  open,
  category,
  categories,
  parent,
  onClose,
  onSave
}: CategoryDialogProps) => {
  const [visibleCategories, setVisibleCategories] = useState<Category[]>([]);
  const [checked, setChecked] = useState<number[]>([]);
  const [name, setName] = useState('');
  const [selectedParent, setSelectedParent] = useState<Category | null>(parent);

  const classes = useStyles();

  useEffect(() => {
    setName(category && category.name ? category.name : '');
    // TODO Review category removal process when possible
    // setVisibleCategories(category && category.children ? category.children : []);
    setChecked([]);
  }, [open, category]);

  const handleToggle = (value?: number) => () => {
    if (value) {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    }
  };

  const handleDelete = () => {
    setVisibleCategories(
      visibleCategories.filter((c: Category) => (c.id ? !checked.includes(c.id) : false))
    );
  };

  const categorySearch = (
    <AMSAutocomplete
      label="Родителска категория"
      options={categories.map((c) => ({
        id: c.id,
        value: `${getExtendedCategoryId(c.id)} ${c.name}`
      }))}
      value={
        parent && parent.id
          ? {
              id: parent.id,
              value: `${getExtendedCategoryId(parent.id)} ${parent.name}`
            }
          : ''
      }
      onChange={(selectedValue: Category | null) => {
        setSelectedParent(selectedValue);
      }}
      minChar={0}
    />
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        {category && category.id ? category.name : 'Категория'}
      </DialogTitle>
      <DialogContent>
        {categorySearch}
        <TextField
          autoFocus
          margin="dense"
          label="Име"
          fullWidth
          variant="outlined"
          defaultValue={name}
          onChange={(event) => setName(event.target.value)}
        />
        {visibleCategories && (
          <List className={classes.root}>
            {visibleCategories.map((child: Category) => {
              const labelId = `checkbox-list-label-${child.id}`;
              return (
                <ListItem
                  key={child.id}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(child.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={child.id ? checked.indexOf(child.id) !== -1 : false}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${child.name}`} />
                </ListItem>
              );
            })}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        {category && checked.length > 0 && (
          <Button
            onClick={handleDelete}
            disabled={checked.length === 0}
            variant="contained"
            color="default"
          >
            Изтриване
          </Button>
        )}
        <Button onClick={onClose} variant="contained" color="secondary">
          Отказ
        </Button>
        <Button
          onClick={() => {
            let parentId = selectedParent?.id;
            if (parent) {
              parentId = parent.id;
            }
            onSave({
              id: category ? category.id : undefined,
              name: name ? name : category ? category.name : '',
              parentId,
              children: visibleCategories
            });
          }}
          variant="contained"
          color="primary"
        >
          Запиши
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryDialog;
