import React, { useState } from 'react';

import AMSCountryAutocomplete from '../AMSCountryAutocomplete/AMSCountryAutocomplete';
import Country from '../../../models/country';

interface AMSCountrySelectorProps {
  onChange: (country: Country | null) => void;
  required?: boolean;
  placeholder?: string;
  defaultValue?: Country | null;
  disabled?: boolean;
}

const AMSCountrySelector = ({
  onChange,
  required = false,
  placeholder,
  defaultValue = null,
  disabled = false
}: AMSCountrySelectorProps) => {
  const [country, setCountry] = useState<Country | null>(defaultValue);
  return (
    <AMSCountryAutocomplete
      country={country}
      onChange={(_, value: any) => {
        setCountry(value);
        onChange(value);
      }}
      required={required}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default AMSCountrySelector;
