import { InputBaseComponentProps, OutlinedInputProps, TextField } from '@material-ui/core';

import React from 'react';

export interface AMSTextFieldProps {
  type?: string;
  label: string;
  value?: string;
  defaultValue?: string;
  onChange?: (text?: string) => void;
  onEnter?: (event: any) => void;
  onBlur?: () => void;
  multiline?: boolean;
  rows?: string | number;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  InputProps?: Partial<OutlinedInputProps> | undefined;
  inputProps?: InputBaseComponentProps | undefined;
}

const AMSTextField = ({
  type = 'text',
  label,
  value,
  defaultValue,
  onChange,
  onEnter,
  onBlur,
  multiline,
  rows,
  placeholder,
  disabled = false,
  required = false,
  error = false,
  helperText,
  InputProps,
  inputProps
}: AMSTextFieldProps) => {
  return (
    <TextField
      type={type}
      label={label}
      margin="dense"
      variant="outlined"
      fullWidth
      value={value}
      defaultValue={defaultValue}
      onChange={(event) => (onChange ? onChange(event?.target.value) : null)}
      onKeyPress={
        onEnter
          ? (ev) => {
              if (ev.key === 'Enter') {
                onEnter(ev);
              }
            }
          : undefined
      }
      onBlur={onBlur}
      multiline={multiline}
      rows={rows}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      error={error}
      helperText={helperText}
      InputProps={InputProps}
      inputProps={inputProps}
    />
  );
};

export default AMSTextField;
