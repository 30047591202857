import { AMSPdfViewerDialog, AMSTable } from '../../helpers/ui';
import { AccessPermissions, InvoiceListResponse, LegalEntityListResponse } from '../../services';
import React, { CSSProperties, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useFlag, usePermissions } from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import { Description } from '@material-ui/icons';
import { getInvoiceUrl } from '../../helpers/utils';
import { useHistory } from 'react-router-dom';

interface InvoicesTableComponentProps {
  invoices: InvoiceListResponse[];
  setInvoices: Dispatch<SetStateAction<InvoiceListResponse[]>>;
  legalEntities: LegalEntityListResponse[];
  loading?: boolean;
  minBodyHeight?: string;
  maxBodyHeight?: string;
}

const InvoicesTableComponent = ({
  invoices,
  setInvoices,
  legalEntities,
  loading = false,
  minBodyHeight = '65vh',
  maxBodyHeight = '65vh'
}: InvoicesTableComponentProps) => {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [openInvoicePreview, setOpenInvoicePreview] = useFlag(false);
  const [canCreateInvoice, canReadInvoice] = usePermissions([
    AccessPermissions.CAN_CREATE_INVOICE,
    AccessPermissions.CAN_READ_INVOICE
  ]);

  const legalEntitiesMap = useMemo(
    () =>
      legalEntities.reduce((res: any, le: LegalEntityListResponse) => {
        res[le.name] = le.id;
        return res;
      }, {}),
    [legalEntities]
  );

  return (
    <>
      <AMSTable
        title="Фактури"
        isLoading={loading}
        columns={[
          {
            title: 'Създадена на',
            field: 'createdAt',
            type: 'date',
            cellStyle: { width: '6%' }
          },
          {
            title: 'Номер',
            field: 'number',
            type: 'string',
            cellStyle: { width: '7%' },
            render: canReadInvoice
              ? (invoice: InvoiceListResponse) => (
                  <AMSLink href={`/invoice?id=${invoice.id}`}>{invoice.number}</AMSLink>
                )
              : undefined
          },
          {
            title: 'Плащане до',
            field: 'paymentDue',
            cellStyle: { width: '7%' },
            align: 'right'
          },
          {
            title: 'Юридическо лице',
            field: 'receiverName',
            cellStyle: { width: '14%' },
            render: (invoice: InvoiceListResponse) =>
              legalEntitiesMap[invoice.receiverName] ? (
                <AMSLink href={`/legal-entity?id=${legalEntitiesMap[invoice.receiverName]}`}>
                  {invoice.receiverName}
                </AMSLink>
              ) : (
                invoice.receiverName
              )
          },
          {
            title: 'Контрагент',
            field: 'receiverPartnerName',
            cellStyle: { width: '14%' }
          },
          {
            title: 'Компания',
            field: 'companyName',
            cellStyle: { width: '14%' }
          },
          {
            title: 'Сума без ДДС',
            field: 'netTotal',
            cellStyle: { width: '7%' },
            align: 'right',
            type: 'currency',
            currencySetting: {
              locale: 'bg',
              currencyCode: 'bgn',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          },
          {
            title: 'ДДС',
            field: 'vatTotal',
            cellStyle: { width: '7%' },
            align: 'right',
            type: 'currency',
            currencySetting: {
              locale: 'bg',
              currencyCode: 'bgn',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          },
          {
            title: 'Сума с ДДС',
            field: 'totalIncludingVat',
            cellStyle: { width: '7%' },
            align: 'right',
            type: 'currency',
            currencySetting: {
              locale: 'bg',
              currencyCode: 'bgn',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          },
          {
            title: 'Начин на плащане',
            field: 'paymentOption',
            cellStyle: { width: '13%' },
            align: 'right'
          },
          {
            title: 'За плащане',
            field: 'remainingAmount',
            cellStyle: { width: '4%' },
            align: 'right',
            type: 'currency',
            currencySetting: {
              locale: 'bg',
              currencyCode: 'bgn',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          }
        ]}
        rowStyle={(rowData: InvoiceListResponse) => {
          const style: CSSProperties = {};
          style.backgroundColor = '#fff68f60';
          if (rowData.isPaidInFull) {
            style.backgroundColor = '#D9FFEC60';
          }
          if (rowData.isCancelled) {
            style.backgroundColor = '#FAB1C460';
            style.textDecoration = 'line-through';
          }
          return style;
        }}
        paging={false}
        overflowY="scroll"
        minBodyHeight={minBodyHeight}
        maxBodyHeight={maxBodyHeight}
        data={invoices}
        onAdd={canCreateInvoice ? () => history.push('/invoice') : undefined}
        customAction={
          canReadInvoice
            ? {
                icon: () => (
                  <Description
                    style={{
                      color: '#252525 !important'
                    }}
                  />
                ),
                tooltip: 'Преглед на фактура',
                onClick: (event: Event, invoice: InvoiceListResponse) => {
                  setTitle(invoice.number);
                  setUrl(getInvoiceUrl(invoice.id));
                  setOpenInvoicePreview(true);
                }
              }
            : undefined
        }
      />
      <AMSPdfViewerDialog
        title={title}
        url={url}
        open={openInvoicePreview}
        onClose={() => setOpenInvoicePreview(false)}
      />
    </>
  );
};

export default InvoicesTableComponent;
