import { AMSButton, AMSConfirmDialog, AMSViewOnlyTextField } from '../../../../helpers/ui';
import {
  AccessPermissions,
  DeliveryRequestStatus,
  SetPricesRequest,
  StockReceivingProcessItemResponse,
  amsV3Service,
  snackbarService
} from '../../../../services';
import { Grid, Paper } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import {
  useFlag,
  usePermissions,
  useStockReceivingProcessItems,
  useStyles
} from '../../../../helpers/hooks';

import DateFnsUtils from '@date-io/date-fns';
import FinalizeStockReceivingProcessTableComponent from './FinalizeStockReceivingProcessTableComponent';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import { getExtendedDeliveryRequestId } from '../../../../helpers/utils';
import { useHistory } from 'react-router-dom';

interface FinalizeStockReceivingProcessComponentProps {
  deliveryRequestId: number;
  supplierInfo: string;
  warehouseInfo: string;
  deliveryDate: string;
  createdDate: string;
  status: DeliveryRequestStatus;
}

const FinalizeStockReceivingProcessComponent = ({
  deliveryRequestId,
  supplierInfo,
  warehouseInfo,
  deliveryDate,
  createdDate,
  status
}: FinalizeStockReceivingProcessComponentProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { stockReceivingProcessItems, setStockReceivingProcessItems } =
    useStockReceivingProcessItems(deliveryRequestId);
  const [openConfirmDialog, setOpenConfirmDialog] = useFlag();
  const [deliveryId, setDeliveryId] = useState<number | null>(null);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_DELIVERY,
    AccessPermissions.CAN_UPDATE_DELIVERY
  ]);

  const willCreateCreditNote = useMemo(() => {
    return stockReceivingProcessItems.some(
      (item: StockReceivingProcessItemResponse) =>
        item.finalQuantity && +item.finalQuantity < +item.documentQuantity
    );
  }, [stockReceivingProcessItems]);

  const submitDeliveryRequestTask = useCallback(async () => {
    const request: SetPricesRequest = {
      items: stockReceivingProcessItems.map((item: StockReceivingProcessItemResponse) => ({
        id: item.id,
        finalQuantity:
          item.finalQuantity && !isNaN(+item.finalQuantity) && +item.finalQuantity >= 0
            ? item.finalQuantity
            : (item.verifiedQuantity ?? '0.000'),
        price: item.price!
      })),
      requestType: 'set-prices'
    };
    const resp = await amsV3Service.updateStockReceivingProcessSetPrices(
      deliveryRequestId,
      request
    );
    if (resp.data) {
      setDeliveryId(resp.data.deliveryId!);
      snackbarService.setSnackbarOpen(true);
      if (willCreateCreditNote) {
        setOpenConfirmDialog(true);
      } else {
        history.push(`/delivery?id=${resp.data.deliveryId!}`);
      }
    }
  }, [
    stockReceivingProcessItems,
    deliveryRequestId,
    willCreateCreditNote,
    setOpenConfirmDialog,
    history
  ]);

  const totalPrice = useMemo(
    () =>
      stockReceivingProcessItems.reduce((res: number, item: StockReceivingProcessItemResponse) => {
        return (res =
          res + +(item.price ?? 0) * +(item.finalQuantity ?? item.verifiedQuantity ?? 0));
      }, 0),
    [stockReceivingProcessItems]
  );

  return (
    <Paper elevation={2} className={classes.paper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <OutlinedDiv label="Данни от документ" disabled={true}>
              <Grid container spacing={2}>
                <Grid item sm={12} md={3} lg={3}>
                  <AMSViewOnlyTextField
                    label="Номер"
                    value={getExtendedDeliveryRequestId(deliveryRequestId)}
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={6}>
                  <AMSViewOnlyTextField label="Склад" value={warehouseInfo} />
                </Grid>
                <Grid item sm={12} md={3} lg={3}>
                  <AMSViewOnlyTextField label="Доставка на" value={deliveryDate} />
                </Grid>
                <Grid item sm={12} md={3} lg={3}>
                  <AMSViewOnlyTextField label="Създадена на" value={createdDate} />
                </Grid>
                <Grid item sm={12} md={4} lg={4}>
                  <AMSViewOnlyTextField label="Доставчик" value={supplierInfo} />
                </Grid>
                <Grid item sm={12} md={2} lg={2}>
                  <AMSViewOnlyTextField
                    label="Обща сума"
                    value={totalPrice ? totalPrice.toFixed(2) : '0.00'}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'right', fontSize: '125%', color: '#3D3D3D' }
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={3} lg={3}>
                  <AMSViewOnlyTextField label="Статус" value={status.label} />
                </Grid>
              </Grid>
            </OutlinedDiv>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <FinalizeStockReceivingProcessTableComponent
                  items={stockReceivingProcessItems}
                  setItems={setStockReceivingProcessItems}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item lg={12} justify="flex-end">
            {(canCreate || canUpdate) && status.key !== DeliveryRequestStatus.DELIVERED.key && (
              <AMSButton
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={submitDeliveryRequestTask}
                text="Запиши"
              />
            )}
          </Grid>
        </Grid>
        <AMSConfirmDialog
          open={openConfirmDialog}
          onConfirm={async () => {
            history.push(`/delivery-request?originalId=${deliveryRequestId}`);
            setOpenConfirmDialog(false);
          }}
          onClose={() => {
            history.push(`/delivery?id=${deliveryId}`);
            setOpenConfirmDialog(false);
          }}
          title={'Създаване на нова заявка за доставка'}
          message={'Желаете ли да създадете нова заявка за доставка с пропуснатите количества?'}
        />
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

export default FinalizeStockReceivingProcessComponent;
