import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { PricingPolicyResponse } from '../../services/api';
import { amsLegacyService } from '../../services';

export interface IPricingPolicies<T> extends IAsyncType<T> {
  pricingPolicies: T;
  setPricingPolicies: Dispatch<SetStateAction<T>>;
}

/**
 *
 * @deprecated
 */
const usePricingPolicies = (search?: string): IPricingPolicies<PricingPolicyResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    PricingPolicyResponse[]
  >(amsLegacyService.getPricingPolicies, [], [search]);
  return {
    pricingPolicies: data,
    setPricingPolicies: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default usePricingPolicies;
