import { AMSV3Api } from '../helpers/openapi-axios-helper';
import { Observable } from '../helpers/observable';
import { UserAccessResponse } from './api-v3';

export interface CurrentUser {
  readonly user?: UserAccessResponse;
}

export enum AccessPermissions {
  CAN_CREATE_ITEM = 'CAN_CREATE_ITEM',
  CAN_READ_ITEMS = 'CAN_READ_ITEMS',
  CAN_READ_ITEM = 'CAN_READ_ITEM',
  CAN_UPDATE_ITEM = 'CAN_UPDATE_ITEM',
  CAN_DELETE_ITEM = 'CAN_DELETE_ITEM',
  CAN_CREATE_PROMOTION = 'CAN_CREATE_PROMOTION',
  CAN_READ_PROMOTIONS = 'CAN_READ_PROMOTIONS',
  CAN_UPDATE_PROMOTION = 'CAN_UPDATE_PROMOTION',
  CAN_DELETE_PROMOTION = 'CAN_DELETE_PROMOTION',
  CAN_CREATE_ITEM_CATEGORY = 'CAN_CREATE_ITEM_CATEGORY',
  CAN_READ_ITEM_CATEGORIES = 'CAN_READ_ITEM_CATEGORIES',
  CAN_READ_ITEM_CATEGORY = 'CAN_READ_ITEM_CATEGORY',
  CAN_UPDATE_ITEM_CATEGORY = 'CAN_UPDATE_ITEM_CATEGORY',
  CAN_DELETE_ITEM_CATEGORY = 'CAN_DELETE_ITEM_CATEGORY',
  CAN_CREATE_TREASURY = 'CAN_CREATE_TREASURY',
  CAN_UPDATE_TREASURY = 'CAN_UPDATE_TREASURY',
  CAN_DELETE_TREASURY = 'CAN_DELETE_TREASURY',
  CAN_CREATE_TRANSACTION = 'CAN_CREATE_TRANSACTION',
  CAN_DELETE_TRANSACTION = 'CAN_DELETE_TRANSACTION',
  CAN_CREATE_DELIVERY = 'CAN_CREATE_DELIVERY',
  CAN_READ_DELIVERIES = 'CAN_READ_DELIVERIES',
  CAN_READ_DELIVERY = 'CAN_READ_DELIVERY',
  CAN_UPDATE_DELIVERY = 'CAN_UPDATE_DELIVERY',
  CAN_DELETE_DELIVERY = 'CAN_DELETE_DELIVERY',
  CAN_CREATE_SUPPLIER = 'CAN_CREATE_SUPPLIER',
  CAN_READ_SUPPLIERS = 'CAN_READ_SUPPLIERS',
  CAN_READ_SUPPLIER = 'CAN_READ_SUPPLIER',
  CAN_UPDATE_SUPPLIER = 'CAN_UPDATE_SUPPLIER',
  CAN_DELETE_SUPPLIER = 'CAN_DELETE_SUPPLIER',
  CAN_CREATE_PARTNER = 'CAN_CREATE_PARTNER',
  CAN_READ_PARTNERS = 'CAN_READ_PARTNERS',
  CAN_READ_PARTNER = 'CAN_READ_PARTNER',
  CAN_UPDATE_PARTNER = 'CAN_UPDATE_PARTNER',
  CAN_CREATE_LEGAL_ENTITY = 'CAN_CREATE_LEGAL_ENTITY',
  CAN_READ_LEGAL_ENTITIES = 'CAN_READ_LEGAL_ENTITIES',
  CAN_READ_LEGAL_ENTITY = 'CAN_READ_LEGAL_ENTITY',
  CAN_UPDATE_LEGAL_ENTITY = 'CAN_UPDATE_LEGAL_ENTITY',
  CAN_DELETE_LEGAL_ENTITY = 'CAN_DELETE_LEGAL_ENTITY',
  CAN_CREATE_PRICING_POLICY = 'CAN_CREATE_PRICING_POLICY',
  CAN_READ_PRICING_POLICIES = 'CAN_READ_PRICING_POLICIES',
  CAN_READ_PRICING_POLICY = 'CAN_READ_PRICING_POLICY',
  CAN_UPDATE_PRICING_POLICY = 'CAN_UPDATE_PRICING_POLICY',
  CAN_DELETE_PRICING_POLICY = 'CAN_DELETE_PRICING_POLICY',
  CAN_CREATE_COMPANY_CATEGORY = 'CAN_CREATE_COMPANY_CATEGORY',
  CAN_READ_COMPANY_CATEGORIES = 'CAN_READ_COMPANY_CATEGORIES',
  CAN_READ_COMPANY_CATEGORY = 'CAN_READ_COMPANY_CATEGORY',
  CAN_UPDATE_COMPANY_CATEGORY = 'CAN_UPDATE_COMPANY_CATEGORY',
  CAN_DELETE_COMPANY_CATEGORY = 'CAN_DELETE_COMPANY_CATEGORY',
  CAN_CREATE_ORDER = 'CAN_CREATE_ORDER',
  CAN_READ_ORDERS = 'CAN_READ_ORDERS',
  CAN_READ_ORDER = 'CAN_READ_ORDER',
  CAN_UPDATE_ORDER = 'CAN_UPDATE_ORDER',
  CAN_DELETE_ORDER = 'CAN_DELETE_ORDER',
  CAN_READ_REPORTS = 'CAN_READ_REPORTS',
  CAN_READ_INVOICES = 'CAN_READ_INVOICES',
  CAN_READ_INVOICE = 'CAN_READ_INVOICE',
  CAN_DELETE_INVOICE = 'CAN_DELETE_INVOICE',
  CAN_CREATE_INVOICE = 'CAN_CREATE_INVOICE',
  CAN_READ_REPORTS_DELIVERY_INVOICE = 'CAN_READ_REPORTS_DELIVERY_INVOICE',
  CAN_READ_REPORTS_STOCK = 'CAN_READ_REPORTS_STOCK',
  CAN_READ_REPORTS_ORDER_QUANTITIES = 'CAN_READ_REPORTS_ORDER_QUANTITIES',
  CAN_READ_REPORTS_DELIVERY_ITEM = 'CAN_READ_REPORTS_DELIVERY_ITEM',
  CAN_READ_REPORTS_ORDER_ITEM = 'CAN_READ_REPORTS_ORDER_ITEM',
  CAN_READ_REPORTS_PROFIT_RATIO = 'CAN_READ_REPORTS_PROFIT_RATIO',
  CAN_READ_REPORTS_PRICING_POLICY = 'CAN_READ_REPORTS_PRICING_POLICY',
  CAN_READ_REPORTS_ORDER_BALANCE = 'CAN_READ_REPORTS_ORDER_BALANCE',
  CAN_READ_REPORTS_WAREHOUSE_RESERVE = 'CAN_READ_REPORTS_WAREHOUSE_RESERVE',
  CAN_VIEW_FINANCE_DASHBOARD = 'CAN_VIEW_FINANCE_DASHBOARD',
  CAN_CHANGE_ORDER_USER_RESPONSIBLE = 'CAN_CHANGE_ORDER_USER_RESPONSIBLE',
  CAN_CHANGE_ORDER_ITEM_PRICES = 'CAN_CHANGE_ORDER_ITEM_PRICES',
  CAN_DO_ORDER_CHECK = 'CAN_DO_ORDER_CHECK',
  CAN_PICK_ORDER = 'CAN_PICK_ORDER',
  CAN_READ_ALL_LEGAL_ENTITIES = 'CAN_READ_ALL_LEGAL_ENTITIES',
  CAN_READ_ALL_PRICING_POLICIES = 'CAN_READ_ALL_PRICING_POLICIES',
  CAN_MANAGE_ITEM_DELIVERY_SUBSCRIPTION = 'CAN_MANAGE_ITEM_DELIVERY_SUBSCRIPTION',
  CAN_MANAGE_SUPPLIER_RESERVE_SUBSCRIPTION = 'CAN_MANAGE_SUPPLIER_RESERVE_SUBSCRIPTION',
  CAN_READ_STOCKTAKING_REPORTS = 'CAN_READ_STOCKTAKING_REPORTS',
  CAN_CREATE_STOCKTAKING = 'CAN_CREATE_STOCKTAKING'
}

export class AccessManagementService {
  readonly user = new Observable<CurrentUser>({});
  readonly permissions = new Observable<{ permissions: AccessPermissions[] }>({ permissions: [] });

  currentUser: CurrentUser | null = null;
  activePermissions: AccessPermissions[] = [];

  hasPermission = (permission: AccessPermissions) => this.activePermissions.includes(permission);

  getMe = async () => {
    const axios = await AMSV3Api();
    const resp = await axios.getMeMeGet();
    if (resp) {
      this.currentUser = { user: resp.data };
      this.user.set({ user: resp.data });
      if (resp.data?.permissions) {
        this.activePermissions = resp.data.permissions.map(
          (permissionName: string) =>
            AccessPermissions[permissionName as keyof typeof AccessPermissions]
        );
        this.permissions.set({
          permissions: this.activePermissions
        });
      }
    }
  };

  logout = async () => {
    this.currentUser = null;
    this.user.set({});
    this.permissions.set({ permissions: [] });
  };
}
