import {
  DeliveryRequestResponse,
  DeliveryRequestStatus,
  DeliveryRequestStatusKey,
  amsV3Service,
  getDeliveryRequestStatusByKey
} from '../../../services';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getExtendedSupplierId, getExtendedWarehouseId } from '../../../helpers/utils';
import { useDeliveryRequest, useDeliveryRequestItems } from '../../../helpers/hooks';

import { CircularProgress } from '@material-ui/core';
import CreateStockReceivingProcessComponent from './CreateStockReceivingProcessComponent/CreateStockReceivingProcessComponent';
import EditStockReceivingProcessComponent from './EditStockReceivingProcessComponent/EditStockReceivingProcessComponent';
import FinalizeStockReceivingProcessComponent from './FinalizeStockReceivingProcessComponent/FinalizeStockReceivingProcessComponent';
import { isoToDatetimeString } from '../../../helpers/date-helper';

interface StockReceivingProcessComponentProps {
  id: number;
}

interface DeliveryRequestMetaData {
  supplierId: number;
  supplierName: string;
  warehouseId: number;
  warehouseName: string;
}

const StockReceivingProcessComponent = ({ id }: StockReceivingProcessComponentProps) => {
  const { deliveryRequest, setDeliveryRequest } = useDeliveryRequest(id);
  const { deliveryRequestItems } = useDeliveryRequestItems(id);

  const [metaData, setMetaData] = useState<DeliveryRequestMetaData>();

  const loadMetaData = useCallback(
    async (delivery: DeliveryRequestResponse) => {
      const [supplierResp, warehouseResp] = await Promise.all([
        amsV3Service.getSupplier(delivery.supplierId),
        amsV3Service.getWarehouse(delivery.warehouseId)
      ]);
      setMetaData({
        supplierId: supplierResp.data.id,
        supplierName: supplierResp.data.name,
        warehouseId: warehouseResp.data.id,
        warehouseName: warehouseResp.data.name
      });
    },
    [setMetaData]
  );

  useEffect(() => {
    if (deliveryRequest) {
      loadMetaData(deliveryRequest);
    }
  }, [deliveryRequest, loadMetaData]);

  const supplierInfo = useMemo(
    () => `${getExtendedSupplierId(metaData?.supplierId ?? 0)} ${metaData?.supplierName ?? ''}`,
    [metaData]
  );

  const warehouseInfo = useMemo(
    () => `${getExtendedWarehouseId(metaData?.warehouseId ?? 0)} ${metaData?.warehouseName ?? ''}`,
    [metaData]
  );

  const deliveryDate = useMemo(
    () => (deliveryRequest?.deliveryDate ? isoToDatetimeString(deliveryRequest.deliveryDate) : ''),
    [deliveryRequest]
  );

  const createdDate = useMemo(
    () => (deliveryRequest?.createdDate ? isoToDatetimeString(deliveryRequest.createdDate) : ''),
    [deliveryRequest]
  );

  const status = useMemo(
    () => getDeliveryRequestStatusByKey(deliveryRequest?.status as DeliveryRequestStatusKey),
    [deliveryRequest]
  );

  if (!deliveryRequest) {
    return <CircularProgress />;
  }

  if (
    status === DeliveryRequestStatus.REQUESTED ||
    status === DeliveryRequestStatus.DELAYED ||
    status === DeliveryRequestStatus.CANCELLED
  ) {
    return (
      <CreateStockReceivingProcessComponent
        deliveryRequestId={deliveryRequest.id}
        supplierId={deliveryRequest?.supplierId ?? 0}
        supplierInfo={supplierInfo}
        warehouseInfo={warehouseInfo}
        deliveryDate={deliveryDate}
        createdDate={createdDate}
        status={status}
        items={deliveryRequestItems}
      />
    );
  }

  if (
    status === DeliveryRequestStatus.FOR_INSPECTION ||
    status === DeliveryRequestStatus.INSPECTION_IN_PROGRESS ||
    status === DeliveryRequestStatus.INSPECTED ||
    status === DeliveryRequestStatus.INSPECTED_WITH_REMARKS ||
    status === DeliveryRequestStatus.DELIVERED ||
    status === DeliveryRequestStatus.DECLINED_AFTER_VERIFICATION
  ) {
    return (
      <EditStockReceivingProcessComponent
        deliveryRequest={deliveryRequest}
        setDeliveryRequest={setDeliveryRequest}
        supplierInfo={supplierInfo}
        warehouseInfo={warehouseInfo}
        deliveryDate={deliveryDate}
        createdDate={createdDate}
        status={status}
        allowedSubsequentStatuses={deliveryRequest.allowedSubsequentStatuses.map((statusKey) =>
          getDeliveryRequestStatusByKey(statusKey as DeliveryRequestStatusKey)
        )}
      />
    );
  }
  return (
    <FinalizeStockReceivingProcessComponent
      deliveryRequestId={deliveryRequest!.id}
      supplierInfo={supplierInfo}
      warehouseInfo={warehouseInfo}
      deliveryDate={deliveryDate}
      createdDate={createdDate}
      status={status}
    />
  );
};

export default StockReceivingProcessComponent;
