import { DeliveryDocumentType, DocumentTypes, amsV3Service } from '../../../services';

import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import { AMSTable } from '../../../helpers/ui';
import React from 'react';
import { ReportDeliveryDocumentResponse } from '../../../services/api';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';

interface DeliveryDocumentsTableComponentProps {
  deliveryDocuments: ReportDeliveryDocumentResponse[];
  loading?: boolean;
}

const DeliveryDocumentsTableComponent = ({
  deliveryDocuments,
  loading = false
}: DeliveryDocumentsTableComponentProps) => {
  const history = useHistory();
  return (
    <AMSTable
      title="Фактури по доставки"
      isLoading={loading}
      columns={[
        {
          title: 'Тип',
          field: 'deliveryDocumentType',
          render: (rowData: ReportDeliveryDocumentResponse) =>
            amsV3Service.getDeliveryDocumentTypeAlias(
              rowData.deliveryDocumentType as unknown as DeliveryDocumentType
            ),
          cellStyle: { width: '10%' }
        },
        {
          title: 'Създадена на',
          field: 'createdDate',
          cellStyle: { width: '12%' }
        },
        {
          title: 'Номер',
          field: 'number',
          type: 'string',
          cellStyle: { width: '10%' },
          render: (rowData: ReportDeliveryDocumentResponse) => (
            <AMSLink href={`/delivery?id=${rowData.deliveryId}&tabId=1`}>{rowData.number}</AMSLink>
          )
        },
        {
          title: 'Издател',
          field: 'senderName',
          cellStyle: { width: '15%' }
        },
        {
          title: 'Плащане до',
          field: 'paymentDue',
          cellStyle: { width: '12%' }
        },
        {
          title: 'Юридическо лице',
          field: 'receiverName',
          cellStyle: { width: '15%' }
        },
        {
          title: 'Сума',
          field: 'totalIncludingVat',
          cellStyle: { width: '10%' },
          align: 'right',
          type: 'currency',
          currencySetting: {
            locale: 'bg',
            currencyCode: 'bgn',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        },
        {
          title: 'Начин на плащане',
          field: 'paymentOption',
          cellStyle: { width: '16%' },
          align: 'right'
        },
        {
          title: 'За плащане',
          field: 'remainingAmount',
          cellStyle: { width: '10%' },
          align: 'right',
          type: 'currency',
          currencySetting: {
            locale: 'bg',
            currencyCode: 'bgn',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        }
      ]}
      paging={false}
      overflowY="scroll"
      minBodyHeight="65vh"
      maxBodyHeight="65vh"
      data={deliveryDocuments.map((deliveryDocument: ReportDeliveryDocumentResponse) => ({
        ...deliveryDocument,
        remainingAmount:
          deliveryDocument.deliveryDocumentType === DocumentTypes.CREDIT_NOTE &&
          deliveryDocument.remainingAmount > 0
            ? -deliveryDocument.remainingAmount
            : deliveryDocument.remainingAmount,
        totalIncludingVat:
          deliveryDocument.deliveryDocumentType === DocumentTypes.CREDIT_NOTE
            ? -deliveryDocument.totalIncludingVat
            : deliveryDocument.totalIncludingVat
      }))}
      customAction={{
        icon: () => (
          <VisibilityIcon
            style={{
              color: '#252525 !important'
            }}
          />
        ),
        tooltip: 'Преглед на плащания и фактура',
        onClick: (event: Event, rowData: any) =>
          history.push(`/delivery?id=${rowData.deliveryId}&tabId=1`)
      }}
    />
  );
};

export default DeliveryDocumentsTableComponent;
