import { IAsyncType, useAsync } from '.';
import {
  PaginatedListResponseTreasuryListResponse,
  TreasuryListResponse,
  amsV3Service
} from '../../services';

export interface IV3Treasuries<T> extends IAsyncType<T> {
  treasuries: T;
}

const useTreasuries = (
  ids?: number[] | undefined,
  companyId?: number
): IV3Treasuries<TreasuryListResponse[]> => {
  const { data, loading, setLoading, parameters, setParameters } =
    useAsync<PaginatedListResponseTreasuryListResponse>(
      amsV3Service.getTreasuries,
      {
        data: [],
        total: 0,
        page: 0,
        size: 0
      },
      [ids, companyId],
      true
    );
  return {
    treasuries: data?.data ?? [],
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useTreasuries;
